//import CSS
import '../../../../CSS/Hotel.scss';

import { Button, Card, Col, Empty, Form, Icon, Pagination, Row, Skeleton } from 'antd';
import queryString from 'query-string';
//import library
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//import Actions
import { searchHotel, setHotelSorting } from '../../../../Actions/HotelAction';
//import Model
import { Sort, Travel } from '../../../../Enums/Types';
import ListItem from './../../../commond/ListItem';
import FilterForm from '../../../commond/FilterForm';
import FormSearch from './HotelFormSearch';

class HotelSearch extends React.Component {
  paginationSize = 30;
  paginationSizeMinus1 = this.paginationSize - 1;

  state = {
    //search parameters
    locationName: null,
    locationCode: null,
    adults: [2],
    children: [0],
    ages: [0],
    night: 0,
    first: 0,
    last: this.paginationSizeMinus1,
    checkInDefault: null,
    checkoutDefault: null,
    //show result
    formValidationRequest: true,
    showHotelResult: false,
    dateInfo: null,
    NationalityCode: 0,
    typeSearch: null,
    //state sort
    salePrice: true,
    name: true,
    rating: true,
    //clickedSort
    sortSelect: 'salePrice',
    searched: false,
  };
  //handleSort
  handleSort = (type, reserveMode) => {
    this.setState(
      (prevState) => {
        return { [type]: !prevState[type], sortSelect: type };
      },
      () => {
        this.props.setHotelSorting({ typeSort: type, isReverse: reserveMode });
      }
    );
  };
  //receive page number form Page Component
  handleChangeValue = (e) => {
    const last = e * this.paginationSize - 1;
    const first = last - this.paginationSizeMinus1;
    this.setState({ first: first, last: last });
  };

  componentDidMount() {
    //if in this page we have Search Param URL we call get hotel to search
    if (this.props.location.search) {
      this.getHotel();
      this.setState({
        first: 0,
        last: this.paginationSizeMinus1,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search) {
      if (this.props.location.search !== prevProps.location.search) {
        this.setState({
          first: 0,
          last: this.paginationSizeMinus1,
        });
        //if in this page available if user change URL we have to search again
        this.getHotel();
      }
    }
    if (this.props.hotels !== prevProps.hotels) {
      this.setState({
        first: 0,
        last: this.paginationSizeMinus1,
      });
    }
  }

  //convert URL to Search Param and call Search Action
  getHotel = () => {
    //first we take URL and convert it to URLSearchParam
    let parameters = queryString.parse(this.props.location.search);
    let params = new URLSearchParams();
    parameters.Adults.split(',').forEach((item) => params.append('adults', item));
    parameters.Children.split(',').forEach((item) => params.append('children', item));
    if (parameters.Ages && parameters.Ages.length !== 0) {
      parameters.Ages.split(',').forEach((item) => params.append('ages', item));
    }
    params.append('LocationId', parameters.LocationId);
    params.append('checkin', parameters.CheckIn);
    params.append('checkout', parameters.CheckOut);
    if (parameters.NationalityCode) {
      params.append('NationalityCode', parameters.NationalityCode);
    }
    //pass URLParam to Actions for Search
    this.props.searchHotel(`?${params.toString()}`);
    //change default value and read their new value from URL
    this.setState({
      showHotelResult: true,
      searched: true,
    });
  };

  render() {
    return (
      <>
        <div className={this.state.showHotelResult ? 'hidden-xs' : null}>
          <FormSearch />
        </div>
        <div className="resultssection">
          <div className="container">
            <div className="vr-padding">
              <Row gutter={15}>
                <Col lg={6} md={8} sm={24} xs={24}>
                  {this.props.showHotelLoad ? (
                    <div className="filterholder">
                      <Card title={'Filter'}>
                        <Skeleton active paragraph={{ rows: 3 }} />
                        <Skeleton active paragraph={{ rows: 3 }} />
                        <Skeleton active paragraph={{ rows: 3 }} />
                        <Skeleton active paragraph={{ rows: 3 }} />
                        <Skeleton active paragraph={{ rows: 3 }} />
                      </Card>
                    </div>
                  ) : this.state.showHotelResult ? (
                    <FilterForm />
                  ) : null}
                </Col>
                <Col lg={18} md={16} sm={24} xs={24} className={'sortContainer'}>
                  <div>
                    <div className="margin-bottom">
                      {this.state.showHotelResult && this.props.hotels && this.props.hotels.length > 0 ? (
                        <Row gutter={[10, 10]}>
                          <Col lg={10} xs={24} className={'buttonSort'}>
                            <Button.Group>
                              <Button
                                disabled={!(this.props.hotels && this.props.hotels.length > 0)}
                                type={this.state.sortSelect === 'salePrice' ? 'primary' : null}
                                value={0}
                                onClick={() => {
                                  this.handleSort(Sort.price, this.state.salePrice);
                                }}>
                                <span> Price {!this.state.salePrice ? <Icon type="caret-down" /> : <Icon type="caret-up" />} </span>
                              </Button>
                              <Button
                                disabled={!(this.props.hotels && this.props.hotels.length > 0)}
                                type={this.state.sortSelect === 'name' ? 'primary' : null}
                                value={1}
                                onClick={() => {
                                  this.handleSort(Sort.name, this.state.name);
                                }}>
                                <span>Name {!this.state.name ? <Icon type="caret-down" /> : <Icon type="caret-up" />} </span>
                              </Button>
                              <Button
                                disabled={!(this.props.hotels && this.props.hotels.length > 0)}
                                type={this.state.sortSelect === 'rating' ? 'primary' : null}
                                value={2}
                                onClick={() => {
                                  this.handleSort(Sort.rate, this.state.rating);
                                }}>
                                <span>Rate {!this.state.rating ? <Icon type="caret-down" /> : <Icon type="caret-up" />} </span>
                              </Button>
                            </Button.Group>
                          </Col>
                          <Col lg={14} xs={24} className={'paginationTop'}>
                            <Pagination
                              showLessItems={true}
                              className="pull-right"
                              pageSize={this.paginationSize}
                              current={(this.state.last + 1) / this.paginationSize}
                              total={this.props.hotels.length}
                              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                              onChange={this.handleChangeValue}
                            />
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                  </div>
                  {this.props.showHotelLoad ? (
                    <>
                      <Card className="margin-bottom">
                        <Skeleton active paragraph={{ rows: 3 }} />
                      </Card>
                      <Card className="margin-bottom">
                        <Skeleton active paragraph={{ rows: 3 }} />
                      </Card>
                      <Card className="margin-bottom">
                        <Skeleton active paragraph={{ rows: 3 }} />
                      </Card>
                      <Card className="margin-bottom">
                        <Skeleton active paragraph={{ rows: 3 }} />
                      </Card>
                    </>
                  ) : this.state.showHotelResult ? (
                    <>
                      {this.props.hotels && this.props.hotels.length > 0 ? (
                        <>
                          <ListItem data={this.props.hotels} last={this.state.last} start={this.state.first} type={Travel.hotel} />
                          <Pagination
                            showLessItems={true}
                            className="pull-right"
                            pageSize={this.paginationSize}
                            current={(this.state.last + 1) / this.paginationSize}
                            total={this.props.hotels.length}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            onChange={this.handleChangeValue}
                          />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Col>
                {!this.props.showHotelLoad && this.state.showHotelResult && this.props.hotels.length === 0 ? (
                  <Col xs={24}>
                    <Empty
                      description={
                        <>
                          {' '}
                          <h3>No hotels found for your request.</h3>
                          <div>Please, try again.</div>
                        </>
                      }
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const WrappedSearchHotelForm = Form.create({
  name: 'normal_login',
})(HotelSearch);
const WithRouter = withRouter(WrappedSearchHotelForm);
//MapToProp
const MapStateToProp = (state) => ({
  hotels: state.Hotel.hotels,
  showHotelLoad: state.Hotel.loadingHotel,
});
const MapDispatchToProp = (dispatch) => ({
  searchHotel: (param) => {
    dispatch(searchHotel(param));
  },
  setHotelSorting: (param) => {
    dispatch(setHotelSorting(param));
  },
});
export default connect(MapStateToProp, MapDispatchToProp)(WithRouter);
