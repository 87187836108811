//import actionType and type
import { PaymentActions } from '../Enums/ActionTypes';
import { Payment as PaymentModel } from '../Enums/Types';
//Payment reducer
export const Payment = (state = new PaymentModel(), action) => {
  switch (action.type) {
    case PaymentActions.setReserveID:
      return {
        ...state,
        reserveId: action.input.reserveId,
        username: action.input.username,
      };
    case PaymentActions.setReserveInformation:
      return {
        ...state,
        reserverInformation: { ...action.input },
      };
    case PaymentActions.setPaymentInformation:
      return {
        ...state,
        paymentList: action.input,
      };
    case PaymentActions.setDeposits:
      return {
        ...state,
        depositList: action.input,
      };
    case PaymentActions.setDepositsGateways:
      return {
        ...state,
        depositBanks: action.input,
      };
    case PaymentActions.setDepositBalance:
      return {
        ...state,
        depositBalance: action.input,
      };
    case PaymentActions.setBookStatus:
      return {
        ...state,
        bookStatus: action.input,
      };
    case PaymentActions.bookErrorMessage:
      return {
        ...state,
        bookErrorMessage: action.input,
      };
    default:
      return {
        ...state,
      };
  }
};
