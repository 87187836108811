import { Card, Col, Row } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import defaultRoomImage from '../../../../../Images/design/hotelNoImage.svg';
import HotelBundlePrice from '../HotelBundlePrice';
import HotelBoardPolicy from './HotelBoardPolicy';
import HotelBookButton from './HotelBookButton';

HotelMultipleRoom.propTypes = {
  rooms: PropTypes.array.isRequired,
  rates: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

function HotelMultipleRoom(props) {
  return (
    <Card className={'margin-bottom multiple-rooms'}>
      <div className={'multy-rooms-titles'}>
        {props.rooms.map((room, index) => {
          return (
            <div key={index} className={'items'}>
              <strong>
                {props.rooms.indexOf(room) + 1}. {room.name}
              </strong>
              <div>
                <div className={'inline-block'}>
                  <img src={defaultRoomImage} className={'room-image'} alt={'room'} />
                </div>
                <div className={'inline-block'}>
                  <div className={'list-label'}>
                    {room.adults} adults , {room.children} children
                  </div>
                  {/*<div className={"list-label"}>*/}
                  {/*    {room.name}*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <Row className="hidden-xs">
          <Col xs={24} sm={12} className={'list-label'}>
            Select an option for these rooms
          </Col>
          <Col xs={24} sm={6} className={'list-label'}>
            Total for {props.rooms.length} rooms
          </Col>
        </Row>
        {props.rates.map((rate, index) => {
          return (
            <div key={index} className={'margin-bottom option-item'}>
              <Row type="flex">
                <Col xs={24} sm={12}>
                  <HotelBoardPolicy rate={rate} date={props.date} rooms={props.rooms} />
                  <div className={'rooms-capacity'}>
                    {props.rooms.length} rooms: {_.sumBy(props.rooms, (room) => room.adults)} adults , {_.sumBy(props.rooms, (room) => room.children)} child
                  </div>
                </Col>
                <Col xs={24} sm={6}>
                  <HotelBundlePrice rate={rate} date={props.date} currency={props.currency} />
                </Col>
                <Col xs={24} sm={6}>
                  <HotelBookButton
                    className={`button white-btn full-width relative supplier-color _${_.padStart(rate.supplierType, 3, 0)}`}
                    rate={rate}
                    onClick={props.onSelectRoom}>
                    Book {props.rooms.length} rooms
                  </HotelBookButton>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </Card>
  );
}

export default HotelMultipleRoom;
