import { Button, Card, Col, Form, Icon, Input, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { changePassword } from './../../../../Actions/AccountAction';

class ChangePassword extends React.Component {
  state = {
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
    hideForm: true,
  };
  //handle form
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        this.props.changePassword({ current: this.state.currentPassword, new: this.state.confirmPassword });
      }
    });
  };
  //validate functions
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('You should enter new password again,two new password not equal!!');
    } else {
      callback();
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Card
        className="margin-bottom"
        title={
          <>
            <Icon type="unlock" /> Change your Password
          </>
        }>
        <p>Whant to change your password? just click the button below</p>
        {this.state.hideForm ? (
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              this.setState({ hideForm: false });
            }}>
            Change your password
          </Button>
        ) : (
          <Form onSubmit={this.handleSubmit} className={'change_password'}>
            {/* <Row gutter={15} style={{ marginBottom: '10px' }}>
              <Col xs={24} md={8} className="gutter-row">
                <div className="gutter-box">
                  <label className="form-label">Username</label>
                  <Input prefix={<Icon type="user" className={'user'} />} value={this.props.username} />
                </div>
              </Col>
            </Row> */}
            <Row gutter={15}>
              <Col xs={24} sm={8} className="gutter-row">
                <div className="gutter-box">
                  <label className="form-label">Current password</label>
                  <Form.Item>
                    {getFieldDecorator('currentPassword', {
                      rules: [{ required: true, message: 'Please enter your current password' }],
                    })(
                      <Input.Password
                        prefix={<Icon type="safety" className={'icon_input'} />}
                        placeholder={'current password'}
                        onChange={(e) => {
                          this.setState({ currentPassword: e.target.value });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={8} className="gutter-row">
                <div className="gutter-box">
                  <label className="form-label">New password</label>
                  <Form.Item>
                    {getFieldDecorator('newPassword', {
                      rules: [
                        { required: true, message: 'Please enter your new password' },
                        { min: 6, message: 'your password should have more than 6 characters' },
                      ],
                    })(
                      <Input.Password
                        prefix={<Icon type="safety" className={'icon_input'} />}
                        placeholder={'new password'}
                        onChange={(e) => {
                          this.setState({ newPassword: e.target.value });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={8} className="gutter-row">
                <div className="gutter-box">
                  <label className="form-label">Confirm new password</label>
                  <Form.Item>
                    {getFieldDecorator('confirmPassword', {
                      rules: [
                        { required: true, message: 'Please confirm your new password' },
                        { min: 6, message: 'your password should have more than 6 characters' },
                        { validator: this.compareToFirstPassword },
                      ],
                    })(
                      <Input.Password
                        prefix={<Icon type="safety" className={'icon_input'} />}
                        placeholder={'confirm password'}
                        onChange={(e) => {
                          this.setState({ confirmPassword: e.target.value });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} className="margin-top">
                <Button loading={this.props.showInlineLoad} type="primary" htmlType={'submit'}>
                  Change Password
                </Button>
                <Button
                  htmlType="button"
                  className="margin-left"
                  onClick={() => {
                    this.setState({ hideForm: true });
                  }}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    );
  }
}
//map to props
const mapDispatchToProps = (dispatch) => ({
  changePassword: (params) => dispatch(changePassword(params)),
});
const MapStateToProp = (state) => ({
  showInlineLoad: state.Notification.loading.showInline,
  username: state.User.userName,
});
const WrappedChangePasswordForm = Form.create({ name: 'change_password' })(ChangePassword);
export default connect(MapStateToProp, mapDispatchToProps)(WrappedChangePasswordForm);
