import { Alert, Button, Card, Col, Icon, Result, Row, Skeleton, Spin, Steps, Table } from 'antd';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { IssuePNR, setSelectedFlight } from '../../../../Actions/FlightAction.js';
import { book, clearHotelSelected } from '../../../../Actions/HotelAction';
//import action
import { confirmDeposit, confirmPayment, getDepositBalance, getPaymentList, getReserveByID, sendPaymentMethod, setBookStatus } from '../../../../Actions/PaymentAction';
import { Urls } from '../../../../Enums/APIAddress';
//import URL and Type
import { bookStatus } from '../../../../Enums/Types';
import Price from '../../../commond/Price';
import FlightSummary from '../Flight/FlightSummary';
//import component
import SummaryHotel from '../Hotel/SummaryHotel';
import PaymentList from './PaymentList';

const ShowText = (props) => {
  return (
    <div>
      {props.text} {props.showButton && <Link to={'/tracking/reserves'}>Reserves list</Link>}
    </div>
  );
};

class ChoicePayment extends React.Component {
  state = {
    showSuccess: false,
    selectedBank: false,
    reserveId: null,
  };
  getInit = () => {
    this.props.setSelectedFlight(null);
    let parameters = queryString.parse(this.props.location.search);
    this.props.confirmPayment(parameters);
    this.setState({
      reserveId: parameters.reserveId,
    });
  };

  componentWillMount() {
    this.props.clearHotelSelected();
  }

  componentDidMount() {
    //reset payment status
    this.props.setBookStatus(bookStatus.idle);
    this.getInit();
  }

  bankSelect = (bankID, param) => {
    let urlParams = queryString.parse(this.props.location.search);
    urlParams = urlParams.reserveId;
    let callBackUrl;
    if (this.props.hotelInformation && this.props.hotelInformation.accommodation) {
      callBackUrl = `http://${Urls.Local.Url}/hotel/confirm`;
      //callBackUrl = `http://${Urls.Local.Url}/payment/choice`;
    } else {
      callBackUrl = `http://${Urls.Local.Url}/payment/choice`;
    }
    const params = { reserveId: urlParams, gatewayId: bankID, callbackUrl: callBackUrl, ipAddress: Urls.Local.Url, formParams: { ...param } };

    this.props.sendPaymentMethod(params);
  };
  confirmDeposit = () => {
    let parameters = queryString.parse(this.props.location.search);
    const userName=this.props.hotelInformation?.reserver?.username || this.props.flightInformation?.reserver?.username || parameters.username.trim();
    this.props.confirmDeposit({ reserveId: parameters.reserveId.trim(), username: userName });
  };

  render() {
    const { PaymentInfo, hotelInformation, flightInformation } = this.props;
    const { Step } = Steps;
    const depositHeader = (
      <div>
        <div className="clearfix">
          <div className="pull-left">Deposit Payment</div>
          <span className={'deposit-icon itours-icon'} />
        </div>
      </div>
    );
    let flightPassengersList = [];
    if (flightInformation) {
      flightPassengersList = flightInformation.passengers;
    }
    const flightPassengersTableHead = [
      {
        title: 'Type',
        dataIndex: 'passengerType',
        key: 'passengerType',
        render: (text, record) => (record.code === 'ADT' ? 'Adult' : record.code === 'CHD' ? 'Child' : record.code === 'INF' ? 'Infant' : record.code),
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'gender',
        render: (text, record) => (record.title ? 'Male' : 'Female'),
      },
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        render: (text, record) => record.firstName + ' ' + record.lastName,
      },
    ];

    return (
      <div className="container vr-padding">
        {PaymentInfo && hotelInformation && hotelInformation.accommodation && (
          <div className={'vr-padding margin-bottom'}>
            {/* % current based on payment status */}
            <Steps current={2}>
              <Step title="Choose your room" />
              <Step title="Enter your details" />
              <Step title="Confirm and payment" />
              <Step title="Complete the purchase" />
            </Steps>
          </div>
        )}
        {/* {
                            (flightInformation) &&
                            <div className={"vr-padding margin-bottom"}>
                                //current based on payment status
                                <Steps current={2}>
                                    <Step title="Choose your flight"/>
                                    <Step title="Enter your details"/>
                                    <Step title="Confirm and payment"/>
                                    <Step title="Complete the purchase"/>
                                </Steps>
                            </div>
                        } */}

        {PaymentInfo ? (
          <>
            {/*show Error in payment*/}
            {this.state.showError && (
              <Alert className="margin-bottom" message="Payment operation failed" description="try again ,  You can call (+47) 22112744 for more tracing." type="error" showIcon />
            )}
            {this.props.bookStatus === bookStatus.inProgress && (
              <Result
                status="success"
                icon={
                  <div className="result-process-loading">
                    <Icon type="loading" />
                  </div>
                }
                title="Booking is in progress!"
                subTitle="It may takes up to 20 minutes, Please do not close this page until the ticket is issued."
                extra={[
                  <div className="margin-bottom-light">
                    <strong className="font-18">Please wait ...</strong>
                  </div>,
                  <div className="red margin-bottom-light">Please do not close this page until the ticket is issued.</div>,
                  <div>For questions regarding this reservation, please contact iTours directly : +47 22112744</div>,
                ]}
              />
            )}
            {this.props.bookStatus === bookStatus.process && (
              <Result
                status="success"
                icon={
                  <div className="result-process-loading">
                    <Icon type="loading" />
                  </div>
                }
                title="Your payment has been successful!"
                subTitle="Your ticket will be issued in a few moments, Please do not close this page until the ticket is issued."
                extra={[
                  <div className="margin-bottom-light">
                    <strong className="font-18">Issuing tickets</strong>
                  </div>,
                  <div className="red margin-bottom-light">Please do not close this page until the ticket is issued.</div>,
                  <div>For questions regarding this reservation, please contact iTours directly : +47 22112744</div>,
                ]}
              />
            )}
            {this.props.bookStatus === bookStatus.done && (
              <Result
                status="success"
                title="Your ticket was successfully issued!"
                subTitle="We wish you a happy and memorable trip"
                extra={[
                  <strong className="font-22 margin-bottom">Your reserve ID: {this.state.reserveId} </strong>,
                  <div className="margin-bottom">
                    {this.props.PaymentInfo.type === 'FlightGlobal' ? (
                      <Link to={`/tracking/reserves/flight?id=${this.state.reserveId}`}>
                        <button type="button" className="button white-btn">
                          Details
                        </button>
                      </Link>
                    ) : (
                      <Link to={`/tracking/reserves/hotel?id=${this.state.reserveId}`}>
                        <button type="button" className="button white-btn">
                          Details
                        </button>
                      </Link>
                    )}
                  </div>,
                  <div className="margin-bottom-light">
                    <ShowText text={<span>You can check your reserves list in </span>} showButton={true} />
                  </div>,
                  <div>For questions regarding this reservation, please contact iTours directly : +47 22112744</div>,
                ]}
              />
            )}
            {this.props.bookStatus === bookStatus.error && (
              <Result
                status="error"
                title={this.props.bookErrorMessage ? 'Booking failed!' : 'Unsuccessful payment!'}
                subTitle={this.props.bookErrorMessage ? this.props.bookErrorMessage : 'There was a problem with the reservation process'}
                extra={[
                  <div className="margin-bottom">
                    {/* % search again */}
                    {this.props.PaymentInfo.type === 'FlightGlobal' ? (
                      <Link to={`/flight`}>
                        <button type="button" className="button white-btn">
                          Search Again
                        </button>
                      </Link>
                    ) : (
                      <Link to={`/hotel`}>
                        <button type="button" className="button white-btn">
                          Search Again
                        </button>
                      </Link>
                    )}
                  </div>,
                  <div>For questions regarding this reservation, please contact iTours directly : +47 22112744</div>,
                ]}
              />
            )}
            {this.props.bookStatus === bookStatus.now && (
              <Result
                status="info"
                title="Your ticket was successfully Booked!"
                subTitle="Your PNR will be issued Automatically and you can see your ticket on reserve page."
                extra={[
                  <strong className="font-22 margin-bottom">Your reserve ID: {this.state.reserveId} </strong>,
                  <div className="margin-bottom">
                    {this.props.PaymentInfo.type === 'FlightGlobal' ? (
                      <Link to={`/tracking/reserves/flight?id=${this.state.reserveId}`}>
                        <button type="button" className="button white-btn">
                          Details
                        </button>
                      </Link>
                    ) : (
                      <Link to={`/tracking/reserves/hotel?id=${this.state.reserveId}`}>
                        <button type="button" className="button white-btn">
                          Details
                        </button>
                      </Link>
                    )}
                  </div>,
                  <div className="margin-bottom-light">
                    <ShowText text={<span>You can check your reserves list in </span>} showButton={true} />
                  </div>,
                  <div>For questions regarding this reservation, please contact iTours directly : +47 22112744</div>,
                ]}
              />
            )}
          </>
        ) : null}

        <Row gutter={[15, 15]}>
          <Col className="choose-payment-sidebar gutter-row pull-right-md" xs={24} md={8} xl={6}>
            <div className="gutter-box">
              {PaymentInfo && hotelInformation && hotelInformation.accommodation && hotelInformation.reserver ? (
                <SummaryHotel model={hotelInformation} currencyCode={hotelInformation.currency.code} showButton={false} totalPrice={null} night={hotelInformation.date.duration} />
              ) : PaymentInfo && flightInformation ? (
                <FlightSummary
                  providerName={flightInformation.providerName}
                  showButton={true}
                  model={flightInformation.airItinerary.originDestinationOptions}
                  currencyCode={flightInformation.currency}
                  fareInfo={null}
                  totalPrice={flightInformation.flightFare.totalFare}
                  feild={null}
                  layout={'sidebar'}
                  paymentPassengerInfo={this.props.flightInformation && this.props.flightInformation.passengersFare}
                  totalbase={this.props.flightInformation && this.props.flightInformation.flightFare.baseFare}
                  totaltax={this.props.flightInformation && this.props.flightInformation.flightFare.totalTaxes}
                />
              ) : (
                <Card title="Booking information" className="light-padding">
                  {/* <div className="hotel-img-titles">
                                            <div className="loading-images hotel-image">
                                                <div className="image" />
                                            </div>
                                            <div className="hotel-name-star full-width">
                                                <Skeleton active paragraph={{ rows: 3 }} />
                                            </div>
                                        </div> */}
                  <Skeleton active paragraph={{ rows: 3 }} />
                  <Skeleton className="no-title-skeleton" active paragraph={{ rows: 3 }} />
                  <div className="border-top">
                    <Skeleton active paragraph={{ rows: 2 }} />
                  </div>
                  <div className="border-top">
                    <Skeleton active paragraph={{ rows: 2 }} />
                  </div>
                </Card>
              )}
            </div>
          </Col>
          <Col className="gutter-row" xs={24} md={16} xl={18}>
            <div className="gutter-box">
              <h5 className={'normal-title'}>
                <Icon type="user" className="margin-right-light" />
                Contact person information
              </h5>
              {/*show hotel reserver's Information*/}
              {PaymentInfo ? (
                <Card title="" className="margin-bottom">
                  <div className="pair-row">
                    <span>Gender: </span>
                    <span>{PaymentInfo.gender ? 'male' : 'female'}</span>
                  </div>
                  <div className="pair-row">
                    <span>First name: </span>
                    <span>{PaymentInfo.firstName}</span>
                  </div>
                  <div className="pair-row">
                    <span>Last name: </span>
                    <span>{PaymentInfo.lastName}</span>
                  </div>
                  <div className="pair-row">
                    <span>Email: </span>
                    <span>{PaymentInfo.email}</span>
                  </div>
                  <div className="pair-row">
                    <span>Phone number: </span>
                    <span>{PaymentInfo.phoneNumber}</span>
                  </div>
                </Card>
              ) : (
                <Card>
                  <Skeleton active paragraph={{ rows: 5 }} className="no-title-skeleton" />
                </Card>
              )}
              {PaymentInfo && flightInformation ? (
                <>
                  <h5 className={'normal-title'}>
                    <Icon type="user" className="margin-right-light" />
                    Passengers information
                  </h5>
                  <div className="margin-bottom box-border no-border-bottom">
                    <Table columns={flightPassengersTableHead} dataSource={flightPassengersList} rowKey="passengerId" pagination={false} />
                  </div>
                </>
              ) : PaymentInfo && hotelInformation ? null : (
                <Card className="margin-top margin-bottom">
                  <Skeleton active paragraph={{ rows: 2 }} className="no-title-skeleton" />
                </Card>
              )}

              {(this.props.bookStatus === bookStatus.error || this.props.bookStatus === bookStatus.idle) && (
                <>
                  <h5 className="normal-title">
                    <Icon type="dollar" className="margin-right-light" />
                    Payment method
                  </h5>
                  <div>
                    {this.props.banks ? (
                      this.props.currency === 'IRR' && <PaymentList banks={this.props.banks} selectedBankthis={this.bankSelect} />
                    ) : (
                      <Card
                        title={
                          <div className="clearfix">
                            <div className="pull-left">Direct Payment</div>
                            <span className={'deposit-icon itours-icon'} />
                          </div>
                        }
                        className="deposit-card margin-bottom">
                        <div className="gateway-item">
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} avatar />
                        </div>
                      </Card>
                    )}
                  </div>

                  {this.props.DepositInfo ? (
                    this.props.permissions &&
                    this.props.permissions.includes('UseDeposit') && (
                      <Card title={depositHeader} className="margin-top margin-bottom deposit-card">
                        <div className="clearfix">
                          <Button className="pull-right" type="danger" htmlType="button" onClick={() => this.confirmDeposit()}>
                            Pay by Deposit
                          </Button>
                          <div>
                            <b className="margin-right">Remaining:</b>
                            <Price currency={this.props.DepositInfo.currencyType}>{this.props.DepositInfo.amount}</Price>
                          </div>
                        </div>
                      </Card>
                    )
                  ) : (
                    <Card
                      title={
                        <div className="clearfix">
                          <div className="pull-left">Deposit Payment</div>
                          <span className={'deposit-icon itours-icon'} />
                        </div>
                      }
                      className="deposit-card margin-bottom">
                      <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />
                    </Card>
                  )}
                </>
              )}

              {PaymentInfo && this.props.bookStatus !== bookStatus.idle && this.props.bookStatus === bookStatus.error && (
                <div className="status-box error margin-bottom">
                  <div className="icon-box ">
                    <Icon type="close-circle" className="red" />
                  </div>
                  <div className="details-box">
                    <strong className="title">Unsuccessful payment</strong>
                    <p className="description">Error in processing Payment.</p>
                  </div>
                </div>
              )}

              {PaymentInfo && this.state.showSuccess && this.props.PaymentInfo.type === 'FlightGlobal' && (
                <div className={'SpinPayment'}>
                  <Spin tip={'Ticketing is processing...please wait a moment,you will redirect to ticket details after a few seconds'} />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const MapDispatchToProps = (dispatch) => ({
  getReserveByID: (param) => {
    dispatch(getReserveByID(param));
  },
  getPaymentList: (param) => {
    dispatch(getPaymentList(param));
  },
  book: (param) => {
    dispatch(book(param));
  },
  sendPaymentMethod: (param) => {
    dispatch(sendPaymentMethod(param));
  },
  clearHotelSelected: () => {
    dispatch(clearHotelSelected());
  },
  getDepositBalance: (param) => {
    dispatch(getDepositBalance(param));
  },
  confirmDeposit: (param) => {
    dispatch(confirmDeposit(param));
  },
  IssuePNR: (param) => {
    dispatch(IssuePNR(param));
  },
  setBookStatus: (param) => {
    dispatch(setBookStatus(param));
  },
  confirmPayment: (param) => {
    dispatch(confirmPayment(param));
  },
  setSelectedFlight: (param) => dispatch(setSelectedFlight(param)),
});
const MapStateToProps = (state) => ({
  banks: state.Payment.paymentList,
  PaymentInfo: state.Payment.reserverInformation,
  DepositInfo: state.Payment.depositBalance,
  hotelInformation: state.Hotel.selectedHotel,
  flightInformation: state.Flight.flightSelect,
  currency: state.User.currency,
  bookStatus: state.Payment.bookStatus,
  permissions: state.User.permissions,
  bookErrorMessage: state.Payment.bookErrorMessage,
});
const RouterBind = withRouter(ChoicePayment);
export default connect(MapStateToProps, MapDispatchToProps)(RouterBind);
