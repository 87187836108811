import PropTypes from 'prop-types';
import React from 'react';

import Price from '../../../commond/Price';
import HotelAgentGainPopover from './HotelAgentGainPopover';

HotelBundlePrice.propTypes = {
  rate: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
};

function HotelBundlePrice(props) {
  const hasDiscount = props.rate.discountPrice > 0;

  return (
    <>
      {hasDiscount ? (
        <>
          <div className={'old-price'}>
            <Price currency={props.currency}>{props.rate.regularPrice}</Price>
          </div>
        </>
      ) : null}
      <div className={'new-price'}>
        <Price currency={props.currency}>{props.rate.salePrice}</Price>
        <span className="margin-left-light">
          <HotelAgentGainPopover introPrice={props.rate.introPrice} regularPrice={props.rate.regularPrice} salePrice={props.rate.salePrice} />
        </span>
      </div>
      <div className={'font-12'}>Total for {props.date.duration} night (incl. VAT)</div>
    </>
  );
}

export default HotelBundlePrice;
