import { Table } from 'antd';
import moment from 'moment';
import React from 'react';

import Price from '../commond/Price';

export default class Transaction extends React.Component {
  render() {
    const column = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Confirmed',
        dataIndex: 'isConfirmed',
        key: 'isConfirmed',
        render: (text) => (text ? 'Confirmed' : 'Not Confirmed'),
      },
      {
        title: 'Bank',
        dataIndex: 'gateway',
        key: 'gateway',
        render: (text) => text && text.name,
      },
      {
        title: 'Time',
        dataIndex: 'createDateTime',
        key: 'createDateTime',
        render: (date) => date && moment(date, 'YYYY-MM-DDTHH:mm:SS').format('YYYY/MM/DD'),
      },
      {
        title: 'Transaction Type',
        dataIndex: 'transactionType',
        key: 'transactionType',
      },
      {
        title: 'Transfer Type',
        dataIndex: 'transferType',
        key: 'transferType',
      },
      {
        title: 'Card Number',
        dataIndex: 'cardNumber',
        key: 'cardNumber',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (text) => <Price currency={null}> {text}</Price>,
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        render: (currency) => currency.code,
      },
      {
        title: 'Account Name',
        dataIndex: 'accountName',
        key: 'accountName',
      },
    ];
    return (
      <div className={'Transaction'}>
        <div className={'title'}>Gateways List</div>
        {this.props.model && <Table className="itours-table" dataSource={this.props.model} columns={column} />}
      </div>
    );
  }
}
