import { Badge, Spin, Tag } from 'antd';
import React from 'react';

const Status = (props) => {
  let color, statusText, badgeStatus;
  switch (props.status) {
    case 'Issued':
      color = 'green';
      statusText = 'Issued';
      badgeStatus = 'success';
      break;
    case 'Refunded':
      color = 'purple';
      statusText = 'Refunded';
      badgeStatus = 'purple';
      break;
    case 'Registered':
      color = 'purple';
      statusText = 'Registered';
      badgeStatus = 'purple';
      break;
    case 'PaymentSuccessful':
      color = 'gold';
      statusText = 'Payment Successful';
      badgeStatus = 'gold';
      break;
    case 'WebServiceUnsuccessful':
      color = 'red';
      statusText = 'Web Service Unsuccessful';
      badgeStatus = 'error';
      break;
    case 'Unavailable':
      color = 'red';
      statusText = 'Unavailable';
      badgeStatus = 'error';
      break;
    case 'Undefined':
      color = 'red';
      statusText = 'Undefined';
      badgeStatus = 'error';
      break;
    case 'PriceChange':
      color = 'red';
      statusText = 'Price Change';
      badgeStatus = 'error';
      break;
    case 'Canceled':
      color = '';
      statusText = 'Canceled';
      badgeStatus = 'default';
      break;
    case 'WebServiceCancel':
      color = '';
      statusText = 'Web Service Cancel';
      badgeStatus = 'gold';
      break;
    case 'Pending':
      color = 'blue';
      statusText = 'Pending';
      badgeStatus = 'processing';
      break;
    default:
      color = '';
      statusText = props.status;
      badgeStatus = 'default';
  }

  return !props.status ? (
    <Spin />
  ) : props.displayType === 'Tag' ? (
    <Tag color={color}>{statusText}</Tag>
  ) : props.displayType === 'Badge' ? (
    <Badge status={badgeStatus} text={statusText} />
  ) : (
    statusText
  );
};
export default Status;
