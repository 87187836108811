//import from public library
import { Icon, Menu, Modal, Popover, Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//import Actions
import { changeCurrency, logoutUser } from '../../../../Actions/AccountAction';
import { getPermissions } from '../../../../Actions/AccountAction';
import { getSupplier } from '../../../../Actions/FlightAction';
import { getDeposits } from '../../../../Actions/PaymentAction';
import { Currency, PNRStatus } from '../../../../Enums/Types';
import Price from '../../../commond/Price';

class Header extends React.Component {
  state = {
    //for set current link in Menu
    current: 'home',
    disableURL: false,
    mobileView: false,
    visibleSupport: false,
    userMenuVisible: false,
  };

  closeUserMenu = () => {
    this.setState({
      userMenuVisible: false,
    });
  };

  handleVisibleChange = (userMenuVisible) => {
    this.setState({ userMenuVisible });
  };

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  }; //when click on Menu link ,it active by set current state

  componentDidMount() {
    this.props.getSupplier(true);
    if (window.innerWidth < 850) {
      this.setState({
        mobileView: true,
      });
    }
    let address = window.location.href;
    address = address.split('/')[3];
    this.setState({
      current: address,
    });
  } //when component mount it takes url and active current link
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      let path = this.props.history.location.pathname.split('/');
      if (path[1] === 'payment' || path[1] === 'domesticflight' || (path[1] === 'hotel' && path[2] === 'passenger')) {
        if (path[2] === 'deposit' || path[2] === 'transactions') {
          this.setState({
            disableURL: false,
          });
        } else {
          this.setState({
            disableURL: true,
          });
        }
      } else if (path[1] === 'flight' && path[2] === 'ticketing' && this.props.PNRDetails) {
        if (this.props.PNRDetails.isOutSidePNR && this.props.PNRDetails.pnrStatus === PNRStatus.Made) {
          this.setState({
            disableURL: false,
          });
        } else {
          this.setState({
            disableURL: true,
          });
        }
      } else {
        this.setState({
          disableURL: false,
        });
      }
    }
  }

  render() {
    let selectedCurrencyDeposit;
    let depositBalance = null;
    if (this.props.permissions && this.props.permissions.includes('UseDeposit')) {
      if (this.props.depositList && this.props.depositList.length > 0) {
        selectedCurrencyDeposit = this.props.depositList.filter((item) => item.currencyType === this.props.currency);
      }
      if (this.props.depositList && selectedCurrencyDeposit && selectedCurrencyDeposit.length > 0) {
        depositBalance = (
          <div>
            <span className="grey font-12"> DEPOSIT BALANCE: </span>
            <Price currency={selectedCurrencyDeposit[0].currencyType}>{selectedCurrencyDeposit[0].amount}</Price>
          </div>
        );
      } else {
        depositBalance = (
          <div>
            <span className="grey font-12"> DEPOSIT BALANCE: </span>
            <Price currency={this.props.currency}>0</Price>
          </div>
        );
      }
    }
    return (
      <>
        <Modal title={'Contact Support'} visible={this.state.visibleSupport} onCancel={() => this.setState({ visibleSupport: false })} footer={null} width={1100}>
          <div>
            {this.props.user.nationalityId === 'IR' ? (
              <div className="support-wrapper">
                <div>
                  <a href="tel:02143938" className="contact-link">
                    <span className="phone-icon itours-icon icon-xlarge" />
                    <strong className="contact-title">Call Us</strong>
                    <p>(+98) 21 91008091</p>
                  </a>
                </div>
                <div>
                  <a href="mailto:online@itours.no" className="contact-link">
                    <span className="email-icon itours-icon icon-xlarge" />
                    <strong className="contact-title">Email</strong>
                    <p title="online@itours.no">online@itours.no</p>
                  </a>
                </div>
                {/*<div>*/}
                {/*  <div className="contact-link">*/}
                {/*    <span className="chat-icon itours-icon icon-xlarge" />*/}
                {/*    <strong className="contact-title">Chat</strong>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <div className="contact-link">*/}
                {/*    <span className="whatsapp-icon itours-icon icon-xlarge" />*/}
                {/*    <strong className="contact-title">Whats app</strong>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div>
                  <a href="https://t.me/padideh81agc" className="contact-link">
                    <span className="telegram-icon itours-icon icon-xlarge" />
                    <strong className="contact-title">Telegram</strong>
                  </a>
                </div>
              </div>
            ) : (
              <div className="support-wrapper">
                <div>
                  <a href="tel:02143938" className="contact-link">
                    <span className="phone-icon itours-icon icon-xlarge" />
                    <strong className="contact-title">Call Us</strong>
                    <p>(+47) 22112744</p>
                  </a>
                </div>
                <div>
                  <a href="mailto:info@itours.no" className="contact-link">
                    <span className="email-icon itours-icon icon-xlarge" />
                    <strong className="contact-title">Write Us</strong>
                    <p>info@itours.no</p>
                  </a>
                </div>
                {/*<div>*/}
                {/*  <div className="contact-link">*/}
                {/*    <span className="chat-icon itours-icon icon-xlarge" />*/}
                {/*    <strong className="contact-title">Chat</strong>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <div className="contact-link">*/}
                {/*    <span className="whatsapp-icon itours-icon icon-xlarge" />*/}
                {/*    <strong className="contact-title">Whats app</strong>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div>
                  <a href="https://t.me/padideh81agc" className="contact-link">
                    <span className="telegram-icon itours-icon icon-xlarge" />
                    <strong className="contact-title">Telegram</strong>
                  </a>
                </div>
              </div>
            )}
          </div>
        </Modal>
        <header className="main-header" id="main_header">
          <div className="container">
            <div className="clearfix navbar">
              <div className={'companyInfo'}>
                <Tooltip title={this.props.user.companyName}>
                  <Link to={'/'}>
                    <span className="company-name">{this.props.user.companyName}</span>
                  </Link>
                </Tooltip>
                <div>
                  <span>Account number: </span> {this.props.user.accountId}
                </div>
                {/*Logo*/}
              </div>
              <div className="main-menu">
                <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode={'horizontal'}>
                  <Menu.Item key="home">
                    <Link to={'/'}>
                      <span className="itours-icon icon-small home-icon margin-right-light" />
                      Home
                    </Link>
                  </Menu.Item>

                  {/* comment to hide flight,ethnic,hotel from header links - start*/}
                  {/* <Menu.Item key="flight">
                    <Link to={'/flight?isEthnic=false'}>
                      <span className="itours-icon icon-small flight-icon margin-right-light" />
                      Flight
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="ethnic">
                    <Link to={'/flight?isEthnic=true'}>
                      <span className="itours-icon icon-small flight-icon margin-right-light" />
                      Ethnic
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="hotel">
                    <Link to={'/hotel'}>
                      <span className="itours-icon icon-small hotel-icon margin-right-light" />
                      Hotel
                    </Link>
                  </Menu.Item> */}
                  {/* comment to hide flight,ethnic,hotel from header links - end */}
                  {this.props.permissions && this.props.permissions.includes('ViewBookings') && (
                    <Menu.SubMenu
                      title={
                        <Link to={'/tracking/reserves'} className="menu-main-item">
                          <span className="itours-icon icon-small list-icon margin-right-light" />
                          Bookings
                        </Link>
                      }>
                      <Menu.Item>
                        <Link
                          to={
                            '/tracking/reserves?createFrom&createTo&departureFrom&departureTo&email&lastname&pageNumber=1&pageSize=20&phoneNumber&reference&reserveId&reserveStatus&reserveTypeIds=FLIGHT_GLOBAL'
                          }>
                          Flight
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to={
                            '/tracking/reserves?createFrom&createTo&departureFrom&departureTo&email&lastname&pageNumber=1&pageSize=20&phoneNumber&reference&reserveId&reserveStatus&reserveTypeIds=HOTEL_GLOBAL'
                          }>
                          Hotel
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="ISSUEDReserve">
                        <Link
                          to={
                            '/tracking/reserves?createFrom&createTo&departureFrom&departureTo&email&lastname&pageNumber=0&pageSize=20&phoneNumber&reserveId&reserveStatus=Issued&reserveTypeIds'
                          }>
                          Ticketing Request Queue
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="CANCELEDReserve">
                        <Link
                          to={
                            '/tracking/reserves?createFrom&createTo&departureFrom&departureTo&email&lastname&pageNumber=0&pageSize=20&phoneNumber&reserveId&reserveStatus=Canceled&reserveTypeIds'
                          }>
                          Canceled Queue
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item key="TICKETING">
                      <Link to={'/flight/ticketing'}>Ticketing Flight PNR</Link>
                    </Menu.Item> */}
                    </Menu.SubMenu>
                  )}
                  {this.props.permissions && this.props.permissions.includes('ChargeDeposit') && (
                    <Menu.Item key="ChgargeDeposit">
                      <Link to={'/payment/deposit'}>
                        <span className="itours-icon icon-small wallet-icon margin-right-light" />
                        Charge Deposit
                      </Link>
                    </Menu.Item>
                  )}
                  {this.props.permissions && this.props.permissions.includes('ViewTransactions') && (
                    <Menu.Item key="Transactions">
                      <Link to={'/payment/transactions'}>
                        <span className="itours-icon icon-small list-icon margin-right-light" />
                        Transactions
                      </Link>
                    </Menu.Item>
                  )}
                </Menu>
                {/*Menu*/}
              </div>
              {/*account popover*/}
              <Popover
                className="account-popover"
                title={null}
                content={
                  <div className={'account-popover'}>
                    <Menu onClick={this.handleClick}>
                      <Menu.Item onClick={this.closeUserMenu}>
                        <Link to={'/profile'}>Profile settings</Link>
                      </Menu.Item>
                      {/* <Menu.Item disabled={false}>
                              <Link to={"/company/"} >
                                  Company Management
                              </Link>
                          </Menu.Item> */}
                      {this.props.permissions && this.props.permissions.includes('ManageCompanyAndUser') && (
                        <Menu.Item onClick={this.closeUserMenu}>
                          <Link to={'/user'}>Users Management</Link>
                        </Menu.Item>
                      )}
                      <Menu.Item onClick={this.closeUserMenu}>
                        <Link to={'/commission'}>Commission</Link>
                      </Menu.Item>
                      <Menu.Item onClick={() => this.setState({ visibleSupport: true })}>
                        {' '}
                        <span className="no-link-item">Support</span>{' '}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          this.props.logoutUser();
                        }}>
                        <span className="no-link-item">Logout</span>
                      </Menu.Item>
                    </Menu>
                  </div>
                }
                placement="bottomRight"
                visible={this.state.userMenuVisible}
                onVisibleChange={this.handleVisibleChange}>
                <div className="profile-link-holder">
                  <div className="profile-link-inner">
                    <div className="profile-icon margin-right-light">
                      {!this.props.user.firstName ? (
                        <span className="itours-icon icon-small user-icon hidden-xs" />
                      ) : (
                        <span className="name-avatar hidden-xs">
                          {this.props.user.firstName.substring(0, 1)}
                          {this.props.user.lastName && this.props.user.lastName.substring(0, 1)}
                        </span>
                      )}
                    </div>
                    <div className="text margin-right-light">
                      <div className={'user-full-name'}>{!this.props.user.displayName ? 'User' : `${this.props.user.displayName}`}</div>
                      <div className="deposit">{depositBalance}</div>
                    </div>
                    <Icon type="down" />
                  </div>
                </div>
              </Popover>
              {this.props.currency && (
                <div className="currency-select-holder">
                  <select
                    disabled={this.state.disableURL}
                    defaultValue={this.props.currency}
                    onChange={(e) => {
                      this.props.changeCurrency(e.target.value);
                      window.location.reload();
                    }}>
                    {Currency.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        </header>
      </>
    );
  }
}
//map to prop
const MapStateToProp = (state) => ({
  user: state.User,
  currency: state.User.currency,
  depositList: state.Payment.depositList,
  PNRDetails: state.Flight.PNRDetails,
  permissions: state.User.permissions,
});
const MapDispatchToProp = (dispatch) => ({
  logoutUser: () => {
    dispatch(logoutUser());
  },
  changeCurrency: (currency) => {
    dispatch(changeCurrency(currency));
  },
  getDeposits: () => dispatch(getDeposits()),
  getSupplier: (param) => dispatch(getSupplier(param)),
});
const RouterHeader = withRouter(Header);
export default connect(MapStateToProp, MapDispatchToProp)(RouterHeader);
