//import library
import _ from 'lodash';
import moment from 'moment';

//import action and model
import { FlightActions } from '../Enums/ActionTypes';
import { Filter, FilterType, Flight as FlightModel } from '../Enums/Types';

//Flight Reducer
export const Flight = (state = new FlightModel(), action) => {
  switch (action.type) {
    case FlightActions.SetFlightLoading:
      return {
        ...state,
        loadingFlight: action.input,
      };
    case FlightActions.SaveFlightResult:
      //collect and convert flight result data
      let flights = [];
      let flightsBackup = [];
      let stop = null;
      let flightFilter = new Filter();
      flightFilter.stop.value[0] = 0;
      flightFilter.stop.value[1] = 0;
      flightFilter.stop.value[2] = 0;
      action.input.pricedItineraries.forEach((item) => {
        let segmentStop = [];
        let wayLength = item.airItinerary.originDestinationOptions.length;
        item.airItinerary.originDestinationOptions.forEach((item) => {
          segmentStop.push(item.flightSegments.length);
        });
        let sumStop = segmentStop.reduce((sum, item) => (sum += item));
        if (sumStop === wayLength) {
          stop = 0;
          flightFilter.stop.value[0]++;
        } else if (sumStop > wayLength && sumStop <= wayLength * 2) {
          stop = 1;
          flightFilter.stop.value[1]++;
        } else {
          stop = 2;
          flightFilter.stop.value[2]++;
        }
        //calculate total time travel
        let totaltimeTravel = 0;
        item.airItinerary.originDestinationOptions.forEach((routeitem) => {
          let time = moment.duration(routeitem.journeyDuration);
          totaltimeTravel += time;
        });
        //create flightMode for client
        let flightItem = {
          paymentBeforePNR: item.paymentBeforePNR,
          totaltimeTravel: totaltimeTravel,
          priceInfo: item.airItineraryPricingInfo.itinTotalFare,
          pricePerPerson: item.airItineraryPricingInfo.ptC_FareBreakdown,
          key: item.key,
          totalPrice: item.airItineraryPricingInfo.itinTotalFare.totalFare,
          airItinerary: item.airItinerary.originDestinationOptions,
          stop,
          class: item.airItinerary.originDestinationOptions[0].flightSegments[0].cabinClass.name,
          airline: item.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline.name,
          departureDateTime: item.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime,
          arrivalDateTime:
            item.airItinerary.originDestinationOptions[item.airItinerary.originDestinationOptions.length - 1].flightSegments[
              item.airItinerary.originDestinationOptions[item.airItinerary.originDestinationOptions.length - 1].flightSegments.length - 1
            ].arrivalDateTime,
        };
        flights.push(flightItem);
        flightsBackup.push(flightItem);
        //Prepare Filters
        flightFilter.class.value.push(flightItem.class);
        flightFilter.airline.value.push(flightItem.airline);
      });

      //delete duplicate result
      flightFilter.class.value = _.uniq(flightFilter.class.value);
      flightFilter.airline.value = _.uniq(flightFilter.airline.value);
      //create airline name filter

      // let uniqAirItinerary=[];
      // let leg=flights.length && flights[0].airItinerary.length;
      let airlineGroup = null;
      if (flights.length) {
        airlineGroup = _.groupBy(flights, 'airline');
        for (let airlineitem in airlineGroup) {
          if (airlineGroup.hasOwnProperty(airlineitem)) {
            airlineGroup[airlineitem] = _.groupBy(airlineGroup[airlineitem], function (item) {
              return item.stop ? 'multiStop' : 'noneStop';
            });
            if (airlineGroup[airlineitem]['noneStop']) {
              airlineGroup[airlineitem]['noneStop'] = _.minBy(airlineGroup[airlineitem]['noneStop'], 'totalPrice');
            }
            if (airlineGroup[airlineitem]['multiStop']) {
              airlineGroup[airlineitem]['multiStop'] = _.minBy(airlineGroup[airlineitem]['multiStop'], 'totalPrice');
            }
          }
        }
        let flightFilterArr = [];
        Object.entries(airlineGroup).forEach((item) => flightFilterArr.push(item[1]));
        flightFilter.airlinePrice = flightFilterArr;
      }

      // flights.forEach(item=>{
      //     let cashAirline=[];
      //     //new grouping by first airline name
      //     let airlineTarget=item.airline;
      //
      //     //classic grouping
      //     // item.airItinerary.forEach(airItinerayItem=>airItinerayItem.flightSegments.forEach(segmentItem=>cashAirline.push(segmentItem.marketingAirline.code)));
      //     // if(cashAirline.every((value,index,array)=>value===array[0])){
      //         //let airlineTarget=item.airItinerary[0].flightSegments[0].marketingAirline.code;
      //         if(uniqAirItinerary[airlineTarget]){
      //             if(item.airItinerary.length===item.airItinerary[0].flightSegments.length){
      //                 if(uniqAirItinerary[airlineTarget].noneStop){
      //                     if(item.priceInfo.totalFare < uniqAirItinerary[airlineTarget].noneStop.priceInfo.totalFare){
      //                         uniqAirItinerary[airlineTarget].noneStop=item;
      //                     }
      //                 }else{
      //                     uniqAirItinerary[airlineTarget]={...uniqAirItinerary[airlineTarget],noneStop:item};
      //                 }
      //             }else{
      //                 if(uniqAirItinerary[airlineTarget].multiStop){
      //                     if(item.priceInfo.totalFare < uniqAirItinerary[airlineTarget].multiStop.priceInfo.totalFare){
      //                         uniqAirItinerary[airlineTarget].multiStop=item;
      //                     }
      //                 }else{
      //                     uniqAirItinerary[airlineTarget]={...uniqAirItinerary[airlineTarget],multiStop:item};
      //                 }
      //             }
      //         }else{
      //             if(item.airItinerary.length===item.airItinerary[0].flightSegments.length){
      //                 uniqAirItinerary[airlineTarget]={noneStop:item}
      //             }
      //             else{
      //                 uniqAirItinerary[airlineTarget]={multiStop:item}
      //             }
      //         }
      //
      //     // }
      // });
      //check oneStop or twoStop
      // let tempUniqAirItinerary=Object.entries(uniqAirItinerary);
      // uniqAirItinerary=tempUniqAirItinerary.map(item=>item[1]);
      // flightFilter.airlinePrice=uniqAirItinerary;

      //create flightResult model and filterShow

      return {
        ...state,
        flights: flights,
        flightBackup: flights,
        filterShow: flightFilter,
      };
    case FlightActions.SetFlightFilter:
      let filterSelect = state.filterSelect;

      if (action.input.isAdd) {
        filterSelect[action.input.type].value.push(action.input.value);
      } else {
        filterSelect[action.input.type].value = filterSelect[action.input.type].value.filter((item) => item !== action.input.value);
      }
      return {
        ...state,
        filterSelect: filterSelect,
      };
    case FlightActions.ApplyFlightFilter:
      const filtering = (item) => {
        let status = true;
        for (let filterName in state.filterSelect) {
          let filterItem = state.filterSelect[filterName].value;
          if (filterItem !== null && filterItem.length) {
            if (filterName === 'departureDateTime' || filterName === 'arrivalDateTime') {
              //filter time
              let timeStatus = false;
              filterItem.forEach((itemFilter) => {
                let minTime = moment(itemFilter.split('-')[0], 'HH:mm');
                let maxTime = moment(itemFilter.split('-')[1], 'HH:mm');
                let currentTime = moment(item[filterName].split('T')[1], 'HH:mm:ss');
                if (currentTime.isSameOrAfter(minTime) && currentTime.isSameOrBefore(maxTime)) {
                  timeStatus = true;
                }
              });
              if (!timeStatus) {
                status = false;
              }
            } else if (filterName === 'airlinePrice') {
              //status=false;
              let airlineStatus = false;
              filterItem.forEach((itemFilter) => {
                let airlineFilter = itemFilter.split('|')[0];
                let airlineStop = itemFilter.split('|')[1];
                if (item.airline === airlineFilter) {
                  //detect airline length
                  if (item.stop) {
                    //flight is not direct
                    if (airlineStop === '1') {
                      airlineStatus = true;
                    }
                  } else {
                    //flight is direct
                    if (airlineStop === '0') {
                      airlineStatus = true;
                    }
                  }
                }
              });
              if (airlineStatus) {
                status = true;
              } else {
                status = false;
              }
            } else {
              //convert number value to array
              let valueFilterName = [item[filterName]];
              let differentCount = _.differenceWith(filterItem, valueFilterName, _.isEqual);
              //when if hotel should have all of selected  item in filter choice Intersection but hotel should have some item choice Union
              if (state.filterSelect[filterName].type === FilterType.Union) {
                if (differentCount.length === filterItem.length) {
                  status = false;
                }
              } else if (state.filterSelect[filterName].type === FilterType.Intersection) {
                if (differentCount.length !== 0) {
                  status = false;
                }
              }
            }
          }
        }
        if (status) {
          return item;
        }
      };

      let flightResult = state.flightBackup.filter(filtering);
      return {
        ...state,
        flights: flightResult,
      };
    case FlightActions.SetFlightSelected:
      return {
        ...state,
        flightSelect: action.input,
      };
    case FlightActions.setFlightPNR:
      return {
        ...state,
        PNRInformation: action.input,
      };
    case FlightActions.setPNRDetails:
      return {
        ...state,
        PNRDetails: action.input,
      };
    case FlightActions.sortFlightResult:
      let flightData = JSON.parse(JSON.stringify(state.flights));
      let flightDataBackup = JSON.parse(JSON.stringify(state.flights));
      flightData = _.sortBy(flightData, action.input.typeSort);
      flightDataBackup = _.sortBy(flightDataBackup, action.input.typeSort);
      if (action.input.isReverse) {
        flightData = flightData.reverse();
        flightDataBackup = flightDataBackup.reverse();
      }
      return {
        ...state,
        flights: flightData,
        flightBackup: flightDataBackup,
      };
    case FlightActions.setSupplier:
      return {
        ...state,
        ticketingSupplier: action.input,
      };
    default:
      return {
        ...state,
      };
  }
};
