//import library
import axios from 'axios';

//import API URL
import { Account as AccountURL, Coordinate, HeaderRequest, Urls, getHeaderRequest } from '../Enums/APIAddress';
import { getStorage, setStorage } from '../utils/localstorage';

//for ajax request from server

class Account {
  login = async (param) => {
    try {
      let header = getHeaderRequest(false);
      let response = await axios.post(
        `${Urls.Type}${Urls.User}${AccountURL.Login}`,
        {
          emailOrPhoneNumber: Object.values(param)[0],
          password: Object.values(param)[1],
        },
        { headers: { ...header } }
      );
      return response.data.result;
    } catch (error) {
      return error;
    }
  }; //login pass username and password to API and get user  information
  authentication = async (param) => {
    try {
      let response = await axios.get(`${Urls.Type}${Urls.User}${AccountURL.GetUser}`, { headers: { Authorization: `Bearer ${param}`, ...HeaderRequest } });
      return response.data.result;
    } catch (error) {
      return error;
    }
  }; //send token USER-ID for Authentication USER ID that store in cookies
  sendRecoverEmail = async (param) => {
    let header = getHeaderRequest(false);
    try {
      let response = await axios.post(`${Urls.Type}${Urls.User}${AccountURL.ForgotPassword}`, { emailOrPhoneNumber: Object.values(param)[0] }, { headers: { ...header } });
      return response.data.success;
    } catch (error) {
      return error;
    }
  }; //send email address recover for reset password
  changePassword = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.post(
        `${Urls.Type}${Urls.User}${AccountURL.ChangePassword}`,
        {
          currentPassword: Object.values(param)[0],
          newPassword: Object.values(param)[1],
        },
        { headers: { ...header } }
      );
      return response.data.success;
    } catch (error) {
      return error;
    }
  };
  resetPassword = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.post(
        `${Urls.Type}${Urls.User}${AccountURL.ResetPassword}`,
        {
          userId: Object.values(param)[0],
          password: Object.values(param)[1],
          code: Object.values(param)[2],
        },
        { headers: { ...header } }
      );
      return response.data.success;
    } catch (error) {
      return error;
    }
  };
  requestTenant = async (param) => {
    try {
      let response = await axios.get(`${Urls.Type}${Urls.User}${AccountURL.GetTenant}`, { params: param });
      return response.data.result;
    } catch (error) {
      return error;
    }
  };
  getToken = () => {
    return getStorage('token'); //CookieManage.getCookie("token");
  };
  getTenant = () => {
    return getStorage('ag_it_tenant'); //CookieManage.getCookie("ag_it_tenant");
  };
  setTenant = (value) => {
    return setStorage('ag_it_tenant', value, 86400); //CookieManage.setCookie("ag_it_tenant", value, 30);
  };
  checkTenant = () => {
    let token = getStorage('ag_it_tenant'); // CookieManage.getCookie("ag_it_tenant");
    if (token) {
      return token;
    } else {
      return false;
    }
  };
  editUser = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.put(`${Urls.Type}${Urls.User}${AccountURL.Update}`, { ...param }, { headers: { ...header } });
      return response.data.result;
    } catch (error) {
      return error;
    }
  };

  sendVerificationSms = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.post(`${Urls.Type}${Urls.User}${AccountURL.SendVerificationSms}`, { ...param }, { headers: { ...header } });
      return response.data.result;
    } catch (error) {
      return error;
    }
  };

  CheckVerificationCode = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.post(`${Urls.Type}${Urls.User}${AccountURL.CheckVerification}`, { ...param }, { headers: { ...header } });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
  CheckVerificationStatus = async () => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.get(`${Urls.Type}${Urls.User}${AccountURL.CheckPhoneVerificationStatus}`, { headers: { ...header } });
      return response;
    } catch (error) {
      return error;
    }
  };

  getAffiliate = async (token) => {
    const response = await axios.get(`${Urls.Coordinator}${Coordinate.GetAffiliate}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data.result;
  };
  setCompanyName = (value) => {
    return setStorage('ag_it_company', value, 86400); // CookieManage.setCookie("ag_it_company", value, 30);
  };
  setTerminal = (value) => {
    return setStorage('ag_it_terminal', value, 86400);
    //return CookieManage.setCookie("ag_it_terminal", value, 30);
  };
  getTerminalIDCookie = () => {
    return getStorage('ag_it_terminal');
    //return CookieManage.getCookie("ag_it_terminal");
  };
}

export default new Account();
