import queryString from 'query-string';

export default class objectConverter {
  static jsonDeserialize = (input) => {
    const obj = JSON.parse(input);
    return obj;
  };

  static jsonSerialize = (input) => {
    const json = JSON.stringify(input);
    return json;
  };

  static toQueryString = (input) => {
    const data = queryString.stringify(input);
    return data;
  };
}
