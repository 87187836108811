import { Breadcrumb, Button, Form, Icon, Input, Layout, Modal, Result, Select, Skeleton, Table } from 'antd';
import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';

import { PricingPNR, getPNRDetails } from '../../../../Actions/FlightAction';
import { Account, Urls } from '../../../../Enums/APIAddress';
import { getHeaderRequest } from '../../../../Enums/APIAddress';
import ChangeInformation from './ChangeInformation';
import ChangePassword from './ChangePassword';
import EditUser from './EditUser';
import ResetPassword from './ResetPassword';

const { Option } = Select;

const { Content } = Layout;

class AccountIndex extends React.Component {
  state = {
    data: [],
    visible: false,
    password: '',
    id: '',
    statusUser: 'all',
    Keyword: '',
    visibleDelete: false,
    spinner: true,
    modalVisibility: false,
    modalTitle: '',
    modalDescriptions: '',
    expandedRowsId: [],
  };

  //openModal
  openModal = (option) => {
    this.setState({
      modalVisibility: true,
      modalStatus: option.status,
      modalTitle: option.title,
      modalDescriptions: option.description,
    });
  };
  //closeModal
  closeModal = () => {
    this.setState({
      modalVisibility: false,
      modalStatus: '',
      modalTitle: '',
      modalDescriptions: '',
    });
  };

  //delete user
  confirmDelete = (id) => {
    let header = getHeaderRequest(true);
    axios
      .delete(`${Urls.Type}${Urls.User}${Account.DeleteUsers}?id=${id}`, {
        headers: {
          ...header,
        },
      })
      .then((data) => {
        if (data.error) {
          // notification.info({
          //     message: "error",
          //     description: data.error.message,
          // });
          this.openModal({
            status: 'error',
            title: 'Error',
            description: data.error.message,
          });
        } else {
          this._getData();
          // notification.info({
          //     message: "success",
          //     description: "user deleted",
          // });
          this.openModal({
            status: 'success',
            title: 'Success',
            description: 'user deleted',
          });
        }
        Modal.destroyAll();
      })
      .catch((e) => {
        // notification.info({
        //     message: "error",
        //     description: "error",
        // });
        this.openModal({
          status: 'error',
          title: 'Error',
          description: 'Error',
        });
        Modal.destroyAll();
      });
  };
  delete = (id) => {
    const { confirm } = Modal;
    confirm({
      title: 'delete user',
      content: 'Are you sure to delete?',
      okText: 'Yes',
      cancelText: 'No',
      onCancel: () => Modal.destroyAll(),
      onOk: () => this.confirmDelete(id),
    });
  };
  async componentDidMount() {
    //get data user
    this._getData();
  }
  //get data filter
  _filter = () => {
    let { statusUser, Keyword } = this.state;
    let param = new URLSearchParams();
    param.append('MaxResultCount', 100);
    param.append('SkipCount', 0);
    param.append('Keyword', Keyword);
    if (this.state.statusUser !== 'all') {
      param.append('IsActive', statusUser);
    }
    let header = getHeaderRequest(true);
    axios
      .get(`${Urls.Type}${Urls.User}${Account.GetAll}?${param}`, {
        headers: {
          ...header,
        },
      })
      .then(({ data }) => {
        this.setState({ data: data.result.items });
      });
  };
  //list user
  _getData = () => {
    let req = {
      MaxResultCount: 100,
      SkipCount: 0,
    };
    let param = new URLSearchParams();
    param.append('MaxResultCount', req.MaxResultCount);
    param.append('SkipCount', req.SkipCount);
    let header = getHeaderRequest(true);
    axios
      .get(`${Urls.Type}${Urls.User}${Account.GetAll}?${param}`, {
        headers: {
          ...header,
        },
      })
      .then(({ data }) => {
        this.setState({
          data: data.result.items,
          spinner: false,
        });
      });
  };
  // clear data filter to get all user
  clear = () => {
    this.setState({
      statusUser: 'all',
      Keyword: '',
    });
    this._getData();
  };
  _handleOk = () => {
    let { id, password } = this.state;
    let header = getHeaderRequest(true);
    if (this.state.confirmPassword !== this.state.password) {
      // notification.error({
      //     message: "Error",
      //     description: "The passwords not equal",
      // });
      this.openModal({
        status: 'error',
        title: 'Error',
        description: 'The passwords not equal',
      });
    } else {
      axios
        .post(
          `${Urls.Type}${Urls.User}${Account.ResetPasswordUser}`,
          {
            Id: id,
            NewPassword: password,
          },
          {
            headers: {
              ...header,
            },
          }
        )
        .then((data) => {
          // notification.info({
          //     message: "success",
          //     description: "The password changed",
          // });
          this.openModal({
            status: 'success',
            title: 'Success',
            description: 'The password changed Successfully',
          });
          this.setState({
            visible: false,
            password: '',
            confirmPassword: '',
          });
        });
    }
  };
  //close modal
  _handleCancel = (e) => {
    this.setState({
      visible: false,
      password: '',
      confirmPassword: '',
    });
  };

  onExpand = (expended, record) => {
    const expanddedRows = this.state.expandedRowsId;
    if (expended) {
      expanddedRows.push(record.id);
      this.setState({ expandedRowsId: expanddedRows });
    } else {
      const index = expanddedRows.indexOf(record.id);
      if (index > -1) {
        expanddedRows.splice(index, 1);
        this.setState({ expandedRowsId: expanddedRows });
      }
    }
  };
  render() {
    let { data } = this.state;
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Email Address',
        dataIndex: 'emailAddress',
        key: 'emailAddress',
      },
      {
        title: 'Name',
        key: 'name',
        render: (data) => (
          <span>
            {data.gender ? 'Mr ' : 'Ms '}
            {data.firstName} {data.lastName}
          </span>
        ),
      },
      {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
      },
      {
        title: 'Status',
        key: 'isActive',
        render: (data) =>
          data.isActive ? (
            <span>
              <Icon style={{ color: 'green' }} type="check" /> Enable
            </span>
          ) : (
            <span>
              <Icon style={{ color: 'red' }} type="close" /> Disable
            </span>
          ),
      },
      {
        title: 'Action',
        key: 'action',
        className: 'show-on-row-hover',
        render: (data) => (
          <>
            <Button onClick={() => this.delete(data.id)} type="danger" icon="delete" className="margin-right-light margin-bottom-light" title="Delete">
              <span className="hidden-xs hidden-sm hidden-md hidden-lg">Delete</span>
            </Button>
            <Button
              className="margin-bottom-light"
              onClick={() => {
                this.setState({ password: '', id: data.id, visible: true });
              }}
              icon="unlock"
              title="Change password">
              <span className="hidden-xs hidden-sm hidden-md hidden-lg">Change Password</span>
            </Button>
          </>
        ),
      },
    ];

    const loadingColumns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: (data) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Email Address',
        dataIndex: 'emailAddress',
        key: 'emailAddress',
        render: (data) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Name',
        key: 'name',
        render: (data) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: (data) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Status',
        key: 'isActive',
        render: (data) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Action',
        key: 'action',
        render: (data) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
    ];
    const loadingData = [{ key: '1' }, { key: '2' }, { key: '3' }, { key: '4' }];
    return this.props.permissions && this.props.permissions.includes('ManageCompanyAndUser') ? (
      <Content>
        <Modal visible={this.state.modalVisibility} footer={null} closable={false} className={'higher-z-index'}>
          <Result
            status={this.state.modalStatus}
            title={this.state.modalTitle}
            subTitle={this.state.modalDescriptions}
            extra={
              <Button htmlType="button" type={'primary'} onClick={this.closeModal}>
                Ok
              </Button>
            }
          />
        </Modal>
        <div className="breadcrumpt-section">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={'/'}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={'/user'}>user</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="container">
          <Modal title="Change Password" visible={this.state.visible} onOk={() => this._handleOk()} onCancel={() => this._handleCancel()}>
            <div className="form-group">
              <label>New Password</label>
              <Input.Password value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
            </div>
            <label>Confirm Password</label>
            <Input.Password value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
          </Modal>
          <div className="user-creat-filter">
            <Link to={`/user/create`}>
              <Button type="primary" className="margin-bottom">
                create a new user
                <Icon type="right" />
              </Button>
            </Link>
            <Form className="table-filter-wrapper">
              <div>
                <label>Keyword</label>
                <Form.Item>
                  <Input placeholder={'Search ...'} value={this.state.Keyword} onChange={(e) => this.setState({ Keyword: e.target.value })} />
                </Form.Item>
              </div>
              <div>
                <label>User Active </label>
                <Select
                  showSearch
                  placeholder="Select a User Status"
                  optionFilterProp="children"
                  value={this.state.statusUser}
                  onChange={(value) => this.setState({ statusUser: value })}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Option value={'all'}>All</Option>
                  <Option value={'true'}>Active</Option>
                  <Option value={'false'}>Disable</Option>
                </Select>
              </div>
              <div>
                <label> filter </label>
                <Form.Item>
                  <Button onClick={() => this._filter()} htmlType="submit" type="primary" className="margin-right-light">
                    <Icon type="filter" />
                  </Button>
                  <Button onClick={() => this.clear()} htmlType="reset" className="margin-right-light">
                    <Icon type="close" />
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
          {this.state.spinner ? (
            <Table columns={loadingColumns} dataSource={loadingData} />
          ) : (
            <Table
              rowKey="id"
              className="users-expandable-table"
              columns={columns}
              dataSource={data}
              expandIconColumnIndex={5}
              expandIconAsCell={false}
              onExpand={this.onExpand}
              rowClassName={(record, index) => (this.state.expandedRowsId.includes(record.id) ? 'is-expanded' : null)}
              expandedRowRender={(record) => <EditUser id={record.id} />}
            />
          )}
          <Switch>
            <Route path="/user/resetpassword" exact component={ResetPassword} />
            <Route path="/user/changepassword" exact component={ChangePassword} />
            <Route path="/user/changeinformation" exact component={ChangeInformation} />
          </Switch>
        </div>
      </Content>
    ) : null;
  }
}

const MapStateToProps = (state) => ({
  PNRDetails: state.Flight.PNRDetails,
  permissions: state.User.permissions,
});
const DispatchToProps = (dispatch) => ({
  getPNRDetails: (param) => {
    dispatch(getPNRDetails(param));
  },
  PricingPNR: (param) => {
    dispatch(PricingPNR(param));
  },
});
export default connect(MapStateToProps, DispatchToProps)(AccountIndex);
