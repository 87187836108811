import { Empty } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Travel } from '../../Enums/Types';
import HotelItem from './HotelItem';
import Itinerary from './Itinerary';

class ListItem extends React.Component {
  static propTypes = {
    data: PropTypes.any.isRequired,
    last: PropTypes.number.isRequired,
    start: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  };
  render() {
    let resultData = this.props.data;
    return (
      <>
        {resultData.length > 0 ? (
          resultData.map((item, index) => {
            return (
              index >= this.props.start &&
              index <= this.props.last && (
                <>
                  {this.props.type === Travel.hotel && <HotelItem key={item.id} model={item} currency={this.props.currency} params={2} />}
                  {this.props.type === Travel.flight && <Itinerary key={item.key} model={item} currency={this.props.currency} isDomesticFlight={this.props.isDomesticFlight} />}
                </>
              )
            );
          })
        ) : (
          <Empty description="There is not any flights for this route" />
        )}
      </>
    );
  }
}
//maping
const mapStateToProp = (state) => ({
  currency: state.User.currency,
});
export default connect(mapStateToProp)(ListItem);
