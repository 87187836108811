import { Button, Card, Col, Empty, Modal, Pagination, Row, Skeleton, Spin } from 'antd';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { CancelPNR } from '../../../../Actions/FlightAction';
import { getFlightInfo, getGatewaysInfo, getHotelInfo, getReserves, getTransactionInfo } from '../../../../Actions/ReservesAction';
import { PaymentType, ReserveInput, SupplierType } from '../../../../Enums/Types';
//import actions and model
import InfoCard from '../Tracking/InfoCard';
import FilterSide from './FilterSide';

class Reserve extends React.Component {
  state = {
    showMenu: false,
    number: 1,
    suppliers: null,
  };

  async getData() {
    let param = new ReserveInput();
    let paramURL = queryString.parse(this.props.location.search);
    this.props.getReserves({ ...param, ...paramURL });
    let numberPage = paramURL.pageNumber ? paramURL.pageNumber : 1;
    this.setState({
      showMenu: false,
      number: parseInt(numberPage),
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location !== this.props.location) {
      this.getData();
    }
    if (this.props.flightSelect) {
      if (JSON.stringify(this.props.flightSelect) !== JSON.stringify(prevProps.flightSelect)) {
        if (this.props.flightSelect.pnrStatus.toUpperCase() === PaymentType.Voided) {
          Modal.confirm({
            title: 'Attention',
            content: `The flight with Reserve number ${this.props.flightSelect.reserveId} has been ${this.props.flightSelect.pnrStatus} you have to cancel it`,
            onOk: () => {
              let supplierId = SupplierType.filter((item) => item.value === this.props.flightSelect.supplierId)[0].name;
              this.props.cancelPNR({ pnrCode: this.props.flightSelect.pnrCode, supplierId });
            },
            onCancel: () => {},
            okText: 'Cancel it now',
            cancelText: 'Cancel it later',
          });
        } else if (this.props.flightSelect.pnrStatus.toUpperCase() === PaymentType.Canceled) {
          Modal.info({
            title: 'Attention',
            content: `The flight with Reserve number ${this.props.flightSelect.reserveId} has been ${this.props.flightSelect.pnrStatus}`,
          });
        }
        this.getData();
      }
    }
  }

  onChangePage = (e) => {
    this.setState(
      {
        number: e,
      },
      () => {
        let parsedURL = queryString.parse(this.props.location.search);
        let paramInput = null;
        if (Object.entries(parsedURL).length > 0) {
          paramInput = { pageNumber: e };
        } else {
          paramInput = new ReserveInput();
          paramInput.pageNumber = e;
        }
        let paramURL = queryString.stringify({ ...parsedURL, ...paramInput });
        this.props.history.push('reserves?' + paramURL);
      }
    );

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };
  isTicketing = (param) => {
    let result = [];
    if (isNaN(parseInt(param))) {
      result = this.props.ticketingSupplier.filter((item) => item.supplierName === param);
    } else {
      result = this.props.ticketingSupplier.filter((item) => item.supplierId === parseInt(param));
    }
    return Boolean(result.length);
  };

  render() {
    return this.props.permissions && this.props.permissions.includes('ViewBookings') ? (
      <div className="container vr-padding reserves-page">
        <Row gutter={15}>
          <Button id={'filterButton'} shape={'round'} type={'primary'} onClick={() => this.setState({ showMenu: true })}>
            Filter
          </Button>
          <Col xs={24} md={8} lg={6} xl={6} id={'filterSide'} className={!this.state.showMenu && 'close'}>
            <Button className={'closeFilter'} shape="circle" type={'danger'} icon={'close'} size={'large'} onClick={() => this.setState({ showMenu: false })} />
            <FilterSide />
          </Col>
          {this.props.reserveList ? (
            <Col xs={24} md={16} lg={16} xl={18} id={'reserveList'}>
              {/* <div>
                                        <Alert  className={"margin-bottom"} message={`${this.props.reserveList.totalCount} item found`} type="info" />
                                    </div> */}
              <div>
                {this.props.reserveList.totalCount > 0 ? (
                  <div className="clearfix margin-top margin-bottom">
                    <Pagination
                      className="pull-right"
                      pageSize={20}
                      defaultCurrent={this.state.number}
                      total={this.props.reserveList.totalCount}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                      onChange={this.onChangePage}
                    />
                  </div>
                ) : null}
              </div>
              {this.props.reserveList.reserves ? (
                this.props.reserveList.reserves.map((item) => <InfoCard isTicket={this.isTicketing(item.supplier.id)} user={item} key={item.reserveNumber} />)
              ) : (
                <div>
                  <Spin tip="loading..."></Spin>
                </div>
              )}
              <div>
                {this.props.reserveList.totalCount > 0 ? (
                  <div className="clearfix margin-top margin-bottom">
                    <Pagination
                      className="pull-right"
                      pageSize={20}
                      defaultCurrent={this.state.number}
                      total={this.props.reserveList.totalCount}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                      onChange={this.onChangePage}
                    />
                  </div>
                ) : (
                  <Empty />
                )}
              </div>
            </Col>
          ) : (
            <Col xs={24} md={16} lg={16} xl={18} id={'reserveList'}>
              <Card className="margin-bottom">
                <Skeleton avatar active paragraph={{ rows: 4 }} />
              </Card>
              <Card className="margin-bottom">
                <Skeleton avatar active paragraph={{ rows: 4 }} />
              </Card>
              <Card className="margin-bottom">
                <Skeleton avatar active paragraph={{ rows: 4 }} />
              </Card>
              <Card className="margin-bottom">
                <Skeleton avatar active paragraph={{ rows: 4 }} />
              </Card>
            </Col>
          )}
        </Row>
      </div>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  getReserves: (param) => {
    dispatch(getReserves(param));
  },
  getTransactionInfo: (id) => {
    dispatch(getTransactionInfo(id));
  },
  getGatewaysInfo: (id) => {
    dispatch(getGatewaysInfo(id));
  },
  getFlightInfo: (id) => {
    dispatch(getFlightInfo(id));
  },
  getHotelInfo: (id) => {
    dispatch(getHotelInfo(id));
  },
  cancelPNR: (param) => {
    dispatch(CancelPNR(param));
  },
});
const mapStateToProps = (state) => ({
  reserveList: state.Reserve.reserveList,
  filterParam: state.Reserve.filterParam,
  flightSelect: state.Flight.PNRDetails,
  ticketingSupplier: state.Flight.ticketingSupplier,
  permissions: state.User.permissions,
});
const withrouter = withRouter(Reserve);
export default connect(mapStateToProps, mapDispatchToProps)(withrouter);
