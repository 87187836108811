import { Button, Result } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
class Error extends React.Component {
  render() {
    return (
      <div className="section-vertical-padding">
        <div className="page-container">
          <Result
            status="500"
            title="ERROR"
            subTitle="Sorry, Something went wrong."
            extra={
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    this.props.history.push('/');
                  }}>
                  Back Home
                </Button>
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}>
                  Reload
                </Button>
              </>
            }
          />
        </div>
      </div>
    );
  }
}
export default withRouter(Error);
