import { Button, Col, Collapse, Icon, Row, Spin, Tag, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
//import library
import React from 'react';
import { withRouter } from 'react-router-dom';

//import URL an model
import { Flight as FlightUrl, HeaderRequest, Urls } from '../../Enums/APIAddress';
import { supplierCode } from '../../utils/styleName';
//import Component
import Price from '../commond/Price';
import Itineraries from './ItineraryDetail';

//import style and image

class Itinerary extends React.Component {
  static propTypes = {
    model: PropTypes.any.isRequired,
    currency: PropTypes.any.isRequired,
  };
  state = {
    showRules: false,
    rulesContent: null,
    visibleContent: null,
  };
  //event
  getRules = (key) => {
    axios({
      method: 'get',
      url: `${Urls.Type}${Urls.Flight}${FlightUrl.GetRules}`,
      params: { key },
      headers: { ...HeaderRequest },
    })
      .then((response) => {
        this.setState({
          rulesContent: response.data.result,
        });
      })
      .catch((error) => {
        try {
          this.setState({
            rulesContent: error.response.data.error.message,
          });
        } catch (e) {
          this.setState({
            rulesContent: 'Internal Error,Please refresh the page',
          });
        }
      });
  };
  openDetails = (detailItem) => {
    if (detailItem === this.state.visibleContent) {
      this.setState({
        visibleContent: null,
      });
    } else {
      this.setState({
        visibleContent: detailItem,
      });
      if (detailItem === 'rule') {
        this.getRules(this.props.model.key);
      }
    }
  };
  closeDetails = () => {
    this.setState({
      visibleContent: null,
    });
  };

  render() {
    const { Panel } = Collapse;
    let airportChange = false;
    this.props.model.airItinerary.forEach((item) => {
      item.flightSegments.forEach((segmentItem, index) => {
        if (index < item.flightSegments.length - 1) {
          if (!(segmentItem.arrivalAirport.locationCode === item.flightSegments[index + 1].departureAirport.locationCode)) {
            airportChange = true;
          }
        }
      });
    });
    let travelType = 'flight';
    if (this.props.isDomesticFlight) {
      travelType = 'domesticflight';
    }
    return (
      <div className={this.state.visibleContent ? 'flightItem visible-details' : 'flightItem'}>
        {this.state.visibleContent ? <div className={'overlay'} onClick={this.closeDetails} /> : null}
        <div className={'flight-item-box'}>
          {this.props.model.airItinerary.map((item, index) => {
            return (
              <div key={index}>
                {item.flightSegments.map((segmentItem, segmentIndex) => {
                  return (
                    <Row key={segmentIndex} type="flex" align="middle" className={'segment-item-row padding-cols'}>
                      <Col xs={24} sm={8} md={7} lg={8}>
                        <Row type="flex" align="middle">
                          <Col xs={24} sm={12} md={4} lg={5} className="static">
                            <div>
                              <img src={segmentItem.marketingAirline.photoUrl} alt={segmentItem.marketingAirline.name} className={'itinerary-airline-logo'} />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={20} lg={19} className={'font-13'}>
                            <div className="class-airline-info">
                              <div>{segmentItem.marketingAirline.name}</div>
                              <div>
                                <span> {segmentItem.marketingAirline.code} </span> {segmentItem.flightNumber}
                              </div>
                              {segmentItem.marketingAirline.code !== segmentItem.operatingAirline.code && <div>Operating by {segmentItem.operatingAirline.name}</div>}
                              <div>
                                {segmentItem.cabinClass.name}
                                {segmentItem.resBookDesigCode && <> ({segmentItem.resBookDesigCode})</>}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12}>
                        <div className="org-dur-dst">
                          <div>
                            <div className="bold font-16 black no-wrap">{moment(segmentItem.departureDateTime, 'YYYY-MM-DDTHH:mm:SS').format('HH:mm')}</div>
                            <div className="bold font-16 black no-wrap">{moment(segmentItem.departureDateTime, 'YYYY-MM-DDTHH:mm:SS').format('ddd, DD MMM')}</div>
                            <div>{segmentItem.departureAirport.locationCode}</div>
                          </div>
                          <div className="duration-of-flight">
                            {segmentItem.flightDuration === '00:00:00' ? null : (
                              <>
                                <span className="hidden-xs hidden-sm hidden-md">Flight duration: </span> {moment(segmentItem.flightDuration, 'HH:mm:ss').format('HH[h] mm[m]')}
                              </>
                            )}
                          </div>
                          <div className="dest-of-flight">
                            <div className="bold font-16 black no-wrap">
                              {segmentItem.arrivalDateTime === '0001-01-01T00:00:00' ? '--:--' : moment(segmentItem.arrivalDateTime, 'YYYY-MM-DDTHH:mm:SS').format('HH:mm')}
                            </div>
                            <div className="bold font-16 black no-wrap">
                              {segmentItem.arrivalDateTime === '0001-01-01T00:00:00'
                                ? '--, -- --'
                                : moment(segmentItem.arrivalDateTime, 'YYYY-MM-DDTHH:mm:SS').format('ddd, DD MMM')}
                            </div>
                            <div>{segmentItem.arrivalAirport.locationCode}</div>
                          </div>
                        </div>
                      </Col>
                      {/* <Col xs={24} lg={3}>
                                                    <div className={"itinerary-mobile-pair"}>
                                                        <div className={"itinerary-mobile-label hidden-lg hidden-xl"}>Stop</div>
                                                        <div>
                                                            {segmentItem.stopTime}
                                                        </div>
                                                    </div>
                                                </Col> */}
                      <Col className="hidden-xs" sm={4} md={5} lg={4}>
                        <div className="baggage-column">
                          {segmentItem.baggageInformation.map((baggageItem) => (
                            <div key={baggageItem.passengerType}>
                              {baggageItem.baggageAllowance} {baggageItem.unitType} for {baggageItem.passengerType}
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
                {this.props.model.airItinerary.length > 1 && index !== this.props.model.airItinerary.length - 1 && <div className="hr-dash-line" />}
              </div>
            );
          })}
          <div className="flight-item-footer">
            <Row align="middle" type="flex">
              <Col xs={24} sm={14}>
                <div className="flight-details-btns">
                  <div>
                    {this.props.model.key.slice(0, 3) === '009' ? (
                      <>
                        <Tag color="#f5222d" className="font-16">
                          non changeable
                        </Tag>
                        <Tag color="#f5222d" className="font-16">
                          non refundable
                        </Tag>
                      </>
                    ) : null}
                    {airportChange && (
                      <div className="has-airport-change inline-block margin-bottom-light" onClick={() => this.openDetails('detail')}>
                        <Icon type="warning" theme="filled" className="font-18 inline-block margin-right-light" />
                        <span className=" inline-block "> Stop for airport change</span>
                      </div>
                    )}

                    <div className="flight-details-btns-inner">
                      <button className="button gray-btn margin-right-light" type="button" onClick={() => this.openDetails('rule')}>
                        Rules
                      </button>
                      <button className="button gray-btn margin-right-light" type="button" onClick={() => this.openDetails('detail')}>
                        Details
                      </button>
                      <button className="button gray-btn margin-right-light" type="button" onClick={() => this.openDetails('break')}>
                        Break Down
                      </button>
                      <button className="button gray-btn margin-right-light" type="button" onClick={() => this.openDetails('baggage')}>
                        Baggage
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={10}>
                <div className="price-buttons-holder">
                  <div className="inline-block">
                    <Price currency={this.props.currency}>{this.props.model.totalPrice}</Price>
                  </div>
                  <div className="inline-block align-btns-width">
                    <div className="align-btns">
                      {/*{!this.props.model.paymentBeforePNR && (*/}
                      {/*  <Tooltip placement={'top'} title={'Book flight without ticketing'}>*/}
                      {/*    <a*/}
                      {/*      target="_blank"*/}
                      {/*      rel="noopener noreferrer"*/}
                      {/*      className={`button full-width blue-btn book-now-btn`}*/}
                      {/*      href={`/${travelType}/getpassenger?key=${this.props.model.key}&now=true`}>*/}
                      {/*      Book Now*/}
                      {/*    </a>*/}
                      {/*  </Tooltip>*/}
                      {/*)}*/}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`full-width ${supplierCode(this.props.model.key.slice(0, 3))}`}
                        href={`/${travelType}/getpassenger?key=${this.props.model.key}&now=false`}>
                        Book
                        <Icon type="right" />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className={this.state.visibleContent === 'baggage' ? 'itours-accardion visible' : 'itours-accardion'}>
          <div className="itours-accardion-inner">
            <div className="itours-accardion-header clearfix">
              <strong className="inline-block margin-top-light">Baggage informations</strong>
              <Button
                className="pull-right"
                type="primary"
                htmlType="button"
                onClick={() => {
                  this.setState({ visibleContent: null });
                }}>
                Close details
              </Button>
            </div>
            <div className="itours-accardion-main">
              <div className="flight-baggage-detail">
                {this.props.model.airItinerary.map((item) => {
                  return item.flightSegments.map((item) => {
                    return (
                      <div key={item.flightNumber} className="baggegesInformation" dir="ltr">
                        <div className="origin">
                          <div>
                            <b>{item.departureAirport.cityName}</b> - <small>{item.departureAirport.countryName}</small>
                          </div>
                        </div>
                        <div className={'airplaneIcon'}>
                          <span className={'itours-icon to-right-flight-icon'} />
                        </div>
                        <div className="destination">
                          <div>
                            <b>{item.arrivalAirport.cityName}</b> - <small>{item.arrivalAirport.countryName}</small>
                          </div>
                        </div>
                        <div className="margin-left baggages-list">
                          {item.baggageInformation.map((item) => (
                            <div key={item.passengerType} className={item.passengerType + ' no-wrap'} dir="ltr">
                              {item.baggageAllowance} {item.unitType} for {item.passengerType === 'ADT' && <span>Adult</span>} {item.passengerType === 'CHD' && <span>Child </span>}{' '}
                              {item.passengerType === 'INF' && <span>Infant</span>}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.visibleContent === 'break' ? 'itours-accardion visible' : 'itours-accardion'}>
          <div className="itours-accardion-inner">
            <div className="itours-accardion-header clearfix">
              <strong className="inline-block margin-top-light">Fare informations</strong>
              <Button
                className="pull-right"
                type="primary"
                htmlType="button"
                onClick={() => {
                  this.setState({ visibleContent: null });
                }}>
                Close details
              </Button>
            </div>
            <div className="itours-accardion-main">
              <div className={'PassengersInfo'}>
                <table className="itours-table bg-white">
                  <thead>
                    <tr>
                      <th>Passenger Type</th>
                      <th>Base Fare</th>
                      <th>Fees</th>
                      <th>Taxes</th>
                      <th>Commission</th>
                      <th>Per Passenger</th>
                      <th>Total Fare</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.model.pricePerPerson.map((passengerItem, index) => {
                      const passengerTypeTotalFare = passengerItem.passengerFare.totalFare * passengerItem.passengerTypeQuantity.quantity;
                      return (
                        <tr key={index}>
                          <td>
                            <span className="no-wrap">
                              {passengerItem.passengerTypeQuantity.code} (x {passengerItem.passengerTypeQuantity.quantity})
                            </span>
                          </td>
                          <td>
                            <span className="no-wrap">
                              <Price currency={this.props.currency}>{passengerItem.passengerFare.baseFare}</Price> (x {passengerItem.passengerTypeQuantity.quantity})
                            </span>
                          </td>
                          <td>
                            {passengerItem.passengerFare.fess
                              ? passengerItem.passengerFare.fess.map((item) => (
                                  <div key={item.code} className="no-wrap">
                                    {/* <span>{item.code}: </span> */}
                                    {item.amount === 0 ? ' 0 ' : <Price currency={this.props.currency}>{item.amount}</Price>} (x {passengerItem.passengerTypeQuantity.quantity})
                                  </div>
                                ))
                              : '--'}
                          </td>
                          <td>
                            {passengerItem.passengerFare.taxes.map((item) => (
                              <div key={item.code} className="no-wrap">
                                {/* <span>{item.code}: </span> */}
                                <Price currency={this.props.currency}>{item.amount}</Price> (x {passengerItem.passengerTypeQuantity.quantity})
                              </div>
                            ))}
                          </td>
                          <td>
                            <span className="no-wrap">
                              {passengerItem.passengerFare.commission !== 0 ? <Price currency={this.props.currency}>{passengerItem.passengerFare.commission}</Price> : '0 '}
                              (x {passengerItem.passengerTypeQuantity.quantity})
                            </span>
                          </td>
                          <td>
                            <span className="no-wrap">
                              <Price currency={this.props.currency}>{passengerItem.passengerFare.totalFare}</Price> (x {passengerItem.passengerTypeQuantity.quantity})
                            </span>
                          </td>
                          <td>
                            <Price currency={this.props.currency}>{passengerTypeTotalFare}</Price>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>Total</td>
                      <td>
                        <Price currency={this.props.currency}>{this.props.model.priceInfo.baseFare}</Price>
                      </td>
                      <td>
                        {this.props.model.priceInfo.fess
                          ? this.props.model.priceInfo.fess.map((item, index) => (
                              <div key={index}>
                                {/*<span>{item.name}</span>*/}
                                <Price currency={this.props.currency}>{item.amount}</Price>
                              </div>
                            ))
                          : '--'}
                      </td>
                      <td>
                        {this.props.model.priceInfo.taxes
                          ? this.props.model.priceInfo.taxes.map((item, index) => (
                              <div key={index}>
                                {/*<span>{item.name}</span>*/}
                                <Price currency={this.props.currency}>{item.amount}</Price>
                              </div>
                            ))
                          : '--'}
                      </td>
                      <td>{this.props.model.priceInfo.commission !== 0 ? <Price currency={this.props.currency}>{this.props.model.priceInfo.commission}</Price> : ' 0 '}</td>
                      <td />
                      <td>
                        <Price currency={this.props.currency}>{this.props.model.priceInfo.totalFare}</Price>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.visibleContent === 'detail' ? 'itours-accardion visible' : 'itours-accardion'}>
          <div className="itours-accardion-inner">
            <div className="itours-accardion-header clearfix">
              <strong className="inline-block margin-top-light">Flight Detail</strong>
              <Button
                className="pull-right"
                type="primary"
                htmlType="button"
                onClick={() => {
                  this.setState({ visibleContent: null });
                }}>
                Close details
              </Button>
            </div>
            <div className="itours-accardion-main">
              <Itineraries model={this.props.model.airItinerary} />
            </div>
          </div>
        </div>
        <div className={this.state.visibleContent === 'rule' ? 'itours-accardion visible' : 'itours-accardion'}>
          <div className="itours-accardion-inner">
            <div className="itours-accardion-header clearfix">
              <strong className="inline-block margin-top-light">Flight rules</strong>
              <Button
                className="pull-right"
                type="primary"
                htmlType="button"
                onClick={() => {
                  this.setState({ visibleContent: null });
                }}>
                Close details
              </Button>
            </div>
            <div className="itours-accardion-main">
              <div className="rules-container">
                {this.state.rulesContent ? (
                  <>
                    {this.state.rulesContent.flightRules.length === 0 ? (
                      <div>{'Information not Found'}</div>
                    ) : (
                      <>
                        {this.state.rulesContent.flightRules.map((item, index) => {
                          return (
                            <div className="rules-item en-font" key={index}>
                              <div className="rule-title margin-top">FareBase: {item.fareBase}</div>
                              <Collapse>
                                {item.flightRule.map((itemIn, indexIn) => {
                                  return (
                                    <Panel header={itemIn.ruleTitle} key={indexIn}>
                                      <p>{itemIn.ruleDescription}</p>
                                    </Panel>
                                  );
                                })}
                              </Collapse>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <div className="text-center">
                    <div className="margin-bottom">Loading Data</div>
                    <div>
                      <Spin />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Itinerary);
