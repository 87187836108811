import { Button, Col, DatePicker, Form, Icon, Input, Row, Select, Skeleton, Table } from 'antd';
import moment from 'moment';
//import library
import React from 'react';
import { connect } from 'react-redux';

import PaymentApi from '../../../../APIs/Payment';
import { Urls } from '../../../../Enums/APIAddress';
//import model and action
import { Currency } from '../../../../Enums/Types';
import Price from '../../../commond/Price';

class TransactionsReport extends React.Component {
  state = {
    transactionListData: [],
    filteredData: [],
    loading: true,
  };
  async componentDidMount() {
    //get transactions list
    const response = await PaymentApi.getReport('MaxResultCount=300');
    if (response.data) {
      this.setState({
        transactionListData: response.data.result.items,
        filteredData: response.data.result.items,
        loading: false,
      });
    }
  }

  //excel export:
  handleExport = async () => {
    const response = await PaymentApi.getReportExcel('MaxResultCount=300');
    if (response.data) {
      if (response.data.success) {
        let fileName = response.data.result.fileName;
        let fileToken = response.data.result.fileToken;
        let fileType = response.data.result.fileType;
        const downloadResponse = await PaymentApi.downloadExcelFile(`fileType=${fileType}&fileToken=${fileToken}&fileName=${fileName}`);
      }
    }
  };
  //filter action:
  handleFilter = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      } else {
        let list = this.state.transactionListData;
        //filter TransactionType
        if (fieldsValue.TransactionType) {
          list = list.filter((item) => item.type === fieldsValue.TransactionType);
        }
        //filter creation date
        if (fieldsValue.Date) {
          const dateFrom = fieldsValue.Date[0];
          const dateTo = fieldsValue.Date[1];
          list = list.filter((item) => moment(item.creationTime).isSameOrAfter(dateFrom, 'day') && moment(item.creationTime).isSameOrBefore(dateTo, 'day'));
        }
        //filter amount from
        if (fieldsValue.AmountFrom) {
          list = list.filter((item) => item.amount >= fieldsValue.AmountFrom);
        }
        //filter amount To
        if (fieldsValue.AmountTo) {
          list = list.filter((item) => item.amount <= fieldsValue.AmountTo);
        }
        //filter currency
        if (fieldsValue.currency) {
          list = list.filter((item) => item.currencyType === fieldsValue.currency);
        }
        this.setState({
          filteredData: list,
        });
      }
    });
  };
  //handle reset
  handleReset = () => {
    this.props.form.resetFields();
    document.getElementById('submit_btn').click();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const TransactionsTableLoadingData = [
      { fakeRecord1: 'data1', key: 1 },
      { fakeRecord1: 'data2', key: 2 },
      { fakeRecord1: 'data3', key: 3 },
      { fakeRecord1: 'data4', key: 4 },
    ];
    const TransactionsTableLoadingHead = [
      {
        title: 'Date And Time',
        dataIndex: 'dateAndTime',
        key: 'dateAndTime',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Transaction Type',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '85%' }} />,
      },
      {
        title: 'Descriptions',
        key: 'information',
        dataIndex: 'information',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '85%' }} />,
      },
      {
        title: 'Amount',
        key: 'price',
        dataIndex: 'price',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Credit balance',
        key: 'initialAmount',
        dataIndex: 'initialAmount',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
    ];
    const TransactionsTableHead = [
      {
        title: 'Date And Time',
        dataIndex: 'dateAndTime',
        key: 'dateAndTime',
        className: 'no-wrap padding-10',
        render: (text, record) => (
          <>
            <div className="no-wrap">{moment(record.creationTime).format('YYYY-MM-DD')}</div>
            <div>{moment(record.creationTime).format('hh:mm')}</div>
          </>
        ),
      },
      {
        title: 'Transaction Type',
        dataIndex: 'type',
        key: 'type',
        className: 'padding-10 font-12',
        render: (text, record) =>
          record.type === 'IncreaseDepositByPaymentGateway'
            ? 'Increase deposit by payment gateway'
            : record.type === 'IncreaseDepositByRefund'
            ? 'Increase deposit by refund'
            : record.type === 'IncreaseDepositByReverse'
            ? 'Increase deposit by reverse'
            : record.type === 'IncreaseDeposit'
            ? 'Increase deposit'
            : record.type === 'ManualIncreaseDeposit'
            ? 'Manual increase deposit'
            : record.type === 'DecreaseDepositBySale'
            ? 'Decrease deposit by sale'
            : record.type === 'DecreaseDepositByWithdraw'
            ? 'Decrease deposit by withdraw'
            : record.type === 'DecreaseDeposit'
            ? 'Decrease deposit'
            : record.type === 'ManualDecreaseDeposit'
            ? 'Manual decrease deposit'
            : record.type,
      },
      {
        title: 'Descriptions',
        key: 'information',
        dataIndex: 'information',
        className: 'padding-10 font-12',
        render: (text, record) => <div>{record.information ? record.information : null}</div>,
      },
      {
        title: 'Amount',
        key: 'price',
        dataIndex: 'price',
        className: 'padding-10',
        render: (text, record) => (
          <div>
            {record.amount > 0 ? (
              <>
                <b className="green font-18">+ </b>
                <Price currency={record.currencyType}>{record.amount}</Price>
              </>
            ) : record.amount < 0 ? (
              <>
                <b className="red font-18">- </b>
                <Price currency={record.currencyType}>{-1 * record.amount}</Price>
              </>
            ) : (
              0
            )}
          </div>
        ),
      },
      {
        title: 'Credit balance',
        key: 'initialAmount',
        dataIndex: 'initialAmount',
        className: 'padding-10',
        render: (text, record) =>
          record.currencyType ? (
            <div className="green">
              <Price currency={record.currencyType}>{record.initialAmount}</Price>
            </div>
          ) : (
            0
          ),
      },
    ];
    const TransactionsTableData = this.state.filteredData;
    const { RangePicker } = DatePicker;
    return this.props.permissions && this.props.permissions.includes('ViewTransactions') ? (
      <div className="container vr-padding deposit-page transaction-report-page">
        <Row justify="space-between" type="flex" align="bottom">
          <Col>
            <h3 className="page-heading">Transaction Report</h3>
          </Col>
          <Col>
            <Button type="primary" htmlType="button" icon="download" onClick={this.handleExport}>
              Export Excel
            </Button>
          </Col>
        </Row>
        <div className="margin-bottom">
          <p>At least one field is required to search your account transactions list.</p>
          <Form onSubmit={this.handleFilter} id="filter_form">
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={12} lg={7} xl={6}>
                <Form.Item label="Transaction Type">
                  {getFieldDecorator(
                    'TransactionType',
                    {}
                  )(
                    <Select placeholder="Transaction Type" allowClear="true">
                      {/* {Currency.map((item, index) => <Select.Option value={item.value} key={index}>{item.value}</Select.Option>)} */}
                      <Select.Option value="IncreaseDepositByPaymentGateway">Increase deposit by payment gateway</Select.Option>
                      <Select.Option value="IncreaseDepositByRefund">Increase deposit by refund</Select.Option>
                      <Select.Option value="IncreaseDepositByReverse">Increase deposit by reverse</Select.Option>
                      <Select.Option value="IncreaseDeposit">Increase deposit</Select.Option>
                      <Select.Option value="ManualIncreaseDeposit">Manual increase deposit</Select.Option>
                      <Select.Option value="DecreaseDepositBySale">Decrease deposit by sale</Select.Option>
                      <Select.Option value="DecreaseDepositByWithdraw">Decrease deposit by withdraw</Select.Option>
                      <Select.Option value="DecreaseDeposit">Decrease deposit</Select.Option>
                      <Select.Option value="ManualDecreaseDeposit">Manual decrease deposit</Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={5} xl={6}>
                <Form.Item label="Date">{getFieldDecorator('Date', {})(<RangePicker className="full-width" />)}</Form.Item>
              </Col>
              <Col xs={24} sm={6} lg={3} xl={3}>
                <Form.Item label="Amount From">{getFieldDecorator('AmountFrom', {})(<Input />)}</Form.Item>
              </Col>
              <Col xs={24} sm={6} lg={3} xl={3}>
                <Form.Item label="Amount To">{getFieldDecorator('AmountTo', {})(<Input />)}</Form.Item>
              </Col>
              <Col xs={24} sm={6} lg={3} xl={3}>
                {this.props.currency && (
                  <Form.Item label="Currency">
                    {getFieldDecorator(
                      'currency',
                      {}
                    )(
                      <Select placeholder="currency" allowClear="true">
                        {Currency.map((item, index) => (
                          <Select.Option value={item.value} key={index}>
                            {item.value}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} sm={6} lg={3} xl={3}>
                <Form.Item label="Filter">
                  <Button id="submit_btn" title="Filter" type="primary" htmlType="submit">
                    <Icon type="filter" />
                  </Button>
                  <Button title="Reset" onClick={this.handleReset} htmlType="reset" className="margin-left-light">
                    <Icon type="close" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        {this.state.loading ? (
          <Table columns={TransactionsTableLoadingHead} dataSource={TransactionsTableLoadingData} />
        ) : (
          <Table columns={TransactionsTableHead} rowKey="id" dataSource={TransactionsTableData} rowClassName={(record, index) => (record.amount < 0 ? 'light-red-bg' : null)} />
        )}
      </div>
    ) : null;
  }
}

const stateMapProp = (state) => ({
  currency: state.User.currency,
  permissions: state.User.permissions,
});

const WrappedForm = Form.create({ name: 'transactionsReport' })(TransactionsReport);
export default connect(stateMapProp)(WrappedForm);
