import { SharedActions } from '../Enums/ActionTypes';

const initialState = {
  ipLocation: undefined,
};

//Payment reducer
export const Shared = (state = initialState, action) => {
  switch (action.type) {
    case SharedActions.SetIpLocation:
      return {
        ...state,
        ipLocation: action.input,
      };
    default:
      return state;
  }
};
