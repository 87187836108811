import { Button, Col, DatePicker, Form, Radio, Row, Select } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
//import library
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { searchFlight } from '../../../../Actions/FlightAction';
import FlightApi from '../../../../APIs/Flight';
//import model and URL
import { Flight, HeaderRequest, Urls } from '../../../../Enums/APIAddress';
import { className } from '../../../../Enums/Types';
import { setStorage } from '../../../../utils/localstorage';
//import React components
import Autocomplete from '../../../commond/Autocomplete';

//import CSS
class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suppliersList: [],
      selectedSupplier: null,
      numberOfTrip: [1],
      validation: true,
      showVisualLoad: true,
      searchButtonText: 'Search',
      //for min and max for each datepicker
      searchParam: {
        departureDateTimes: [null, null],
        originCodes: [0],
        originNames: [0],
        destinationCodes: [0],
        destinationNames: [0],
        airlineCode: 'all',
        airlineText: '-',
        airTripType: 'RoundTrip',
        isEthnic: false,
        adult: 1,
        child: 0,
        infant: 0,
        cabinClassCode: 'All',
        nonStop: 'false',
        supplierId: null,
      },
    };
  }

  //component for lifecycle
  async componentDidMount() {
    //if in this page we have Search Param URL we call get flight to search
    if (this.props.location.search) {
      this.getFromURL();
    }
    const response = await FlightApi.GetSuppliers(null);
    this.setState({ suppliersList: response.supplierList });
    if (this.props.isEthnic) {
      this.setState({
        searchParam: { ...this.state.searchParam, isEthnic: true },
        searchButtonText: 'Search Ethnic',
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      this.getFromURL();
    }
  }

  //validator for input
  disableDate(index, current) {
    if (index) {
      let dates = this.props.form.getFieldsValue();
      dates = dates.departureDateTimes[index - 1];
      return current && current < dates;
    } else {
      return current && current <= moment().endOf('day').subtract(1, 'day');
    }
  }

  validateInfant = (rule, value, callback) => {
    const { form } = this.props;
    if (form.getFieldValue('infant') > form.getFieldValue('adult')) {
      callback("infant's number should be less that adult's number");
    } else {
      callback();
    }
  };
  validatePassengerCount = (rule, value, callback) => {
    const { form } = this.props;
    let adult = form.getFieldValue('adult');
    let child = form.getFieldValue('child');
    let infant = form.getFieldValue('infant');
    if (adult + child + infant > 9) {
      callback('total passenger cant be more that 9');
    } else {
      callback();
    }
  };
  validateDateSeq = (rule, value, callback) => {
    const { form } = this.props;
    let dateStatus = true;
    let dateState = form.getFieldValue('departureDateTimes');
    for (let indexCnt = 0; indexCnt < dateState.length; indexCnt++) {
      if (dateState[indexCnt + 1]) {
        if (dateState[indexCnt + 1].isBefore(dateState[indexCnt])) {
          dateStatus = false;
        }
      }
    }
    if (dateStatus) {
      callback();
    } else {
      callback('selected date must be select sequential');
    }
  };
  //fill form form URL
  getFromURL = () => {
    let parameters = queryString.parse(this.props.location.search);

    const isEthnic = parameters.isEthnic === 'true';
    let searchButtonText = 'Search';
    if (isEthnic) {
      searchButtonText = 'Search Ethnic';
    }
    this.setState({
      searchButtonText: searchButtonText,
      searchParam: { ...this.state.searchParam, isEthnic: isEthnic },
    });

    if (parameters.adult !== undefined) {
      let cntTrip = [];
      let modelLoad = [];
      modelLoad['adult'] = parseInt(parameters.adult);
      modelLoad['child'] = parseInt(parameters.child);
      modelLoad['infant'] = parseInt(parameters.infant);
      modelLoad['airTripType'] = parameters.airTripType;
      modelLoad['airlineCode'] = parameters.airlineCode;
      modelLoad['cabinClassCode'] = parameters.cabinClassCode;
      modelLoad['departureDateTimes'] = parameters.departureDateTimes.split('|');
      modelLoad['destinationCodes'] = parameters.destinationCodes.split('|');
      modelLoad['destinationNames'] = parameters.destinationNames.split('|');
      modelLoad['nonStop'] = parameters.nonStop;
      if (parameters.supplierId) {
        modelLoad['supplierId'] = parameters.supplierId;
        this.setState({
          selectedSupplier: parameters.supplierId,
        });
      }
      modelLoad['originCodes'] = parameters.originCodes.split('|');
      modelLoad['originNames'] = parameters.originNames.split('|');
      modelLoad['airlineText'] = parameters.airlineText;
      modelLoad['isEthnic'] = parameters.isEthnic === 'true';
      if (modelLoad.airTripType === 'RoundTrip') {
        cntTrip = [1];
        modelLoad.originCodes = [];
        modelLoad.originNames = [];
        modelLoad.destinationCodes = [];
        modelLoad.destinationNames = [];
        modelLoad.originCodes.push(parameters.originCodes.split('|')[0]);
        modelLoad.originNames.push(parameters.originNames.split('|')[0]);
        modelLoad.destinationCodes.push(parameters.destinationCodes.split('|')[0]);
        modelLoad.destinationNames.push(parameters.destinationNames.split('|')[0]);
        //create return
        modelLoad.originCodes.push(parameters.destinationCodes.split('|')[0]);
        modelLoad.originNames.push(parameters.destinationNames.split('|')[0]);
        modelLoad.destinationCodes.push(parameters.originCodes.split('|')[0]);
        modelLoad.destinationNames.push(parameters.originNames.split('|')[0]);
      } else if (modelLoad.airTripType === 'OneWay') {
        cntTrip = [1];
      } else if (modelLoad.airTripType === 'OpenJaw') {
        cntTrip = [];
        for (let cnt = 1; cnt <= modelLoad['originCodes'].length; cnt++) {
          cntTrip.push(cnt);
        }
      }
      this.setState(
        {
          numberOfTrip: cntTrip,
          searchParam: { ...modelLoad },
        },
        () => {
          this.props.searchFlight(this.state.searchParam);
        }
      );
    }
  };
  //handle Airline Selector
  getAirline = (value, text) => {
    this.setState({
      searchParam: { ...this.state.searchParam, airlineCode: value ? value : 'all', airlineText: text ? text : '-' },
    });
  };
  //handle tripType
  changeTypeOfTrip = (param) => {
    switch (param) {
      case 'OpenJaw':
        this.setState(
          {
            searchParam: {
              ...this.state.searchParam,
              airTripType: param,
            },
            numberOfTrip: [1, 2],
          },
          () => {
            let searchParamCatch = JSON.parse(JSON.stringify(this.state.searchParam));
            if (searchParamCatch.originCodes.length < 2) {
              searchParamCatch.departureDateTimes.push(0);
              searchParamCatch.originCodes.push(0);
              searchParamCatch.originNames.push(0);
              searchParamCatch.destinationCodes.push(0);
              searchParamCatch.destinationNames.push(0);
              this.setState({
                searchParam: { ...this.state.searchParam, ...searchParamCatch },
              });
            }
          }
        );
        break;
      case 'OneWay':
        this.setState(
          {
            searchParam: {
              ...this.state.searchParam,
              airTripType: param,
            },
            numberOfTrip: [1],
          },
          () => {
            let searchParamCatch = JSON.parse(JSON.stringify(this.state.searchParam));
            searchParamCatch.departureDateTimes = [searchParamCatch.departureDateTimes[0]];
            searchParamCatch.originCodes = [searchParamCatch.originCodes[0]];
            searchParamCatch.originNames = [searchParamCatch.originNames[0]];
            searchParamCatch.destinationCodes = [searchParamCatch.destinationCodes[0]];
            searchParamCatch.destinationNames = [searchParamCatch.destinationNames[0]];
            this.setState({
              searchParam: { ...this.state.searchParam, ...searchParamCatch },
            });
          }
        );
        break;
      default:
        this.setState(
          {
            searchParam: {
              ...this.state.searchParam,
              airTripType: param,
            },
            numberOfTrip: [1],
          },
          () => {
            let searchParamCatch = JSON.parse(JSON.stringify(this.state.searchParam));
            if (searchParamCatch.originCodes.length > 2) {
              searchParamCatch.departureDateTimes = [searchParamCatch.departureDateTimes[0], searchParamCatch.departureDateTimes[1]];
              searchParamCatch.originCodes = [searchParamCatch.originCodes[0], searchParamCatch.originCodes[1]];
              searchParamCatch.originNames = [searchParamCatch.originNames[0], searchParamCatch.originNames[1]];
              searchParamCatch.destinationCodes = [searchParamCatch.destinationCodes[0], searchParamCatch.destinationCodes[1]];
              searchParamCatch.destinationNames = [searchParamCatch.destinationNames[0], searchParamCatch.destinationNames[1]];
            } else if (searchParamCatch.originCodes.length < 2) {
              searchParamCatch.departureDateTimes.push(null);
              searchParamCatch.originCodes.push(searchParamCatch.destinationCodes[0]);
              searchParamCatch.originNames.push(searchParamCatch.destinationNames[0]);
              searchParamCatch.destinationCodes.push(searchParamCatch.originCodes[0]);
              searchParamCatch.destinationNames.push(searchParamCatch.originNames[0]);
            }
            this.setState({
              searchParam: { ...this.state.searchParam, ...searchParamCatch },
            });
          }
        );
    }
  };
  //handle Autocomplete event
  getCallback = (value, text, name, id) => {
    let searchParamCatch = JSON.parse(JSON.stringify(this.state.searchParam));
    searchParamCatch[name + 'Names'][id] = text;
    searchParamCatch[name + 'Codes'][id] = value;
    this.setState({
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };
  //add or remove flight way
  addFlight = () => {
    let numberOfTripCash = this.state.numberOfTrip;
    if (numberOfTripCash.length < 4) {
      numberOfTripCash.push(numberOfTripCash[numberOfTripCash.length - 1] + 1);
      let searchParamCatch = JSON.stringify(this.state.searchParam);
      searchParamCatch = JSON.parse(searchParamCatch);
      searchParamCatch.originCodes.push(0);
      searchParamCatch.originNames.push(0);
      searchParamCatch.destinationCodes.push(0);
      searchParamCatch.destinationNames.push(0);
      this.setState({
        numberOfTrip: numberOfTripCash,
        searchParam: { ...this.state.searchParam, ...searchParamCatch },
      });
    }
  };
  removeFlight = (index) => {
    let datesCatch = this.props.form.getFieldsValue().departureDateTimes;
    datesCatch.splice(index, 1);
    this.props.form.setFieldsValue({
      departureDateTimes: datesCatch,
    });
    let numberOfTripCash = JSON.parse(JSON.stringify(this.state.numberOfTrip));
    numberOfTripCash.splice(index, 1);
    let searchParamCatch = JSON.parse(JSON.stringify(this.state.searchParam));
    searchParamCatch.originCodes.splice(index, 1);
    searchParamCatch.originNames.splice(index, 1);
    searchParamCatch.destinationCodes.splice(index, 1);
    searchParamCatch.destinationNames.splice(index, 1);

    this.setState({
      numberOfTrip: numberOfTripCash,
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };

  //get Data from URL - pass data to URL
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      validation: false,
    });
    this.props.form.validateFields((err, value) => {
      if (!err) {
        let waysStatus = true;
        this.state.searchParam.originCodes.forEach((item) => {
          if (!item) {
            waysStatus = false;
          }
        });
        this.state.searchParam.destinationCodes.forEach((item) => {
          if (!item) {
            waysStatus = false;
          }
        });
        if (waysStatus) {
          this.setState(
            {
              searchParam: { ...this.state.searchParam, ...value },
            },
            () => {
              //Prepare URL
              let dateArray = '';
              for (let date in this.state.searchParam.departureDateTimes) {
                let item = moment(this.state.searchParam.departureDateTimes[date]).format('YYYY-MM-DD');
                parseInt(date) ? (dateArray += '|' + item) : (dateArray += item);
              }
              let originCodes = '';
              this.state.searchParam.originCodes.forEach((item, index) => {
                index ? (originCodes += '|' + item) : (originCodes += item);
              });
              let originNames = '';
              this.state.searchParam.originNames.forEach((item, index) => {
                index ? (originNames += '|' + item) : (originNames += item);
              });
              let destinationCodes = '';
              this.state.searchParam.destinationCodes.forEach((item, index) => {
                index ? (destinationCodes += '|' + item) : (destinationCodes += item);
              });
              let destinationNames = '';
              this.state.searchParam.destinationNames.forEach((item, index) => {
                index ? (destinationNames += '|' + item) : (destinationNames += item);
              });
              let timeSearch = new Date().getTime();
              let param =
                `/flight/result?` +
                `&originNames=${originNames}` +
                `&originCodes=${originCodes}` +
                `&destinationCodes=${destinationCodes}` +
                `&destinationNames=${destinationNames}` +
                `&departureDateTimes=${dateArray}` +
                `&adult=${this.state.searchParam.adult}` +
                `&child=${this.state.searchParam.child}` +
                `&infant=${this.state.searchParam.infant}` +
                `&cabinClassCode=${this.state.searchParam.cabinClassCode}` +
                `&airTripType=${this.state.searchParam.airTripType}` +
                `&nonStop=${this.state.searchParam.nonStop}` +
                `&airlineCode=${this.state.searchParam.airlineCode}` +
                `&airlineText=${this.state.searchParam.airlineText}` +
                `&isEthnic=${this.state.searchParam.isEthnic}&id=${timeSearch}`;
              if (this.state.searchParam.supplierId) {
                param += `&supplierId=${this.state.searchParam.supplierId}`;
              }
              setStorage('ag_flight_history', param);
              this.props.history.push(param);
            }
          );

          //post form data
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        onSubmit={this.handleSubmit.bind(this)}
        className={this.props.home === 'true' ? 'search-section flight-search-section' : 'search-section flight-search-section bg-grey'}>
        <div className="container">
          <Radio.Group
            onChange={(e) => {
              this.changeTypeOfTrip(e.target.value);
            }}
            value={this.state.searchParam.airTripType}
            className="margin-bottom-light">
            <Radio value={'OneWay'}>One Way</Radio>
            <Radio value={'RoundTrip'}>Round Trip</Radio>
            <Radio value={'OpenJaw'}>Multi Destination</Radio>
          </Radio.Group>
          {this.state.numberOfTrip.map((item, index) => {
            return (
              <Row gutter={10} key={item}>
                <Col md={6} sm={12} xs={24} lg={this.props.home === 'true' ? 12 : 6} className="gutter-row">
                  <div className="form-group">
                    <label className="form-label no-margin-top">From</label>
                    <Autocomplete
                      defaultText={this.state.searchParam.originNames[index] ? this.state.searchParam.originNames[index] : null}
                      defaultValue={this.state.searchParam.originCodes[index] ? this.state.searchParam.originCodes[index] : null}
                      getValue={this.getCallback.bind(this)}
                      name={'origin'}
                      index={index}
                      type={'flight'}
                      validate={this.state.validation ? true : this.state.searchParam.originCodes[index] ? true : false}
                      url={Urls.Type + Urls.Flight + Flight.GetLocation}
                      min={3}
                      param="value"
                      header={HeaderRequest}
                    />
                  </div>
                </Col>
                <Col md={6} sm={12} xs={24} lg={this.props.home === 'true' ? 12 : 6} className="gutter-row">
                  <div className="form-group">
                    <label className="form-label no-margin-top">To</label>
                    <Autocomplete
                      defaultText={this.state.searchParam.destinationNames[index] ? this.state.searchParam.destinationNames[index] : null}
                      defaultValue={this.state.searchParam.destinationCodes[index] ? this.state.searchParam.destinationCodes[index] : null}
                      getValue={this.getCallback.bind(this)}
                      name={'destination'}
                      index={index}
                      type={'flight'}
                      validate={this.state.validation ? true : this.state.searchParam.destinationCodes[index] ? true : false}
                      url={Urls.Type + Urls.Flight + Flight.GetLocation}
                      min={3}
                      param="value"
                      header={HeaderRequest}
                    />
                  </div>
                </Col>
                <Col md={6} sm={12} xs={24} lg={this.props.home === 'true' ? 12 : 6} className="gutter-row clear-left-from-sm">
                  <div className="gutter-box">
                    <label className="form-label">Departure</label>
                    <div className="relative">
                      <Form.Item>
                        {getFieldDecorator(`departureDateTimes[${index}]`, {
                          initialValue: this.state.searchParam.departureDateTimes[index] && moment(this.state.searchParam.departureDateTimes[index]),
                          rules: [{ required: true, message: 'Please fill departure date' }, { validator: this.validateDateSeq }],
                        })(
                          <DatePicker
                            defaultPickerValue={this.props.form.getFieldsValue().departureDateTimes[index - 1] && this.props.form.getFieldsValue().departureDateTimes[index - 1]}
                            className="full-width"
                            block
                            placeholder=""
                            format={'YYYY-MM-DD'}
                            showTime={false}
                            disabledDate={this.disableDate.bind(this, index)}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                {index > 1 && (
                  <Col md={6} sm={12} xs={24} lg={this.props.home === 'true' ? 12 : 6} className="gutter-row">
                    <div className="gutter-box">
                      <Button
                        type="danger"
                        className="align-labels remove-flight-btn"
                        onClick={() => {
                          this.removeFlight(index);
                        }}
                        icon={'delete'}>
                        remove way
                      </Button>
                    </div>
                  </Col>
                )}
                {this.state.searchParam.airTripType === 'RoundTrip' && (
                  <Col md={6} sm={12} xs={24} lg={this.props.home === 'true' ? 12 : 6} className="gutter-row">
                    <div className="gutter-box">
                      <label className="form-label">Return</label>
                      <div className="relative">
                        <Form.Item>
                          {getFieldDecorator(`departureDateTimes[${index + 1}]`, {
                            initialValue: this.state.searchParam.departureDateTimes[index + 1] && moment(this.state.searchParam.departureDateTimes[index + 1]),
                            rules: [{ required: true, message: 'Please fill return date' }, { validator: this.validateDateSeq }],
                          })(
                            <DatePicker
                              defaultPickerValue={this.props.form.getFieldsValue().departureDateTimes[index]}
                              className="full-width"
                              block
                              format={'YYYY-MM-DD'}
                              placeholder=""
                              showTime={false}
                              disabledDate={this.disableDate.bind(this, index + 1)}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            );
          })}

          {this.state.searchParam.airTripType === 'OpenJaw' && this.state.numberOfTrip.length < 4 && (
            <Button className="margin-bottom" type={'primary'} icon={'plus'} onClick={() => this.addFlight()}>
              Add new way
            </Button>
          )}

          <Row gutter={15} className="set-details-row">
            <Col md={8} sm={8} xs={24} lg={this.props.home === 'true' ? 8 : 3}>
              <label className="form-label">Adult (12+)</label>
              <Form.Item>
                {getFieldDecorator(`adult`, {
                  initialValue: this.state.searchParam.adult,
                  rules: [{ validator: this.validateInfant }, { validator: this.validatePassengerCount }],
                })(
                  <Select className="itours-select" showSearch={true}>
                    <Select.Option value={1}>1</Select.Option>
                    <Select.Option value={2}>2</Select.Option>
                    <Select.Option value={3}>3</Select.Option>
                    <Select.Option value={4}>4</Select.Option>
                    <Select.Option value={5}>5</Select.Option>
                    <Select.Option value={6}>6</Select.Option>
                    <Select.Option value={7}>7</Select.Option>
                    <Select.Option value={8}>8</Select.Option>
                    <Select.Option value={9}>9</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col lg={this.props.home === 'true' ? 8 : 3} md={8} sm={8} xs={24}>
              <label className="form-label">Children (2-12)</label>
              <Form.Item>
                {getFieldDecorator(`child`, {
                  initialValue: this.state.searchParam.child,
                  rules: [{ validator: this.validatePassengerCount }],
                })(
                  <Select className="itours-select" showSearch={true}>
                    <Select.Option value={0}>0</Select.Option>
                    <Select.Option value={1}>1</Select.Option>
                    <Select.Option value={2}>2</Select.Option>
                    <Select.Option value={3}>3</Select.Option>
                    <Select.Option value={4}>4</Select.Option>
                    <Select.Option value={5}>5</Select.Option>
                    <Select.Option value={6}>6</Select.Option>
                    <Select.Option value={7}>7</Select.Option>
                    <Select.Option value={8}>8</Select.Option>
                    <Select.Option value={9}>9</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col lg={this.props.home === 'true' ? 8 : 3} md={8} sm={8} xs={24}>
              <label className="form-label">Infant (0-2)</label>
              <Form.Item>
                {getFieldDecorator(`infant`, {
                  initialValue: this.state.searchParam.infant,
                  rules: [{ validator: this.validateInfant }, { validator: this.validatePassengerCount }],
                })(
                  <Select className="itours-select" showSearch={true}>
                    <Select.Option value={0}>0</Select.Option>
                    <Select.Option value={1}>1</Select.Option>
                    <Select.Option value={2}>2</Select.Option>
                    <Select.Option value={3}>3</Select.Option>
                    <Select.Option value={4}>4</Select.Option>
                    <Select.Option value={5}>5</Select.Option>
                    <Select.Option value={6}>6</Select.Option>
                    <Select.Option value={7}>7</Select.Option>
                    <Select.Option value={8}>8</Select.Option>
                    <Select.Option value={9}>9</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col lg={this.props.home === 'true' ? 12 : 3} md={5} sm={8} xs={24}>
              <label className="form-label">Cabin Class</label>
              <Form.Item className="form-group">
                {getFieldDecorator(`cabinClassCode`, {
                  initialValue: this.state.searchParam.cabinClassCode,
                })(
                  <Select className="itours-select">
                    {className.map((item, index) => (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col lg={this.props.home === 'true' ? 12 : 3} md={5} sm={8} xs={24}>
              <div className="form-group">
                <label className="form-label">Preferred Airline</label>
                <Autocomplete
                  defaultText={this.state.searchParam.airlineText ? this.state.searchParam.airlineText : null}
                  defaultValue={this.state.searchParam.airlineCode ? this.state.searchParam.airlineCode : null}
                  getValue={this.getAirline.bind(this)}
                  name={'airlineCode'}
                  type={'flight'}
                  validate={true}
                  url={Urls.Type + Urls.Flight + Flight.GetAirline}
                  min={2}
                  param="value"
                  header={HeaderRequest}
                />
              </div>
            </Col>
            <Col lg={this.props.home === 'true' ? 12 : 3} md={5} sm={8} xs={24}>
              <div className="form-group">
                <label className="form-label">Stop</label>
                <Form.Item>
                  {getFieldDecorator(`nonStop`, {
                    initialValue: this.state.searchParam.nonStop,
                  })(
                    <Select className="itours-select">
                      <Select.Option value="false">all</Select.Option>
                      <Select.Option value="true">direct</Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </div>
            </Col>
            <Col lg={this.props.home === 'true' ? 12 : 3} md={5} sm={8} xs={24}>
              <div className="form-group">
                {this.props.isEthnic ? (
                  <>
                    <Form.Item>
                      {getFieldDecorator(`supplierId`, {
                        initialValue: null,
                      })(<input type={'hidden'} />)}
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <label className="form-label">Supplier</label>
                    <Form.Item>
                      {getFieldDecorator(`supplierId`, {
                        initialValue: this.state.selectedSupplier ? this.state.selectedSupplier : null,
                      })(
                        <Select className="itours-select">
                          <Select.Option value={null}>All</Select.Option>
                          {this.state.suppliersList &&
                            this.state.suppliersList.map((item) => (
                              <Select.Option key={item.supplierId} value={item.supplierName}>
                                {item.supplierName}
                              </Select.Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </>
                )}
              </div>
            </Col>
            <Col lg={this.props.home === 'true' ? 12 : 3} md={this.props.home === 'true' ? 4 : 5} sm={24} xs={24}>
              <Button
                icon={this.props.loadingFlight && this.state.showVisualLoad ? 'loading' : 'search'}
                loading={false}
                type="primary"
                block
                htmlType={'submit'}
                className="align-labels"
                onMouseDown={() => this.setState({ showVisualLoad: false })}
                onMouseUp={() => this.setState({ showVisualLoad: true })}>
                {this.state.searchButtonText}
              </Button>
            </Col>
          </Row>
        </div>
        <div className={this.props.loadingFlight && this.state.showVisualLoad ? 'progress loading-bar' : 'loading-bar'} />
      </Form>
    );
  }
}

const WrapFlightForm = Form.create({ name: 'flight_search' })(SearchForm);
const WithRouter = withRouter(WrapFlightForm);
//MapToProp
const MapStateToProp = (state) => ({
  loadingFlight: state.Flight.loadingFlight,
});
const MapDispatchToProp = (dispatch) => ({
  searchFlight: (param) => {
    dispatch(searchFlight(param));
  },
});
export default connect(MapStateToProp, MapDispatchToProp)(WithRouter);
