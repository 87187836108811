import { Button, Col, Icon, Modal, Pagination, Row, Spin } from 'antd';
import moment from 'moment';
//import library
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setFlightSorting } from '../../../../Actions/FlightAction';
//import model and URL
import { Sort, Travel } from '../../../../Enums/Types';
//import Actions
import FilterForm from '../../../commond/FilterForm';
//import React components
import ListItem from '../../../commond/ListItem';
import AirlineFilter from '../../Sections/Flight/AirlineFilter';
import SearchForm from '../../Sections/Flight/SearchFrom';

//import CSS
class FlightSearch extends React.Component {
  state = {
    timeoutModalVisibility: false,
    searchParam: {
      departureDateTimes: [moment(), moment()],
      originCodes: [0],
      originNames: [0],
      destinationCodes: [0],
      destinationNames: [0],
      airlineCode: 'all',
      airlineText: 'all',
      airTripType: 'RoundTrip',
      adult: 1,
      child: 0,
      infant: 0,
      cabinClassCode: 0,
      nonStop: 'false',
    },
    showResult: false,
    first: 0,
    last: 9,

    //state sort
    totalPrice: true,
    totaltimeTravel: true,
    //clickedSort
    sortSelect: 'totalPrice',
  };

  resultsTimer = null;

  componentDidMount() {
    this.setState({
      first: 0,
      last: 9,
    });
    if (window.location.pathname !== '/flight') {
      this.setState({
        showResult: true,
      });
    } else {
      this.setState({
        showResult: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.setState({
        first: 0,
        last: 9,
      });
      if (window.location.pathname !== '/flight') {
        this.setState({
          showResult: true,
        });
      } else {
        this.setState({
          showResult: false,
        });
      }
    }

    if (this.props.flights && this.props.flights.length > 0 && prevProps.flights !== this.props.flights) {
      this.resultsTimer = setTimeout(() => {
        this.setState({ timeoutModalVisibility: true });
      }, 600000);
    }

    if (this.props.location !== prevProps.location) {
      clearTimeout(this.resultsTimer);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.resultsTimer);
  }
  handleSort = (type, reserveMode) => {
    this.setState(
      (prevstate) => {
        return { [type]: !prevstate[type], sortSelect: type };
      },
      () => {
        this.props.setFlightSorting({ typeSort: type, isReverse: reserveMode });
      }
    );
  };
  //handle pagination number
  handleChangeValue = (e) => {
    let last = e * 10 - 1;
    let first = last - 9;
    this.setState({ first: first, last: last });

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };
  handleReload = () => {
    window.location.reload();
  };
  render() {
    return (
      <>
        <Modal visible={this.state.timeoutModalVisibility} footer={null} closable={false} className="timeOut-modal">
          <div className="text-center">
            <Icon type="clock-circle" className="timer-large-icon" />
            <h4 className="font-22 margin-bottom margin-top">Still around?</h4>
            <p>Flight prices change often based on the airline's availability. Refresh the page to see the latest rates.</p>
            <Button htmlType="button" type="primary" className="margin-bottom margin-top" onClick={this.handleReload}>
              Refresh
            </Button>
          </div>
        </Modal>
        <div className={this.state.showResult ? 'hidden-xs' : null}>
          <SearchForm home="false" />
        </div>
        {this.props.loadingFlight ? (
          <div className={'text-center loading-section'}>
            <Spin tip="loading..."></Spin>
          </div>
        ) : this.state.showResult ? (
          <div className="resultssection">
            <div className="airline-filter-section">
              <div className="container">
                <AirlineFilter />
              </div>
            </div>
            <div className="container">
              <div className="vr-padding">
                <Row gutter={15}>
                  <Col lg={6} md={8} sm={24} xs={24} className="margin-bottom">
                    <FilterForm type={'flight'} />
                  </Col>
                  <Col lg={18} md={16} sm={24} xs={24} className={'sortContainer'}>
                    <div className={'margin-bottom'}>
                      <Row gutter={[10, 10]}>
                        <Col lg={14} md={14} sm={24} xs={24} className={'buttonSort'}>
                          <span className={'font-18 margin-right hotel-results-number'}>
                            {this.props.flights && (
                              <span>
                                <b>{this.props.flights.length}</b> flight(s) found
                              </span>
                            )}{' '}
                          </span>
                          <Button.Group>
                            <Button
                              type={this.state.sortSelect === 'totalPrice' ? 'primary' : null}
                              value={0}
                              onClick={() => {
                                this.handleSort(Sort.flightPrice, this.state.totalPrice);
                              }}>
                              <span>
                                {!this.state.totalPrice ? (
                                  <span>
                                    Price <Icon type="caret-down" />
                                  </span>
                                ) : (
                                  <span>
                                    Price <Icon type="caret-up" />
                                  </span>
                                )}{' '}
                              </span>
                            </Button>
                            <Button
                              type={this.state.sortSelect === 'totaltimeTravel' ? 'primary' : null}
                              value={1}
                              onClick={() => {
                                this.handleSort(Sort.flightTime, this.state.totaltimeTravel);
                              }}>
                              <span>
                                {!this.state.totaltimeTravel ? (
                                  <span>
                                    {' '}
                                    Travel Time <Icon type="caret-down" />
                                  </span>
                                ) : (
                                  <span>
                                    {' '}
                                    Travel Time <Icon type="caret-up" />
                                  </span>
                                )}
                              </span>
                            </Button>
                          </Button.Group>
                        </Col>
                        {/*<Col lg={8} md={8} sm={24} xs={24} className={"paginationTop"}>*/}
                        {/*    {*/}
                        {/*        this.props.flights && <Page length={this.props.flights.length} itemPerPage={10} hideOnSinglePage={true} itemCurrent={this.state.first} onChangeValue={this.handleChangeValue} />*/}
                        {/*    }*/}
                        {/*</Col>*/}
                        <Col lg={10} md={10} sm={24} xs={24} className={'paginationTop'}>
                          {this.props.flights && (
                            <Pagination
                              showLessItems={true}
                              className="pull-right"
                              pageSize={10}
                              current={(this.state.last + 1) / 10}
                              total={this.props.flights.length}
                              onChange={this.handleChangeValue}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                    {/*<div><Button onClick={()=>this.handleSort(Sort.flightPrice,true)}>Test Sort</Button></div>*/}

                    {/*<div className="result-list-label-holder hidden-xs hidden-sm hidden-md">*/}
                    {/*    <Row gutter={15}>*/}
                    {/*        <Col  xs={24} md={18} xl={20}>*/}
                    {/*            <div className="flight-results-label">*/}
                    {/*                <div className="airline-col">*/}
                    {/*                    Airline*/}
                    {/*                </div>*/}
                    {/*                <div className="stops-col">*/}
                    {/*                    Stop*/}
                    {/*                </div>*/}
                    {/*                <div className="depart-col">*/}
                    {/*                    Departure*/}
                    {/*                </div>*/}
                    {/*                <div className="arrive-col">*/}
                    {/*                    Arrival*/}
                    {/*                </div>*/}
                    {/*                <div className="duration-col">*/}
                    {/*                    Duration*/}
                    {/*                </div>*/}
                    {/*                <div className="baggage-col">*/}
                    {/*                    Baggage*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        <Col  xs={24} md={6} xl={4}>*/}
                    {/*            <div className="item-footer" />*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</div>*/}
                    <div className="result-list-label-holder hidden-xs">
                      <Row className={'padding-cols'}>
                        <Col xs={12} sm={8} md={7} lg={8}>
                          Airline
                        </Col>
                        {/* <Col xs={12} sm={12} md={4} lg={3}>Cabin Class</Col> */}

                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div className="org-dur-dst">
                            <div>Departure</div>
                            <div>Duration</div>
                            <div>Arrival</div>
                          </div>
                        </Col>
                        {/* <Col xs={12} sm={12} md={12} lg={3}>Stop</Col> */}
                        <Col xs={12} sm={4} md={5} lg={4} className="baggage-column">
                          Baggage
                        </Col>
                      </Row>
                    </div>
                    <ListItem data={this.props.flights} start={this.state.first} last={this.state.last} type={Travel.flight} />
                    <div className="clearfix margin-top-light">
                      <div className="pull-right">
                        {this.props.flights && (
                          <Pagination
                            showLessItems={true}
                            className="pull-right"
                            pageSize={10}
                            current={(this.state.last + 1) / 10}
                            total={this.props.flights.length}
                            onChange={this.handleChangeValue}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
//MapToDispatch
const MapDispatchToProp = (dispatch) => ({
  setFlightSorting: (param) => {
    dispatch(setFlightSorting(param));
  },
});
//MapToProp
const MapStateToProp = (state) => ({
  flights: state.Flight.flights,
  loadingFlight: state.Flight.loadingFlight,
});
export default withRouter(connect(MapStateToProp, MapDispatchToProp)(FlightSearch));
