import '../../CSS/PDatePicker.scss';

import moment from 'moment-jalaali';
import React from 'react';
import { Calendar } from 'react-datepicker2';

export default class PDatePicker extends React.PureComponent {
  state = {
    isGregorian: false,
    showCalendar: false,
    isOpen: false,
  };
  toggleContainer = React.createRef();
  componentDidMount() {
    moment.loadPersian({ dialect: 'persian-modern', usePersianDigits: false });
    window.addEventListener('click', this.onClickOutsideHandler);
    document.querySelectorAll('.PDatePicker-calnedar .heading .title').forEach((item) => {
      item.removeEventListener('click', this.onClickOutsideHandler);
    });
    if (this.props.gregorianMode) {
      this.setState({ isGregorian: true });
    } else {
      this.setState({ isGregorian: false });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.gregorianMode !== prevProps.gregorianMode) {
      if (this.props.gregorianMode) {
        this.setState({ isGregorian: true });
      } else {
        this.setState({ isGregorian: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }
  onClickHandler = () => {
    this.setState((currentState) => ({
      isOpen: !currentState.isOpen,
    }));
  };
  onClickOutsideHandler = (event) => {
    if (event.target.type !== 'submit') {
      if (this.state.isOpen && !this.toggleContainer.current.contains(event.target)) {
        this.setState({ isOpen: false });
      }
    }
  };
  toggleMenu() {
    this.setState((prevState) => {
      return { showCalendar: !prevState.showCalendar };
    });
  }
  getCustomFormat(inputVal, isGregorian) {
    let inputValue = inputVal;
    if (!inputValue) {
      return '';
    }
    const inputFormat = isGregorian ? 'YYYY/M/D' : 'jYYYY/jM/jD';

    inputValue = moment(inputValue).format(inputFormat);

    return moment(inputValue, inputFormat);
  }
  showValue(value) {
    if (value) {
      if (typeof value === 'string') {
        return value;
      } else {
        if (this.state.isGregorian) {
          return moment(value).format('YYYY-MM-DD');
        } else {
          return moment(value).format('jYYYY-jMM-jDD');
        }
      }
    }
  }
  changeType() {
    this.setState((prevState) => {
      return {
        isGregorian: !prevState.isGregorian,
      };
    });
  }
  onChange(value) {
    this.setState({
      isOpen: false,
    });
    this.props.onChange(value);
  }
  render() {
    return (
      <div className={'PDatePicker-holder'} ref={this.toggleContainer}>
        <input
          className={'PDatePicker-input'}
          type={'text'}
          placeholder={this.props.placeholder}
          value={this.showValue(this.props.value)}
          onClick={this.onClickHandler}
          onChange={this.onClickHandler}
          readOnly={true}
        />
        {
          <div style={this.state.isOpen ? { display: 'block' } : { display: 'none' }}>
            <div className={`PDatePicker-buttons clearfix ${this.props.gregorianMode ? '' : 'font-fa'}`}>
              {this.props.diableTypeChange ? null : (
                <button className={'PDatePicker-switch button color-1-btn pull-right font-fa'} type={'button'} onClick={this.props.changeType}>
                  {this.props.gregorianMode ? 'تقویم شمسی' : 'تقویم میلادی'}
                </button>
              )}
              <button className="PDatePicker-close button red-btn pull-left" type={'button'} onClick={this.onClickHandler}>
                {this.props.gregorianMode ? 'close' : 'بستن'}
              </button>
            </div>
            <Calendar
              inputFormat={'YYYY-MM-DD'}
              inputJalaaliFormat={'jYYYY-jMM-jDD'}
              className={this.props.gregorianMode ? 'PDatePicker-calnedar english' : 'PDatePicker-calnedar persian'}
              min={this.props.min}
              max={this.props.max}
              isGregorian={this.props.gregorianMode}
              value={this.getCustomFormat(this.props.value, this.props.gregorianMode)}
              timePicker={false}
              onChange={(value) => this.onChange(value)}
              setTodayOnBlur={false}
              showTodayButton={false}
            />
          </div>
        }
      </div>
    );
  }
}
