import { Button, Checkbox, Form, Icon, Input } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { getTenant, loginUser, sendEmailRecoverUser } from '../../../../Actions/AccountAction';
import Account from '../../../../APIs/Account';
import Logo from '../../../../Images/design/itours-logo.svg';

class Login extends React.Component {
  //input of form
  state = { username: null, password: null, isRemember: false, recoverEmail: null, showRecover: false };

  componentDidUpdate(prevProps, prevState, snapshot) {
    //Account.checkTenant();
  }

  componentDidMount() {
    Account.checkTenant();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        if (e.target.id === 'login') {
          this.props.loginUser({
            username: value.username,
            password: value.password,
            Name: value.Name,
            remember: this.state.isRemember,
          });
        } else {
          this.props.sendEmailRecoverUser({ emailAddress: this.state.recoverEmail });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form" id={!this.state.showRecover ? 'login' : 'recover'}>
        {/* <div className={'logo'}>
          <img src={Logo} alt={'itoursAgent'} title={'itoursAgent'} />
        </div> */}
        <h1 className="text-center bold">Travelport</h1>
        {!this.state.showRecover ? (
          <>
            <label>Enter your Office ID:</label>
            <Form.Item>
              {getFieldDecorator('Name', {
                rules: [{ required: true, message: 'Please input your Office ID:' }],
              })(<Input autoComplete={'off'} prefix={<Icon type="user" className={'icon_input'} />} placeholder="office ID" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(
                <Input
                  autoComplete={'off'}
                  prefix={<Icon type="user" className={'icon_input'} />}
                  placeholder="Username"
                  onChange={(e) => {
                    this.setState({ username: e.target.value });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your password!' }],
              })(
                <Input.Password
                  autoComplete={'off'}
                  prefix={<Icon type="safety" className={'icon_input'} />}
                  placeholder={'Password'}
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item>
              <Checkbox
                onChange={(e) => {
                  e.target.checked && this.setState({ isRemember: e.target.checked });
                }}>
                Remember Me (save your account info for 30days)
              </Checkbox>
            </Form.Item>

            <Form.Item>
              {
                <>
                  <Button className="min-width-150" loading={this.props.showInlineLoad} type="primary" size={'large'} htmlType={'submit'}>
                    Login
                  </Button>
                  {/*<div className="margin-top-light">*/}
                  {/*    <Button className="no-padding" type="link" onClick={() => {*/}
                  {/*        this.setState({showRecover: true})*/}
                  {/*    }}>*/}
                  {/*        I forget my password*/}
                  {/*    </Button>*/}
                  {/*</div>*/}
                  {/*<div>*/}
                  {/*    <Button className="no-padding" type="link" onClick={() => {*/}
                  {/*        CookieManage.deleteCookie("ag_it_tenant");*/}
                  {/*        window.location.reload()*/}
                  {/*    }}>*/}
                  {/*        I want to change my office ID*/}
                  {/*    </Button>*/}
                  {/*</div>*/}
                </>
              }
            </Form.Item>
          </>
        ) : (
          <>
            <div>Please enter your email to send link to your email for reset your password</div>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ],
              })(
                <Input
                  autoComplete={'off'}
                  prefix={<Icon type="mail" className={'icon_input'} />}
                  placeholder={'Your email address'}
                  onChange={(e) => {
                    this.setState({ recoverEmail: e.target.value });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="margin-right"
                onClick={() => {
                  this.setState({ showRecover: false });
                }}>
                Try to login
              </Button>
              <Button loading={this.props.showInlineLoad} type="primary" htmlType={'submit'}>
                Send email
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    );
  }
}
//map
const MapStateToProp = (state) => ({
  showInlineLoad: state.Notification.loading.showInline,
  tenant: state.User.tenant,
});
const MapDispatchToProp = (dispatch) => ({
  loginUser: (input) => {
    dispatch(loginUser(input));
  },
  sendEmailRecoverUser: (input) => {
    dispatch(sendEmailRecoverUser(input));
  },
  getTenant: (input) => {
    dispatch(getTenant(input));
  },
});
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(Login);
export default connect(MapStateToProp, MapDispatchToProp)(WrappedNormalLoginForm);
