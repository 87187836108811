import { Breadcrumb, Button, Col, DatePicker, Dropdown, Form, Icon, Input, Layout, Menu, Pagination, Row, Select, Spin, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Urls, getHeaderRequest } from '../../../../Enums/APIAddress';
import { CurrencyType, ReserveType } from '../../../../Enums/Types';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type Id',
    dataIndex: 'typeId',
    key: 'typeId',
  },
  {
    title: 'Date from',
    dataIndex: 'dateFrom',
    key: 'dateFrom',
    render: (date) => (date ? moment(date, 'YYYY-MM-DDTHH:mm:SS').format('YYYY/MM/DD') : '-'),
  },
  {
    title: 'Date to',
    dataIndex: 'dateTo',
    key: 'dateTo',
    render: (date) => (date ? moment(date, 'YYYY-MM-DDTHH:mm:SS').format('YYYY/MM/DD') : '-'),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },

  {
    title: 'Currency Id',
    dataIndex: 'currencyId',
    key: 'currencyId',
    render: (dataset) => {
      if (!dataset) {
        return <span>%</span>;
      } else {
        return <span>{dataset}</span>;
      }
    },
  },
  {
    title: 'Is Enabled',
    key: 'isEnabled',
    render: (dataSet) => {
      return dataSet.isEnabled ? <Icon style={{ color: 'green' }} type="check" /> : <Icon style={{ color: 'red' }} type="close" />;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (dataSet) => (
      <Link to={`/commission/edit/${dataSet.id}?type=${dataSet.typeId}`}>
        <Button type="primary" icon="edit">
          Edit
        </Button>
      </Link>
    ),
  },
];
class Index extends Component {
  state = {
    data: [],
    spinner: true,
    currencyType: Object.keys(CurrencyType),
    supplierType: [],
    transferType: Object.keys(ReserveType),
    countData: 0,
    param: {
      name: null,
      dateRange: null,
      currencyId: null,
      typeId: null,
      pageNumber: 1,
      pageSize: 20,
    },
  };
  componentDidMount() {
    this._getData(this.state.param);
  }
  _getData = (req) => {
    this.setState({ spinner: true }, () => {
      let headers = getHeaderRequest(true);
      axios
        .post(
          Urls.GraphQL,
          {
            query: `
                    mutation($filter:CommissionInputType){
                        commissions(filter:$filter)
                        {
                        commissions{
                          name,
                          isEnabled,
                          typeId,
                          dateFrom,
                          dateTo,
                          amount,
                          currencyId ,
                          id
                        },
                        totalCount
                      }
                    }
                `,
            variables: {
              filter: {
                ...req,
              },
            },
          },
          { headers: { ...headers } }
        )
        .then((data) => {
          if (data.data.data.commissions) {
            this.setState({
              spinner: false,
              data: data.data.data.commissions.commissions,
              countData: data.data.data.commissions.totalCount,
            });
          }
        });
    });
  };
  _fetchData = () => {
    let param = {
      name: this.state.name || null,
      dateRange: this.state.dateRange || null,
      currencyId: this.state.currencyId ? String(this.state.currencyId) : null,
      typeId: this.state.typeId || null,
    };
    this.setState(
      {
        param: {
          ...this.state.param,
          ...param,
        },
      },
      () => {
        this._getData(this.state.param);
      }
    );
  };
  onChangePage = (e) => {
    this.setState(
      {
        param: {
          ...this.state.param,
          pageNumber: e,
        },
      },
      () => {
        this._fetchData();
      }
    );
  };
  onChangeDataPicker = (dates, dateStrings) => {
    if (dateStrings[0]) {
      this.setState({
        dateRange: dateStrings[0] + '-' + dateStrings[1],
      });
    } else {
      this.setState({
        dateRange: null,
      });
    }
  };
  render() {
    const subMenu = (
      <Menu onClick={null}>
        <Menu.Item key="1">
          <Link to={`/commission/create?type=${ReserveType.HOTEL_GLOBAL}`}>Hotel Commission</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to={`/commission/create?type=${ReserveType.FLIGHT_GLOBAL}`}>Flight Commission</Link>
        </Menu.Item>
      </Menu>
    );
    let { data } = this.state;
    return (
      <Content>
        <div className="breadcrumpt-section">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={'/'}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={'/commission'}>Commission</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="container">
          {/*<Link to={`/commission/create`}>*/}
          {/*    <Button className="margin-bottom"*/}
          {/*        type="primary">*/}
          {/*        create a new Commiton*/}
          {/*    </Button>*/}
          {/*</Link>*/}
          <Dropdown overlay={subMenu}>
            <Button type={'primary'} className={'margin-bottom'}>
              Create new Commission <Icon type="down" />
            </Button>
          </Dropdown>
          <Form>
            <Row gutter={10}>
              <Col xs={24} sm={12} md={6} lg={5}>
                <label className="block-label">Name</label>
                <Form.Item>
                  <Input onChange={(e) => this.setState({ name: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={4} lg={4} xl={3} className="margin-bottom">
                <label className="block-label"> Type</label>
                {this.state.transferType ? (
                  <Select defaultValue={null} showSearch placeholder="Select a Type" onChange={(value) => this.setState({ typeId: value })}>
                    <Select.Option value={null}>All</Select.Option>
                    {this.state.transferType.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <span>Loading...</span>
                )}
              </Col>
              <Col xs={24} sm={12} md={6} lg={5} className="margin-bottom">
                <label className="block-label">Data Range</label>
                <RangePicker format={dateFormat} onChange={this.onChangeDataPicker} />
              </Col>
              <Col xs={24} sm={12} md={4} lg={3} xl={2} className="margin-bottom">
                {this.state.currencyType ? (
                  <>
                    <label className="block-label">Currency </label>
                    <Select defaultValue={null} showSearch placeholder="Select a Currency" onChange={(value) => this.setState({ currencyId: value })}>
                      <Select.Option value={null}>All</Select.Option>
                      {this.state.currencyType.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <span>Loading...</span>
                )}
              </Col>
              <Col xs={24} sm={24} md={4} lg={2}>
                <label className="block-label">Filter</label>
                <Form.Item>
                  <Button onClick={() => this._fetchData()} htmlType="submit" type="primary">
                    Apply filter
                    <Icon type="filter" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {data ? (
            <>
              <Table loading={this.state.spinner} className="itours-table users-list-table" pagination={false} rowKey="id" columns={columns} dataSource={data} />
              <div className="clearfix margin-top margin-bottom">
                <Pagination className="pull-right" pageSize={20} defaultCurrent={1} total={this.state.countData} onChange={this.onChangePage} />
              </div>
            </>
          ) : (
            <div className="text-center loading-section">
              <Spin tip="Loading data ..." />
            </div>
          )}
        </div>
      </Content>
    );
  }
}

export default Index;
