import { Button, Card, Col, Collapse, Icon, Input, Modal, Popover, Result, Row, Select, Skeleton, Spin, Statistic, Table, Tabs, Tag } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
//import library
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getDeposits, getDepositsGateways, sendDepositMethod } from '../../../../Actions/PaymentAction';
// import hintPicture from '../../../../Images/design/offlinepaimenthint.jpg';
import PaymentApi from '../../../../APIs/Payment';
import { Urls } from '../../../../Enums/APIAddress';
//import model and action
import { Currency } from '../../../../Enums/Types';
import Price from '../../../commond/Price';
//import component
import ManualReceiptForm from './manualReceiptForm';
import PaymentList from './PaymentList';

class AddDeposit extends React.Component {
  state = {
    selectCurrency: this.props.currency,
    amount: 0,
    sendData: false,
    transactionLoading: true,
    paymentListLoading: true,
    transactionListData: [],
    paymentListData: [],
    modalVisibility: false,
    modalStatus: '',
    modalTitle: '',
    modalDescriptions: '',
    ammountMinErr: false,
    ammountEmptyErr: false,
  };
  //openModal
  openModal = (option) => {
    this.setState({
      modalVisibility: true,
      modalStatus: option.status,
      modalTitle: option.title,
      modalDescriptions: option.description,
    });
  };
  //closeModal
  closeModal = () => {
    this.setState({
      modalVisibility: false,
      modalStatus: '',
      modalTitle: '',
      modalDescriptions: '',
    });
    const location = window.location.href;
    if (location.includes('?')) {
      const newLocation = location.split('?')[0];
      window.location.assign(newLocation);
    }
  };
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //     if (this.props.currency !== prevProps.currency) {
  //         if(this.props.currency){
  //             let param = new URLSearchParams();
  //             param.append("Currency", this.props.currency);
  //             this.props.getDepositsGateways(param);
  //         }
  //     }
  // }
  // changeCurrency = (currency) => {
  //     this.setState({
  //         selectCurrency: currency
  //     }, () => {
  //         let param = new URLSearchParams();
  //         param.append("Currency", this.state.selectCurrency);
  //         this.props.getDepositsGateways(param);
  //     });
  // };
  async componentDidMount() {
    //get transactions list
    const response = await PaymentApi.getReport();
    this.setState({
      transactionListData: response.data.result.items,
      transactionLoading: false,
    });

    //get manual receipt payments list
    const responsePaymentsList = await PaymentApi.getPaymentsReport('maxresultcount=100');
    this.setState({
      paymentListData: responsePaymentsList.data.result.items,
      paymentListLoading: false,
    });

    //getDepositsGateway
    let param = new URLSearchParams();
    param.append('CurrencyType', this.props.currency);
    const gateWaysList = await PaymentApi.getDepositsGateway(param);
    this.setState({
      gateWays: gateWaysList,
    });

    //show deposit
    this.props.getDeposits();
    //load payment list
    // if(this.props.currency){
    //     let param = new URLSearchParams();
    //     param.append("Currency", this.props.currency);
    //     this.props.getDepositsGateways(param);
    // }
    // check payment status
    let parameters = queryString.parse(this.props.location.search);
    if (parameters.status) {
      if (parseInt(parameters.status)) {
        this.openModal({
          status: 'success',
          title: 'Payment completed successfully',
          description: 'your deposit charged',
        });
      } else {
        this.openModal({
          status: 'error',
          title: 'Payment failed',
          description: 'deposit charging failed',
        });
      }
    }
  }
  bankSelect = (bankID, param) => {
    this.setState(
      {
        sendData: true,
      },
      () => {
        if (this.state.amount) {
          let params = {
            userId: this.props.userID,
            amount: parseInt(this.state.amount),
            gatewayId: bankID,
            callbackUrl: `${Urls.Type}${Urls.Local.Url}/payment/deposit`,
            ipAddress: Urls.Local.Url,
            formParams: { ...param },
            // currencyId: this.state.selectCurrency,
            currencyId: 'IRR',
          };
          this.props.sendDepositMethod(params);
        }
      }
    );
  };
  //charge deposit value check:
  changeAmount = (e) => {
    this.setState({ amount: e.target.value });
    if (e.target.value) {
      this.setState({ ammountEmptyErr: false });
    }
    if (e.target.value >= 10000) {
      this.setState({ ammountMinErr: false });
    }
  };
  blurAmount = () => {
    if (this.state.amount) {
      this.setState({ ammountEmptyErr: false });
      if (this.state.amount < 10000) {
        this.setState({ ammountMinErr: true });
      } else {
        this.setState({ ammountMinErr: false });
      }
    } else {
      this.setState({ ammountEmptyErr: true });
      this.setState({ ammountMinErr: false });
    }
  };

  render() {
    const { Panel } = Collapse;
    const { TabPane } = Tabs;
    const reservesListLink = <Link to={'/payment/transactions'}>See All Transactions</Link>;

    const TransactionsTableLoadingData = [
      { fakeRecord1: 'data1', key: 1 },
      { fakeRecord1: 'data2', key: 2 },
      { fakeRecord1: 'data3', key: 3 },
      { fakeRecord1: 'data4', key: 4 },
    ];
    const TransactionsTableLoadingHead = [
      {
        title: 'Date And Time',
        dataIndex: 'dateAndTime',
        key: 'dateAndTime',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Transaction Type',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0, width: 200 }} title={{ width: '85%' }} />,
      },
      {
        title: 'Descriptions',
        key: 'information',
        dataIndex: 'information',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0, width: 200 }} title={{ width: '85%' }} />,
      },
      {
        title: 'Amount',
        key: 'price',
        dataIndex: 'price',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Credit balance',
        key: 'initialAmount',
        dataIndex: 'initialAmount',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
    ];
    const paymentsTableLoadingHead = [
      {
        title: 'Holder Name',
        dataIndex: 'holderName',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
        key: 'holderName',
      },
      {
        title: 'Status',
        key: 'paymentStatus',
        dataIndex: 'paymentStatus',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },

      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Bank Brand',
        dataIndex: 'bankBrand',
        key: 'bankBrand',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Transfer Number',
        dataIndex: 'transactionNumber',
        key: 'transactionNumber',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Transfer Time',
        dataIndex: 'transferTime',
        key: 'transferTime',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Details',
        key: 'details',
        dataIndex: 'details',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
    ];
    const TransactionsTableHead = [
      {
        title: 'Date And Time',
        dataIndex: 'dateAndTime',
        key: 'dateAndTime',
        className: 'no-wrap padding-10',
        render: (text, record) => (
          <>
            <div className="no-wrap">{moment(record.creationTime).format('YYYY-MM-DD')}</div>
            <div>{moment(record.creationTime).format('hh:mm')}</div>
          </>
        ),
      },
      {
        title: 'Transaction Type',
        dataIndex: 'type',
        key: 'type',
        className: 'padding-10 font-12',
        render: (text, record) =>
          record.type === 'IncreaseDepositByPaymentGateway'
            ? 'Increase deposit by payment gateway'
            : record.type === 'IncreaseDepositByRefund'
            ? 'Increase deposit by refund'
            : record.type === 'IncreaseDepositByReverse'
            ? 'Increase deposit by reverse'
            : record.type === 'IncreaseDeposit'
            ? 'Increase deposit'
            : record.type === 'ManualIncreaseDeposit'
            ? 'Manual increase deposit'
            : record.type === 'DecreaseDepositBySale'
            ? 'Decrease deposit by sale'
            : record.type === 'DecreaseDepositByWithdraw'
            ? 'Decrease deposit by withdraw'
            : record.type === 'DecreaseDeposit'
            ? 'Decrease deposit'
            : record.type === 'ManualDecreaseDeposit'
            ? 'Manual decrease deposit'
            : record.type,
      },
      {
        title: 'Descriptions',
        key: 'information',
        dataIndex: 'information',
        className: 'padding-10 font-12',
        render: (text, record) => <div>{record.information ? record.information : null}</div>,
      },
      {
        title: 'Amount',
        key: 'price',
        dataIndex: 'price',
        className: 'padding-10',
        render: (text, record) => (
          <div>
            {record.amount > 0 ? (
              <>
                <b className="green font-18">+ </b>
                <Price currency={record.currencyType}>{record.amount}</Price>
              </>
            ) : record.amount < 0 ? (
              <>
                <b className="red font-18">- </b>
                <Price currency={record.currencyType}>{-1 * record.amount}</Price>
              </>
            ) : (
              0
            )}
          </div>
        ),
      },
      {
        title: 'Credit balance',
        key: 'initialAmount',
        dataIndex: 'initialAmount',
        className: 'padding-10',
        render: (text, record) =>
          record.currencyType ? (
            <div className="green">
              <Price currency={record.currencyType}>{record.initialAmount}</Price>
            </div>
          ) : (
            0
          ),
      },
    ];
    const TransactionsTableData = this.state.transactionListData;
    const PaymentsTableHead = [
      {
        title: 'Holder Name',
        dataIndex: 'holderName',
        className: 'no-wrap padding-10',
        key: 'holderName',
      },
      {
        title: 'Status',
        key: 'paymentStatus',
        className: 'no-wrap padding-10',
        dataIndex: 'paymentStatus',
        render: (text, record) => (
          <Tag
            color={
              record.status === 'Unaccepted'
                ? 'red'
                : record.status === 'Accepted'
                ? 'green'
                : record.status === 'Duplicate'
                ? 'gold'
                : record.status === 'Register'
                ? 'blue'
                : record.status === 'Increase'
                ? '#4caf50'
                : null
            }
            className="no-margin">
            {record.status === 'Increase' ? <Icon className="margin-right-light" type="check" /> : null}
            {record.status}
          </Tag>
        ),
      },

      {
        title: 'Type',
        key: 'type',
        className: 'no-wrap padding-10',
        dataIndex: 'type',
      },
      {
        title: 'Price',
        key: 'price',
        className: 'no-wrap padding-10',
        dataIndex: 'price',
        render: (text, record) => <Price currency={record.currencyType}>{record.amount}</Price>,
      },
      {
        title: 'Bank Brand',
        dataIndex: 'bankBrand',
        className: 'no-wrap padding-10',
        key: 'bankBrand',
      },
      {
        title: 'Transfer Number',
        dataIndex: 'transactionNumber',
        className: 'no-wrap padding-10',
        key: 'transactionNumber',
      },
      {
        title: 'Transfer Time',
        dataIndex: 'transferTime',
        key: 'transferTime',
        className: 'no-wrap padding-10',
        render: (text, record) => (
          <>
            <div className="no-wrap">{moment(record.transferTime).format('YYYY-MM-DD')}</div>
            <div>{moment(record.transferTime).format('hh:mm')}</div>
          </>
        ),
      },
      {
        title: 'Details',
        key: 'details',
        className: 'padding-10',
        dataIndex: 'details',
        render: (text, record) =>
          record.operatorDescription ? (
            <Popover content={<div className="card-padding">{record.operatorDescription}</div>} className="operator-description" title="Operator Description" trigger="click">
              <Button className="no-padding" type="link">
                <Icon type="info-circle" />
              </Button>
            </Popover>
          ) : null,
      },
    ];
    const PaymentsTableData = this.state.paymentListData;
    return this.props.permissions && this.props.permissions.includes('ChargeDeposit') ? (
      <div className="container vr-padding deposit-page">
        <Modal visible={this.state.modalVisibility} footer={null} closable={false} className={'higher-z-index'}>
          <Result
            icon={this.state.modalStatus === 'info' ? <Spin /> : null}
            status={this.state.modalStatus}
            title={this.state.modalTitle}
            subTitle={this.state.modalDescriptions}
            extra={
              this.state.modalStatus === 'info' ? null : (
                <Button htmlType="button" type={'primary'} onClick={this.closeModal}>
                  Ok
                </Button>
              )
            }
          />
        </Modal>
        <h3 className="page-heading">Your deposit</h3>
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12} lg={14} xl={16}>
            <div className="deposit-lists">
              {this.props.depositList && this.props.depositList.length > 0 ? (
                this.props.depositList.map((item) => {
                  return <Statistic key={item.currencyType} title={item.currencyType} value={item.amount} />;
                })
              ) : this.props.depositList ? (
                <div className="font-18">
                  <Icon type="warning" className="red" />
                  <span> You have no deposit! </span>
                </div>
              ) : (
                <div className="text-center vr-padding">
                  <Spin />
                </div>
              )}
            </div>
          </Col>
          <Col xs={24} md={12} lg={10} xl={8} className="charge-deposit-col">
            {/* if currency is IRR, show gateways */}
            {this.props.currency === 'IRR' ? (
              <>
                <h5 className="normal-title">Charge Deposit</h5>
                <Collapse accordion defaultActiveKey={['1']}>
                  <Panel header="Online Payment" key="1">
                    <div>
                      <Row gutter={15} className="margin-bottom">
                        <Col xs={8} md={6}>
                          {this.props.currency && (
                            <>
                              <label htmlFor={'selCurrency'}>Currency:</label>
                              <Select disabled id={'selCurrency'} defaultValue={this.props.currency}>
                                {/* <Select id={"selCurrency"} defaultValue={this.props.currency} onChange={(e) => this.changeCurrency(e.target.value)}> */}
                                {Currency.map((item, index) => (
                                  <Select.Option value={item.value} key={index}>
                                    {item.value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </>
                          )}
                        </Col>
                        <Col xs={16} md={18}>
                          <label htmlFor={'currency'}>Amount:</label>
                          <Input id={'currency'} onBlur={this.blurAmount} onChange={(e) => this.changeAmount(e)} />
                          {this.state.ammountEmptyErr ? <div className="red">You must enter amount</div> : null}
                          {this.state.ammountMinErr ? <div className="red">The amount must be at least 1000</div> : null}
                        </Col>
                      </Row>
                      {this.state.gateWays && this.state.gateWays.length > 0 ? (
                        <PaymentList isDisabled={this.state.amount < 10000} banks={this.state.gateWays} selectedBankthis={this.bankSelect} />
                      ) : (
                        <div>
                          <Card className="margin-bottom">
                            <Skeleton active avatar paragraph={{ rows: 1 }} />
                          </Card>
                          <Card>
                            <Skeleton active avatar paragraph={{ rows: 1 }} />
                          </Card>
                        </div>
                      )}
                    </div>
                  </Panel>
                  <Panel header="Offline Payment" key="2">
                    <ManualReceiptForm currency={this.props.currency} />
                  </Panel>
                </Collapse>
              </>
            ) : (
              <Card title="Charge Deposit">
                <ManualReceiptForm currency={this.props.currency} />
              </Card>
            )}
          </Col>
          <Col xs={24} md={12} lg={14} xl={16}>
            <Tabs type="card" tabBarExtraContent={this.props.permissions && this.props.permissions.includes('ViewTransactions') && reservesListLink} className="tab-extra-block-xs">
              <TabPane tab="Payments" key="1">
                {this.state.paymentListLoading ? (
                  <Table columns={paymentsTableLoadingHead} dataSource={TransactionsTableLoadingData} />
                ) : (
                  <Table columns={PaymentsTableHead} rowKey="id" dataSource={PaymentsTableData} />
                )}
              </TabPane>

              {this.props.permissions && this.props.permissions.includes('ViewTransactions') && (
                <TabPane tab="Transactions" key="2">
                  {this.state.transactionLoading ? (
                    <Table columns={TransactionsTableLoadingHead} dataSource={TransactionsTableLoadingData} />
                  ) : (
                    <Table
                      columns={TransactionsTableHead}
                      rowClassName={(record, index) => (record.amount < 0 ? 'light-red-bg' : null)}
                      rowKey="id"
                      dataSource={TransactionsTableData}
                    />
                  )}
                </TabPane>
              )}
            </Tabs>
          </Col>
        </Row>
      </div>
    ) : null;
  }
}

const stateMapProp = (state) => ({
  currency: state.User.currency,
  depositBanks: state.Payment.depositBanks,
  userID: state.User.id,
  depositList: state.Payment.depositList,
  permissions: state.User.permissions,
});
const dispatchToProp = (dispatch) => ({
  getDepositsGateways: (param) => {
    dispatch(getDepositsGateways(param));
  },
  sendDepositMethod: (param) => {
    dispatch(sendDepositMethod(param));
  },
  getDeposits: () => dispatch(getDeposits()),
});
export default connect(stateMapProp, dispatchToProp)(AddDeposit);
