import '../../CSS/Layout.scss';

import { BackTop, Button, notification } from 'antd';
import { Layout } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import { authentication, getPermissions } from '../../Actions/AccountAction';
import { setLoading, setNotification } from '../../Actions/NotificationAction';
import { getDeposits } from '../../Actions/PaymentAction';
import { getIpLocation } from '../../Actions/SharedActions';
import NotFound from '../../Components/Errors/NotFound';
import AddDeposit from '../../Components/view/Sections/Payment/AddDeposit';
import TransactionReports from '../../Components/view/Sections/Payment/Transactions';
import Logo from '../../Images/design/Logo.svg';
import { getStorage } from '../../utils/localstorage';
import ErrorBoundary from '../Errors/ErrorBoundary';
import { default as CompanyIndex } from '../view/Sections/Company/Index';
import AccountIndex from './Sections/Account/AccountIndex';
import Login from './Sections/Account/Login';
import ResetPassword from './Sections/Account/ResetPassword';
import UserCreate from './Sections/Account/UserCreate';
import CommissionCreate from './Sections/Comission/CommissionCreate';
import CommissionEdit from './Sections/Comission/CommissionEdit';
import CommissionIndex from './Sections/Comission/Index';
import DomesticFlightSearch from './Sections/Flight/DomesticFlightSearch';
import FlightGetPassenger from './Sections/Flight/FlightGetPassenger';
import FlightSearch from './Sections/Flight/FlightSearch.js';
import FlightTicketing from './Sections/Flight/FlightTicketing';
import Home from './Sections/Home';
import HotelConfirmPayment from './Sections/Hotel/HotelConfirmPayment';
import HotelDetails from './Sections/Hotel/HotelDetails';
import HotelSearch from './Sections/Hotel/HotelSearch';
import HotelSetPassenger from './Sections/Hotel/HotelSetPassenger';
import Header from './Sections/Layout/Header';
import ChoicePayment from './Sections/Payment/ChoicePayment';
import { default as Profile } from './Sections/Profile/index';
import TestC from './Sections/TestC';
import { default as FLITGH } from './Sections/Tracking/FlightDetails';
import { default as HOTEL } from './Sections/Tracking/HotelDetails';
import Reserves from './Sections/Tracking/Reserves';

const { Content, Footer } = Layout;

class Stage extends React.Component {
  componentDidMount() {
    const officeID = getStorage('token'); // CookieManage.getCookie("token");
    const token = getStorage('ag_it_tenant'); // CookieManage.getCookie("ag_it_tenant");
    if (token) {
      this.props.authentication({ token, officeID });
      this.props.getPermissions();
      this.props.getDeposits();
    } else {
      this.props.setLoading({ showAllPage: false, showInline: false });
    }
    this.props.getIpLocation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.MessageInformation.show && this.props.MessageInformation.show !== prevProps.MessageInformation.show) {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          onClick={() => {
            if (this.props.MessageInformation.content === 'This user does not have access to this PNR.') {
              notification.close(key);
              window.history.back();
            } else {
              notification.close(key);
              window.location.reload();
            }
          }}>
          Ok
        </Button>
      );
      notification[this.props.MessageInformation.type]({
        message: 'Attentions',
        description: this.props.MessageInformation.content,
        placement: 'topRight',
        duration: 0,
        btn,
        key,
      });
    }
    if (this.props.location !== prevProps.location) {
      this.props.getDeposits();
    }
  }

  render() {
    const { showAllPage, userID } = this.props;
    return (
      <ErrorBoundary>
        {showAllPage && (
          <div className="loading-full-page">
            <div className="loading-section text-center">
              <div className="logo-loading">
                <img src={Logo} alt={'iTours logo'} title={'iTours logo'} />
              </div>
              <div>
                <strong>Loading data,please wait ...</strong>
              </div>
            </div>
          </div>
        )}

        {/*{(MessageInformation.show) &&*/}
        {/*<Alert closable message='Message' description={MessageInformation.content}*/}
        {/*       type={MessageInformation.type} showIcon/>*/}
        {/*}*/}
        {!userID ? (
          <div className={'loginScreen'}>
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/resetpassword" component={ResetPassword} />
              <Route component={Login} />
            </Switch>
          </div>
        ) : (
          <Layout>
            <Header />
            <Content>
              <div>
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route exact path="/user" component={AccountIndex} />
                  <Route path="/user/create" exact component={UserCreate} />
                  <Route path="/flight" exact component={FlightSearch} />
                  <Route path="/flight/result" exact component={FlightSearch} />
                  <Route path="/domesticflight/result" exact component={DomesticFlightSearch} />
                  <Route path="/flight/getpassenger" component={FlightGetPassenger} />
                  <Route path="/domesticflight/getpassenger" component={FlightGetPassenger} />
                  <Route path="/flight/ticketing" component={FlightTicketing} />
                  <Route path="/hotel" exact component={HotelSearch} />
                  <Route path="/hotel/result" component={HotelSearch} />
                  <Route path="/hotel/details" component={HotelDetails} />
                  <Route path="/hotel/passenger" component={HotelSetPassenger} />
                  <Route path="/hotel/confirm" component={HotelConfirmPayment} />
                  <Route path="/commission" exact component={CommissionIndex} />
                  <Route path="/commission/create" exact component={CommissionCreate} />
                  <Route path="/commission/edit/:id" exact component={CommissionEdit} />
                  <Route path="/payment/choice" component={ChoicePayment} />
                  <Route path="/payment/deposit" component={AddDeposit} />
                  <Route path="/payment/transactions" component={TransactionReports} />
                  <Route path="/tracking/reserves" exact={true} component={Reserves} />
                  <Route path="/tracking/reserves/hotel" component={HOTEL} />
                  <Route path="/tracking/reserves/flight" component={FLITGH} />
                  <Route path="/Profile" component={Profile} />
                  <Route path="/company/" component={CompanyIndex} />
                  <Route path="/testc" component={TestC} />
                  <Route component={NotFound} />
                </Switch>
              </div>
            </Content>
            <Footer className="footer">
              <div className="container">Copyright © 2020 iTours. All rights reserved.</div>
            </Footer>
          </Layout>
        )}
        <BackTop />
      </ErrorBoundary>
    );
  }
}

//Mapping functions
const WrrapedRouter = withRouter(Stage);
const mapStateToProps = (state) => ({
  showAllPage: state.Notification.loading.showAllPage,
  userID: state.User.id,
  MessageInformation: state.Notification.notificationMessage,
});
const mapDispatchToProps = (dispatch) => ({
  authentication: (token) => dispatch(authentication(token)),
  setLoading: (param) => dispatch(setLoading(param)),
  setNotification: (param) => dispatch(setNotification(param)),
  getDeposits: () => dispatch(getDeposits()),
  getIpLocation: () => dispatch(getIpLocation()),
  getPermissions: () => dispatch(getPermissions()),
});
export default connect(mapStateToProps, mapDispatchToProps)(WrrapedRouter);
