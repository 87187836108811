import PropTypes from 'prop-types';
import React from 'react';

HotelBookButton.propTypes = {
  rate: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function HotelBookButton(props) {
  return (
    <button type={'button'} className={props.className} onClick={() => props.onClick(props.rate.bookingToken)}>
      {' '}
      {props.children}
    </button>
  );
}

export default HotelBookButton;
