//import library
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { setLoading, setNotification } from '../Actions/NotificationAction';
import { setFlightInfo, setGatewaysInfo, setHotelInfo, setReserveStatus, setReserves, setTransactionInfo } from '../Actions/ReservesAction';
import Flight from '../APIs/Flight';
import Hotel from '../APIs/Hotel';
import Reserves from '../APIs/Reserves';
//import actionType and action
import { ReserveActions } from '../Enums/ActionTypes';
import { MessageType } from '../Enums/Types';

//fetch general function
function* getReservesFN(action) {
  yield put(setLoading({ showAllPage: false, showInline: false }));
  //clean model before GET form API
  yield put(setReserves(null));
  const response = yield call(Reserves.getReserves, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setReserves(response));
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}
function* getTransactionFN(action) {
  //clean model before GET API
  yield put(setTransactionInfo(null));
  const response = yield call(Reserves.getTransactions, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setTransactionInfo(response.transactions));
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}
function* getGatewaysLogsFN(action) {
  //clear log before get form API
  yield put(setGatewaysInfo(null));
  const response = yield call(Reserves.getGatewayLogs, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setGatewaysInfo(response.gatewayLogs.gatewayLogs));
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}
function* getFlightDetailsFN(action) {
  //clear Model before GET API
  yield put(setFlightInfo(null));
  yield put(setLoading({ showAllPage: true, showInline: false }));
  let paramGet = new URLSearchParams();
  const username = yield select((state) => state.User.userName);
  paramGet.append('reserveId', action.input);
  paramGet.append('username', username);
  const response = yield call(Flight.getReserveID, paramGet);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setFlightInfo(response));
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}
function* getHotelDetailsFN(action) {
  //clear Model before GET API
  yield put(setHotelInfo(null));
  yield put(setLoading({ showAllPage: true, showInline: false }));
  const response = yield call(Hotel.getHotelDetails, `reserveId=${action.input}`);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setHotelInfo(response));
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}
function* getReserveStatusFN() {
  //clear model before get API
  yield put(setReserveStatus(null));
  const response = yield call(Reserves.getReserveStatus);
  if (response.isAxiosError === undefined) {
    yield put(setReserveStatus(response.reserveStatusType));
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
}
//flow function
export function* getReserves() {
  yield takeEvery(ReserveActions.GetReserves, getReservesFN);
}
export function* getTransactions() {
  yield takeEvery(ReserveActions.getTransaction, getTransactionFN);
}
export function* getGatewaysLogs() {
  yield takeEvery(ReserveActions.getGatewaysLog, getGatewaysLogsFN);
}
export function* getFlightDetails() {
  yield takeEvery(ReserveActions.getFlightInfo, getFlightDetailsFN);
}
export function* getHotelDetails() {
  yield takeEvery(ReserveActions.getHotelInfo, getHotelDetailsFN);
}
export function* getReserveStatus() {
  yield takeEvery(ReserveActions.getReserveStatus, getReserveStatusFN);
}
