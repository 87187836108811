import axios from 'axios';

import { IpLocationUrls, Urls } from '../Enums/APIAddress';

const getIpLocationHttp = async () => {
  try {
    let response = await axios.get(`${Urls.Type}${Urls.IpLocation}${IpLocationUrls.GetIpLocation}`);
    return response.data.result;
  } catch (error) {
    return error;
  }
};

export { getIpLocationHttp };
