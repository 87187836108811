import { Alert, Button, Card, Col, Descriptions, Empty, Form, Row, Skeleton, Table, Tabs } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getFlightInfo, getGatewaysInfo, getTransactionInfo } from '../../../../Actions/ReservesAction';
import CoordinatorApi from '../../../../APIs/Coordinator';
import { Urls } from '../../../../Enums/APIAddress';
import { SupplierType } from '../../../../Enums/Types';
import Gateways from '../../../commond/Gateways';
import Price from '../../../commond/Price';
import Transaction from '../../../commond/Transaction';
import FlightSummary from '../Flight/FlightSummary';
import Refund from './Refund';
import Status from './ReserveStatus';

class FlightDetails extends React.Component {
  state = {
    reserveStatus: null,
  };

  isTicketing = (param) => {
    let result = [];
    if (isNaN(parseInt(param))) {
      result = this.props.ticketingSupplier.filter((item) => item.supplierName === param);
    } else {
      result = this.props.ticketingSupplier.filter((item) => item.supplierId === parseInt(param));
    }
    return Boolean(result.length);
  };
  componentDidMount() {
    this.getFlight();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.flightInfo !== this.props.flightInfo) {
      let parameters = queryString.parse(this.props.location.search);
      this.props.flightInfo &&
        CoordinatorApi.GetOrder(parameters.id, this.props.flightInfo.reserver.username).then((response) => {
          this.setState({ reserveStatus: response.data.result.status });
        });
    }
    if (this.props.location.search !== prevProps.location.search) {
      //if in this page available if user change URL we have to search again
      this.getFlight();
    }
  }
  getFlight = () => {
    let parameters = queryString.parse(this.props.location.search);
    this.props.getFlightInfo(parameters.id);
    this.props.getTransactionInfo(parameters.id);
    this.props.getGatewaysInfo(parameters.id);
  };
  operatingFlight = () => {
    let idSupplier = SupplierType.filter((item) => item.name === this.props.flightInfo.providerName)[0].value;
    this.props.history.push(`/flight/ticketing?&pnrCode=${this.props.flightInfo.pnrCode}&supplierId=${idSupplier}`);
  };
  render() {
    const { TabPane } = Tabs;
    const { flightInfo, transactionsList, gatewaysLogs } = this.props;
    const parameters = queryString.parse(this.props.location.search);
    let passengersTableData = [];
    if (flightInfo && flightInfo.passengers) {
      passengersTableData = flightInfo.passengers;
    }
    const passengersTableHead = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (text, record) => (record.title ? 'Mr' : 'Ms'),
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Ticket Number',
        dataIndex: 'ticketNumber',
        key: 'ticketNumber',
      },
    ];

    const loadingTableData = [{ key: '1' }, { key: '2' }, { key: '3' }];
    const loadingTableHead = [
      {
        title: 'Passenger Type',
        dataIndex: '1',
        key: '1',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Base Fare',
        dataIndex: '2',
        key: '2',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Commission',
        dataIndex: '3',
        key: '3',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Tax',
        dataIndex: '4',
        key: '4',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Total',
        dataIndex: '5',
        key: '5',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
    ];

    return (
      <div className="container vr-padding">
        <h5 className="page-heading">Booking Details</h5>
        <Row gutter={15} className={'flightDetails reserved-flight-detail'}>
          <Col xs={24} sm={24} md={10} lg={8} xl={6} className={'aside'}>
            {flightInfo ? (
              <>
                <Descriptions bordered>
                  <Descriptions.Item span={3} label={'PNR Code'}>
                    {flightInfo.pnrCode}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={'Status'}>
                    <Status displayType="Badge" status={this.state.reserveStatus} />
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={'Reserve Number'}>
                    {parameters.id}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={'Departure Date'}>
                    {moment(flightInfo.airItinerary.originDestinationOptions[0].flightSegments[0].departureDateTime, 'YYYY-MM-DDTHH:mm:SS').format('YYYY/MM/DD')}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={'Create Date'}>
                    {' '}
                    {moment(flightInfo.bookedDateTime, 'YYYY-MM-DDTHH:mm:SS').format('YYYY/MM/DD')}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={'Supplier'}>
                    {' '}
                    {flightInfo.providerName}
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={'Intro Price'}>
                    <Price currency={flightInfo.currency}>{flightInfo.flightFare.baseFare}</Price>
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={'Sale Price'}>
                    <Price currency={flightInfo.currency}>{flightInfo.flightFare.totalFare}</Price>
                  </Descriptions.Item>
                  <Descriptions.Item span={3} label={'Tax Price'}>
                    <Price currency={flightInfo.currency}>{flightInfo.flightFare.totalTaxes}</Price>
                  </Descriptions.Item>
                </Descriptions>
                <div>
                  {this.state.reserveStatus === 'Issued' && (
                    <>
                      <Button
                        type={'primary'}
                        className="large-button margin-top"
                        block
                        onClick={() => {
                          window.open(`${Urls.FlightTicket.replace('****', localStorage.getItem('tenantName'))}?reserveId=${parameters.id}`, '_blank');
                        }}
                        icon={'printer'}>
                        Print Ticket
                      </Button>
                      <Button
                        className="large-button margin-top"
                        target="_blank"
                        block
                        htmlType="button"
                        href={`https://voucher.imt.as/en/Factor/Flight?reserveId=${parameters.id}&username=${flightInfo.reserver.username}`}>
                        Invoice
                      </Button>
                      {flightInfo.providerName === 'Farelogix' ||
                      flightInfo.providerName === 'Ethnic' ||
                      flightInfo.providerName === 'Sabre' ||
                      flightInfo.providerName === 'Amadeus' ? (
                        <Refund reserveType="flight" reserveId={parameters.id} username={flightInfo.reserver.username} flightInfo={flightInfo} />
                      ) : null}
                    </>
                  )}
                </div>
                <div>
                  {this.isTicketing(flightInfo.providerName) && (
                    <Button className="large-button margin-top" type={'primary'} block onClick={() => this.operatingFlight()}>
                      Ticketing/Show PNR
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <Descriptions bordered>
                <Descriptions.Item span={3} label={<Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />}>
                  <Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />
                </Descriptions.Item>
                <Descriptions.Item span={3} label={<Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />}>
                  <Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />
                </Descriptions.Item>
                <Descriptions.Item span={3} label={<Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />}>
                  <Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />
                </Descriptions.Item>
                <Descriptions.Item span={3} label={<Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />}>
                  <Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />
                </Descriptions.Item>
                <Descriptions.Item span={3} label={<Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />}>
                  <Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />
                </Descriptions.Item>
                <Descriptions.Item span={3} label={<Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />}>
                  <Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />
                </Descriptions.Item>
                <Descriptions.Item span={3} label={<Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />}>
                  <Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />
                </Descriptions.Item>
                <Descriptions.Item span={3} label={<Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />}>
                  <Skeleton active paragraph={{ rows: 1 }} className="no-title-skeleton" />
                </Descriptions.Item>
              </Descriptions>
            )}
          </Col>

          <Col xs={24} sm={24} md={14} lg={16} xl={18} className={'flightInfo'}>
            {flightInfo ? (
              <Tabs type="card">
                <TabPane tab="Flight Detail" key="1">
                  <div className="margin-bottom">
                    <FlightSummary
                      showButton={true}
                      noTitle={true}
                      PriceAntdTable={true}
                      model={flightInfo.airItinerary.originDestinationOptions}
                      currencyCode={flightInfo.currency}
                      fareInfo={flightInfo.passengersFare}
                      totalPrice={flightInfo.flightFare.totalFare}
                      feild={'fare'}
                      hideCommission={true}
                      hideFees={true}
                    />
                  </div>
                  {
                    transactionsList ? (
                      transactionsList.length ? (
                        <div className="container margin-bottom">
                          <Transaction model={transactionsList} />
                        </div>
                      ) : (
                        <Alert message="Attention" description="There isn't any Transactions List" type="info" showIcon className={'margin-bottom'} />
                      )
                    ) : (
                      <Card className="margin-bottom small-empty-box">
                        <Empty description={"There isn't any Transactions List"} />
                      </Card>
                    )
                    // <div>loading transaction data</div>
                  }

                  {
                    gatewaysLogs ? (
                      gatewaysLogs.length ? (
                        <div className="container margin-bottom">
                          <Gateways model={gatewaysLogs} />
                        </div>
                      ) : (
                        <Alert message="Attention" description="There isn't any Gateways" type="info" className={'margin-bottom'} showIcon />
                      )
                    ) : (
                      <Card className="small-empty-box">
                        <Empty description={"There isn't any Gateways"} />
                      </Card>
                    )
                    // <div>loading gatewaysLogs data</div>
                  }
                </TabPane>
                <TabPane tab="Reserver Information" key="2">
                  <table>
                    <tbody>
                      <tr>
                        <td className="padding-right-20">Name: </td>
                        <td>
                          {flightInfo.reserver.gender ? 'Mr' : 'Ms'} {flightInfo.reserver.firstName} {flightInfo.reserver.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-right-20">Phone number:</td>
                        <td>{flightInfo.reserver.phoneNumber ? flightInfo.reserver.phoneNumber : '--'}</td>
                      </tr>
                      <tr>
                        <td className="padding-right-20">Email: </td>
                        <td>{flightInfo.reserver.email}</td>
                      </tr>
                      <tr>
                        <td className="padding-right-20">Username: </td>
                        <td>{flightInfo.reserver.username}</td>
                      </tr>
                    </tbody>
                  </table>
                </TabPane>
                <TabPane tab="Passengers Information" key="3">
                  <Table columns={passengersTableHead} dataSource={passengersTableData} pagination={false} className="margin-bottom" size="middle" rowKey="firstName" />
                </TabPane>
              </Tabs>
            ) : (
              <Tabs type="card">
                <TabPane tab="Flight Detail" key="1">
                  <Skeleton active title={{ width: '100%' }} paragraph={{ rows: 0 }} className="one-line-skleton" />
                  <Card className="light-padding">
                    <div className="reserve-detail-itinery-loading">
                      <Skeleton className="center-align-avatar" active avatar paragraph={{ rows: 1 }} />
                      <Skeleton active paragraph={{ rows: 1 }} />
                      <Skeleton active paragraph={{ rows: 1 }} />
                      <Skeleton active paragraph={{ rows: 1 }} />
                      <Skeleton active paragraph={{ rows: 1 }} />
                    </div>
                  </Card>
                  <Skeleton active className="one-line-skleton" paragraph={{ rows: 0 }} title={{ width: '80px' }} />
                  <Table className="margin-top" pagination={false} columns={loadingTableHead} dataSource={loadingTableData} />
                </TabPane>
                <TabPane tab="Reserver Information" key="2" disabled></TabPane>
                <TabPane tab="Passengers Information" key="3" disabled></TabPane>
              </Tabs>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getTransactionInfo: (id) => {
    dispatch(getTransactionInfo(id));
  },
  getGatewaysInfo: (id) => {
    dispatch(getGatewaysInfo(id));
  },
  getFlightInfo: (id) => {
    dispatch(getFlightInfo(id));
  },
});
const mapStateToProps = (state) => ({
  transactionsList: state.Reserve.transactionList,
  flightInfo: state.Reserve.flightDetails,
  gatewaysLogs: state.Reserve.gatewaysLogs,
  ticketingSupplier: state.Flight.ticketingSupplier,
});
const FlightDetailsRouter = withRouter(FlightDetails);
const FormEnable = Form.create({ name: 'cancelFlight' })(FlightDetailsRouter);
export default connect(mapStateToProps, mapDispatchToProps)(FormEnable);
