import { Col, Empty, Row, Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { withRouter } from 'react-router-dom';

import Price from '../../Components/commond/Price';
import hotelNoImage from '../../Images/design/default-hotel-image.svg';
import { supplierCode } from '../../utils/styleName';
import HotelAgentGainPopover from '../view/Sections/Hotel/HotelAgentGainPopover';
import Rate from './Rate';

class HotelItem extends React.Component {
  static propTypes = {
    model: PropTypes.any.isRequired,
    currency: PropTypes.string.isRequired,
    params: PropTypes.any.isRequired,
  };

  render() {
    const parameters = queryString.parse(this.props.location.search);
    const { features, id, rating, salePrice, name, address, boards, mainPhoto, regions, regularPrice, introPrice, supplierType } = this.props.model;
    const nationalityCodeQs = parameters.NationalityCode ? `&NationalityCode=${parameters.NationalityCode}` : '';

    return (
      <div>
        {this.props.model == null ? (
          <Empty description={'Hotel not Found'} />
        ) : (
          <div className="hotel-result-item">
            {mainPhoto == null ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/hotel/details?Id=${id}&Adults=${parameters.Adults}&Children=${parameters.Children}&Ages=${parameters.Ages}&Checkin=${parameters.CheckIn}&Checkout=${parameters.CheckOut}`}
                className="hotel-results-image default-hotel-image"
                style={{ backgroundImage: 'url(' + hotelNoImage + ')' }}>
                &nbsp;{' '}
              </a>
            ) : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/hotel/details?Id=${id}&Adults=${parameters.Adults}&Children=${parameters.Children}&Ages=${parameters.Ages}&Checkin=${parameters.CheckIn}&Checkout=${parameters.CheckOut}`}
                className="hotel-results-image main-hotel-image"
                style={{ backgroundImage: 'url(' + mainPhoto + ')' }}>
                &nbsp;
              </a>
            )}
            <div className="hotel-result-main">
              <div className="hotel-results-content">
                <Row align="bottom" type="flex">
                  <Col xl={18} lg={16} md={12} sm={16} xs={24}>
                    <div className="margin-bottom">
                      <div className="clearfix">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/hotel/details?Id=${id}&Adults=${parameters.Adults}&Children=${parameters.Children}&Ages=${parameters.Ages}&Checkin=${parameters.CheckIn}&Checkout=${parameters.CheckOut}`}>
                          <strong className="hotel-name">{name}</strong>
                        </a>
                        <div className="rating">
                          <Rate number={rating} />
                        </div>
                      </div>
                      <div className="addressFeild">{address}</div>
                    </div>
                    {boards && boards.length > 0 ? (
                      <div className="board-list">
                        <strong>Rooms Include: </strong>
                        {boards.map((item) => (
                          <Tooltip title={item.description}>
                            <Tag>{item.code}</Tag>
                          </Tooltip>
                        ))}
                      </div>
                    ) : null}
                    {regions && regions.length > 0 ? (
                      <div className="location-list">
                        <strong>Locations: </strong>
                        {regions.map((item) => (
                          <Tag key={item.id}>{item.name}</Tag>
                        ))}
                      </div>
                    ) : null}
                    {features && features.length > 0 ? (
                      <div className="feature-list">
                        {features.map((item, index) => (
                          <Tag color="green" key={index}>
                            {item.name}
                          </Tag>
                        ))}
                      </div>
                    ) : null}
                  </Col>
                  <Col xl={6} lg={8} md={12} sm={8} xs={24}>
                    <div className="priceInfo margin-bottom-light text-right">
                      {regularPrice > salePrice ? (
                        <div className={'introPrice font-14 old-price'}>
                          <b>
                            {' '}
                            <Price currency={this.props.currency}>{regularPrice}</Price>
                          </b>
                        </div>
                      ) : null}
                      <div className={'introPrice font-18 red'}>
                        <b>
                          {' '}
                          <Price currency={this.props.currency}>{salePrice}</Price>{' '}
                        </b>
                        <HotelAgentGainPopover introPrice={introPrice} regularPrice={regularPrice} salePrice={salePrice} />
                      </div>
                    </div>
                    <div className="text-right">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`full-width ${supplierCode(supplierType)}`}
                        href={`/hotel/details?Id=${id}&Adults=${parameters.Adults}&Children=${parameters.Children}&Ages=${parameters.Ages}&Checkin=${parameters.CheckIn}&Checkout=${parameters.CheckOut}${nationalityCodeQs}`}>
                        See rooms
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const WithRouter = withRouter(HotelItem);
export default WithRouter;
