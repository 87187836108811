import { call, put, takeEvery } from 'redux-saga/effects';

import { setLoading, setNotification } from './../Actions/NotificationAction';
import { setBookStatus, setReserveID } from './../Actions/PaymentAction';
import { FilterActions, HotelActions } from './../Enums/ActionTypes';
import {
  saveHotelResult,
  saveRoomSelected,
  setHotel,
  setHotelFilterApply,
  setHotelFilterItems,
  setHotelLoading,
  setHotelSorting,
  setPreCancel,
  setRoomsKey,
} from '../Actions/HotelAction';
import { getGatewaysInfo, getHotelInfo, getTransactionInfo } from '../Actions/ReservesAction';
import Hotel from '../APIs/Hotel';
import { HotelAvailable, MessageType, Sort, bookStatus } from '../Enums/Types';
import { Constants } from '../utils/constants';

function* error(response) {
  let message;
  response.response ? (message = response.response.data.error.message) : (message = Constants.Messages.InternetErrorMessage);
  yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
}

//fetch general function
function* searchHotelFN(action) {
  yield put(setHotelLoading(true));
  //clear hotel models before get info from API
  yield put(saveHotelResult(new HotelAvailable()));
  yield put(setHotelFilterItems(new HotelAvailable().hotels));
  const response = yield call(Hotel.searchHotel, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (!response.cancelled) {
    if (response.isAxiosError === undefined) {
      yield put(saveHotelResult(response));
      yield put(setHotelFilterItems(response.hotels));
      yield put(setHotelSorting({ typeSort: Sort.price, isReverse: false }));
    } else {
      yield error(response);
    }
    yield put(setHotelLoading(false));
  }
}

function* filterHotelFN() {
  yield put(setHotelFilterApply());
}

function* hotelDetailsFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  //clear hotel model before get
  yield put(setHotel(null));
  const response = yield call(Hotel.hotelDetails, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setHotel(response));
  } else {
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

function* setRoomFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  //clear Key before get new key
  yield put(setRoomsKey(null));
  const response = yield call(Hotel.preReserve, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setRoomsKey(response.preReserveKey));
  } else {
    yield error(response);
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

function* getPassengerFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  //clear passengerModel before get info from API
  yield put(saveRoomSelected(null));
  const response = yield call(Hotel.getPassengerInfo, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(saveRoomSelected(response));
  } else {
    yield error(response);
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

function* reserveFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));

  const response = yield call(Hotel.reserve, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setReserveID(response));
  } else {
    yield error(response);
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

function* bookHotelFN(action) {
  const response = yield call(Hotel.book, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setBookStatus(bookStatus.done));
  } else {
    yield put(setBookStatus(bookStatus.error));
  }
}

function* getHotelSelectedFN(action) {
  const response = yield call(Hotel.getHotelDetails, action.input);
  //clear model before get it from API
  yield put(setHotel(null));
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setHotel(response));
  } else {
    yield error(response);
  }
}

function* PreCancelHotelFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  yield put(setPreCancel(null));
  const response = yield call(Hotel.getPreCancel, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setPreCancel(response));
  } else {
    yield error(response);
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

function* cancelHotelFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));

  const response = yield call(Hotel.cancelHotel, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    if (response.status) {
      yield put(setPreCancel(null));
      yield put(setNotification({ show: true, content: 'Cancellation Successful', type: MessageType.Success }));
      yield put(getHotelInfo(action.input.reserveId));
      yield put(getTransactionInfo(action.input.reserveId));
      yield put(getGatewaysInfo(action.input.reserveId));
    }
  } else {
    yield error(response);
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

//flow function
export function* watchSearchHotel() {
  yield takeEvery(HotelActions.SearchHotel, searchHotelFN);
}

export function* watchHotelDetails() {
  yield takeEvery(HotelActions.SearchRoom, hotelDetailsFN);
}

export function* watchFilterHotel() {
  yield takeEvery(FilterActions.FilterSelect, filterHotelFN);
}

export function* watchSelectRoom() {
  yield takeEvery(HotelActions.SetRoom, setRoomFN);
}

export function* watchGetPassenger() {
  yield takeEvery(HotelActions.GetPassengerInfo, getPassengerFN);
}

export function* watchReserve() {
  yield takeEvery(HotelActions.Reserve, reserveFN);
}

export function* watchBookHotel() {
  yield takeEvery(HotelActions.HotelBook, bookHotelFN);
}

export function* watchGetHotelSelected() {
  yield takeEvery(HotelActions.GetHotelSelected, getHotelSelectedFN);
}

export function* watchGetHotelPreCancel() {
  yield takeEvery(HotelActions.PreCancelHotel, PreCancelHotelFN);
}

export function* watchGetHotelCancel() {
  yield takeEvery(HotelActions.CancelHotel, cancelHotelFN);
}
