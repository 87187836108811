import { Button, Checkbox, Col, DatePicker, Form, Icon, Input, Layout, Modal, Radio, Result, Row, Select, Skeleton } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { connect } from 'react-redux';

import { CountryCode } from './../../../../Enums/Types';
import { Account as AccountURL, Urls, getHeaderRequest } from '../../../../Enums/APIAddress';

const { Content } = Layout;

class UserEdit extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    value: true,
    description: '',
    data: [],
    currencyType: [],
    supplierType: [],
    birthDay: null,
    modalVisibility: false,
    modalTitle: '',
    modalDescriptions: '',
    modalCloseAction: 'close',
  };
  //openModal
  openModal = (option) => {
    this.setState({
      modalVisibility: true,
      modalStatus: option.status,
      modalTitle: option.title,
      modalDescriptions: option.description,
      modalCloseAction: option.closeAction,
    });
  };
  //closeModal
  closeModal = () => {
    this.setState({
      modalVisibility: false,
      modalStatus: '',
      modalTitle: '',
      modalDescriptions: '',
    });
  };
  getFullNumber = () => {
    let phoneNumber;
    const phoneNumberInput = this.phoneNumberElement.current.state.value;
    const dialCode = this.phoneNumberElement.current.state.dialCode;
    if (phoneNumberInput.startsWith(dialCode)) {
      phoneNumber = phoneNumberInput.substring(dialCode.length, phoneNumberInput.length);
    } else {
      phoneNumber = phoneNumberInput;
    }
    phoneNumber = dialCode.concat(phoneNumber);
    phoneNumber = phoneNumber.replace(/\s/g, '');
    return phoneNumber;
  };
  componentDidMount() {
    let headers = getHeaderRequest(true);
    axios
      .get(`${Urls.Type}${Urls.User}${AccountURL.GetUsers}?id=${this.props.id}`, {
        headers: {
          ...headers,
        },
      })
      .then(({ data }) => {
        let item = data.result;
        this.setState({ item });
      });

    axios
      .get(`${Urls.Type}${Urls.User}${AccountURL.GetPermissions}?id=${this.props.id}`, {
        headers: {
          ...headers,
        },
      })
      .then(({ data }) => {
        this.setState({ permisions: data.result.permissions, activePermissions: data.result.grantedPermissionNames });
      });
  }
  phoneNumberElement = React.createRef();
  handleSubmitInformations = (e) => {
    this.openModal({
      status: 'loading',
      title: 'Sending informations',
      description: 'user information is sending ...',
      closeAction: 'close',
    });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      let headers = getHeaderRequest(true);
      if (!err) {
        values.phoneNumber = this.getFullNumber();
        let param = { ...values, id: this.props.id };
        axios
          .put(`${Urls.Type}${Urls.User}${AccountURL.UpdateUsers}`, param, {
            headers: {
              ...headers,
            },
          })
          .then((data) => {
            this.openModal({
              status: 'success',
              title: 'Success',
              description: 'user information updated',
              closeAction: 'reload',
            });
          });
      } else {
        this.openModal({
          status: 'warning',
          title: 'Warning',
          description: 'Please complete the form',
          closeAction: 'close',
        });
      }
    });
  };
  handleSubmitPermissions = (e) => {
    this.openModal({
      status: 'loading',
      title: 'Sending informations',
      description: 'user information is sending ...',
      closeAction: 'close',
    });
    e.preventDefault();
    this.props.form.validateFields((err2, values) => {
      let headers = getHeaderRequest(true);
      let permissionsParam = { id: this.props.id, grantedPermissionNames: values.grantedPermissionNames, roleNames: this.state.item.roleNames };
      axios
        .put(`${Urls.Type}${Urls.User}${AccountURL.UpdatePermissions}`, permissionsParam, {
          headers: {
            ...headers,
          },
        })
        .then((data) => {
          this.openModal({
            status: 'success',
            title: 'Success',
            description: 'user information updated',
            closeAction: 'reload',
          });
        });
    });
  };
  clearAllPermissions = () => {
    this.setState({ activePermissions: [] });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    let { item } = this.state;
    let plusPhoneNumber;
    if (item && item.phoneNumber) {
      if (item.phoneNumber.includes('+')) {
        plusPhoneNumber = item.phoneNumber;
      } else {
        plusPhoneNumber = '+' + item.phoneNumber;
      }
    }

    return (
      <Content>
        <Modal visible={this.state.modalVisibility} footer={null} closable={false} className={'higher-z-index'}>
          <Result
            status={this.state.modalStatus}
            title={this.state.modalTitle}
            subTitle={this.state.modalDescriptions}
            icon={
              this.state.modalStatus === 'loading' ? (
                <div className="result-process-loading">
                  <Icon type="loading" />
                </div>
              ) : null
            }
            extra={
              this.state.modalStatus === 'loading' ? null : this.state.modalCloseAction === 'reload' ? (
                <Button
                  htmlType="button"
                  type={'primary'}
                  // onClick={() => {
                  //   window.location.reload();
                  // }}
                  onClick={this.closeModal}>
                  Ok
                </Button>
              ) : (
                <Button htmlType="button" type={'primary'} onClick={this.closeModal}>
                  Ok
                </Button>
              )
            }
          />
        </Modal>

        {item ? (
          <>
            <Form className="user-form" onSubmit={this.handleSubmitInformations}>
              <Form.Item label="Gender">
                {getFieldDecorator('gender', {
                  initialValue: item && item.gender,
                })(
                  <Radio.Group>
                    <Radio value={true}>Male</Radio>
                    <Radio value={false}>Female</Radio>
                  </Radio.Group>
                )}
              </Form.Item>{' '}
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="First name">
                    {getFieldDecorator('firstName', {
                      initialValue: item && item.firstName,
                      rules: [
                        {
                          required: true,
                          message: 'Please enter first name',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Last name">
                    {getFieldDecorator('lastName', {
                      initialValue: item && item.lastName,
                      rules: [
                        {
                          required: true,
                          message: 'Please enter last name',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Phone number">
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control"
                      fieldName={'phoneNumber'}
                      placeholder={'Phone number'}
                      defaultValue={item.phoneNumber === null ? '' : plusPhoneNumber}
                      useMobileFullscreenDropdown={true}
                      ref={this.phoneNumberElement}
                      separateDialCode={true}
                    />
                  </Form.Item>{' '}
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Birthday">
                    {getFieldDecorator('birthDay', {
                      initialValue: item.birthDay && moment(item.birthDay),
                      rules: [{ required: true, message: 'Please select your birthday' }],
                    })(<DatePicker className="full-width" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Nationality">
                    {getFieldDecorator('nationalityId', {
                      rules: [{ required: true, message: 'Please input your nationality' }],
                      initialValue: item.nationalityId && item.nationalityId,
                    })(
                      <Select
                        showSearch
                        className="full-width"
                        onChange={() => {
                          this.setState({ isEdited: true });
                        }}>
                        {CountryCode.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.code}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Status">
                    {getFieldDecorator('isActive', {
                      initialValue: item && item.isActive,

                      // rules: [
                      //   {
                      //     required: true,
                      //     message: 'Please select activation status',
                      //   },
                      // ],
                    })(
                      <Radio.Group>
                        <Radio value={true}>Active</Radio>
                        <Radio value={false}>Deactive</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <div className="text-right">
                    <Button type="primary" htmlType="submit" className="align-labels">
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
            {this.props.permissions && this.props.permissions.includes('Permissions') && (
              <Form className="user-form clearfix margin-top" onSubmit={this.handleSubmitPermissions}>
                <div>
                  <h5 className="normal-title">User Permisions</h5>
                  <p>User is able to</p>
                  <Form.Item>
                    {getFieldDecorator('grantedPermissionNames', {
                      initialValue: this.state.activePermissions,
                    })(
                      <Checkbox.Group style={{ width: '100%' }}>
                        <Row gutter={[20, 10]}>
                          {this.state.permisions &&
                            this.state.permisions.map((item) => (
                              <Col xs={24} sm={12} md={8} lg={6} key={item.name}>
                                <Checkbox value={item.name}>{item.displayName}</Checkbox>
                              </Col>
                            ))}
                        </Row>
                      </Checkbox.Group>
                    )}
                  </Form.Item>

                  <div className="text-right">
                    <Button type="link" htmlType="button" onClick={this.clearAllPermissions}>
                      Clear All Permissios
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </>
        ) : (
          <div className=" margin-top-light">
            <Row>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton" active paragraph={{ rows: 2 }} />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton" active paragraph={{ rows: 2 }} />
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton" active paragraph={{ rows: 2 }} />
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton" active paragraph={{ rows: 2 }} />
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton" active paragraph={{ rows: 2 }} />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton margin-top margin-bottom" active paragraph={{ rows: 2 }} />
              </Col>
            </Row>
            <Row gutter={[20, 10]}>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton" active paragraph={{ rows: 4 }} />
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton" active paragraph={{ rows: 3 }} />
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton" active paragraph={{ rows: 3 }} />
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Skeleton className="no-title-skeleton" active paragraph={{ rows: 3 }} />
              </Col>
            </Row>
            <div className="text-right">
              <Button type={'primary'} disabled>
                Save
              </Button>
            </div>
          </div>
        )}
      </Content>
    );
  }
}
//export default Form.create({ name: 'UserEdit' })(UserEdit);

//map to prop
const MapStateToProp = (state) => ({
  permissions: state.User.permissions,
});

const EditUserForm = Form.create({ name: 'UserEdit' })(UserEdit);
export default connect(MapStateToProp)(EditUserForm);
