import { ConnectedRouter } from 'connected-react-router';
//start file
//import library
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

//insert view file
import App from './Components/App';
import { history, store } from './Reducers/configureStore';

// import {BrowserRouter} from 'react-router-dom';

//run ReactDOM JSX file
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
