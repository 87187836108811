import { Alert, Button, Card, Col, Empty, Form, Icon, Row, Skeleton, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { cancel, getPreCancel, setPreCancel } from '../../../../Actions/HotelAction';
import { getGatewaysInfo, getHotelInfo, getTransactionInfo } from '../../../../Actions/ReservesAction';
import CoordinatorApi from '../../../../APIs/Coordinator';
import Gateways from '../../../commond/Gateways';
import Price from '../../../commond/Price';
import Rate from '../../../commond/Rate';
import Transaction from '../../../commond/Transaction';
import Refund from './Refund';
import Status from './ReserveStatus';

class HotelDetails extends React.Component {
  state = {
    showModal: false,
    reserveStatus: null,
  };
  componentDidMount() {
    this.getHotel();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hotelInfo !== this.props.hotelInfo) {
      let parameters = queryString.parse(this.props.location.search);
      if (this.props.hotelInfo) {
        CoordinatorApi.GetOrder(parameters.id, this.props.hotelInfo.reserver.username).then((response) => {
          this.setState({ reserveStatus: response.data.result.status });
        });
      }
    }

    if (this.props.location.search !== prevProps.location.search) {
      //if in this page available if user change URL we have to search again
      this.getHotel();
    }
    if (this.props.cancelInfo !== prevProps.cancelInfo) {
      if (this.props.cancelInfo) {
        this.setState({
          showModal: true,
        });
      } else {
        this.setState({
          showModal: false,
        });
      }
    }
  }

  getHotel = () => {
    let parameters = queryString.parse(this.props.location.search);
    // this.props.getFlightInfo(10231);
    // this.props.getTransactionInfo(10231);
    this.props.getHotelInfo(parameters.id);
    this.props.getTransactionInfo(parameters.id);
    this.props.getGatewaysInfo(parameters.id);
  };

  render() {
    const { hotelInfo, transactionsList, gatewaysLogs } = this.props;

    let rooms = [];
    if (hotelInfo && hotelInfo.accommodation && hotelInfo.accommodation.rooms) {
      rooms = hotelInfo.accommodation.rooms;
    }
    const RoomDetailTableHead = [
      {
        title: 'Room Name',
        dataIndex: 'name',
        key: 'name',
        className: 'no-expandable',
      },
      {
        title: 'Board',
        dataIndex: 'boardName',
        key: 'boardName',
        render: (text, record) => record.board.name,
      },
      {
        title: 'Passenger (type)',
        dataIndex: 'passengers',
        key: 'passengers',
        render: (text, record) =>
          record.passengers.map((item, index) => {
            return (
              <div key={index} className="no-wrap">
                {index ? <div className="margin-top-light" /> : null}
                <span>
                  {item.gender ? 'Mr' : 'Ms'} {item.firstname} {item.lastname}{' '}
                </span>
                <span> ({item.ageCategory})</span>
              </div>
            );
          }),
      },
    ];

    const loadingTableData = [{ key: '1' }, { key: '2' }];
    const loadingTableHead = [
      {
        title: 'Room Name',
        dataIndex: '1',
        key: '1',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Board',
        dataIndex: '2',
        key: '2',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Passenger(Type)',
        dataIndex: '3',
        key: '3',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Regular Price',
        dataIndex: '4',
        key: '4',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Intro Price',
        dataIndex: '5',
        key: '5',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Sale Price',
        dataIndex: '6',
        key: '6',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Discount Amount',
        dataIndex: '7',
        key: '7',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
      {
        title: 'Discount Percent',
        dataIndex: '8',
        key: '8',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} />,
      },
    ];
    return (
      <div className="container vr-padding">
        <div className="inlined-info-boxes">
          {hotelInfo ? (
            <>
              <div className="info-box">
                <label>Reserve Number</label>
                <div className="value">{hotelInfo.reserveId}</div>
              </div>
              <div className="info-box">
                <label>Status</label>
                <div className="value">
                  <Status displayType="Badge" status={this.state.reserveStatus} />
                </div>
              </div>
              <div className="info-box">
                <label>Checkin Date</label>
                <div className="value">{moment(hotelInfo.date.checkIn, 'YYYY-MM-DDTHH:mm:SS').format('YYYY/MM/DD')}</div>
              </div>
              <div className="info-box">
                <label>Creation Date</label>
                <div className="value">{moment(hotelInfo.bookingDatetime, 'YYYY-MM-DDTHH:mm:SS').format('YYYY/MM/DD')}</div>
              </div>
              {/* <div className="info-box">
                  <label>Regular Price</label>
                  <div className="value">{<Price currency={hotelInfo.currency.code}>{hotelInfo.regularPrice}</Price>}</div>
                </div> */}
              <div className="info-box">
                {/* <label>Sale Price</label> */}
                <label>Total Price</label>
                <div className="value">{<Price currency={hotelInfo.currency.code}>{hotelInfo.salePrice}</Price>}</div>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <Skeleton active paragraph={{ rows: 1 }} />
              </div>
              <div className="info-box">
                <Skeleton active paragraph={{ rows: 1 }} />
              </div>
              <div className="info-box">
                <Skeleton active paragraph={{ rows: 1 }} />
              </div>
              <div className="info-box">
                <Skeleton active paragraph={{ rows: 1 }} />
              </div>
              <div className="info-box">
                <Skeleton active paragraph={{ rows: 1 }} />
              </div>
            </>
          )}
        </div>
        <Row gutter={15} className={'flightDetails'}>
          <Col xs={24} sm={24} md={10} lg={8} xl={6} className={'aside'}>
            {hotelInfo ? (
              <Card
                title={hotelInfo.accommodation.mainPhoto ? null : 'Booking Summary'}
                className="light-padding"
                cover={hotelInfo.accommodation.mainPhoto ? <img alt="hotel" src={hotelInfo.accommodation.mainPhoto} /> : null}>
                <h5 className="normal-title">
                  <span className="inline-block"> {hotelInfo.accommodation.name} </span>{' '}
                  <span className="inline-block">{hotelInfo.accommodation.rating && <Rate number={hotelInfo.accommodation.rating} />}</span>
                </h5>
                <div className={'hotellocation'}>
                  {hotelInfo.country.name} - {hotelInfo.city.name}
                </div>
                <div className="addressFeild">
                  <Icon type="environment" /> {hotelInfo.accommodation.address}
                </div>

                <div className="pair-row">
                  <span>Check-in</span>
                  <Tooltip placement="top" title="Property local time">
                    <b>{hotelInfo.date.checkIn.split('T')[0]}</b> <Icon type="info-circle" />
                  </Tooltip>
                </div>

                <div className="pair-row">
                  <span>Check-out</span>
                  <Tooltip placement="top" title="Property local time">
                    <b>{hotelInfo.date.checkOut.split('T')[0]}</b> <Icon type="info-circle" />
                  </Tooltip>
                </div>
                {hotelInfo.accommodation.phones.map((item, index) => (
                  <div key={index} className="pair-row">
                    <span>{item.type} </span> <b> {item.value}</b>
                  </div>
                ))}
                {hotelInfo.accommodation.emails && hotelInfo.accommodation.emails.length > 0 ? (
                  <div className="pair-row wrap">
                    <span>Email </span>
                    {hotelInfo.accommodation.emails.map((item, index) => (
                      <div className="break-word" key={index}>
                        <b>{item.value}</b>
                      </div>
                    ))}
                  </div>
                ) : null}

                <Button
                  type={'primary'}
                  className="large-button margin-top"
                  block
                  onClick={() => {
                    window.open(
                      `https://voucher.imt.as/en/${localStorage.getItem('tenantName')}/Hotel/Voucher?reserveId=${hotelInfo.reserveId}&username=${hotelInfo.reserver.username}`,
                      '_blank'
                    );
                  }}
                  icon={'printer'}>
                  Print Voucher
                </Button>
                {this.state.reserveStatus === 'Issued' && (
                  <>
                    {/* <Button
                        className="large-button margin-top"
                        target="_blank"
                        block
                        htmlType="button"
                        href={`https://voucher.imt.as/en/factor/hotel?reserveId=${hotelInfo.reserveId}&username=${hotelInfo.reserver.username}`}>
                        Invoice
                      </Button> */}
                    <Refund reserveType="hotel" reserveId={hotelInfo.reserveId} username={hotelInfo.reserver.username} hotelInfo={hotelInfo} />
                  </>
                )}
              </Card>
            ) : (
              <Card
                className="light-padding"
                cover={
                  <div className="loading-images">
                    <div className="image min-height-150" />
                  </div>
                }>
                <Skeleton active paragraph={{ rows: 4 }} />
                <Button type={'primary'} disabled className="large-button margin-top" block icon={'printer'}>
                  Print Voucher
                </Button>
              </Card>
            )}
          </Col>
          <Col xs={24} sm={24} md={14} lg={16} xl={18}>
            <Card title="Reserver Information" className="margin-bottom">
              {hotelInfo ? (
                <table>
                  <tbody>
                    <tr>
                      <td className="padding-right-20">Name: </td>
                      <td>
                        {hotelInfo.reserver.gender ? 'Mr' : 'Ms'} {hotelInfo.reserver.firstName} {hotelInfo.reserver.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="padding-right-20">Phone number:</td>
                      <td>{hotelInfo.reserver.phoneNumber ? hotelInfo.reserver.phoneNumber : '--'}</td>
                    </tr>
                    <tr>
                      <td className="padding-right-20">Email: </td>
                      <td>{hotelInfo.reserver.email}</td>
                    </tr>
                    <tr>
                      <td className="padding-right-20">Username: </td>
                      <td>{hotelInfo.reserver.username}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Skeleton active paragraph={{ rows: 4 }} className="no-title-skeleton" />
              )}
            </Card>
            {hotelInfo ? (
              <>
                <div>
                  <h5 className="normal-title">Rooms Details</h5>
                  <Table columns={RoomDetailTableHead} dataSource={rooms} pagination={false} className="margin-bottom" size="middle" rowKey="passengers" />
                </div>
                {hotelInfo.cancellationPolicy && (
                  <>
                    <h5 className="normal-title">
                      Cancellation rules{' '}
                      {hotelInfo.cancellationPolicy.status && (
                        <Tag color={hotelInfo.cancellationPolicy.status === 'Refundable' ? 'green' : 'red'}>{hotelInfo.cancellationPolicy.status}</Tag>
                      )}
                    </h5>
                    <ul>
                      {hotelInfo.cancellationPolicy.fees.map((item, index) =>
                        item.amount === 0 ? (
                          <li key={item.fromDate}>
                            Cancellation is <span className="green">FREE</span> before
                            <Tooltip className="no-wrap" placement="top" title={moment(item.toDate).format('MM/DD/YYYY')}>
                              {moment(item.toDate).format('DD MMM YYYY')}
                            </Tooltip>
                          </li>
                        ) : (
                          <li key={item.fromDate}>
                            Cancellation penalty is <Price currency={hotelInfo.currency.code}>{item.amount}</Price> on and after date
                            <Tooltip className="no-wrap" placement="top" title={moment(item.fromDate).format('MM/DD/YYYY')}>
                              {moment(item.fromDate).format('DD MMM YYYY')}
                            </Tooltip>
                          </li>
                        )
                      )}
                    </ul>
                  </>
                )}
                {hotelInfo.accommodation.rooms && hotelInfo.accommodation.rooms[0].remarks && (
                  <div className="margin-bottom">
                    <h5 className="normal-title">Remarks</h5>
                    {hotelInfo.accommodation.rooms[0].remarks}
                  </div>
                )}
                {
                  transactionsList ? (
                    transactionsList.length ? (
                      <div className="container">
                        <Transaction model={transactionsList} />
                      </div>
                    ) : (
                      <Alert message="Attention" description="There isn't any Transactions List" type="info" showIcon className={'margin-bottom'} />
                    )
                  ) : (
                    <Card className="margin-bottom small-empty-box">
                      <Empty description={"There isn't any Transactions List"} />
                    </Card>
                  )
                  // <div>transcation loading</div>
                }
                {
                  gatewaysLogs ? (
                    gatewaysLogs.length ? (
                      <div className="container">
                        <Gateways model={gatewaysLogs} />
                      </div>
                    ) : (
                      <Alert message="Attention" description="There isn't any Gateways" type="info" className={'margin-bottom'} showIcon />
                    )
                  ) : (
                    <Card className="small-empty-box">
                      <Empty description={"There isn't any Gateways"} />
                    </Card>
                  )
                  // <div>gatewaysLogs loading</div>
                }
              </>
            ) : (
              <>
                <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100px' }} className="one-line-skleton" />
                <Table className="margin-top margin-bottom" pagination={false} columns={loadingTableHead} dataSource={loadingTableData} />
                <Skeleton className="margin-bottom" active paragraph={{ rows: 1 }} title={{ width: '100px' }} />
                <Skeleton className="margin-bottom" active paragraph={{ rows: 3 }} title={{ width: '100px' }} />
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getTransactionInfo: (id) => {
    dispatch(getTransactionInfo(id));
  },
  getGatewaysInfo: (id) => {
    dispatch(getGatewaysInfo(id));
  },
  getHotelInfo: (id) => {
    dispatch(getHotelInfo(id));
  },
  getPreCancel: (param) => {
    dispatch(getPreCancel(param));
  },
  setPreCancel: (param) => {
    dispatch(setPreCancel(param));
  },
  cancel: (param) => {
    dispatch(cancel(param));
  },
});
const mapStateToProps = (state) => ({
  transactionsList: state.Reserve.transactionList,
  hotelInfo: state.Reserve.hotelDetails,
  gatewaysLogs: state.Reserve.gatewaysLogs,
  cancelInfo: state.Hotel.cancelInfo,
});
const HotelDetailsRouter = withRouter(HotelDetails);
const FormEnable = Form.create({ name: 'cancelHotel' })(HotelDetailsRouter);
export default connect(mapStateToProps, mapDispatchToProps)(FormEnable);
