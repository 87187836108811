import _ from 'lodash';

import { FilterActions, HotelActions } from '../Enums/ActionTypes';
import { FilterName, FilterType, Hotel as HotelModel } from '../Enums/Types';

//hotel Reducer
export const Hotel = (state = new HotelModel(), action) => {
  switch (action.type) {
    case HotelActions.SaveResult:
      return {
        ...state,
        ...action.input,
        /*we need to save hotelResult to another place for filtering result*/
        hotelsBackup: action.input.hotels,
      };
    case HotelActions.SetHotelLoading:
      return {
        ...state,
        loadingHotel: action.input,
      };
    case HotelActions.SetHotelFilter:
      return {
        ...state,
        filterShow: action.input,
      };
    case FilterActions.FilterSelect:
      let filterSelect = state.filterSelect;
      if (action.input.isAdd) {
        if (action.input.type === FilterName.name) {
          filterSelect[action.input.type].value = action.input.textInput;
        } else {
          filterSelect[action.input.type].value.push(JSON.parse(action.input.value));
        }
      } else {
        filterSelect[action.input.type].value = filterSelect[action.input.type].value.filter((item) => {
          let value = typeof action.input.value === 'object' ? JSON.stringify(action.input.value) : action.input.value.toString();
          return JSON.stringify(item) !== value;
        });
      }
      return {
        ...state,
        filterSelect,
      };
    case FilterActions.FilterApply:
      const filtering = (item) => {
        let status = true;
        for (let filterName in state.filterSelect) {
          let filterItem = state.filterSelect[filterName].value;
          if (filterItem !== null && filterItem.length) {
            //convert number value to array
            let valueFilterName = typeof item[filterName] === 'number' ? [item[filterName]] : item[filterName];
            if (state.filterSelect[filterName].type === FilterType.Like) {
              if (valueFilterName.toLowerCase().indexOf(filterItem.toLowerCase()) === -1) {
                status = false;
              }
            } else {
              let differentCount = _.differenceWith(filterItem, valueFilterName, _.isEqual);
              //when if hotel should have all of selected  item in filter choice Intersection but hotel should have some item choice Union
              if (state.filterSelect[filterName].type === FilterType.Union) {
                if (differentCount.length === filterItem.length) {
                  status = false;
                }
              } else if (state.filterSelect[filterName].type === FilterType.Intersection) {
                if (differentCount.length !== 0) {
                  status = false;
                }
              }
            }
          }
        }
        if (status) {
          return item;
        }
      };
      let hotelResult = state.hotelsBackup.filter(filtering);
      return {
        ...state,
        hotels: hotelResult,
      };
    case FilterActions.Sort:
      let hotelData = JSON.stringify(state.hotels);
      let hotelDataBackup = JSON.stringify(state.hotelsBackup);
      hotelData = JSON.parse(hotelData);
      hotelDataBackup = JSON.parse(hotelDataBackup);
      hotelData = _.sortBy(hotelData, action.input.typeSort);
      hotelDataBackup = _.sortBy(hotelDataBackup, action.input.typeSort);
      if (action.input.isReverse) {
        hotelData = hotelData.reverse();
        hotelDataBackup = hotelDataBackup.reverse();
      }
      return {
        ...state,
        hotels: hotelData,
        hotelsBackup: hotelDataBackup,
      };
    case HotelActions.SetHotel:
      return {
        ...state,
        selectedHotel: action.input,
      };
    case HotelActions.SetRoomKey:
      return {
        ...state,
        reserveId: action.input,
      };
    case HotelActions.SaveRoomSelected:
      return {
        ...state,
        selectedRooms: action.input,
      };
    case HotelActions.ClearHotelSelected:
      return {
        ...state,
        selectedHotel: null,
      };
    case HotelActions.SetCancelInformation:
      return {
        ...state,
        cancelInfo: action.input,
      };

    default:
      return {
        ...state,
      };
  }
};
