//import library
import axios from 'axios';

import { HotelV2 as HotelURL, Urls, getHeaderRequest } from '../Enums/APIAddress';
import { objectConverter } from '../utils/helpers';

const hotelAxios = axios.create();
hotelAxios.defaults.baseURL = `${Urls.Type}${Urls.HotelV2}/`;
hotelAxios.interceptors.request.use((config) => {
  config.headers = getHeaderRequest(true);
  return config;
});

class Hotel {
  searchHotelToken;

  searchHotel = async (param) => {
    if (this.searchHotelToken) {
      this.searchHotelToken.cancel('operation cancelled');
    }
    this.searchHotelToken = axios.CancelToken.source();
    try {
      let response = await hotelAxios.get(`${HotelURL.GetHotel}${param}`, { cancelToken: this.searchHotelToken.token });
      this.searchHotelToken = null;
      return response.data.result;
    } catch (error) {
      if (axios.isCancel(error)) {
        return { cancelled: true };
      }
      return error;
    }
  };

  hotelDetails = async (param) => {
    try {
      let response = await hotelAxios.get(`${HotelURL.GetHotelDetails}${param}`);
      return response.data.result;
    } catch (error) {
      return error;
    }
  };
  preReserve = async (param) => {
    try {
      let response = await hotelAxios.post(HotelURL.PreReserve, param);
      return response.data.result;
    } catch (error) {
      return error;
    }
  };
  getPassengerInfo = async (param) => {
    try {
      let response = await hotelAxios.get(`${HotelURL.GetPreReserveID}?${param}`);
      return response.data.result;
    } catch (error) {
      return error;
    }
  };
  reserve = async (param) => {
    try {
      let response = await hotelAxios.post(HotelURL.PostReserve, param);
      return response.data.result;
    } catch (error) {
      return error;
    }
  };
  book = async (param) => {
    try {
      let response = await hotelAxios.post(HotelURL.PostBook, param);
      return response.data.result;
    } catch (error) {
      return error;
    }
  };
  getHotelDetails = async (param) => {
    try {
      let response = await hotelAxios.get(`${HotelURL.GetReserveByID}?${param}`);
      return response.data.result;
    } catch (error) {
      return error;
    }
  };

  getPreCancel = async (param) => {
    try {
      let response = await hotelAxios.get(`${HotelURL.getPreCancel}?reserveId=${param.reserveId}&username=${param.username}`);
      return response.data.result;
    } catch (error) {
      return error;
    }
  };

  getCancellationPolicy = async (params) => {
    try {
      const data = objectConverter.toQueryString(params);
      let response = await hotelAxios.get(`${HotelURL.GetCancellationPolicy}?${data}`);
      return response.data.result;
    } catch (error) {
      return error;
    }
  };

  cancelHotel = async (param) => {
    try {
      let response = await hotelAxios.post(HotelURL.cancel, param);
      return response.data.result;
    } catch (error) {
      return error;
    }
  };
}

export default new Hotel();
