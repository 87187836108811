//import library
import axios from 'axios';

import { Coordinate as CoordinateURL, Urls, getHeaderRequest } from '../Enums/APIAddress';

class Coordinator {
  CreateNewDescription = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.post(`${Urls.Coordinator}${CoordinateURL.CreateLog}`, param, { headers: { ...header } });
      return response;
    } catch (error) {
      return error;
    }
  };
  GetAllDescriptions = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.get(`${Urls.Coordinator}${CoordinateURL.GetAllLogs}?ReserveId=${param}`, { headers: { ...header } });
      return response;
    } catch (error) {
      return error;
    }
  };
  DeleteDescription = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.delete(`${Urls.Coordinator}${CoordinateURL.DeleteLog}?Id=${param}`, { headers: { ...header } });
      return response;
    } catch (error) {
      return error;
    }
  };
  GetDescriptionDetail = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.get(`${Urls.Coordinator}${CoordinateURL.GetLog}?Id=${param}`, { headers: { ...header } });
      return response;
    } catch (error) {
      return error;
    }
  };

  UpdateDescription = async (param) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.put(`${Urls.Coordinator}${CoordinateURL.UpdateLog}`, param, { headers: { ...header } });
      return response;
    } catch (error) {
      return error;
    }
  };

  GetOrder = async (reserveId, username) => {
    let header = getHeaderRequest(true);
    try {
      let response = await axios.get(`${Urls.Coordinator}${CoordinateURL.OrderGet}?Id=${reserveId}&Username=${username}`, { headers: { ...header } });
      return response;
    } catch (error) {
      return error;
    }
  };
}
export default new Coordinator();
