import '../../../../CSS/Reserves.scss';

import { Card, Col, Icon, Modal, Row, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { CancelPNR, VoidPNR } from '../../../../Actions/FlightAction';
import CoordinatorApi from '../../../../APIs/Coordinator';
import { Urls } from '../../../../Enums/APIAddress';
import { PNRStatus } from '../../../../Enums/Types';
import Price from '../../../commond/Price';
// import Descriptions from './Descriptions/Descriptions';
import Refund from './Refund';
import Status from './ReserveStatus';

class InfoCardInner extends React.Component {
  state = {
    createDecription: false,
    reserveStatus: null,
  };
  operatingFlight = () => {
    let idSupplier = this.props.suppliers.filter((item) => item.supplierName === this.props.user.supplier.id)[0].supplierId;
    this.props.history.push(`/flight/ticketing?&pnrCode=${this.props.user.reference}&supplierId=${idSupplier}`);
  };

  getWeekday(date) {
    var weekday = new Date(date).getDay();
    var options = { weekday: 'long' };
    return new Intl.DateTimeFormat('en-US', options).format(weekday);
  }

  createPDF() {
    const tenantName = localStorage.getItem('tenantName');
    if (this.props.user.type === 'HOTEL_GLOBAL') {
      window.open(`${Urls.HotelTicket.replace('****', tenantName)}?reserveId=${this.props.user.reserveNumber}&username=${this.props.user.username}`, '_blank');
    } else {
      window.open(`${Urls.FlightTicket.replace('****', tenantName)}?reserveId=${this.props.user.reserveNumber}`, '_blank');
    }
  }
  openNewDescriptionModal = () => {
    this.setState({ createDecription: true });
  };
  closeNewDescriptionModal = () => {
    this.setState({ createDecription: false });
  };

  // getMonthName(date){
  //     let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  //     let monthNumber = (new Date(date).getMonth()+1);
  //     return monthNames[monthNumber];
  // }
  // getFormatDate(date){
  //     return `${this.getWeekday(date)}, ${this.getMonthName(date)} ${new Date(date).getDate()}
  //     ${new Date(date).getFullYear()} - ${new Date(date).getHours()}:${new Date(date).getMinutes()} `
  // }
  // voidIssued = () => {
  //   Modal.confirm({
  //     title: 'Attention',
  //     content: "After voiding this ticket you have to cancel it,if you don't do it,you must pay charges",
  //     onOk: () => {
  //       this.props.VoidPNR({ pnrCode: this.props.user.reference, supplierId: this.props.user.supplier.id });
  //     },
  //     onCancel() {},
  //   });
  // };
  cancelPNR = () => {
    this.props.CancelPNR({ pnrCode: this.props.user.reference, supplierId: this.props.user.supplier.id });
  };
  componentDidMount() {
    CoordinatorApi.GetOrder(this.props.user.reserveNumber, this.props.user.username).then((response) => {
      response.data && this.setState({ reserveStatus: response.data.result.status });
    });
  }
  render() {
    const isHottel = this.props.user.type;
    let icon, page;
    // if (isHottel === "HOTEL_GLOBAL") {
    //     icon = <Link to={"/hotel"} className="full-width"><span className={"icon-large hotel-orange-icon itours-icon"} /> </Link>;
    //     page = "hotel";
    // } else {
    //     icon = <Link to={"/flight"} className="full-width"><span className={"icon-large flight-icon itours-icon"} /></Link>
    //     page = "flight";
    // }
    if (isHottel === 'HOTEL_GLOBAL') {
      icon = <span className={'infocard-item-icon margin-right-half hotel-orange-icon itours-icon'} />;
      page = 'hotel';
    } else {
      icon = <span className={'infocard-item-icon margin-right-half flight-icon itours-icon'} />;
      page = 'flight';
    }
    return (
      <Card className="margin-bottom">
        <h3 className="reserve-title">
          <Link to={`reserves/${page}?id=${this.props.user.reserveNumber}`}>
            {icon}
            {/* %%% طبق طرح اینجا باید اسم هتل یا مبدا و مقصد پرواز باشد */}
            {this.props.user.firstName} {this.props.user.lastName}
          </Link>
        </h3>

        <Row className="reserve-item-grid-row">
          <Col xs={24} lg={24} xl={7}>
            {/* %%% <div className="margin-bottom-light"><b>{this.props.user.firstName}  {this.props.user.lastName}</b></div> */}
            {this.props.user.reference ? (
              isHottel === 'FLIGHT_GLOBAL' ? (
                <div className="margin-bottom-light">
                  <b>PNR code: </b> {this.props.user.reference}
                </div>
              ) : null
            ) : null}
            {isHottel === 'FLIGHT_GLOBAL' ? (
              <div className="margin-bottom-light">
                <b>Supplier name: </b> {this.props.user.supplier.id}
              </div>
            ) : null}
            {this.props.user.phoneNumber ? (
              <div className="margin-bottom-light">
                <Icon type="mobile" className="margin-right-light orange" />
                {this.props.user.phoneNumber}
              </div>
            ) : null}
            {this.props.user.email ? (
              <div className="margin-bottom-light">
                <Icon type="mail" className="margin-right-light red" />
                {this.props.user.email}
              </div>
            ) : null}
            {this.props.user.terminal.name ? (
              <div className="margin-bottom-light">
                <Icon type="global" className="margin-right-light blue" />
                {this.props.user.terminal.name}
              </div>
            ) : null}
          </Col>
          <Col xs={24} lg={24} xl={9}>
            <div className="margin-bottom-light">
              <b>Reserve Number: </b>
              <Link to={`reserves/${page}?id=${this.props.user.reserveNumber}`}>{this.props.user.reserveNumber}</Link>
            </div>

            <div className={'margin-bottom-light'}>
              <b>Booking Date: </b>
              <span className="font-13">{moment(this.props.user.createDate).format('dddd, MMMM DD YYYY - hh:mm')}</span>
            </div>
            <div className={'margin-bottom-light'}>
              <b>Departure Date: </b>
              <span className="font-13">{moment(this.props.user.departureDate).format('dddd, MMMM DD YYYY - hh:mm')}</span>
            </div>
          </Col>
          <Col xs={24} lg={24} xl={8} className="status-price-block-col">
            <div className="status-price-block">
              <div className="margin-right-light status-block">
                <div className="grey margin-bottom-light">Status</div>
                <Status displayType="Tag" status={this.state.reserveStatus} />
              </div>
              <div>
                <div className="grey margin-bottom-light">Price</div>
                <div>
                  <Price currency={this.props.user.saleCurrency.code}>{this.props.user.salePrice}</Price>
                </div>
                {this.props.user.discountPrice === 0 ? null : this.props.user.salePrice - this.props.user.introPrice > 0 ? (
                  <div className={'green'}>
                    <div className="margin-bottom-light">
                      <Icon type="check-circle" className={'font-22 margin-right-light'} />
                      Your Profit: <Price currency={this.props.user.introCurrency.code}>{this.props.user.salePrice - this.props.user.introPrice}</Price>
                    </div>
                    <div className="margin-bottom-light">
                      Profit Percentage:
                      <span className="no-wrap"> {Math.round(((this.props.user.salePrice - this.props.user.introPrice) * 100) / this.props.user.salePrice)} %</span>
                    </div>
                  </div>
                ) : (
                  <div className={'red'}>
                    <div className="margin-bottom-light">
                      <Tooltip placement="top" title={' Commission is negative! '}>
                        <Icon type="warning" className={'font-22 margin-right-light'} />
                      </Tooltip>
                      Your Loss: <Price currency={this.props.user.introCurrency.code}>{this.props.user.introPrice - this.props.user.salePrice}</Price>
                    </div>
                    <div className="margin-bottom-light">
                      Loss Percentage:
                      <span className="no-wrap">{Math.round(((this.props.user.introPrice - this.props.user.salePrice) * 100) / this.props.user.salePrice)} %</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} lg={24} xl={17} className="pull-right-lg">
            <div className="reserve-button-group margin-top text-right">
              {/* <button type="button" className="button description-btn" onClick={() => this.openNewDescriptionModal()}>
                Create Description
              </button> */}
              {this.state.reserveStatus === 'Issued' && (
                <button type="button" className="button voucher-ticket-btn" onClick={() => this.createPDF()}>
                  <Icon type="printer" className="margin-right-light" />
                  {isHottel === 'HOTEL_GLOBAL' ? 'Voucher' : 'Ticket'}
                </button>
              )}
              {isHottel === 'FLIGHT_GLOBAL' && this.props.isTicket && (
                <button type="button" className="button voucher-ticket-btn" onClick={() => this.operatingFlight()}>
                  Ticketing/Show PNR
                </button>
              )}

              {this.state.reserveStatus === 'Issued' && (
                <>
                  <a
                    className="button invoice-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://voucher.imt.as/en/${isHottel === 'HOTEL_GLOBAL' ? 'factor/hotel' : 'Factor/Flight'}?reserveId=${this.props.user.reserveNumber}&username=${
                      this.props.user.username
                    }`}>
                    Invoice
                  </a>
                  {(isHottel === 'HOTEL_GLOBAL' ||
                    this.props.user.supplier.id === 'Farelogix' ||
                    this.props.user.supplier.id === 'Ethnic' ||
                    this.props.user.supplier.id === 'Sabre' ||
                    this.props.user.supplier.id === 'Amadeus') && (
                    <Refund
                      reserveType={isHottel === 'HOTEL_GLOBAL' ? 'hotel' : 'flight'}
                      reserveId={this.props.user.reserveNumber}
                      username={this.props.user.username}
                      inlineButton={true}
                    />
                  )}
                </>
              )}

              {/* {this.props.user.pNRStatus === PNRStatus.Issued && (
                <button type="button" className="button voucher-ticket-btn" onClick={() => this.voidIssued()}>
                  Void
                </button>
              )} */}
              {(this.props.user.pNRStatus === PNRStatus.Voided || this.props.user.pNRStatus === PNRStatus.Priced || this.props.user.pNRStatus === PNRStatus.Made) &&
                this.state.reserveStatus !== 'Issued' && (
                  <button type="button" className="button voucher-ticket-btn" onClick={() => this.cancelPNR()}>
                    Cancel
                  </button>
                )}

              <Link className="button details-btn" to={`reserves/${page}?id=${this.props.user.reserveNumber}`}>
                Details
              </Link>
              {this.state.reserveStatus === 'Pending' && isHottel === 'HOTEL_GLOBAL' && (
                <Link className="button payment-btn" to={`/payment/choice?reserveId=${this.props.user.reserveNumber}&username=${this.props.user.username}`}>
                  Payment
                </Link>
              )}
            </div>
          </Col>
        </Row>

        {/* <Descriptions closeNewDescription={this.closeNewDescriptionModal} addNewDescription={this.state.createDecription} reserveNumber={this.props.user.reserveNumber} /> */}
      </Card>
    );
  }
}

const RouterHOC = withRouter(InfoCardInner);
const mapDispatchToProps = (dispatch) => ({
  VoidPNR: (param) => dispatch(VoidPNR(param)),
  CancelPNR: (param) => dispatch(CancelPNR(param)),
});
const stateToProps = (state) => ({
  suppliers: state.Flight.ticketingSupplier,
});
export default connect(stateToProps, mapDispatchToProps)(RouterHOC);
