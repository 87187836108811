import { Button, Card, Col, Form, Input, Radio, Result, Row, Spin } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
//import library
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//import actions and model
import { getPNRFlight, setPNRFlight, validateFlight } from '../../../../Actions/FlightAction';
import { CountryCode } from '../../../../Enums/Types';
import DatePickerDrop from '../../../commond/DatePickerDrop';
//import component
import FlightSummary from '../Flight/FlightSummary';

class FlightGetPassenger extends React.Component {
  state = {
    passengerBirthday: [],
    passengerPassportExp: [],
    showExtraInput: [],
    passengerInformation: null,
    reserverPhoneNumber: undefined,
    phoneNumberEmpty: false,
    rangeAge: {
      ADT: { min: null, max: null },
      CHD: { min: null, max: null },
      INF: { min: null, max: null },
    },
    expireDate: null,
    showPNR: false,
    bookNow: false,
    showPersian: false,
  };
  //event handle for datepicker
  handleBirthdayInput(param) {
    let passengerBirthday = this.state.passengerBirthday;
    passengerBirthday[param.index] = moment(param.value).format('YYYY-MM-DD');
    this.setState({
      passengerBirthday: passengerBirthday,
    });
  }
  handlePassportExpInput(param) {
    let passportExp = this.state.passengerPassportExp;
    passportExp[param.index] = moment(param.value).format('YYYY-MM-DD');
    this.setState({
      passengerPassportExp: passportExp,
    });
  }
  getPassengers() {
    let parameters = queryString.parse(this.props.location.search);
    if (parameters.now === 'true') {
      this.setState({
        bookNow: true,
      });
    }
    if (parameters.key.toString().substr(0, 3) === '015') {
      this.setState({
        showPersian: true,
      });
    }
    this.props.validateFlight({ key: parameters.key });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    //phone input only allow numbers,back,delete,and right & left arrow
    const InputElement = document.getElementById('phoneInputId');
    if (InputElement) {
      InputElement.addEventListener('keydown', (e) => {
        var pattern = new RegExp(/[0-9]/g);
        var checkResult = pattern.test(e.key);
        if (!checkResult && e.which !== 8 && e.which !== 9 && e.which !== 46 && e.which !== 37 && e.which !== 39) {
          e.preventDefault();
        }
      });
    }

    //when PNR show
    if (prevProps.PNRInformation !== this.props.PNRInformation) {
      this.setState({
        showPNR: this.props.PNRInformation !== null,
      });
    }

    //calculate Passenger information
    let passengerInfo = [];
    //show passport and national ID for each user and fill showExtra in state
    let showExtraInfo = [];
    if (this.props.flightSelect && this.props.flightSelect !== prevProps.flightSelect) {
      this.props.flightSelect.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown.forEach((item) => {
        for (let cnt = 1; cnt <= item.passengerTypeQuantity.quantity; cnt++) {
          let passitem = { type: item.passengerTypeQuantity.code };
          passengerInfo.push(passitem);
          showExtraInfo.push(false);
        }
      });
      //set limit date
      let ADT = {
        min: null,
        max: null,
      };
      let CHD = {
        min: null,
        max: null,
      };
      let INF = {
        min: null,
        max: null,
      };
      let info = this.props.flightSelect.pricedItinerary.airItineraryPricingInfo;
      info.ptC_FareBreakdown.forEach((item) => {
        if (item.passengerTypeQuantity.code === 'ADT') {
          ADT.min = item.passengerBirthDateRange.minBirhDate;
          ADT.max = item.passengerBirthDateRange.maxBirthDate;
        } else if (item.passengerTypeQuantity.code === 'CHD') {
          CHD.min = item.passengerBirthDateRange.minBirhDate;
          CHD.max = item.passengerBirthDateRange.maxBirthDate;
        } else {
          INF.min = item.passengerBirthDateRange.minBirhDate;
          INF.max = item.passengerBirthDateRange.maxBirthDate;
        }
      });
      let dataFlight = this.props.flightSelect.pricedItinerary.airItinerary.originDestinationOptions;
      let arrivalDate = dataFlight[dataFlight.length - 1].flightSegments[dataFlight[dataFlight.length - 1].flightSegments.length - 1].arrivalDateTime;
      let expDate;
      if (arrivalDate === '0001-01-01T00:00:00') {
        expDate = dataFlight[dataFlight.length - 1].flightSegments[dataFlight[dataFlight.length - 1].flightSegments.length - 1].departureDateTime;
      } else {
        expDate = arrivalDate;
      }

      this.setState({
        rangeAge: { ADT, CHD, INF },
        passengerInformation: passengerInfo,
        expireDate: moment(expDate).add(1, 'd'),
        showExtraInput: showExtraInfo,
      });
    }
  }
  componentDidMount() {
    //get key and validate
    this.getPassengers();
    //clear PNR information
    this.props.setPNRFlight(null);
    if (this.props.userData && this.props.userData) {
      this.setState({
        reserverPhoneNumber: this.props.userData.phoneNumber,
        phoneNumberEmpty: false,
      });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const phoneNumberInput = this.phoneNumberElement.current.state.value;
      if (!phoneNumberInput) {
        this.setState({
          phoneNumberEmpty: true,
        });
        return null;
      } else {
        this.setState({
          phoneNumberEmpty: false,
        });
      }
      values.reserver.countryCallingCode = this.phoneNumberElement.current.state.dialCode.replace('+', '00');
      values.reserver.phoneNumber = this.phoneNumberElement.current.state.value.replace(/ /g, '');
      if (!err) {
        //append date pickers and redux value to Inputs
        const { userData } = this.props;
        //1-get user ID
        values.reserver['userId'] = userData.id;
        //2-3-get firstname and lastname get from form
        //4 email get from form
        //5 countryCallingCode and phoneNumber get from form phoneNumberInput
        //6-username
        values.reserver['username'] = userData.userName ? userData.userName : values.reserver['email'];
        //7-gender and countryCode get from Form
        values.passengers.forEach((item, index) => {
          item['birthDate'] = this.state.passengerBirthday[index];
          item['code'] = this.state.passengerInformation[index].type;
          if (
            (this.props.flightSelect.pricedItinerary.isDomestic && item.nationalityCode !== 'IR') ||
            (!this.props.flightSelect.pricedItinerary.isDomestic && (this.props.currencyCode === 'IRR' || item.nationalityCode === 'IR'))
          ) {
            item['passportExpireDate'] = this.state.passengerPassportExp[index];
          }
        });
        values['key'] = this.props.flightSelect.pricedItinerary.key;
        values['IsBookNowRequested'] = this.state.bookNow;
        this.props.getPNRFlight({ param: values, hasPayment: this.props.flightSelect.pricedItinerary.paymentBeforePNR || this.state.bookNow });
      }
    });
  };
  changeShowExtraInfo = (index, value) => {
    let showExtraStatus = this.state.showExtraInput;
    if (value === 'IR') {
      showExtraStatus[index] = true;
    } else {
      showExtraStatus[index] = false;
    }

    this.setState({
      showExtraInput: showExtraStatus,
    });
  };
  getFullNumber = () => {
    const phoneNumberInput = this.phoneNumberElement.current.state.value;
    if (!phoneNumberInput) {
      this.setState({
        phoneNumberEmpty: true,
      });
    } else {
      this.setState({
        phoneNumberEmpty: false,
      });
    }
  };
  phoneNumberElement = React.createRef();
  render() {
    const { flightSelect, currencyCode, PNRInformation } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        {flightSelect ? (
          <div className="container flight-get-passenger">
            {this.state.showPNR ? (
              <>
                {/*(this.state.showPNR.result)?*/}
                <div>
                  <Result
                    className="messsage-card"
                    status="success"
                    title="PNR created successfully!"
                    extra={[
                      <>
                        <div className="font-18 margin-bottom">
                          {' '}
                          <b>PNR Code: {PNRInformation.pnrCode}</b>
                        </div>
                        <div className="margin-bottom">Supplier Name:{PNRInformation.providerName}</div>
                        <div className="margin-bottom">Reserve Status: {PNRInformation.reserveStatus}</div>
                        <div className={'margin-bottom'}>
                          <Link
                            className={'button blue-btn'}
                            to={`/flight/ticketing?pnrCode=${PNRInformation.pnrCode}&supplierId=${
                              this.props.suppliers.filter((item) => item.supplierName === PNRInformation.providerName)[0].supplierId
                            }`}>
                            Ticket this PNR
                          </Link>
                        </div>
                        <div>
                          <Link className={'button white-btn'} to="/flight">
                            Search Again
                          </Link>
                        </div>
                      </>,
                    ]}
                  />
                </div>
              </>
            ) : (
              <div>
                <FlightSummary
                  showButton={true}
                  model={flightSelect.pricedItinerary.airItinerary.originDestinationOptions}
                  currencyCode={currencyCode}
                  fareInfo={flightSelect.pricedItinerary.airItineraryPricingInfo.ptC_FareBreakdown}
                  totalPrice={flightSelect.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare}
                  feild={'passengerFare'}
                  layout={'wide'}
                  hideCommission={false}
                  hideFees={false}
                  supplierKey={flightSelect.pricedItinerary.key}
                />
                <Form onSubmit={this.handleSubmit}>
                  <Card title="Reserver Contact Information" className="margin-bottom">
                    <Row gutter={15}>
                      <Col xs={24} sm={8}>
                        <label>Gender</label>
                        <Form.Item>
                          {getFieldDecorator(`reserver.gender`, {
                            initialValue: this.props.userData.gender,
                            rules: [
                              {
                                required: true,
                                message: 'you should input your gender',
                              },
                            ],
                          })(
                            <select>
                              <option value={true}>Mr</option>
                              <option value={false}>Ms</option>
                            </select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8}>
                        <label>First Name</label>
                        <Form.Item>
                          {getFieldDecorator(`reserver.firstName`, {
                            initialValue: this.props.userData.firstName,
                            rules: [
                              {
                                required: true,
                                message: 'you should input first name',
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z ]*$/),
                                message: `Please enter first name using letters only`,
                              },
                            ],
                          })(<Input type={'text'} placeholder={'insert your  first name'} />)}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8}>
                        <label>Last Name</label>
                        <Form.Item>
                          {getFieldDecorator(`reserver.lastName`, {
                            initialValue: this.props.userData.lastName,
                            rules: [
                              {
                                required: true,
                                message: 'you should input last name',
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z ]*$/),
                                message: `Please enter last name using letters only`,
                              },
                            ],
                          })(<Input type={'text'} placeholder={'insert your  last name'} />)}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={15}>
                      <Col xs={24} sm={8}>
                        <label>Phone Number</label>
                        <Form.Item className={this.state.phoneNumberEmpty ? 'error' : null}>
                          <IntlTelInput
                            preferredCountries={[]}
                            defaultCountry={'no'}
                            fieldId="phoneInputId"
                            defaultValue={this.state.reserverPhoneNumber ? this.state.reserverPhoneNumber : undefined}
                            onPhoneNumberChange={this.getFullNumber}
                            onSelectFlag={this.getFullNumber}
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            fieldName={'phoneNumber'}
                            placeholder={'Phone number'}
                            useMobileFullscreenDropdown={true}
                            ref={this.phoneNumberElement}
                            separateDialCode={true}
                          />
                        </Form.Item>
                        <div className={this.state.phoneNumberEmpty ? 'phone-number-validation-message show' : 'phone-number-validation-message'}>Please fill phone number</div>
                      </Col>
                      <Col xs={24} sm={8}>
                        <label>Email Address</label>
                        <Form.Item>
                          {getFieldDecorator(`reserver.email`, {
                            initialValue: this.props.userData.emailAddress,
                            rules: [
                              {
                                required: true,
                                message: 'you should input your email',
                              },
                            ],
                          })(<Input type={'email'} placeholder={'insert your  email'} />)}
                        </Form.Item>
                      </Col>
                      {flightSelect.captchaLink && (
                        <Col xs={24} sm={8}>
                          <label>Enter Captcha Code</label>
                          <Row gutter={[10, 0]}>
                            <Col xs={12}>
                              <div className="captha-image-holder">
                                <img src={`//images.weserv.nl/?url=${flightSelect.captchaLink}&h=32&Cache-Control=2`} alt={'captcha'} className="captha-image" />
                              </div>
                            </Col>
                            <Col xs={12}>
                              <Form.Item>
                                {getFieldDecorator(`captchaResult`, {
                                  initialValue: null,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'you should insert captha code',
                                    },
                                  ],
                                })(<Input type={'text'} placeholder={'insert captha code'} />)}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Card>
                  {/*reserver information*/}

                  <Card className="margin-bottom" title="Passengers information">
                    {this.state.passengerInformation ? (
                      <>
                        {this.state.passengerInformation.length &&
                          this.state.passengerInformation.map((item, index) => {
                            return (
                              <div className="passenger-item" key={index}>
                                <div className="passenger-title margin-bottom">
                                  <b>
                                    <span>Passenger </span>
                                    <span> {index + 1} </span>
                                    <span>
                                      ({item.type === 'ADT' && <>Adult</>}
                                      {item.type === 'CHD' && <>Child</>}
                                      {item.type === 'INF' && <>Infant</>})
                                    </span>
                                  </b>
                                </div>
                                <Form.Item>
                                  {getFieldDecorator(`passengers[${index}].gender`, {
                                    rules: [
                                      {
                                        required: true,
                                        message: "Please select passenger's gender",
                                      },
                                    ],
                                  })(
                                    <Radio.Group>
                                      <Radio value={true}>Mr</Radio>
                                      <Radio value={false}>Ms</Radio>
                                    </Radio.Group>
                                  )}
                                </Form.Item>
                                <Row gutter={15} className="clear-4n">
                                  <Col xs={24} md={6}>
                                    <label>First Name</label>
                                    <Form.Item>
                                      {getFieldDecorator(`passengers[${index}].firstName`, {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Please input passenger's first name",
                                          },
                                          {
                                            pattern: new RegExp(/^[a-zA-Z ]*$/),
                                            message: `Please enter first name using letters only`,
                                          },
                                        ],
                                      })(<Input placeholder={"insert passenger's firstname"} />)}
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <label>Last Name</label>
                                    <Form.Item>
                                      {getFieldDecorator(`passengers[${index}].lastName`, {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Please input passenger's last name",
                                          },
                                          {
                                            pattern: new RegExp(/^[a-zA-Z ]*$/),
                                            message: `Please enter last name using letters only`,
                                          },
                                        ],
                                      })(<Input placeholder={"insert passenger's lastName"} />)}
                                    </Form.Item>
                                  </Col>

                                  {this.props.flightSelect.pricedItinerary.isDomestic && (
                                    <>
                                      <Col xs={24} md={6}>
                                        <label>Persian First Name</label>
                                        <Form.Item>
                                          {getFieldDecorator(`passengers[${index}].persianFirstName`, {
                                            rules: [
                                              {
                                                required: true,
                                                message: "Please input passenger's first name",
                                              },
                                              {
                                                pattern: new RegExp(/^[آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهیئ\s]*$/),
                                                message: `Please use persian letters only`,
                                              },
                                            ],
                                          })(<Input placeholder={"insert passenger's firstname"} className="font-fa text-right left-placeholder" />)}
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} md={6}>
                                        <label>Persian Last Name</label>
                                        <Form.Item>
                                          {getFieldDecorator(`passengers[${index}].persianLastName`, {
                                            rules: [
                                              {
                                                required: true,
                                                message: "Please input passenger's last name",
                                              },
                                              {
                                                pattern: new RegExp(/^[آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهیئ\s]*$/),
                                                message: `Please use persian letters only`,
                                              },
                                            ],
                                          })(<Input placeholder={"insert passenger's lastName"} className="font-fa text-right left-placeholder" />)}
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}

                                  <Col xs={24} md={6}>
                                    <label>Nationality</label>
                                    <Form.Item>
                                      {getFieldDecorator(`passengers[${index}].nationalityCode`, {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Please input passenger's last name",
                                          },
                                        ],
                                      })(
                                        <select placeholder={"insert passengers's nationality"} onChange={(e) => this.changeShowExtraInfo(index, e.target.value)}>
                                          <option value={null}></option>
                                          {CountryCode.map((item) => (
                                            <option value={item.value} key={item.code}>
                                              {item.code}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} md={6}>
                                    <label>Birthday</label>
                                    <DatePickerDrop
                                      defaultValue={this.state.rangeAge[item.type].min}
                                      format={'YYYY-MM-DD'}
                                      name={`passengers[${index}].birthDate`}
                                      min={this.state.rangeAge[item.type].min}
                                      max={this.state.rangeAge[item.type].max}
                                      change={(value) => this.handleBirthdayInput({ index, value })}
                                    />
                                  </Col>
                                  {this.props.flightSelect.pricedItinerary.isDomestic === true ? (
                                    this.state.showExtraInput[index] ? (
                                      <Col xs={24} md={6}>
                                        <label>National Id</label>
                                        <Form.Item>
                                          {getFieldDecorator(`passengers[${index}].nationalId`, {
                                            rules: [
                                              {
                                                required: true,
                                                message: "Please input passenger's national ID",
                                              },
                                            ],
                                          })(<Input placeholder={"insert Passenger's National ID"} />)}
                                        </Form.Item>
                                      </Col>
                                    ) : (
                                      <>
                                        <Col xs={24} md={6}>
                                          <label>Passport Number</label>
                                          <Form.Item>
                                            {getFieldDecorator(`passengers[${index}].passportNumber`, {
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "Please input passenger's Passport Number",
                                                },
                                                {
                                                  pattern: new RegExp(/^[0-9a-zA-Z ]*$/),
                                                  message: `Passport number is invalid`,
                                                },
                                              ],
                                            })(<Input placeholder={"insert passenger's Passport Number"} />)}
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                          <label>Passport Expire Date</label>
                                          <DatePickerDrop
                                            defaultValue={this.state.expireDate}
                                            format={'YYYY-MM-DD'}
                                            name={`passengers[${index}].passportExpireDate`}
                                            min={this.state.expireDate}
                                            max={moment(this.state.expireDate).add(10, 'years')}
                                            change={(value) => this.handlePassportExpInput({ index, value })}
                                          />
                                        </Col>
                                      </>
                                    )
                                  ) : // ) : this.props.currencyCode === 'IRR' ? (
                                  //for corona rules:
                                  true ? (
                                    <>
                                      <Col xs={24} md={6}>
                                        <label>Passport Number</label>
                                        <Form.Item>
                                          {getFieldDecorator(`passengers[${index}].passportNumber`, {
                                            rules: [
                                              {
                                                required: true,
                                                message: "Please input passenger's Passport Number",
                                              },
                                              {
                                                pattern: new RegExp(/^[0-9a-zA-Z ]*$/),
                                                message: `Passport number is invalid`,
                                              },
                                            ],
                                          })(<Input placeholder={"insert passenger's Passport Number"} />)}
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} md={6}>
                                        <label>Passport Expire Date</label>
                                        <DatePickerDrop
                                          defaultValue={this.state.expireDate}
                                          format={'YYYY-MM-DD'}
                                          name={`passengers[${index}].passportExpireDate`}
                                          min={this.state.expireDate}
                                          max={moment(this.state.expireDate).add(10, 'years')}
                                          change={(value) => this.handlePassportExpInput({ index, value })}
                                        />
                                      </Col>
                                    </>
                                  ) : (
                                    this.state.showExtraInput[index] && (
                                      <>
                                        <Col xs={24} md={6}>
                                          <label>Passport Number</label>
                                          <Form.Item>
                                            {getFieldDecorator(`passengers[${index}].passportNumber`, {
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "Please input passenger's Passport Number",
                                                },
                                                {
                                                  pattern: new RegExp(/^[0-9a-zA-Z ]*$/),
                                                  message: `Passport number is invalid`,
                                                },
                                              ],
                                            })(<Input placeholder={"insert passenger's Passport Number"} />)}
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                          <label>Passport Expire Date</label>
                                          <DatePickerDrop
                                            defaultValue={this.state.expireDate}
                                            format={'YYYY-MM-DD'}
                                            name={`passengers[${index}].passportExpireDate`}
                                            min={this.state.expireDate}
                                            max={moment(this.state.expireDate).add(10, 'years')}
                                            change={(value) => this.handlePassportExpInput({ index, value })}
                                          />
                                        </Col>
                                      </>
                                    )
                                  )}
                                </Row>
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      <div>Loading data ... </div>
                    )}
                  </Card>

                  <div className="margin-bottom text-right">
                    <Form.Item>
                      {flightSelect.pricedItinerary.paymentBeforePNR || this.state.bookNow ? (
                        <Button type="primary" size={'large'} className="margin-bottom" htmlType="submit">
                          Payment for Ticket
                        </Button>
                      ) : (
                        <Button type="primary" size={'large'} className="margin-bottom" htmlType="submit">
                          Get PNR
                        </Button>
                      )}
                    </Form.Item>
                  </div>
                </Form>
              </div>
            )}
          </div>
        ) : (
          <div className="loading-section text-center">
            <div className="margin-bottom">
              <Spin />
            </div>
            <div>
              <strong>Loading data ...</strong>
            </div>
          </div>
        )}
      </>
    );
  }
}

const dispatchToProps = (dispatch) => ({
  validateFlight: (key) => dispatch(validateFlight(key)),
  getPNRFlight: (param) => dispatch(getPNRFlight(param)),
  setPNRFlight: (param) => dispatch(setPNRFlight(param)),
});
const stateToProps = (state) => ({
  userData: state.User,
  flightSelect: state.Flight.flightSelect,
  currencyCode: state.User.currency,
  PNRInformation: state.Flight.PNRInformation,
  suppliers: state.Flight.ticketingSupplier,
});
const FormWrap = Form.create({ name: 'passenger_form' })(FlightGetPassenger);
export default connect(stateToProps, dispatchToProps)(FormWrap);
