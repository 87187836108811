import 'react-intl-tel-input/dist/main.css';

import { Breadcrumb, Button, Col, DatePicker, Form, Icon, Input, Layout, Modal, Radio, Result, Row, Select, Spin } from 'antd';
import axios from 'axios';
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { CountryCode } from './../../../../Enums/Types';
import { Account as AccountURL, Urls, getHeaderRequest } from '../../../../Enums/APIAddress';

const { Content } = Layout;

class UserCreate extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    value: true,
    description: '',
    data: [],
    currencyType: [],
    supplierType: [],
    spinner: false,
    modalVisibility: false,
    modalTitle: '',
    modalDescriptions: '',
    modalCloseAction: 'close',
  };
  //openModal
  openModal = (option) => {
    this.setState({
      modalVisibility: true,
      modalStatus: option.status,
      modalTitle: option.title,
      modalDescriptions: option.description,
      modalCloseAction: option.closeAction,
    });
  };
  //closeModal
  closeModal = () => {
    this.setState({
      modalVisibility: false,
      modalStatus: '',
      modalTitle: '',
      modalDescriptions: '',
    });
  };

  getFullNumber = () => {
    let phoneNumber;
    const phoneNumberInput = this.phoneNumberElement.current.state.value;
    if (phoneNumberInput) {
      const dialCode = this.phoneNumberElement.current.state.dialCode;
      if (phoneNumberInput.startsWith(dialCode)) {
        phoneNumber = phoneNumberInput.substring(dialCode.length, phoneNumberInput.length);
      } else {
        phoneNumber = phoneNumberInput;
      }
      phoneNumber = dialCode.concat(phoneNumber);
      phoneNumber = phoneNumber.replace(/\s/g, '');
      return phoneNumber;
    } else {
      return null;
    }
  };

  phoneNumberElement = React.createRef();

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ spinner: true });

        let headers = getHeaderRequest(true);
        values.phoneNumber = this.getFullNumber();
        values.roleNames = ['Affiliate'];
        axios
          .post(`${Urls.Type}${Urls.User}${AccountURL.CreateUsers}`, values, {
            headers: {
              ...headers,
            },
          })
          .then(({ data }) => {
            if (data.error) {
              this.openModal({
                status: 'error',
                title: 'Error',
                description: data.error.message,
                closeAction: 'close',
              });
            } else {
              this.openModal({
                status: 'success',
                title: 'Success',
                description: 'User created successfully',
                closeAction: 'backToUsers',
              });
            }
            this.setState({ spinner: false });
          })
          .catch((error) => {
            this.setState({ spinner: false });
            this.openModal({
              status: 'error',
              title: 'Error',
              description: error.response.data.error.message,
              closeAction: 'close',
            });
          });
      } else {
        this.openModal({
          status: 'warning',
          title: `Warning`,
          description: 'Please complete the form',
          closeAction: 'close',
        });
      }
    });
  };
  // componentDidMount() {
  //   let headers = getHeaderRequest(true);
  //   const currentUserId = this.props.userId;
  //   axios
  //     .get(`${Urls.Type}${Urls.User}${AccountURL.GetPermissions}?id=${currentUserId}`, {
  //       headers: {
  //         ...headers,
  //       },
  //     })
  //     .then(({ data }) => {
  //       const Allpermisions = data.result.permissions;
  //       const checkedPermisions = data.result.grantedPermissionNames;
  //       let availablePermissions = [];
  //       availablePermissions = Allpermisions.filter((item) => {
  //         return checkedPermisions.includes(item.name);
  //       });
  //       this.setState({ permisions: availablePermissions });
  //     });
  // }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    let { spinner } = this.state;
    return (
      <Content>
        <Modal visible={this.state.modalVisibility} footer={null} closable={false} className={'higher-z-index'}>
          <Result
            status={this.state.modalStatus}
            title={this.state.modalTitle}
            subTitle={this.state.modalDescriptions}
            extra={
              this.state.modalCloseAction === 'backToUsers' ? (
                <Button
                  htmlType="button"
                  type={'primary'}
                  onClick={() => {
                    this.props.history.push(`/user`);
                  }}>
                  Ok
                </Button>
              ) : (
                <Button htmlType="button" type={'primary'} onClick={this.closeModal}>
                  Ok
                </Button>
              )
            }
          />
        </Modal>
        <div className="breadcrumpt-section">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={'/'}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={'/user'}>User</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Create</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="container">
          <div className={'margin-top'}>
            <Form className="user-form" onSubmit={this.onSubmit}>
              <Form.Item label="Gender">
                {getFieldDecorator('gender', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select gender',
                    },
                  ],
                })(
                  <Radio.Group>
                    <Radio value={true}>Male</Radio>
                    <Radio value={false}>Female</Radio>
                  </Radio.Group>
                )}
              </Form.Item>{' '}
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="First name">
                    {getFieldDecorator('firstname', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter first name',
                        },
                        {
                          pattern: /[a-zA-Z]/,
                          message: 'Only letters are allowed',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Last name">
                    {getFieldDecorator('lastname', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter last name',
                        },
                        {
                          pattern: /[a-zA-Z]/,
                          message: 'Only letters are allowed',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Phone number">
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control"
                      fieldName={'phoneNumber'}
                      placeholder={'Phone number'}
                      useMobileFullscreenDropdown={true}
                      ref={this.phoneNumberElement}
                      separateDialCode={true}
                    />
                  </Form.Item>{' '}
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Birthday">
                    {getFieldDecorator('birthDay', {
                      rules: [{ required: true, message: 'Please select your birthday' }],
                    })(<DatePicker className="full-width" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Nationality">
                    {getFieldDecorator('nationalityId', {
                      rules: [{ required: true, message: 'Please input your nationality' }],
                    })(
                      <Select
                        showSearch
                        className="full-width"
                        onChange={() => {
                          this.setState({ isEdited: true });
                        }}>
                        {CountryCode.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.code}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Email address">
                    {getFieldDecorator('emailAddress', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter email address',
                        },
                        {
                          type: 'email',
                          message: 'Email address is not valid',
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Password">
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter password',
                        },
                        {
                          min: 6,
                        },
                      ],
                    })(<Input type="password" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item label="Status">
                    {getFieldDecorator('isActive', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select activation status',
                        },
                      ],
                    })(
                      <Radio.Group>
                        <Radio value={true}>Active</Radio>
                        <Radio value={false}>Deactive</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                {/*<Col xs={24}>*/}
                {/*  {this.state.permisions && this.state.permisions.length > 0 ? (*/}
                {/*    <>*/}
                {/*      <h5 className="normal-title">User Permisions</h5>*/}
                {/*      <p>User is able to</p>*/}
                {/*      <Form.Item>*/}
                {/*        {getFieldDecorator('roleNames', {*/}
                {/*          initialValue: this.state.activePermissions,*/}
                {/*        })(*/}
                {/*          <Checkbox.Group style={{ width: '100%' }}>*/}
                {/*            <Row gutter={[20, 10]}>*/}
                {/*              {this.state.permisions &&*/}
                {/*                this.state.permisions.map((item) => (*/}
                {/*                  <Col xs={24} sm={12} md={8} lg={6} key={item.name}>*/}
                {/*                    <Checkbox value={item.name}>{item.displayName}</Checkbox>*/}
                {/*                  </Col>*/}
                {/*                ))}*/}
                {/*            </Row>*/}
                {/*          </Checkbox.Group>*/}
                {/*        )}*/}
                {/*      </Form.Item>*/}
                {/*    </>*/}
                {/*  ) : null}*/}
                {/*</Col>*/}
              </Row>
              <div className="text-right margin-bottom">
                <Button disabled={spinner} type="primary" htmlType="submit">
                  {spinner ? (
                    <>
                      <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
                      Create
                    </>
                  ) : (
                    'Create'
                  )}
                </Button>
              </div>
            </Form>
          </div>{' '}
        </div>
      </Content>
    );
  }
}

// export default Form.create({ name: 'UserCreate' })(UserCreate);

const stateMapProp = (state) => ({
  userId: state.User.id,
});

const WrappedForm = Form.create({ name: 'UserCreate' })(UserCreate);
export default connect(stateMapProp)(WrappedForm);
