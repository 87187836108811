import { Button, Card, Col, Form, Icon, Input, Popover, Radio, Row, Skeleton, Steps, Tag, Tooltip } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
//import library
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { connect } from 'react-redux';

//import actions
import { getPassengerInforamtion, reserve } from '../../../../Actions/HotelAction';
import { setNotification } from '../../../../Actions/NotificationAction';
import { CountryCode, MessageType } from '../../../../Enums/Types';
//import assest
import HotelNoImage from '../../../../Images/design/default-hotel-image.svg';
import { getCancellationStatusName } from '../../../../utils/enumConverter';
//Import Component
import Price from '../../../commond/Price';
import Rate from '../../../commond/Rate';
import HotelAgentGainPopover from './HotelAgentGainPopover';

class HotelSetPassenger extends React.Component {
  state = {
    firstGuestInfoChanged: false,
    defaultCountry: undefined,
  };

  init = () => {
    let parameters = queryString.parse(this.props.location.search);
    let param = new URLSearchParams();
    param.append('preReserveKey', parameters.reserveId);
    this.props.getPassengerInformation(param);
  };
  getFullNumber = () => {
    let phoneNumber;
    const phoneNumberInput = this.phoneNumberElement.current.state.value;
    if (!phoneNumberInput) {
      return null;
    }
    const dialCode = this.phoneNumberElement.current.state.dialCode;
    if (phoneNumberInput.startsWith(dialCode)) {
      phoneNumber = phoneNumberInput.substring(dialCode.length, phoneNumberInput.length);
    } else {
      phoneNumber = phoneNumberInput;
    }
    phoneNumber = dialCode.concat(phoneNumber);
    phoneNumber = phoneNumber.replace(/\s/g, '');
    return phoneNumber;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      values.reserver.phoneNumber = this.getFullNumber();
      if (!values.reserver.phoneNumber && !values.reserver.email) {
        err = true;
        this.props.setNotification({ show: true, content: "Either contact person's email or phone number is required.", type: MessageType.Error });
      }
      if (!err) {
        this.props.reserve(values);
      }
    });
  };

  handleContactPersonFirstNameChanged = (e) => {
    if (!this.state.firstGuestInfoChanged) {
      this.props.form.setFieldsValue({
        'passengers[0].firstName': e.target.value,
      });
    }
  };

  handleContactPersonLastNameChanged = (e) => {
    if (!this.state.firstGuestInfoChanged) {
      this.props.form.setFieldsValue({
        'passengers[0].lastName': e.target.value,
      });
    }
  };

  handleContactPersonGenderChanged = (e) => {
    if (!this.state.firstGuestInfoChanged) {
      this.props.form.setFieldsValue({
        'passengers[0].gender': e.target.value,
      });
    }
  };

  handleFirstGuestInfoChanged = () => {
    this.setState({
      firstGuestInfoChanged: true,
    });
  };

  setCountryCodeOnFormField = () => {
    if (this.props.ipLocation) {
      let countryCode = this.props.ipLocation.alpha2;
      if (!countryCode) {
        countryCode = 'us';
      }
      this.setState({
        defaultCountry: countryCode.toLowerCase(),
      });
    }
  };

  componentDidMount() {
    this.init();
    this.setCountryCodeOnFormField();
    setTimeout(() => {
      if (this.props.ipLocation === undefined) {
        this.setState({
          defaultCountry: 'us',
        });
      }
    }, 3000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      this.init();
    }
    if (this.props.ipLocation !== prevProps.ipLocation) {
      this.setCountryCodeOnFormField();
    }
    if (this.props.payment !== prevProps.payment && this.props.payment.reserveId !== null) {
      this.props.history.push(`/payment/choice?reserveId=${this.props.payment.reserveId}&username=${this.props.payment.username}`);
    }
    //phone input only allow numbers,back,delete,and right & left arrow
    const InputElement = document.getElementById('phoneInputId');
    if (InputElement) {
      InputElement.addEventListener('keydown', (e) => {
        var pattern = new RegExp(/[0-9]/g);
        var checkResult = pattern.test(e.key);
        if (!checkResult && e.which !== 8 && e.which !== 9 && e.which !== 46 && e.which !== 37 && e.which !== 39) {
          e.preventDefault();
        }
      });
    }
  }

  phoneNumberElement = React.createRef();
  render() {
    //declaration var
    const { userData, selectedRooms } = this.props;
    let night = 0;
    let RoomRender = [];
    let userInformationStatus = userData.id === undefined || userData.id;
    let passengerCounter = -1;
    const { getFieldDecorator } = this.props.form;
    //calculate Passenger
    if (selectedRooms) {
      night = selectedRooms.date.duration;
      if (selectedRooms.accommodation) {
        selectedRooms.accommodation.rooms.forEach((item) => {
          let passenger = { adults: [], children: [] };
          for (let itr = 1; itr <= item.adults; itr++) {
            passenger.adults.push(itr);
          }
          for (let itr = 1; itr <= item.children; itr++) {
            passenger.children.push(itr);
          }
          RoomRender.push(passenger);
        });
      }
    }
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="container vr-padding">
          <div className="vr-padding margin-bottom">
            <Steps current={1}>
              <Steps.Step title="Choose your room" />
              <Steps.Step title="Enter your details" />
              <Steps.Step title="Confirm and payment" />
              <Steps.Step title="Complete the purchase" />
            </Steps>
          </div>
          <div className="margin-bottom">
            <h2 className="page-title">Hotel Reservation</h2>
          </div>
          <div className="getPassengers">
            {selectedRooms === null || this.state.defaultCountry === undefined ? (
              <Row gutter={[15, 15]}>
                <Col xs={24} lg={16} xl={18}>
                  <div>
                    <Card className="margin-bottom" title="Contact person information (order information is sent to the client contact number or email)">
                      <Row gutter={[15, 15]}>
                        <Col xs={24}>
                          <Skeleton active paragraph={{ rows: 1, width: '110px' }} title={{ width: '60px' }} className="form-group-skeleton" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                      </Row>
                    </Card>
                    <Card className="margin-bottom" title={'Guests information'}>
                      <Row gutter={[15, 15]}>
                        <Col xs={24}>
                          <Skeleton active paragraph={{ rows: 1, width: '250px' }} />
                          <Skeleton active paragraph={{ rows: 0 }} title={{ width: '60px' }} className="one-line-skleton" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                        <Col xs={24}>
                          <Skeleton active paragraph={{ rows: 0 }} title={{ width: '60px' }} className="one-line-skleton" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                        <Col xs={24} md={8}>
                          <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                        </Col>
                      </Row>
                    </Card>
                    <Card title="Description" className="margin-bottom">
                      <Skeleton active paragraph={{ rows: 3 }} title={{ width: '100px' }} className="no-title-skeleton" />
                    </Card>
                    <Card title={'Remarks'} className="margin-bottom">
                      <Skeleton active paragraph={{ rows: 2 }} title={{ width: '100px' }} className="no-title-skeleton" />
                    </Card>
                  </div>
                </Col>
                <Col xs={24} lg={8} xl={6}>
                  <Card title="Booking information" className="light-padding">
                    <div className="hotel-img-titles">
                      <div className="loading-images hotel-image">
                        <div className="image" />
                      </div>
                      <div className="hotel-name-star full-width">
                        <Skeleton active paragraph={{ rows: 3 }} />
                      </div>
                    </div>
                    <Skeleton className="no-title-skeleton" active paragraph={{ rows: 3 }} />
                    <div className="border-top">
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </div>
                    <div className="border-top">
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </div>
                    <div className="border-top">
                      <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100px' }} className="form-group-skeleton full-width-li" />
                    </div>
                  </Card>
                </Col>
              </Row>
            ) : (
              selectedRooms.accommodation !== undefined && (
                <Row gutter={[15, 15]}>
                  <Col xs={24} lg={16} xl={18}>
                    <div>
                      <Card className="margin-bottom" title="Contact person information (order information is sent to the client contact number or email)">
                        <label>Gender</label>
                        <div className="inline-radio">
                          <Form.Item>
                            {getFieldDecorator('reserver.gender', {
                              initialValue: this.props.userData.gender,
                              rules: [{ required: true, message: 'Please select gender' }],
                            })(
                              <Radio.Group onChange={this.handleContactPersonGenderChanged.bind(this)}>
                                <Radio value={true}>Mr</Radio>
                                <Radio value={false}>Ms</Radio>
                              </Radio.Group>
                            )}
                          </Form.Item>
                        </div>
                        <Row gutter={15}>
                          <Col xs={24} sm={12} md={8}>
                            <div className="form-group relative required">
                              <label>First name</label>
                              <Form.Item>
                                {getFieldDecorator('reserver.firstName', {
                                  initialValue: userData.firstName,
                                  rules: [
                                    { required: true, message: 'Please input contact person firstname' },
                                    {
                                      pattern: new RegExp(/^[a-zA-Z ]*$/),
                                      message: `Please enter first name using letters only`,
                                    },
                                  ],
                                })(<Input placeholder={'Contact person first name'} onInput={this.handleContactPersonFirstNameChanged.bind(this)} />)}
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={8}>
                            <div className="form-group relative required">
                              <label>Last name</label>
                              <Form.Item>
                                {getFieldDecorator('reserver.lastName', {
                                  initialValue: userData.lastName,
                                  rules: [
                                    { required: true, message: 'Please input contact person lastname' },
                                    {
                                      pattern: new RegExp(/^[a-zA-Z ]*$/),
                                      message: `Please enter last name using letters only`,
                                    },
                                  ],
                                })(<Input placeholder={'Contact person last name'} onInput={this.handleContactPersonLastNameChanged.bind(this)} />)}
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={8} className="clear-left-sm">
                            <div className="form-group relative required">
                              <label>Email</label>
                              <Form.Item>
                                {getFieldDecorator('reserver.email', {
                                  initialValue: userData.emailAddress,
                                  rules: [{ type: 'email', message: 'The input is not valid E-mail!' }],
                                })(<Input placeholder={'Contact person email'} />)}
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={8} lg={8} xl={8} className="clear-left-md clear-left-lg clear-left-xl">
                            <Popover
                              title={'Phone Number'}
                              trigger="focus"
                              content={
                                <div className="padding-10">
                                  <p>Phone number will be used to contact and inform you.</p>
                                  <p>e.g., You'll be informed about cancelling.</p>
                                </div>
                              }>
                              <div>
                                <label>Phone number</label>
                                <Form.Item>
                                  <IntlTelInput
                                    defaultCountry={this.state.defaultCountry}
                                    fieldId="phoneInputId"
                                    containerClassName="intl-tel-input"
                                    inputClassName="form-control"
                                    fieldName={'phoneNumber'}
                                    placeholder={'Phone number'}
                                    useMobileFullscreenDropdown={true}
                                    ref={this.phoneNumberElement}
                                    separateDialCode={true}
                                  />
                                </Form.Item>
                              </div>
                            </Popover>
                          </Col>
                        </Row>
                        {userInformationStatus && (
                          <Form.Item className={'hide'}>{getFieldDecorator('reserver.userID', { initialValue: userData.id })(<Input type={'hidden'} />)}</Form.Item>
                        )}
                        <Form.Item className={'hide'}>{getFieldDecorator('reserver.username', { initialValue: userData.userName })(<Input type={'hidden'} />)}</Form.Item>
                      </Card>
                      <Card className="margin-bottom" title={'Guests information'}>
                        <div className={RoomRender.length > 1 ? 'multiple-room' : ' '}>
                          {RoomRender.map((item, index) => {
                            return (
                              <div className="RoomItem" key={index}>
                                <h5 className="normal-title">
                                  <span className="inline-block en-font" dir="ltr">
                                    {' '}
                                    Room {index + 1} ({selectedRooms.accommodation.rooms[index].name}){' '}
                                  </span>
                                </h5>
                                {
                                  <div>
                                    <div>
                                      <div className="margin-bottom">Adults passengers information</div>
                                      {item.adults.map((item, indexIN) => {
                                        passengerCounter++;
                                        return (
                                          <div key={indexIN}>
                                            <div className="inline-radio">
                                              <Form.Item>
                                                {getFieldDecorator(`passengers[${passengerCounter}].gender`, {
                                                  initialValue: true,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "Please select your passenger's gender",
                                                    },
                                                  ],
                                                })(
                                                  <Radio.Group onChange={passengerCounter === 0 ? this.handleFirstGuestInfoChanged : null}>
                                                    <Radio value={true}>Mr</Radio>
                                                    <Radio value={false}>Ms</Radio>
                                                  </Radio.Group>
                                                )}
                                              </Form.Item>
                                            </div>

                                            <Row gutter={15}>
                                              <Col xs={24} sm={12} md={8}>
                                                <div className="form-group relative required">
                                                  <label>First name</label>
                                                  <Form.Item>
                                                    {getFieldDecorator(`passengers[${passengerCounter}].firstName`, {
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "Please input your passenger's first name",
                                                        },
                                                        {
                                                          pattern: new RegExp(/^[a-zA-Z ]*$/),
                                                          message: `Please enter first name using letters only`,
                                                        },
                                                      ],
                                                    })(<Input placeholder={'first name'} onInput={passengerCounter === 0 ? this.handleFirstGuestInfoChanged : null} />)}
                                                  </Form.Item>
                                                  {/*<label className="form-label">Fornavn</label>*/}
                                                  {/*<input className="form-control" data-validator={true} placeholder="Fornavn" name={`passengers[${passengerCounter}].firstName`} type="text" />*/}
                                                </div>
                                              </Col>
                                              <Col xs={24} sm={12} md={8}>
                                                <div className="form-group relative required">
                                                  <label>Last name</label>
                                                  <Form.Item>
                                                    {getFieldDecorator(`passengers[${passengerCounter}].lastName`, {
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "Please input your passenger's last name",
                                                        },
                                                        {
                                                          pattern: new RegExp(/^[a-zA-Z ]*$/),
                                                          message: `Please enter last name using letters only`,
                                                        },
                                                      ],
                                                    })(<Input placeholder={'last name'} onInput={passengerCounter === 0 ? this.handleFirstGuestInfoChanged : null} />)}
                                                  </Form.Item>
                                                  {/*<label className="form-label">Etternavn</label>*/}
                                                  {/*<input className="form-control" data-validator={true} placeholder="Etternavn" name={`passengers[${passengerCounter}].lastName`} type="text" />*/}
                                                </div>
                                              </Col>
                                              <Col xs={24} sm={12} md={8} className="clear-left-sm">
                                                <div className="form-group relative required">
                                                  <label>Nationality</label>
                                                  <Form.Item>
                                                    {getFieldDecorator(`passengers[${passengerCounter}].nationalityCode`, {
                                                      initialValue: this.state.defaultCountry.toUpperCase(),
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "Please input your passenger's nationality",
                                                        },
                                                      ],
                                                    })(
                                                      <select>
                                                        <option value={undefined} />
                                                        {CountryCode.map((item) => (
                                                          <option key={item.code} value={item.value}>
                                                            {item.code}
                                                          </option>
                                                        ))}
                                                      </select>
                                                    )}
                                                  </Form.Item>
                                                </div>
                                              </Col>
                                            </Row>

                                            <Form.Item className={'hide'}>
                                              {getFieldDecorator(`passengers[${passengerCounter}].roomNumber`, { initialValue: index + 1 })(<Input type={'hidden'} />)}
                                            </Form.Item>
                                            <Form.Item className={'hide'}>
                                              {getFieldDecorator(`passengers[${passengerCounter}].ageCategory`, { initialValue: 'Adult' })(<Input type={'hidden'} />)}
                                            </Form.Item>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <div>
                                      {item.children.length > 0 && <div className="margin-bottom">Children passengers information</div>}
                                      {item.children.map((item, indexIN) => {
                                        passengerCounter++;
                                        return (
                                          <div key={indexIN}>
                                            <div className="inline-radio">
                                              <Form.Item>
                                                {getFieldDecorator(`passengers[${passengerCounter}].gender`, {
                                                  initialValue: true,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "Please select your passenger's gender",
                                                    },
                                                  ],
                                                })(
                                                  <Radio.Group>
                                                    <Radio value={true}>Mr</Radio>
                                                    <Radio value={false}>Ms</Radio>
                                                  </Radio.Group>
                                                )}
                                              </Form.Item>
                                            </div>
                                            <Row gutter={15}>
                                              <Col xs={24} sm={12} md={8}>
                                                <div className="form-group relative required">
                                                  <label>First name</label>
                                                  <Form.Item>
                                                    {getFieldDecorator(`passengers[${passengerCounter}].firstName`, {
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "Please input your passenger's firstname",
                                                        },
                                                        {
                                                          pattern: new RegExp(/^[a-zA-Z ]*$/),
                                                          message: `Please enter first name using letters only`,
                                                        },
                                                      ],
                                                    })(<Input placeholder={'first name'} />)}
                                                  </Form.Item>
                                                </div>
                                              </Col>
                                              <Col xs={24} sm={12} md={8}>
                                                <div className="form-group relative required">
                                                  <label>Last name</label>
                                                  <Form.Item>
                                                    {getFieldDecorator(`passengers[${passengerCounter}].lastName`, {
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "Please input your passenger's lastName",
                                                        },
                                                        {
                                                          pattern: new RegExp(/^[a-zA-Z ]*$/),
                                                          message: `Please enter last name using letters only`,
                                                        },
                                                      ],
                                                    })(<Input placeholder={'last name'} />)}
                                                  </Form.Item>
                                                </div>
                                              </Col>
                                              <Col xs={24} sm={12} md={8} className="clear-left-sm">
                                                <div className="form-group relative required">
                                                  <label>Nationality</label>
                                                  <Form.Item>
                                                    {getFieldDecorator(`passengers[${passengerCounter}].nationalityCode`, {
                                                      initialValue: this.state.defaultCountry.toUpperCase(),
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message: "Please input your passenger's nationality",
                                                        },
                                                      ],
                                                    })(
                                                      <select className="itours-select">
                                                        <option value={null} />
                                                        {CountryCode.map((item) => (
                                                          <option key={item.code} value={item.value}>
                                                            {item.code}
                                                          </option>
                                                        ))}
                                                      </select>
                                                    )}
                                                  </Form.Item>
                                                </div>
                                              </Col>
                                            </Row>
                                            <Form.Item className={'hide'}>
                                              {getFieldDecorator(`passengers[${passengerCounter}].roomNumber`, { initialValue: index + 1 })(<Input type={'hidden'} />)}
                                            </Form.Item>
                                            <Form.Item className={'hide'}>
                                              {getFieldDecorator(`passengers[${passengerCounter}].ageCategory`, { initialValue: 'Child' })(<Input type={'hidden'} />)}
                                            </Form.Item>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <Form.Item className={'hide'}>
                                      {getFieldDecorator(`preReserveKey`, { initialValue: selectedRooms.preReserveKey })(<input type="hidden" />)}
                                    </Form.Item>
                                    {/*<input type="hidden" name="preReserveKey" value={selectedRooms.preReserveKey} />*/}
                                  </div>
                                }
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                      <Card title="Description" className="margin-bottom">
                        <p>
                          Enter your requests here and we will share them with the hotel.
                          <br />
                          Special requests are not guaranteed, but the hotel will do everything to meet your needs.
                        </p>
                        <Form.Item>{getFieldDecorator(`specialRequest`)(<Input.TextArea />)}</Form.Item>
                      </Card>
                      {selectedRooms.accommodation.rooms[0].remarks && selectedRooms.accommodation.rooms[0].remarks.indexOf('<script') === -1 ? (
                        <Card title="Remarks" className="justified">
                          <div dangerouslySetInnerHTML={{ __html: selectedRooms.accommodation.rooms[0].remarks }}></div>
                        </Card>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={24} lg={8} xl={6}>
                    <Card title="Booking information" className="light-padding">
                      <div className="hotel-img-titles">
                        {selectedRooms.accommodation.mainPhoto == null ? (
                          <div className="default-hotel-image hotel-image" style={{ backgroundImage: 'url(' + HotelNoImage + ')' }} />
                        ) : (
                          <div className="hotel-image main-hotel-image" style={{ backgroundImage: `url(${selectedRooms.accommodation.mainPhoto})` }} />
                        )}
                        <div className="hotel-name-star full-width">
                          <h5 className="normal-title">{selectedRooms.accommodation.name}</h5>
                          <div className="hotelRange">{selectedRooms.accommodation.rating > 0 ? <Rate number={selectedRooms.accommodation.rating} /> : null}</div>
                          <div className="addressFeild">
                            <Icon type="environment" /> {selectedRooms.accommodation.address}
                          </div>
                        </div>
                      </div>
                      <div className="pair-row">
                        <span>Check-in</span>
                        <Tooltip placement="top" title="Property local time">
                          <b>{selectedRooms.date.checkIn.split('T')[0]}</b> <Icon type="info-circle" />
                        </Tooltip>
                      </div>
                      <div className="pair-row">
                        <span>Check-out</span>
                        <Tooltip placement="top" title="Property local time">
                          <b>{selectedRooms.date.checkOut.split('T')[0]}</b> <Icon type="info-circle" />
                        </Tooltip>
                      </div>
                      <div className="pair-row">
                        <span>Length of stay</span>
                        <b>{night} night(s)</b>
                      </div>
                      <div>
                        {selectedRooms.accommodation.rooms.map((item, index) => {
                          return (
                            <div className="clearfix border-top" key={index}>
                              <div className="room-type pull-left">
                                <span className="margin-right">
                                  <span className="blue">{item.name}</span>
                                </span>
                              </div>
                              <div className="clearfix">
                                <ul className="bullet-list">
                                  <li>{item.board.name}</li>
                                </ul>
                              </div>
                              <div className="clearfix">
                                (<span className="inline-block">{item.adults} Adults</span>
                                {item.children > 0 ? <span className="inline-block">, {item.children} Children </span> : null})
                              </div>
                            </div>
                          );
                        })}
                        {selectedRooms.cancellationPolicy ? (
                          <div className="border-top">
                            <Tag color={selectedRooms.cancellationPolicy.status === 'Refundable' ? 'green' : 'red'}>
                              <span className="font-14">{getCancellationStatusName(selectedRooms.cancellationPolicy.status)}</span>
                            </Tag>
                            <ul className={'cancelation-rules-list'}>
                              {selectedRooms.cancellationPolicy.fees.map((fee) => {
                                if (fee.amount === 0) {
                                  return (
                                    <li key={selectedRooms.cancellationPolicy.fees.indexOf(fee)}>
                                      Cancellation is <span className="green">FREE</span> before{' '}
                                      <Tooltip className="no-wrap" placement="top" title={moment(fee.toDate).format('MM/DD/YYYY')}>
                                        {moment(fee.toDate).format('DD MMM YYYY')}
                                      </Tooltip>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li key={selectedRooms.cancellationPolicy.fees.indexOf(fee)}>
                                      <Price currency={this.props.currencyCode}>{fee.amount}</Price> on and after date{' '}
                                      <Tooltip className="no-wrap" placement="top" title={moment(fee.fromDate).format('MM/DD/YYYY')}>
                                        {moment(fee.fromDate).format('DD MMM YYYY')}
                                      </Tooltip>
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        ) : null}
                        <div className="border-top">
                          <div className="pair-row green font-18">
                            <b>Total price</b>
                            <b>
                              <span className="margin-right-light">
                                {' '}
                                <Price currency={this.props.currencyCode}>{selectedRooms.salePrice}</Price>
                              </span>
                              <HotelAgentGainPopover introPrice={selectedRooms.introPrice} regularPrice={selectedRooms.regularPrice} salePrice={selectedRooms.salePrice} />
                            </b>
                          </div>
                          <div className="confirmButton">
                            <Form.Item>
                              <Button block size={'large'} type="danger" htmlType="submit">
                                Continue to payment
                              </Button>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              )
            )}
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedRooms: state.Hotel.selectedRooms,
  userData: state.User,
  payment: state.Payment,
  currencyCode: state.User.currency,
  ipLocation: state.Shared.ipLocation,
});
const mapDispatchToProps = (dispatch) => ({
  getPassengerInformation: (params) => {
    dispatch(getPassengerInforamtion(params));
  },
  reserve: (params) => {
    dispatch(reserve(params));
  },
  setNotification: (params) => {
    dispatch(setNotification(params));
  },
});
const WrappedPassengerForm = Form.create({ name: 'passenger_form' })(HotelSetPassenger);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedPassengerForm);
