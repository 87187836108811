import '../../CSS/Autocomplete.scss';

import { Icon, Input } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';

import hotel from '../../Images/autocomplete/hotel.svg';
import location from '../../Images/autocomplete/location.svg';
import subset from '../../Images/autocomplete/subset.png';

export default class Autocomplete extends React.Component {
  state = {
    selectedValue: this.props.defaultValue ? this.props.defaultValue : null,
    selectedText: this.props.defaultText ? this.props.defaultText : null,
    showlist: false,
    result: null,
    typingValue: '',
    loading: false,
  };
  findParent(list, CityIdLocation) {
    let status = list.filter((item) => item.CityIdLocation === CityIdLocation && item.Type === 1);
    return status && status.length > 0;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({
        selectedValue: this.props.defaultValue ? this.props.defaultValue : null,
        selectedText: this.props.defaultText ? this.props.defaultText : null,
      });
    }
  }

  static propTypes = {
    url: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    delay: PropTypes.number,
    name: PropTypes.string,
    header: PropTypes.object.isRequired,
    param: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    validate: PropTypes.bool,
    type: PropTypes.any,
  };
  timeout;

  fetchData(e) {
    if (e.target.value) {
      let delay = 0;
      if (this.props.delay) {
        delay = this.props.delay;
      }
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      e.persist();
      this.setState(
        {
          selectedValue: null,
          selectedText: null,
        },
        () => {
          if (this.props.type === 'flight') {
            this.props.getValue(this.state.selectedValue, this.state.selectedText, this.props.name, this.props.index);
          }
        }
      );
      this.timeout = setTimeout(
        function () {
          if (e.target.value.length >= this.props.min) {
            this.setState({ loading: true });
            axios({
              method: 'get',
              url: `${this.props.url}?${this.props.param}=${e.target.value}`,
              headers: { ...this.props.header },
            })
              .then((responsive) => {
                this.setState({ loading: false });
                let result = responsive.data.result;
                if (result.length !== 0) {
                  //pre render
                  result.forEach((item) => {
                    if (this.findParent(result, item.CityIdLocation) && item.Type === 2) {
                      item['isChild'] = true;
                    } else {
                      item['isChild'] = false;
                    }
                  });
                  //
                  this.setState({
                    showlist: true,
                    result,
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            this.setState({
              loading: false,
              showlist: false,
              result: null,
            });
          }
        }.bind(this),
        delay
      );
      this.setState({ typingValue: e.target.value });
    } else {
      this.setState(
        {
          selectedValue: null,
          selectedText: null,
          typingValue: null,
        },
        () => {
          if (this.props.type === 'flight') {
            this.props.getValue('', '', this.props.name, this.props.index);
          } else {
            this.props.getValue({ selectedValue: '', selectedText: '', type: this.state.selectedtype });
          }
        }
      );
    }
  }

  selectItem(item) {
    if (this.props.type === 'flight') {
      this.setState(
        {
          selectedValue: item.Code,
          selectedText: item.Name,
          selectedtype: item.Type,
          showlist: false,
          result: null,
        },
        () => {
          this.props.getValue(this.state.selectedValue, this.state.selectedText, this.props.name, this.props.index);
        }
      );
    } else {
      this.setState(
        {
          selectedValue: item.id,
          selectedText: item.name,
          selectedtype: item.typeId,
          showlist: false,
          result: null,
        },
        () => {
          this.props.getValue({ selectedValue: this.state.selectedValue, selectedText: this.state.selectedText, type: this.state.selectedtype });
        }
      );
    }
  }

  render() {
    return (
      <div className={this.props.validate ? 'autocompleteFeild' : 'autocompleteFeild has-error'}>
        <div className="autocompleteText">
          <div className={'autocompleteBox'}>
            <Input
              onFocus={(e) => e.target.select()}
              allowClear={true}
              className="ant-input"
              placeholder={this.props.placeholder}
              type="text"
              autoComplete="disabled"
              onChange={this.fetchData.bind(this)}
              value={this.state.selectedText ? this.state.selectedText : this.state.typingValue}
            />
            {this.state.loading && <Icon className={'icon'} type="loading" />}
          </div>
          {this.state.showlist && (
            <div className="suggestList">
              {this.props.type === 'flight' ? (
                <ul className={'autocomplete-list'}>
                  {this.state.result.map((item, index) => {
                    return (
                      <li key={index}>
                        <div className={item.isChild ? 'list-link subset' : 'list-link'} onClick={this.selectItem.bind(this, item)}>
                          {item.isChild ? <img className={'subset-icon'} src={subset} alt="subset" /> : <img src={location} alt="airport" />}
                          <div className="name">{item.Name}</div>
                          <div className="location">{item.ParentName}</div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className={'autocomplete-list'}>
                  {this.state.result.map((item, index) => {
                    return (
                      <li key={index}>
                        {item.children == null ? (
                          <div className="list-link" onClick={this.selectItem.bind(this, item)}>
                            {item.typeId === 3 ? <img src={location} alt="city" /> : <img className={'hotel-icon'} src={hotel} alt="hotel" />}
                            <div>
                              <div className="name">{item.name}</div>
                              <div className="location">{item.parentName}</div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="list-link" onClick={this.selectItem.bind(this, item)}>
                              {item.typeId === 3 ? <img src={location} alt="city" /> : <img className={'hotel-icon'} src={hotel} alt="hotel" />}
                              <div>
                                <span className="name">{item.name}</span>
                                <div className="location">{item.parentName}</div>
                              </div>
                            </div>
                            {item.children.map((itemIN, indexIN) => (
                              <div className={'list-link subset'} onClick={this.selectItem.bind(this, itemIN)} key={indexIN}>
                                <img className={'subset-icon'} src={subset} alt="subset" />
                                {itemIN.name}
                              </div>
                            ))}
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}
          {this.props.validate ? null : (
            <div>
              <div className="ant-form-explain">{this.props.type === 'flight' ? 'Please enter location' : "Please enter your location or hotel's name"}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
