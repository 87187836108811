import '../../../../CSS/Reserves.scss';

import React from 'react';
import { InView } from 'react-intersection-observer';

import InfoCardInner from './InfoCardInner';

const InfoCard = (props) => (
  <div className="infoCard-container">
    <InView triggerOnce={true}>{({ inView, ref, entry }) => <div ref={ref}>{inView && <InfoCardInner isTicket={props.isTicket} user={props.user} />}</div>}</InView>
  </div>
);

export default InfoCard;
