import React from 'react';

import ShowMap from '../../commond/ShowMap';

function TestC(props) {
  return (
    <>
      <ShowMap />
    </>
  );
}

export default TestC;
