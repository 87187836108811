import { call, put, takeLatest } from 'redux-saga/effects';

import { setIpLocation } from '../Actions/SharedActions';
import { getIpLocationHttp } from '../APIs/Misc';
import { SharedActions } from '../Enums/ActionTypes';

function* getIpLocation() {
  const response = yield call(getIpLocationHttp);
  if (response && !response.isAxiosError) {
    yield put(setIpLocation(response));
  }
}

export function* watchIpLocation() {
  yield takeLatest(SharedActions.GetIpLocation, getIpLocation);
}
