import { Col, Empty, Row, Skeleton, Table, Tabs } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getDeposits } from '../../../Actions/PaymentAction';
import { getReserves } from '../../../Actions/ReservesAction';
import ReserveApi from '../../../APIs/Reserves';
import { getStorage } from '../../../utils/localstorage';
import Price from '../../commond/Price';
import DomesticSearchForm from './Flight/DomesticSearchForm';
import FlightTicketing from './Flight/FlightTicketing';
import FlightSearchFrom from './Flight/SearchFrom';
import HotelSearchFrom from './Hotel/HotelFormSearch';
import HistorySearch from './Layout/HistorySearch';

class Home extends React.Component {
  state = {
    listLoading: true,
    pendingReserves: [],
    finalizedReserves: [],
  };
  async componentDidMount() {
    let param1 = {
      createFrom: null,
      createTo: null,
      departureFrom: null,
      departureTo: null,
      email: null,
      lastname: null,
      pageNumber: '1',
      pageSize: '1000',
      phoneNumber: null,
      reference: null,
      reserveId: null,
      reserveStatus: 'PENDING',
      reserveTypeIds: null,
    };
    let param2 = {
      createFrom: null,
      createTo: null,
      departureFrom: null,
      departureTo: null,
      email: null,
      lastname: null,
      pageNumber: '1',
      pageSize: '1000',
      phoneNumber: null,
      reference: null,
      reserveId: null,
      reserveStatus: 'ISSUED',
      reserveTypeIds: null,
    };
    const pendingList = await ReserveApi.getReserves(param1);
    const finalizedList = await ReserveApi.getReserves(param2);
    this.setState({
      pendingReserves: pendingList && pendingList.reserves,
      finalizedReserves: finalizedList && finalizedList.reserves,
      listLoading: false,
    });
  }
  render() {
    const { TabPane } = Tabs;
    const tableLoadingData = [
      { fakeRecord1: 'data1', key: 1 },
      { fakeRecord1: 'data2', key: 2 },
      { fakeRecord1: 'data3', key: 3 },
      { fakeRecord1: 'data4', key: 4 },
    ];
    const tableLoadingHead = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Reserver',
        dataIndex: 'reserver',
        key: 'reserver',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Reserve Number',
        dataIndex: 'reserveNumber',
        key: 'reserveNumber',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
      {
        title: 'Contact',
        key: 'contact',
        dataIndex: 'contact',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '200px' }} />,
      },
      {
        title: 'Payment',
        key: 'payment',
        dataIndex: 'payment',
        render: (text, record) => <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />,
      },
    ];
    const waitingPaymentsLabels = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) =>
          record.type === 'FLIGHT_GLOBAL' ? (
            <span className={'infocard-item-icon flight-icon itours-icon'} />
          ) : record.type === 'HOTEL_GLOBAL' ? (
            <span className={'infocard-item-icon hotel-orange-icon itours-icon'} />
          ) : null,
      },
      {
        title: 'Reserver',
        dataIndex: 'reserver',
        key: 'reserver',
        render: (text, record) => (
          <strong>
            {record.type === 'FLIGHT_GLOBAL' ? (
              <Link to={`tracking/reserves/flight?id=${record.reserveNumber}`}>
                {record.firstName} {record.lastName}
              </Link>
            ) : record.type === 'HOTEL_GLOBAL' ? (
              <Link to={`tracking/reserves/hotel?id=${record.reserveNumber}`}>
                {record.firstName} {record.lastName}
              </Link>
            ) : (
              <>
                {record.firstName} {record.lastName}
              </>
            )}
          </strong>
        ),
      },
      {
        title: 'Reserve Number',
        dataIndex: 'reserveNumber',
        key: 'reserveNumber',
        render: (text, record) => (
          <>
            <div>{record.reserveNumber}</div>
            {record.reference ? <div>({record.reference})</div> : null}
          </>
        ),
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: (text, record) => <Price currency={record.saleCurrency.code}>{record.salePrice}</Price>,
      },
      {
        title: 'Contact',
        key: 'contact',
        dataIndex: 'contact',
        render: (text, record) => (
          <>
            <div>{record.phoneNumber}</div>
            <div>{record.email}</div>
          </>
        ),
      },
      {
        title: 'Payment',
        key: 'payment',
        dataIndex: 'payment',
        render: (text, record) =>
          record.paymentStatus === 'PENDING' &&
          record.type === 'HOTEL_GLOBAL' && (
            <Link className="button green-btn small-btn" to={`/payment/choice?reserveId=${record.reserveNumber}&username=${record.username}`}>
              Payment
            </Link>
          ),
        // % what if type was flight?
      },
    ];
    const finalizedBookingsLabels = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'finalizedType',
        render: (text, record) =>
          record.type === 'FLIGHT_GLOBAL' ? (
            <span className={'infocard-item-icon flight-icon itours-icon'} />
          ) : record.type === 'HOTEL_GLOBAL' ? (
            <span className={'infocard-item-icon hotel-orange-icon itours-icon'} />
          ) : null,
      },
      {
        title: 'Reserver',
        dataIndex: 'reserver',
        key: 'finalizedReserver',
        render: (text, record) => (
          <strong>
            <a href={record.detailUrl}>
              {record.firstName} {record.lastName}
            </a>
          </strong>
        ),
      },
      {
        title: (
          <div>
            <div>Reserve Number</div>
            <div>(PNR code)</div>
          </div>
        ),
        dataIndex: 'reserveNumber',
        key: 'finalizedReserveNumber',
        render: (text, record) => (
          <div>
            <div>{record.reserveNumber}</div>
            {record.reference ? <div>({record.reference})</div> : null}
          </div>
        ),
      },
      {
        title: 'Booking Date',
        dataIndex: 'createDate',
        key: 'finalizedCreateDate',
        render: (text, record) => (
          <>
            <div className="no-wrap">{moment(record.finalizedCreateDate).format('YYYY-MM-DD')}</div>
            <div>{moment(record.finalizedCreateDate).format('hh:mm')}</div>
          </>
        ),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'finalizedPrice',
        render: (text, record) => <Price currency={record.saleCurrency.code}>{record.salePrice}</Price>,
      },
      {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'finalizedContact',
        render: (text, record) => (
          <>
            <div>{record.phoneNumber}</div>
            <div>{record.email}</div>
          </>
        ),
      },
    ];

    const reservesListLink = <Link to={'/tracking/reserves'}>See All Bookings</Link>;

    return (
      <div className="container vr-padding">
        <Row gutter={15}>
          <Col xs={24} lg={10}>
            <div className="card-container margin-bottom home-search-wrapper">
              <Tabs type="card">
                <TabPane tab="Flight" key="1">
                  <FlightSearchFrom home="true" />
                </TabPane>
                {this.props.user.currency === 'IRR' && (
                  <TabPane tab="Domestic Flight" key="2">
                    <DomesticSearchForm home="true" />
                  </TabPane>
                )}
                <TabPane tab="Hotel" key="3">
                  <HotelSearchFrom home="true" />
                </TabPane>
                <TabPane tab="Ethnic" key="4">
                  <FlightSearchFrom home="true" isEthnic={true} />
                </TabPane>
                <TabPane tab="Ticketing" key="5">
                  <FlightTicketing home={true} />
                </TabPane>
              </Tabs>
            </div>
            <HistorySearch flightHistory={getStorage('ag_flight_history')} hotelHistory={getStorage('ag_hotel_history')} />
          </Col>
          <Col xs={24} lg={14} className="home-right-section">
            <div className="vr-padding margin-bottom-light">
              <strong className="font-18">
                <span> Welcome, </span>
                {this.props.user.firstName} {this.props.user.lastName}!
              </strong>
            </div>
            <Tabs type="card" className="margin-bottom" tabBarExtraContent={reservesListLink}>
              <TabPane tab="Waiting Payments" key="waiting">
                {this.state.listLoading ? (
                  <Table columns={tableLoadingHead} dataSource={tableLoadingData} />
                ) : this.state.pendingReserves && this.state.pendingReserves.length > 0 ? (
                  <Table columns={waitingPaymentsLabels} rowKey="reserveNumber" dataSource={this.state.pendingReserves} />
                ) : (
                  <Empty description="There are no waiting payments" />
                )}
              </TabPane>
              <TabPane tab="Finalized Bookings" key="finalized">
                {this.state.listLoading ? (
                  <Table columns={tableLoadingHead} dataSource={tableLoadingData} />
                ) : this.state.finalizedReserves && this.state.finalizedReserves.length > 0 ? (
                  <Table columns={finalizedBookingsLabels} rowKey="reserveNumber" dataSource={this.state.finalizedReserves} />
                ) : (
                  <Empty description="There are no finalized bookings" />
                )}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getDeposits: () => dispatch(getDeposits()),
  getReserves: (param) => {
    dispatch(getReserves(param));
  },
});
const mapStateToProps = (state) => ({
  depositList: state.Payment.depositList,
  user: state.User,
  Reserve: state.Reserve,
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
