import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

//import reducers
import { User } from './AccountReducer';
import { Flight } from './FlightReducer';
import { Hotel } from './HotelReducer';
import { Notification } from './NotificationReducer';
import { Payment } from './PaymentReducer';
import { Reserve } from './ReservesReducer';
import { Shared } from './SharedReducer';

//combination

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    User,
    Notification,
    Hotel,
    Payment,
    Flight,
    Reserve,
    Shared,
  });

export default createRootReducer;
