import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const DatePickerDrop = (props) => {
  //const [value,setValue]=useState(moment(props.min,props.format));
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);
  const [year, setYear] = useState(moment(props.min, props.format).get('year'));
  const [month, setMonth] = useState(moment(props.min, props.format).get('month') + 1);
  const [day, setDay] = useState(moment(props.min, props.format).get('date'));
  useEffect(() => {
    generateArr('year');
  }, [true]);
  useEffect(() => {
    generateArr('month');
  }, [year]);
  useEffect(() => {
    generateArr('day');
    let value = moment(`${year}-${month}-${day}`, props.format);
    props.change(value);
  }, [month, months]);
  useEffect(() => {
    let value = moment(`${year}-${month}-${day}`, props.format);
    props.change(value);
  }, [day]);

  const generateArr = (type) => {
    let arr = [];
    let min = null;
    let max = null;
    let yearSelected = parseInt(year);
    let monthSelected = parseInt(month);
    // let daySelected = parseInt(day);
    let minDay = moment(props.min, props.format).get('date');
    let minMonth = moment(props.min, props.format).get('month') + 1;
    let minYear = moment(props.min, props.format).get('year');
    let maxDay = moment(props.max, props.format).get('date');
    let maxMonth = moment(props.max, props.format).get('month') + 1;
    let maxYear = moment(props.max, props.format).get('year');
    if (type === 'year') {
      min = minYear;
      max = maxYear;
    } else if (type === 'month') {
      min = yearSelected === minYear ? minMonth : 1;
      max = yearSelected === maxYear ? maxMonth : 12;
    } else if (type === 'day') {
      if (yearSelected === minYear && monthSelected === minMonth) {
        min = minDay;
      } else {
        min = 1;
      }
      if (yearSelected === maxYear && monthSelected === maxMonth) {
        max = maxDay;
      } else {
        let arr31 = [1, 3, 5, 7, 8, 10, 12];
        let arr30 = [4, 6, 9, 11];
        if (arr31.indexOf(monthSelected) !== -1) {
          max = 31;
        } else if (arr30.indexOf(monthSelected) !== -1) {
          max = 30;
        } else {
          if (yearSelected % 4 === 0) {
            max = 29;
          } else {
            max = 28;
          }
        }
      }
    }
    for (let cnt = min; cnt <= max; cnt++) {
      arr.push(cnt);
    }
    if (type === 'year') {
      setYears(arr);
    } else if (type === 'month') {
      setMonths(arr);
      if (!(month >= arr[0] && month <= arr[arr.length - 1])) {
        setMonth(arr[0]);
      }
    } else {
      setDays(arr);
      if (!(day >= arr[0] && day <= arr[arr.length - 1])) {
        setDay(arr[0]);
      }
    }
  };
  return (
    <div className={'date-picker-drop'}>
      <Row gutter={10}>
        <Col xs={8}>
          <select value={year} onChange={(e) => setYear(e.target.value)}>
            {years.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={8}>
          <select value={month} onChange={(e) => setMonth(e.target.value)}>
            {months.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={8}>
          <select value={day} onChange={(e) => setDay(e.target.value)}>
            {days.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      {/*<input type={'hidden'} name={props.name} value={(value)?value:""}/>*/}
    </div>
  );
};
export default DatePickerDrop;
