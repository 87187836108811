import Axios from 'axios';

import { Account, Urls, getHeaderRequest } from '../Enums/APIAddress';

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = `${Urls.Type}${Urls.User}`;
axiosInstance.interceptors.request.use((config) => {
  config.headers = getHeaderRequest(true);
  return config;
});

export const getPermissionsHttp = async () => {
  try {
    const response = await axiosInstance.get(Account.GetPermissions2);
    return response.data.result;
  } catch (error) {
    return error;
  }
};
