import { default as AccountAPI } from '../APIs/Account';

export let Urls = {
  Type: process.env.REACT_APP_ApiProtocolType + '://',
  User: process.env.REACT_APP_AuthApiAddress,
  HotelV2: process.env.REACT_APP_HotelV2ApiAddress,
  Payment: process.env.REACT_APP_PaymentApiAddress,
  Flight: process.env.REACT_APP_FlightApiAddress,
  DomesticFlight: process.env.REACT_APP_DomesticFlightApiAddress,
  Local: { Url: process.env.REACT_APP_HostName },
  FlightTicket: process.env.REACT_APP_FlightTicketApiAddress,
  HotelTicket: process.env.REACT_APP_HotelTicketApiAddress,
  GraphQL: process.env.REACT_APP_GraphQLApiAddress,
  IpLocation: process.env.REACT_APP_IpLocationApiAddress,
  Coordinator: process.env.REACT_APP_Coordinator,
};

///username:peyman@itours.no
///password:1366
//Account API URL service
export const Account = {
  Login: '/api/TokenAuth/Login', //for login user
  GetUser: '/api/services/app/Account/GetUser', //for getting user information
  ForgotPassword: '/api/services/app/Account/ForgotPassword', //send recover email to API
  Confirm: '/api/services/app/Account/ConfirmEmail', //get varification code from API
  ResetPassword: '/api/services/app/Account/ResetPasswordByCode', //get code for reset password
  Update: '/api/services/app/Account/Update', //update user
  ChangePassword: '/api/services/app/Account/ChangePassword', //change password
  GetTenant: '/api/services/app/Tenant/GetByKeyword', //getTenant,
  GetAll: '/api/services/app/User/GetAll',
  ResetPasswordUser: '/api/services/app/User/ResetPassword',
  GetUsers: '/api/services/app/User/Get',
  UpdateUsers: '/api/services/app/User/Update',
  DeleteUsers: '/api/services/app/User/Delete',
  CreateUsers: '/api/services/app/User/Create',
  GetPermissions: '/api/services/app/User/GetUserPermissionsForEdit',
  UpdatePermissions: '/api/services/app/User/UpdateUserPermissions',
  SendVerificationSms: '/api/services/app/Profile/SendVerificationSms',
  CheckVerification: '/api/services/app/Profile/VerifySmsCode',
  CheckPhoneVerificationStatus: '/api/services/app/Profile/GetCurrentUserProfileForEdit',
  GetPermissions2: '/api/services/app/Profile/GetPermissions',
};
//Flight API URL Service
export const Flight = {
  GetLocation: '/api/services/app/BookingFlight/GetAirport',
  GetAirline: '/api/services/app/BookingFlight/GetAirline',
  GetFlights: '/api/services/app/BookingFlight/LowFareSearch',
  ValidateFlight: '/api/services/app/BookingFlight/Validate',
  GetPNR: '/api/services/app/BookingFlight/ReservePNR',
  GetRules: '/api/services/app/BookingFlight/GetFlightRules',
  GetReserveByID: '/api/services/app/BookingFlight/GetFlightReserveById',
  GetDirectTicketByID: '/api/services/app/BookingFlight/GetDirectTicketById',
  GetPNRDetails: '/api/services/app/BookingFlight/GetPNRDetails',
  PricePNR: '/api/services/app/BookingFlight/PricePNR',
  IssuePNR: '/api/services/app/BookingFlight/IssuePNR',
  VoidTicket: '/api/services/app/BookingFlight/VoidTicket',
  cancelPNR: '/api/services/app/BookingFlight/CancelPNR',
  GetFlightReserveByID: '/api/services/app/BookingFlight/GetFlightReserveById',
  GetSuppliers: '/api/services/app/BookingFlight/GetFlightSuppliers',
  GetCancellationPolicies: '/api/services/app/BookingFlight/GetCancellationPolicies',
};
//payment URL service
export const Payment = {
  GetReserveInformation: '/api/services/app/Reserve/GetReserveById',
  GetBanksInformation: '/api/v1/Reserves/Gateway/GetGateways',
  MakeToken: '/api/v1/Reserves/Gateway/MakeToken',
  GatewayReport: '/api/v1/Payment/GatewayReport',
  GetDeposits: '/api/v1/Affiliate/Deposit/Balances',
  GetDepositsGateway: '/api/v1/Affiliate/Deposit/Gateway/GetGateways',
  DepositMakeToken: '/api/v1/Affiliate/Deposit/Gateway/MakeToken',
  GetDepositBalance: '/api/v1/Affiliate/Reserves/Deposit/Balance',
  ConfirmDeposit: '/api/v1/Affiliate/Reserves/Deposit/ConfirmByDeposit',
  RefundDeposit: '/api/v1/Affiliate/Reserves/Deposit/DepositRefund',
  GetReport: '/api/v1/Affiliate/Deposit/Transactions',
  GetReportExcel: '/api/services/app/TenantDepositGateway/GetTransactionsToExcel',
  DownloadExcelReport: '/File/DownloadTempFile',
  ManualReceiptClientGetAll: '/api/services/app/ManualReceiptClient/GetAll',
  CreateManualReceiptClient: '/api/services/app/ManualReceiptClient/Create',
  GetAccountNumbers: '/api/services/app/AccountNumbers/GetAll',
  Refund: '/api/services/app/RefundRequest/Refund',
};

export const HotelV2 = {
  GetLocation: '/api/services/app/BookingHotel/GetLocation',
  GetHotel: '/api/services/app/BookingHotel/GetSearch',
  GetHotelDetails: '/api/services/app/BookingHotel/GetHotelById',
  PreReserve: '/api/services/app/BookingHotel/PreReserve',
  GetPreReserveID: '/api/services/app/BookingHotel/GetPreReserveByKey',
  GetReserveByID: '/api/services/app/BookingHotel/GetReserveById',
  PostReserve: '/api/services/app/BookingHotel/Reserve',
  GetCancellationPolicy: '/api/services/app/BookingHotel/GetCancellationPolicy',
  PostBook: '/api/services/app/BookingHotel/Book',
  getPreCancel: '/api/services/app/BookingHotel/GetPreCancel',
  cancel: '/api/services/app/BookingHotel/Cancel',
};

export const IpLocationUrls = {
  GetIpLocation: '/api/services/app/LocationService/GetIpLocation',
  GetLocationByIp: 'api/services/app/LocationService/GetLocationByIP',
};

export const Coordinate = {
  GetLog: '/api/services/app/EventLogService/Get',
  GetAllLogs: '/api/services/app/EventLogService/GetAll',
  CreateLog: '/api/services/app/EventLogService/Create',
  UpdateLog: '/api/services/app/EventLogService/Update',
  DeleteLog: '/api/services/app/EventLogService/Delete',
  OrderGet: '/api/services/app/Order/Get',
  GetAffiliate: '/api/services/app/Terminal/GetAffiliate',
};

//Header Request for API
export var HeaderRequest = {
  'Content-Type': 'application/json',
  //"Accept-Language":"nb-NO",
  'currency': 'USD',
  'apikey': '93F344D3-2AAD-4C13-9A32-E8AF90AA0206',
  'TenantId': '7',
};
export const getHeaderRequest = (authMode) => {
  let tenant = AccountAPI.getTenant();
  let terminalID = AccountAPI.getTerminalIDCookie();
  if (authMode) {
    let token = AccountAPI.getToken();
    if (token) {
      return {
        'Content-Type': 'application/json',
        //"Accept-Language":"nb-NO",
        'currency': HeaderRequest['currency'],
        'apikey': terminalID,
        'TenantId': tenant,
        Authorization: `Bearer ${token}`,
      };
    } else {
      return {
        'Content-Type': 'application/json',
        //"Accept-Language":"nb-NO",
        'currency': HeaderRequest['currency'],
        'apikey': '93F344D3-2AAD-4C13-9A32-E8AF90AA0206',
        'TenantId': tenant,
      };
    }
  } else {
    return {
      'Content-Type': 'application/json',
      //"Accept-Language":"nb-NO",
      'currency': HeaderRequest['currency'],
      'apikey': terminalID,
      'TenantId': tenant,
    };
  }
};
