import { Button, Col, Form, Icon, Input, Modal, Radio, Result, Row, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import FlightApi from '../../../../APIs/Flight';
import HotelApi from '../../../../APIs/Hotel';
import PaymentApi from '../../../../APIs/Payment';
import Price from '../../../commond/Price';
import Rate from '../../../commond/Rate';

class Refund extends React.Component {
  state = {
    showModal: false,
    cancellationStep: 1,
    refundMethod: 'DepositToWallet',
    reserveStatus: null,
    cancellationInfo: null,
    hotelInfo: this.props.hotelInfo,
    flightInfo: this.props.flightInfo,
  };
  getPreCancel = async () => {
    this.setState({ cancellationStep: 0, refundMethod: 'DepositToWallet', showModal: true });
    let param = {};
    param.reserveId = this.props.reserveId;
    param.username = this.props.username;
    if (this.props.reserveType === 'flight') {
      if (!this.state.flightInfo) {
        const flightDetailsResponse = await FlightApi.getReserveID(`reserveId=${this.props.reserveId}&username=${this.props.username}`);
        this.setState({ flightInfo: flightDetailsResponse });
      }
      const response = await FlightApi.getCancellationPolicy(param);
      this.setState({ cancellationStep: 1, cancellationInfo: response });
    } else if (this.props.reserveType === 'hotel') {
      if (!this.state.hotelInfo) {
        const hotelDetailsResponse = await HotelApi.getHotelDetails(`reserveId=${this.props.reserveId}`);
        this.setState({ hotelInfo: hotelDetailsResponse });
      }
      const response = await HotelApi.getPreCancel(param);
      this.setState({ cancellationStep: 1, cancellationInfo: response });
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      } else {
        this.setState({ cancellationStep: 4 });
        let param = { type: 'Affiliate', method: this.state.refundMethod, reserveId: this.props.reserveId, username: this.props.username, ...values };

        this.sendFormData(param);
      }
    });
  };
  async sendFormData(param) {
    const formResponse = await PaymentApi.Refund(param);
    if (formResponse.data && formResponse.data.success) {
      this.setState({ cancellationStep: 5 });
    } else {
      this.setState({ cancellationStep: 6 });
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <>
        <Tooltip
          title={
            this.props.reserveType === 'flight' ? 'This method will void and cancel the pnr and refund the amount.' : 'This method will cancel the Voucher and refund the amount.'
          }>
          <Button block={!this.props.inlineButton} className={this.props.inlineButton ? 'button refund-btn' : 'margin-top large-button'} type="danger" onClick={this.getPreCancel}>
            {this.props.reserveType === 'flight' ? 'Refund' : 'Cancel this voucher'}
          </Button>
        </Tooltip>

        <Modal
          title={this.props.reserveType === 'flight' ? 'Refund' : 'Cancel and Refund'}
          className="refund-modal"
          visible={this.state.showModal}
          closable={false}
          footer={null}
          width={600}>
          {/* <Form onSubmit={(e) => this.submitForm(e)} > */}
          <Form onSubmit={(e) => this.submitForm(e)} className="cancellation-form">
            <div className="modal-paddidng">
              {this.state.cancellationStep === 1 ? (
                <>
                  {this.state.flightInfo &&
                    this.state.flightInfo.airItinerary.originDestinationOptions.map((item, index) => {
                      return (
                        <div key={index} className="flight-route-item">
                          <div>
                            <img src={item.flightSegments[0].marketingAirline.photoUrl} alt={item.flightSegments[0].marketingAirline.name} className="airline-logo" />
                          </div>
                          <div className="text-center">
                            <b>{item.flightSegments[0].departureAirport.cityName}</b>
                            <div>
                              <span className="margin-right-light">{moment(item.flightSegments[0].departureDateTime).format('YYYY-MM-DD')}</span>
                              <span>{moment(item.flightSegments[0].departureDateTime).format('HH:mm')}</span>
                            </div>
                          </div>
                          <Icon type="arrow-right" className="margin-left margin-right" />
                          <div className="text-center">
                            <b>{item.flightSegments[item.flightSegments.length - 1].arrivalAirport.cityName}</b>
                            <div>
                              <span className="margin-right-light">{moment(item.flightSegments[item.flightSegments.length - 1].departureDateTime).format('YYYY-MM-DD')}</span>
                              <span>{moment(item.flightSegments[item.flightSegments.length - 1].arrivalDateTime).format('HH:mm')}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {this.state.hotelInfo && (
                    <>
                      <div>
                        <strong className=" inline-block margin-right-light font-18">
                          {this.state.hotelInfo && this.state.hotelInfo.accommodation && this.state.hotelInfo.accommodation.name}
                        </strong>
                        {this.state.hotelInfo.accommodation.rating && (
                          <div className="inline-block">
                            <Rate number={this.state.hotelInfo.accommodation.rating} />{' '}
                          </div>
                        )}
                      </div>

                      <div className="margin-bottom grey">
                        <span>{this.state.hotelInfo.country.name} - </span>
                        <span>{this.state.hotelInfo.city.name}</span>
                      </div>
                    </>
                  )}

                  <div className="margin-bottom margin-top">
                    {this.state.cancellationInfo && this.state.cancellationInfo.isRefundable ? <Tag color="#87d068">Refundable</Tag> : <Tag color="red">Non refundable</Tag>}
                  </div>

                  {this.state.cancellationInfo && this.state.cancellationInfo.isRefundable ? (
                    <>
                      <Row gutter={15} className="text-center margin-bottom">
                        <Col xs={12}>
                          <div className="grey">Refund Amount</div>
                          <div className="large-price-holder">
                            {this.state.cancellationInfo && this.state.cancellationInfo.refundAmount !== 0 ? (
                              <Price currency={this.state.cancellationInfo.currencyType}>{this.state.cancellationInfo.refundAmount}</Price>
                            ) : (
                              '0'
                            )}
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="grey">Damage Amount</div>
                          <div className="large-price-holder">
                            {this.state.cancellationInfo && this.state.cancellationInfo.damageAmount !== 0 ? (
                              <Price currency={this.state.cancellationInfo.currencyType}>{this.state.cancellationInfo.damageAmount}</Price>
                            ) : (
                              '0'
                            )}
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <span className="red">Note: </span>
                        If your flight has been canceled by the Airline for any reason, please contact support.
                      </div>
                    </>
                  ) : this.state.cancellationInfo &&
                    this.state.cancellationInfo.response &&
                    this.state.cancellationInfo.response.data &&
                    this.state.cancellationInfo.response.data.error &&
                    this.state.cancellationInfo.response.data.error.message ? (
                    this.state.cancellationInfo.response.data.error.message
                  ) : (
                    'Unfortunately, this ticket is non-refundable!'
                  )}
                </>
              ) : this.state.cancellationStep === 2 ? (
                <>
                  <h5 className="normal-title">How to receive the refunded amount</h5>

                  <Radio.Group defaultValue="DepositToWallet" onChange={(e) => this.setState({ refundMethod: e.target.value })}>
                    <Radio value="DepositToWallet">
                      Deposit charge <Tag color="#87d068">Quick Solution</Tag>
                    </Radio>
                    <div className="grey margin-bottom align-radio-label">
                      The desired amount will be credited to your account within the next <b> 36 working hours</b>.{' '}
                    </div>
                    <Radio value="DepositToAccount">Deposit to bank account</Radio>
                    <div className="grey align-radio-label margin-top-10">
                      By deducting the fee, the amount will be credited to the bank account from which the purchase was made for at least the <b> next 7 working days</b>. (Due to
                      delay in payment by the supplier)
                    </div>
                  </Radio.Group>
                </>
              ) : this.state.cancellationStep === 3 ? (
                <>
                  <Form.Item label="Card Number">
                    {getFieldDecorator('cardNumber', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input card number',
                        },
                        {
                          pattern: new RegExp(/^[0-9a-zA-Z ]*$/),
                          message: `card number is invalid`,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                  <Form.Item label="Account Name">
                    {getFieldDecorator('accountName', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your account name',
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Zآ-ی ]*$/),
                          message: `Please enter account name using letters only`,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </>
              ) : this.state.cancellationStep === 4 || this.state.cancellationStep === 0 ? (
                <Result icon={<Icon type="loading" />} title="Sending Data" subTitle="The information is sending, please wait..." />
              ) : this.state.cancellationStep === 5 ? (
                <Result
                  status="success"
                  title="Your request has been successfully sent"
                  extra={
                    <div className="text-center">
                      <div>
                        The refund amount will be refunded to your account within the{' '}
                        {this.state.refundMethod === 'DepositToWallet' ? <b>next 36 working hours</b> : <b>next 7 working days</b>} and will be notified via SMS.
                      </div>
                      <div>Thank you for your patience.</div>
                    </div>
                  }
                />
              ) : this.state.cancellationStep === 6 ? (
                <Result status="error" title="Your request failed" subTitle="Please contact support for more information." />
              ) : null}
            </div>

            <div className="refund-modal-footer text-right">
              <Button
                className="margin-right-light"
                key="close"
                onClick={() => {
                  if (this.state.cancellationStep === 5 || this.state.cancellationStep === 6) {
                    window.location.reload();
                  } else {
                    this.setState({ showModal: false });
                  }
                }}>
                Close
              </Button>
              {this.state.cancellationStep === 1 && this.state.cancellationInfo && this.state.cancellationInfo.isRefundable && (
                <Button type="primary" htmlType="button" onClick={() => this.setState({ cancellationStep: 2 })}>
                  Next Step
                </Button>
              )}
              {this.state.cancellationStep === 2 && this.state.refundMethod === 'DepositToAccount' && (
                <Button type="primary" htmlType="button" onClick={() => this.setState({ cancellationStep: 3 })}>
                  Next Step
                </Button>
              )}
              {(this.state.cancellationStep === 3 || (this.state.cancellationStep === 2 && this.state.refundMethod === 'DepositToWallet')) && (
                <Button htmlType="submit" type="danger">
                  Submit
                </Button>
              )}
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

const FormEnable = Form.create({ name: 'cancelFlight' })(Refund);
export default connect()(FormEnable);
