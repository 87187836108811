import '../../CSS/RoomSelect.scss';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default class SelectRoom extends React.Component {
  static propTypes = {
    defaultAdult: PropTypes.any,
    defaultChildren: PropTypes.any,
    defaultAges: PropTypes.any,
    onSelectItem: PropTypes.any.isRequired,
  };
  state = {
    room: [{ id: 0, adult: 1, children: [] }],
  };
  //convert paramters to format
  inputToRoomJSON = () => {
    let roomInit = [];
    let baseCounter = 0;
    this.props.defaultAdult.forEach((item, index) => {
      let roomUnit = { adult: item, children: [] };
      for (let childCounter = baseCounter; childCounter <= this.props.defaultChildren[index] + baseCounter - 1; childCounter++) {
        roomUnit.children.push(this.props.defaultAges[childCounter]);
      }
      baseCounter += this.props.defaultChildren[index];
      roomInit.push(roomUnit);
    });
    this.setState({
      room: roomInit,
    });
  };
  outputToRoomJSON = () => {
    let adult = [];
    let children = [];
    let age = [];
    this.state.room.forEach((value, index) => {
      adult[index] = value.adult;
      children[index] = value.children.length;
      age.push(...value.children);
    });
    console.table({ adult, children, age });
    this.props.onSelectItem({ adult, children, age });
  };
  //handle input
  handleEvent = (type, number, index, indexChild) => {
    let cash = JSON.stringify(this.state.room);
    let roomItem = JSON.parse(cash);
    if (type === 'room') {
      let roomLength = roomItem.length;
      if (number > roomLength) {
        for (let cnt = 1; cnt <= number - roomLength; cnt++) {
          roomItem.push({ adult: 2, children: [] });
        }
      } else {
        roomItem = roomItem.slice(0, number);
      }
    } else if (type === 'children') {
      let childrenLength = roomItem[index].children.length;
      if (number > childrenLength) {
        for (let cnt = 1; cnt <= number - childrenLength; cnt++) {
          roomItem[index].children.push(0);
        }
      } else {
        roomItem[index].children = roomItem[index].children.slice(0, number);
      }
    } else if (type === 'adult') {
      roomItem[index].adult = number;
    } else if (type === 'age') {
      roomItem[index].children[indexChild] = number;
    }
    this.setState(
      {
        room: roomItem,
      },
      () => {
        this.outputToRoomJSON();
      }
    );
  };

  componentDidMount() {
    this.inputToRoomJSON();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.defaultAdult !== this.props.defaultAdult || prevProps.defaultChildren !== this.props.defaultChildren || prevProps.defaultAges !== this.props.defaultAges) {
      this.inputToRoomJSON();
    }
  }

  render() {
    return (
      <div className={'SelectRoom'}>
        <div className={'Slide form-group'}>
          <label htmlFor={'SelectNumber'}>Rooms</label>
          {
            <select value={this.state.room.length} onChange={(e) => this.handleEvent('room', e.target.value)}>
              {_.range(1, 10).map((num) => (
                <option value={num} key={num}>
                  {num}
                </option>
              ))}
              ;
            </select>
          }
        </div>
        <div className={'Slide'}>
          {this.state.room.map((item, index) => {
            return (
              <div className={'PassengerContainer'} key={index}>
                <div className={'form-group Slide'}>
                  <label htmlFor={'adultNumber' + index}>Adult</label>
                  <select id={'adultNumber' + index} value={item.adult} onChange={(e) => this.handleEvent('adult', e.target.value, index)}>
                    {_.range(1, 9).map((num) => (
                      <option value={num} key={num}>
                        {num}
                      </option>
                    ))}
                    ;
                  </select>
                </div>
                <div className={'form-group Slide'}>
                  <label htmlFor={'childNumber' + index}>Children</label>
                  <select id={'childNumber' + index} value={item.children.length} onChange={(e) => this.handleEvent('children', e.target.value, index)}>
                    {_.range(0, 5).map((num) => (
                      <option value={num} key={num}>
                        {num}
                      </option>
                    ))}
                    ;
                  </select>
                </div>
                {item.children.map((age, ageIndex) => {
                  return (
                    <div className={'form-group Slide Age'} key={index.toString() + ageIndex.toString()}>
                      <label htmlFor={'childAge' + index.toString() + ageIndex.toString()}>Ages</label>
                      <select id={'childAge' + index.toString() + ageIndex.toString()} value={age} onChange={(e) => this.handleEvent('age', e.target.value, index, ageIndex)}>
                        <option value={0}>under 1</option>
                        {_.range(1, 19).map((num) => (
                          <option value={num} key={num}>
                            {num}
                          </option>
                        ))}
                        ;
                      </select>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
