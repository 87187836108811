import { Button, Col, Form, Input, Modal, Radio, Row, Spin, Tag, Tooltip } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//import actions
import { CancelPNR, IssuePNR, PricingPNR, VoidPNR, getPNRDetails, setPNRDetails } from '../../../../Actions/FlightAction';
//import model and type
import { FareTypeEnum, PNRStatus } from '../../../../Enums/Types';
import Price from '../../../commond/Price';
import Refund from '../../Sections/Tracking/Refund';

class FlightTicket extends React.Component {
  state = {
    fareTypeSelected: null,
    selectedSupplier: null,
    pnrCode: null,
    searched: false,
    suppliers: [],
  };

  //PNR operation
  componentDidMount() {
    function stickySection() {
      if (document.getElementsByClassName('sticky-section').length > 0) {
        let docTop = document.body.scrollTop || document.documentElement.scrollTop,
          headerH = document.getElementById('main_header').clientHeight,
          searchH = document.getElementById('ticketing_search_section').clientHeight,
          elementH = document.getElementsByClassName('sticky-section')[0].clientHeight,
          element = document.getElementsByClassName('sticky-section')[0];
        if (docTop > headerH + searchH) {
          element.style.position = 'fixed';
          element.style.top = '0';
          element.style.right = '0';
          element.style.left = '0';
          element.parentNode.style.paddingTop = elementH + 'px';
        } else {
          element.style.position = 'static';
          element.style.top = '';
          element.style.right = '';
          element.style.left = '';
          element.parentNode.style.paddingTop = '';
        }
      }
    }

    document.onscroll = stickySection;
    window.onresize = stickySection;

    this.getFillInput();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.PNRDetails && this.props.PNRDetails !== prevProps.PNRDetails) {
      this.setState({
        fareTypeSelected: this.props.PNRDetails.fareType,
      });
      let parameters = queryString.parse(this.props.location.search);
      if (!parameters['key']) {
        let key = this.props.PNRDetails.key;
        this.props.history.push(`${this.props.location.search}&key=${key}`);
      }
    }
    if (this.props.location !== prevProps.location && !(!prevProps.location.search.includes('key=') && this.props.location.search.includes('key='))) {
      this.getFillInput();
    }
  }

  getFillInput = () => {
    this.props.setPNRDetails(null);
    if (this.props.location) {
      let parameters = queryString.parse(this.props.location.search);
      if (parameters && parameters.pnrCode && parameters.supplierId) {
        this.setState(
          {
            selectedSupplier: parameters.supplierId,
            pnrCode: parameters.pnrCode,
            searched: true,
          },
          () => {
            let param = new URLSearchParams();
            param.append('PNRCode', this.state.pnrCode);
            param.append('SupplierId', this.state.selectedSupplier);
            //append to key

            this.props.getPNRDetails(param);
          }
        );
      }
    }
  };

  PNRPricing() {
    let parameters = queryString.parse(this.props.location.search);
    // if (!parameters['key']) {
    //   let key = this.props.PNRDetails.key;
    //   this.props.history.edit(`${this.props.location.search}&key=${key}`);
    // }
    this.props.PricingPNR({ fareType: this.state.fareTypeSelected, key: parameters.key });
  }

  // voidIssued = () => {
  //   Modal.confirm({
  //     title: 'Attention',
  //     content: "After voiding this ticket you have to cancel it,if you don't do it,you must pay charges",
  //     onOk: () => {
  //       this.props.VoidPNR({ pnrCode: this.props.PNRDetails.pnrCode, supplierId: this.state.selectedSupplier });
  //     },
  //     onCancel() {},
  //   });
  // };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState(
          {
            selectedSupplier: values.SupplierId,
          },
          () => {
            this.setState({
              searched: true,
            });
            this.props.history.push(`/flight/ticketing?pnrCode=${values.PNRCode}&supplierId=${values.SupplierId}`);
          }
        );
      }
    });
  };
  convertTypeToIndex = (type) => {
    let result = null;
    if (this.props.PNRDetails) {
      this.props.PNRDetails.passengerFare.forEach((item, index) => {
        if (item.passengerType === type) {
          result = index;
        }
      });
    }
    return result;
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { PNRDetails } = this.props;
    //const {fareType} = this.props.PNRDetails;
    //let PNRDetails={"flightSegments":[{"isPast":false,"statusCode":"HK","ticketTimeLimit":null,"airlineRefId":"DCD8*KR5Z3Y","flightNumber":3196,"departureDateTime":"2020-02-26T10:45:00","arrivalDateTime":"2020-02-26T11:55:00","resBookDesigCode":"Q","arrivalAirport":{"locationCode":"ARN","terminalID":"5","locationName":"Arlanda","countryName":"Sweden","cityName":"Stockholm"},"departureAirport":{"locationCode":"CPH","terminalID":"2","locationName":"Copenhagen Airport","countryName":"Denmark","cityName":"Copenhagen"},"marketingAirline":{"code":"D8","name":"Norwegian Air International LTD.","photoUrl":"https://agent.itours.no/Content/images/airlines/Thumbs/Norwegian Air International LTD..png"},"operatingAirline":{"code":"D8","name":"Norwegian Air International LTD.","photoUrl":"https://agent.itours.no/Content/images/airlines/Thumbs/Norwegian Air International LTD..png"},"airEquipType":"73H","flightDuration":"01:10:00","fareBasis":[{"fareBasisCode":"QKAMP","bookingCode":null,"passengerType":"ADT"}],"baggageInformation":[{"baggageAllowance":2,"unitType":"P","passengerType":"ADT"}],"cabinClass":{"name":"ECONOMY","code":"Y"},"isOutbound":false,"stopTime":"00:00:00"}],"passengerFare":[{"fareCalculation":"CPH D8 STO Q8.21 42.52NUC50.73END ROE6.725029","equivalentFare":465,"baseFareCurrencyCode":"DKK","passengerType":"ADT","baseFare":342,"taxes":[{"amount":224,"code":"TotalTax","name":null}],"fess":null,"commission":0,"totalFare":689}],"itinTotalFare":null,"passengers":[{"ticketNumber":null,"firstName":"MOHAMMAD MR","lastName":"KARIMI","code":"ADT","gender":true,"email":null,"birthDate":"1982-10-25T00:00:00","passportNumber":null,"passportExpireDate":null,"nationalId":null}],"pnrStatus":"Made","pnrCode":"YRRRDG","key":"0025096246915541370478","validatingCarrier":null,"fareType":"Publish"}
    return (
      <>
        <div id="ticketing_search_section" className={this.props.home === true ? 'search-section' : 'search-section bg-grey'}>
          <div className="container">
            <Form onSubmit={this.handleSubmit} className="ticketing-form">
              <Row gutter={15}>
                <Col lg={this.props.home ? 12 : 5} sm={9} xs={24}>
                  <label>PNR code</label>
                  <Form.Item>
                    {getFieldDecorator(`PNRCode`, {
                      initialValue: this.state.pnrCode,
                      rules: [
                        {
                          required: true,
                          message: 'insert your PNR code',
                        },
                      ],
                    })(<Input placeholder={'insert PNR code'} />)}
                  </Form.Item>
                </Col>
                <Col lg={this.props.home ? 12 : 5} sm={9} xs={24}>
                  <label>Supplier name</label>
                  {this.props.ticketingSupplier.length > 0 ? (
                    <Form.Item>
                      {getFieldDecorator(`SupplierId`, {
                        initialValue: parseInt(this.state.selectedSupplier),
                        rules: [
                          {
                            required: true,
                            message: 'insert your Supplier name',
                          },
                        ],
                      })(
                        <select>
                          <option value={null}>-</option>
                          {this.props.ticketingSupplier.map((item, index) => (
                            <option key={item.supplierId} value={item.supplierId}>
                              {item.supplierName}
                            </option>
                          ))}
                        </select>
                      )}
                    </Form.Item>
                  ) : (
                    <div>Loading...</div>
                  )}
                </Col>
                <Col lg={this.props.home ? 24 : 4} sm={6} xs={24}>
                  <Form.Item>
                    <Button className="align-labels-sm" type="primary" htmlType="submit">
                      Get Details
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className={'showPNRDetails '}>
          {PNRDetails ? (
            <div>
              {PNRDetails.pnrStatus && (
                <div className="pnr-status-section sticky-section">
                  <div className="container vr-padding font-18 clearfix">
                    <div className="pull-left">
                      <div>
                        <span className="margin-right">PNR Code:</span>
                        <span> {PNRDetails.pnrCode} </span>
                      </div>
                      <div>
                        <span className="margin-right">PNR Status:</span>
                        <Tag
                          className="font-18"
                          color={
                            (PNRDetails.pnrStatus === PNRStatus.Made && 'green') ||
                            (PNRDetails.pnrStatus === PNRStatus.Voided && 'gray') ||
                            (PNRDetails.pnrStatus === PNRStatus.Issued && 'green') ||
                            (PNRDetails.pnrStatus === PNRStatus.Canceled && 'gray') ||
                            (PNRDetails.pnrStatus === PNRStatus.Priced && 'blue')
                          }>
                          {PNRDetails.pnrStatus}
                        </Tag>
                      </div>
                    </div>
                    <div className="pull-right margin-top ">
                      {(PNRDetails.pnrStatus === PNRStatus.Made || PNRDetails.pnrStatus === PNRStatus.Priced) && (
                        <Radio.Group value={this.state.fareTypeSelected} onChange={(e) => this.setState({ fareTypeSelected: e.target.value })}>
                          {FareTypeEnum.map((item, index) => {
                            return (
                              <Radio key={index} value={item.value} className="white">
                                {item.text}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      )}
                      {PNRDetails.pnrStatus === PNRStatus.Issued && PNRDetails.reserveStatus === 'Issued' && (
                        <Refund reserveType="flight" reserveId={PNRDetails.reserveId} username={this.props.Username} inlineButton={true} />
                      )}

                      {(PNRDetails.pnrStatus === PNRStatus.Made || PNRDetails.pnrStatus === PNRStatus.Priced) && (
                        <Tooltip placement="topRight" title={'You must price PNR before issuing it'}>
                          <Button type={'primary'} htmlType="button" className="margin-right" onClick={this.PNRPricing.bind(this)}>
                            Price PNR
                          </Button>
                        </Tooltip>
                      )}
                      {PNRDetails.pnrStatus === PNRStatus.Priced && (
                        <Button
                          type={'primary'}
                          htmlType="button"
                          className="margin-right"
                          onClick={() => {
                            if (this.props.PNRDetails.reserveStatus === 'PaymentSuccessful') {
                              //create param for get request
                              let param = new URLSearchParams();
                              param.set('reserveId', PNRDetails.reserveId);
                              param.set('username', this.props.Username);
                              this.props.IssuePNR(param);
                            } else {
                              this.props.history.push(`/payment/choice?reserveId=${PNRDetails.reserveId}&username=${this.props.Username}&key=${PNRDetails.key}`);
                            }
                          }}>
                          Issue
                        </Button>
                      )}
                      {(PNRDetails.pnrStatus === PNRStatus.Made || PNRDetails.pnrStatus === PNRStatus.Priced || PNRDetails.pnrStatus === PNRStatus.Voided) && (
                        <Button
                          type={'primary'}
                          htmlType="button"
                          className="margin-right"
                          onClick={() => this.props.CancelPNR({ pnrCode: PNRDetails.pnrCode, supplierId: this.state.selectedSupplier })}>
                          Cancel PNR
                        </Button>
                      )}
                      {/* {PNRDetails.pnrStatus === PNRStatus.Issued && (
                        <Button type={'primary'} htmlType="button" className="margin-right" onClick={this.voidIssued}>
                          Void
                        </Button>
                      )} */}
                    </div>
                  </div>
                </div>
              )}
              {PNRDetails.flightSegments && (
                <div className="container vr-padding">
                  <h3 className="normal-title">Flight Details</h3>
                  <div className="table-holder">
                    <table className="itours-table font-13">
                      <thead>
                        <tr>
                          <th rowSpan="2" colSpan={2}>
                            Marketing Airline
                          </th>
                          <th rowSpan="2">Operating Airline</th>
                          <th rowSpan="2">Flight Number</th>
                          <th rowSpan="2" className="min-width-150">
                            Departure
                          </th>
                          <th rowSpan="2" className="min-width-150">
                            Arrival
                          </th>
                          <th rowSpan="2">Duration</th>
                          <th rowSpan="2">Supplier PR</th>
                          <th rowSpan="2">Status code</th>
                          <th rowSpan="2">Ticket time limit</th>
                          <th rowSpan="2">Airline Ref Id</th>
                          <th rowSpan="2">Class</th>
                          {/*<th rowSpan="2">Air Equip Type</th>*/}
                          <th rowSpan="2">Baggage</th>
                          <th className={'no-wrap'}>Fare Base</th>
                        </tr>
                      </thead>
                      <tbody>
                        {PNRDetails.flightSegments.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img className="inline-block airline-logo" src={item.marketingAirline.photoUrl} alt={item.marketingAirline.name} />
                              </td>
                              <td className="font-12">{item.marketingAirline.name}</td>
                              <td className="font-12">{item.operatingAirline.name}</td>
                              <td>
                                <div>
                                  <b>{item.flightNumber}</b>
                                </div>
                                <div className="font-12">{item.cabinClass.name}</div>
                              </td>
                              <td>
                                <div>
                                  <span className="font-12">{item.departureAirport.locationName} </span>
                                  <b>({item.departureAirport.locationCode})</b>
                                  {item.departureAirport.terminalID && <span className="terminal-id">terminal: {item.departureAirport.terminalID}</span>}
                                </div>
                                <div> {moment(item.departureDateTime, 'YYYY-MM-DDTHH:mm:SS').format('YYYY-MM-DD HH:mm')} </div>
                              </td>
                              <td>
                                <div>
                                  <span className="font-12">{item.arrivalAirport.locationName} </span>
                                  <b> ({item.arrivalAirport.locationCode})</b>
                                  {item.arrivalAirport.terminalID && <span className="terminal-id">terminal: {item.arrivalAirport.terminalID}</span>}
                                </div>
                                <div> {moment(item.arrivalDateTime, 'YYYY-MM-DDTHH:mm:SS').format('YYYY-MM-DD HH:mm')} </div>
                              </td>
                              <td className="font-12">{item.flightDuration}</td>
                              <td>
                                <b>{PNRDetails.pnrCode}</b>
                              </td>
                              <td>{item.statusCode}</td>
                              <td>{item.ticketTimeLimit ? item.ticketTimeLimit : ' -- '}</td>
                              <td>{item.airlineRefId}</td>
                              <td>{item.resBookDesigCode}</td>
                              {/*<td>{item.airEquipType}</td>*/}
                              <td>
                                {item.baggageInformation.map((item, index) => {
                                  return (
                                    <div key={index} className="no-wrap">
                                      {' '}
                                      {item.baggageAllowance} {item.unitType} for {item.passengerType}{' '}
                                    </div>
                                  );
                                })}
                              </td>
                              <td>
                                {item.fareBasis.map((itemFare) => {
                                  return (
                                    <div key={index} className="no-wrap">
                                      {itemFare.fareBasisCode}
                                    </div>
                                  );
                                })}
                              </td>
                              {/*<td>*/}
                              {/*    {item.fareBasis.map(itemFare=>{*/}
                              {/*        return(*/}
                              {/*            <div key={index} className="no-wrap">{itemFare.bookingCode}</div>*/}
                              {/*        )*/}
                              {/*    })}*/}
                              {/*</td>*/}
                              {/*<td>*/}
                              {/*    {item.fareBasis.map(itemFare=>{*/}
                              {/*        return(*/}
                              {/*            <div className="no-wrap">{itemFare.passengerType}</div>*/}
                              {/*        )*/}
                              {/*    })}*/}
                              {/*</td>*/}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <div className="container vr-padding">
                <div className="margin-bottom">
                  <h3 className="normal-title">Passenger Fare & Details</h3>
                  <div className="table-holder">
                    <table className="itours-table">
                      <thead>
                        <tr>
                          <th>Row</th>
                          <th>Full Name</th>
                          <th>Ticket Number</th>
                          <th>Email</th>
                          <th>Birthday</th>
                          <th>Passport Number</th>
                          <th>Passport Expire Date</th>
                          <th>National ID</th>
                          <th>Base Fare</th>
                          <th>Total Tax</th>
                          <th>Commission</th>
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {PNRDetails.passengers.map((item, index) => {
                          return (
                            <tr key="index">
                              <td>{index + 1}</td>
                              <td>
                                {PNRDetails.isOutSidePNR !== true && <span className="gender"> {item.gender ? 'Mr' : 'Mrs'} </span>}
                                <span className="first-name">{item.firstName} </span>
                                <span className="last-name">{item.lastName}</span>
                              </td>
                              <td>{item.ticketNumber ? item.ticketNumber : ' -- '}</td>
                              <td>{item.email ? item.email : ' -- '}</td>
                              <td>{moment(item.birthDate, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')}</td>
                              <td>{item.passportNumber ? <span> {item.passportNumber}</span> : ' -- '}</td>
                              <td>{item.passportExpireDate ? <span> {moment(item.passportExpireDate, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')}</span> : ' -- '}</td>
                              <td>{item.nationalId ? <span> {item.nationalId}</span> : ' -- '}</td>
                              <td>
                                <Price currency={this.props.CurrencyCode}>{PNRDetails.passengerFare[this.convertTypeToIndex(item.code)].baseFare}</Price>
                              </td>
                              {/*<td>*/}
                              {/*    {PNRDetails.passengerFare[this.convertTypeToIndex(item.code)].taxes.map((TaxItem,TaxIndex)=>{*/}
                              {/*        return(*/}
                              {/*            <div>{TaxItem.code}: {TaxItem.amount}</div>*/}
                              {/*        )*/}
                              {/*    })}                                                    */}
                              {/*</td>*/}
                              {/*tax sum deprecated this properties only return total tax*/}
                              <td>
                                <Price currency={this.props.CurrencyCode}>{PNRDetails.passengerFare[this.convertTypeToIndex(item.code)].taxes[0].amount}</Price>
                              </td>
                              {/* <div>Fare Calculation : {PNRDetails.passengerFare[this.convertTypeToIndex(item.code)].fareCalculation}</div>
                                                            <div>Equivalent Fare : {PNRDetails.passengerFare[this.convertTypeToIndex(item.code)].equivalentFare}</div>
                                                            <div>Base Fare Currency Code Fare : {PNRDetails.passengerFare[this.convertTypeToIndex(item.code)].baseFareCurrencyCode}</div> */}
                              <td>
                                <Price currency={this.props.CurrencyCode}>{PNRDetails.passengerFare[this.convertTypeToIndex(item.code)].commission}</Price>
                              </td>
                              <td>
                                <Price currency={this.props.CurrencyCode}>{PNRDetails.passengerFare[this.convertTypeToIndex(item.code)].totalFare}</Price>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*{PNRDetails.ticketInformation &&*/}
                {/*<div>*/}
                {/*    <h3 className="normal-title">Ticketing information</h3>*/}
                {/*    <Card className="margin-bottom">*/}
                {/*        <ul className="ticketing-information-list bullet-list">*/}
                {/*            {PNRDetails.ticketInformation.map((item,index)=><li key={index} className="information-item">{item}</li>)}*/}
                {/*        </ul>*/}
                {/*    </Card>*/}
                {/*</div>*/}
                {/*}*/}
              </div>
              {!(PNRDetails.pnrStatus === PNRStatus.Canceled) && (
                <div className={'container total-payment'}>
                  {PNRDetails.itinTotalFare && (
                    <>
                      <h3 className="normal-title">Fare Details</h3>
                      <div className="table-holder">
                        <table className="itours-table margin-bottom">
                          <thead>
                            <tr>
                              {/*<th>Equivalent Fare</th>*/}
                              <th>Base Fare</th>
                              <th>Fee</th>
                              <th>Commission</th>
                              <th>Taxes</th>
                              <th>Total Fare</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {/*<td><Price currency={this.props.CurrencyCode}>{PNRDetails.itinTotalFare.equivalentFare}</Price></td>*/}
                              <td>
                                <Price currency={this.props.CurrencyCode}>{PNRDetails.itinTotalFare.baseFare}</Price>
                              </td>
                              <td>
                                <Price currency={this.props.CurrencyCode}>{PNRDetails.itinTotalFare.fess}</Price>
                              </td>
                              <td>
                                <Price currency={this.props.CurrencyCode}>{PNRDetails.itinTotalFare.comission}</Price>
                              </td>
                              <td>
                                {PNRDetails.itinTotalFare.taxes.map((item, index) => (
                                  <div key={index}>
                                    {item.name}:<Price currency={this.props.CurrencyCode}>{item.amount}</Price>
                                  </div>
                                ))}
                              </td>
                              <td>
                                <Price currency={this.props.CurrencyCode}>{PNRDetails.itinTotalFare.totalFare}</Price>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          ) : (
            this.state.searched && (
              <div className="text-center large-vr-padding loading-min-height">
                <Spin tip="Loading data..." />
              </div>
            )
          )}
        </div>
      </>
    );
  }
}

const FormWrap = Form.create({ name: 'Flight_Ticketing' })(FlightTicket);
const RouterWrap = withRouter(FormWrap);
const MapStateToProps = (state) => ({
  PNRDetails: state.Flight.PNRDetails,
  Username: state.User.userName,
  CurrencyCode: state.Flight.PNRDetails ? state.Flight.PNRDetails.currencyCode : null,
  ticketingSupplier: state.Flight.ticketingSupplier,
});
const DispatchToProps = (dispatch) => ({
  getPNRDetails: (param) => {
    dispatch(getPNRDetails(param));
  },
  PricingPNR: (param) => {
    dispatch(PricingPNR(param));
  },
  CancelPNR: (param) => {
    dispatch(CancelPNR(param));
  },
  VoidPNR: (param) => {
    dispatch(VoidPNR(param));
  },
  setPNRDetails: (param) => {
    dispatch(setPNRDetails(param));
  },
  IssuePNR: (param) => {
    dispatch(IssuePNR(param));
  },
});
export default connect(MapStateToProps, DispatchToProps)(RouterWrap);
