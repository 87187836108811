import { Icon, Spin, Tooltip } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import HotelApi from '../../../../../APIs/Hotel';
import { getCancellationStatusName } from '../../../../../utils/enumConverter';
import Price from '../../../../commond/Price';

const HotelCancellationPolicy = (props) => {
  const [modalVisibility, setModalVisibility] = useState('');
  const [cancellationRules, setCancellationRules] = useState(null);
  const currency = useSelector((state) => state.User.currency);

  const showCancellationPolicy = async (bookingToken) => {
    setCancellationRules(null);
    setModalVisibility('visible');

    let ages = [];
    props.rooms.forEach((room) => room.ages.forEach((age) => ages.push(age)));

    if (ages.length === 0) {
      ages = undefined;
    }
    const cancellationRq = {
      adults: props.rooms.map((room) => room.adults),
      children: props.rooms.map((room) => room.children),
      ages: ages,
      checkin: moment(props.date.checkIn).format('YYYY-MM-DD'),
      checkout: moment(props.date.checkOut).format('YYYY-MM-DD'),
      bookingToken: bookingToken,
    };

    const response = await HotelApi.getCancellationPolicy(cancellationRq);
    if (!response.isAxiosError) {
      setCancellationRules(response);
    } else {
      setCancellationRules(response.response.data.error.message);
    }
  };

  let cancellationStatus = props.rate.cancellationPolicy.status;
  return (
    <>
      {cancellationStatus === 'Refundable' ? (
        <li style={{ textDecoration: 'underline', cursor: 'pointer' }} className={'has-list-icon'} onClick={() => showCancellationPolicy(props.rate.bookingToken)}>
          <span className={'green inline-block'}>
            <Icon type="check" className={'margin-right-light'} />
            <span className={'margin-right-light'}>{getCancellationStatusName(cancellationStatus)}</span>
            <Icon type="info-circle" />
          </span>
        </li>
      ) : (
        <li style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => showCancellationPolicy(props.rate.bookingToken)}>
          <span>
            {getCancellationStatusName(cancellationStatus)} <Icon type="info-circle" />
          </span>
        </li>
      )}

      <div className={`itours-modal ${modalVisibility}`}>
        <div className="modal-overlay" />
        <div className="modal-inner">
          <div className="modal-header">Cancellation rules</div>
          <div className="modal-main">
            {cancellationRules ? (
              cancellationRules.fees ? (
                <>
                  <h2>{getCancellationStatusName(cancellationRules.status)}</h2>
                  <ul className={'cancelation-rules-list'}>
                    {cancellationRules.fees.map((fee) => {
                      if (fee.amount === 0) {
                        return (
                          <li key={cancellationRules.fees.indexOf(fee)}>
                            Cancellation is <span className="green">FREE</span> before{' '}
                            <Tooltip className="no-wrap" placement="top" title={moment(fee.toDate).format('MM/DD/YYYY')}>
                              {moment(fee.toDate).format('DD MMM YYYY')}
                            </Tooltip>
                          </li>
                        );
                      } else {
                        return (
                          <li key={cancellationRules.fees.indexOf(fee)}>
                            <Price currency={currency}>{fee.amount}</Price> on and after date{' '}
                            <Tooltip className="no-wrap" placement="top" title={moment(fee.fromDate).format('MM/DD/YYYY')}>
                              {' '}
                              {moment(fee.fromDate).format('DD MMM YYYY')}
                            </Tooltip>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </>
              ) : (
                <div>{cancellationRules}</div>
              )
            ) : (
              <div className={'text-center vr-padding'}>
                <Spin tip="Loading..." />
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="ant-btn ant-btn-primary" onClick={() => setModalVisibility('')}>
              <span>Ok</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

HotelCancellationPolicy.propTypes = {
  rate: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
};

export default HotelCancellationPolicy;
