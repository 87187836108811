import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

const ShowMap = (props) => {
  const position = [props.latitude, props.longitude];
  const [zoom] = useState(14);
  return (
    <>
      <Map center={position} zoom={zoom} style={{ height: '500px' }}>
        <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position}>
          <Popup>
            {props.locationName}
            <hr />
            {props.locationAddress}
          </Popup>
        </Marker>
      </Map>
    </>
  );
};

ShowMap.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  locationName: PropTypes.string,
  locationAddress: PropTypes.string,
};

export default ShowMap;
//
// export default class ShowMap extends Component {
//     // state = {
//     //     location: [],
//     //     infoLocation: [],
//     //     zoom: 13,
//     // }
//
//     state = {
//         lat: 51.505,
//         lng: -0.09,
//         zoom: 13,
//     };
//
//     // getInfo = () => {
//     //     let pinsArray = [];
//     //     let pinsInfo = [];
//     //     if (this.props.data) {
//     //         this.props.data.forEach((item) => {
//     //             let pinItem = [item.coordinates.latitude, item.coordinates.longitude];
//     //             let pinInfo = {name: item.name, price: item.salePrice}
//     //             pinsArray.push(pinItem);
//     //             pinsInfo.push(pinInfo);
//     //         });
//     //         this.setState({location: pinsArray, infoLocation: pinsInfo})
//     //     }
//     // }
//     //
//     // componentDidMount() {
//     //     this.getInfo();
//     // }
//     //
//     // componentDidUpdate(prevProps, prevState, snapshot) {
//     //     if (prevProps !== this.props) {
//     //         this.getInfo();
//     //     }
//     // }
//
//     render() {
//         const position = [this.state.lat, this.state.lng];
//         return (
//             // <Map center={position} zoom={this.state.zoom}  style={{height:this.props.height?this.props.height:"300px"}}>
//             //     <TileLayer
//             //         attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//             //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//             //     />
//             //     {this.state.location.map((item,index)=>{
//             //         return(
//             //             <Marker position={item} key={index}>
//             //                 <Popup>
//             //                     <div><b>{this.state.infoLocation[index].name}</b></div>
//             //                     <div><Price currency={this.props.currency}>{this.state.infoLocation[index].price}</Price></div>
//             //                 </Popup>
//             //             </Marker>
//             //         )
//             //     })}
//             // </Map>
//
//         )
//     }
// }
