import { Button, Form, Icon, Input, Result, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import AccountApi from '../../../../APIs/Account';

//import model and action

class VerifyMobileNumber extends React.Component {
  state = {
    resultDescription: '',
    verificationStatus: '',
  };
  componentWillUnmount() {
    this.setState({
      resultDescription: '',
      verificationStatus: '',
    });
  }
  submitHandler = (e) => {
    e.preventDefault();
    this.clearWrongCodeMessage();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      } else {
        let Number = this.props.phoneNumber.replace('+', '');
        fieldsValue.phoneNumber = Number;
        this.sendFormData(fieldsValue);
      }
    });
  };
  clearWrongCodeMessage = () => {
    this.setState({
      verificationStatus: '',
      resultDescription: '',
    });
  };
  async sendFormData(param) {
    this.setState({
      resultDescription: '',
      verificationStatus: 'Loading',
    });

    const formResponse = await AccountApi.CheckVerificationCode(param);
    this.props.form.resetFields();
    if (formResponse && formResponse.success) {
      this.setState({
        verificationStatus: 'Success',
        resultDescription: 'Your mobile number verified successfully.',
      });
    } else {
      this.setState({
        verificationStatus: 'Error',
        resultDescription: formResponse.error.message,
      });
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return this.state.verificationStatus === 'Success' ? (
      <Result
        status="success"
        title="Success"
        subTitle={this.state.resultDescription}
        extra={
          <Button htmlType="button" type={'primary'} onClick={this.props.cancelAndReload}>
            Ok
          </Button>
        }
      />
    ) : (
      <>
        <p className="margin-bottom">Please confirm your mobile number.</p>
        <p>
          {this.props.sendingSms ? (
            <Spin />
          ) : (
            <>
              <span className="margin-right">Enter the code we sent to </span> <b> {this.props.phoneNumber}</b>
            </>
          )}
        </p>
        <Form onSubmit={this.submitHandler} className="margin-bottom">
          <Form.Item>
            {getFieldDecorator('code', {
              rules: [{ required: true, message: 'Please input code' }],
            })(<Input placeholder="- - - - - -" onChange={this.clearWrongCodeMessage} />)}
          </Form.Item>
          {this.state.verificationStatus === 'Loading' ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : null}

          {this.state.verificationStatus === 'Error' ? (
            <p className="red">
              <Icon type="warning" className="margin-right-light" /> {this.state.resultDescription}
            </p>
          ) : null}
          <Button className="margin-top margin-right" type="primary" htmlType="submit">
            Verify
          </Button>
          <Button className="margin-top" htmlType="button" onClick={this.props.cancel}>
            Cancel
          </Button>
        </Form>
        <div>
          Didn't receive the SMS?
          <Button
            htmlType="button"
            type="link"
            onClick={() => {
              this.clearWrongCodeMessage();
              this.props.resendSms();
            }}
            loading={this.props.sendingVerificationSms}>
            Resend SMS
          </Button>
        </div>
      </>
    );
  }
}
const VerificationForm = Form.create({ name: 'verification-form' })(VerifyMobileNumber);
export default connect()(VerificationForm);
