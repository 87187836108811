import React from 'react';

import ChangePassword from './changePassword';
import ChangeInformation from './changeProfile';
import Newsletter from './newsletter';

export default class Index extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="vr-padding user-profile-page">
          <ChangeInformation />
          <ChangePassword />
          <Newsletter />
        </div>
      </div>
    );
  }
}
