import { takeLatest } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { call, put, takeEvery } from 'redux-saga/effects';

import { authentication as AuthAction, changeCurrency, editUser, setAccountId, setPermissions, setTenant, setTenantName } from './../Actions/AccountAction';
import Account from './../APIs/Account';
import { setLoading, setNotification } from '../Actions/NotificationAction';
import { getPermissionsHttp } from '../APIs/Identity';
import { UserActions } from '../Enums/ActionTypes';
import { Currency, MessageType } from '../Enums/Types';
import { getStorage, removeStorage, setStorage } from '../utils/localstorage';

//fetch general function
function* loginUserFn(action) {
  yield put(setLoading({ showAllPage: false, showInline: true }));
  //check tenant
  const responseTenant = yield call(Account.requestTenant, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (responseTenant === null) {
    yield put(setNotification({ show: true, content: 'Tenant not found', type: MessageType.Error }));
    yield put(setLoading({ showAllPage: false, showInline: false }));
  } else {
    if (responseTenant.isAxiosError === undefined) {
      yield put(setTenant(responseTenant.id));
      yield put(setTenantName(responseTenant.name));
      localStorage.setItem('tenantName', responseTenant.name);
      yield call(Account.setTenant, responseTenant.id);
      //login the user
      const response = yield call(Account.login, { ...action.input });
      yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
      yield put(setLoading({ showAllPage: false, showInline: false }));
      // //find exception
      if (response.isAxiosError === undefined) {
        if (response.user && response.user.roleNames.includes('Affiliate')) {
          //after get token we should get terminal ID
          const affiliate = yield call(Account.getAffiliate, response.accessToken);

          yield call(Account.setTerminal, affiliate.id);
          yield call(Account.setCompanyName, affiliate.company.name);

          let rememberDays = 1;
          if (action.input.remember) {
            rememberDays = 30;
          }
          yield setStorage('token', response.accessToken, 86400 * rememberDays);
          yield setStorage('currency', Currency[0].value, 86400 * 30);
          yield setStorage('ag_flight_history', null);
          yield setStorage('ag_hotel_history', null);
          yield put(editUser(response.user));
          yield put(setAccountId(affiliate.userId));
          yield put(changeCurrency(Currency[0].value));

          yield getPermissionsFN();
          yield put(push('/'));
        } else {
          yield put(
            setNotification({ show: true, content: "The page you're trying to access has restricted access. Please refer to your system administrator", type: MessageType.Error })
          );
        }
      } else {
        let message;
        response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
        yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
      }
      //
    } else {
      let message;
      responseTenant.response ? (message = responseTenant.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
      yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
      yield put(setLoading({ showAllPage: false, showInline: false }));
    }
  }
}

function* authUserFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  const haveTenant = yield call(Account.checkTenant);
  if (haveTenant) {
    yield put(setTenant(haveTenant));
    yield put(setTenantName(localStorage.getItem('tenantName')));
    const response = yield call(Account.authentication, action.input.officeID);
    // //find exception
    if (response.isAxiosError === undefined) {
      yield put(editUser(response));

      let currency = yield getStorage('currency');
      yield put(changeCurrency(currency));

      const token = yield getStorage('token');
      const affiliate = yield call(Account.getAffiliate, token);
      yield put(setAccountId(affiliate.userId));

      yield put(setLoading({ showAllPage: false, showInline: false }));
    } else {
      // let message = null;
      // (response.response) ? message = response.response.data.error.message : message = "Unknown Error,Please check your internet connection";
      //yield put(setNotification({show: true, content: message}));
      yield put(setLoading({ showAllPage: false, showInline: false }));
    }
  }
}

function* sendRecoverEmailFN(action) {
  yield put(setLoading({ showAllPage: false, showInline: true }));
  const response = yield call(Account.sendRecoverEmail, { ...action.input });
  // //find exception
  yield put(setLoading({ showAllPage: false, showInline: false }));
  if (response.isAxiosError === undefined) {
    if (response) {
      yield put(setNotification({ show: true, content: 'Recovery email send', type: MessageType.Success }));
    } else {
      yield put(setNotification({ show: true, content: 'Recovery email doesnt send', type: MessageType.Error }));
    }
  } else {
    let message;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
}

function* changePasswordFN(action) {
  yield put(setLoading({ showAllPage: false, showInline: true }));
  const response = yield call(Account.changePassword, { ...action.input });
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  yield put(setLoading({ showAllPage: false, showInline: false }));
  if (response.isAxiosError === undefined) {
    if (response) {
      yield put(setNotification({ show: true, content: 'Password changed', type: MessageType.Success }));
    } else {
      yield put(setNotification({ show: true, content: "Password can't change. Please try again.", type: MessageType.Error }));
    }
  } else {
    let message;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
}

function* logoutUserFn() {
  yield removeStorage('token');
  yield removeStorage('currency');
  yield removeStorage('ag_it_tenant');
  yield removeStorage('ag_it_terminal');
  yield removeStorage('ag_flight_history');
  yield removeStorage('ag_hotel_history');
}

function* checkCurrencyFN(action) {
  yield setStorage('currency', action.input, 86400 * 30);
}

function* resetPasswordFN(action) {
  yield put(setLoading({ showAllPage: false, showInline: true }));
  const response = yield call(Account.resetPassword, { ...action.input });
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  yield put(setLoading({ showAllPage: false, showInline: false }));
  if (response.isAxiosError === undefined) {
    if (response) {
      yield put(setNotification({ show: true, content: 'Password changed', type: MessageType.Success }));
    } else {
      yield put(setNotification({ show: true, content: "Password can't change. Please try again", type: MessageType.Error }));
    }
  } else {
    let message;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
}

function* updateUserFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  const response = yield call(Account.editUser, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  yield put(setLoading({ showAllPage: false, showInline: false }));
  if (response.isAxiosError === undefined) {
    let officeID = getStorage('token');
    let token = getStorage('ag_it_tenant');
    yield put(AuthAction({ token, officeID }));
  } else {
    let message;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
}

function* getTenantFN(action) {
  yield put(setLoading({ showAllPage: false, showInline: true }));
  const response = yield call(Account.requestTenant, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response === null) {
    yield put(setNotification({ show: true, content: 'Tenant not found', type: MessageType.Error }));
    yield put(setLoading({ showAllPage: false, showInline: false }));
  } else {
    if (response.isAxiosError === undefined) {
      yield put(setTenant(response.id));
      yield put(setTenantName(response.name));
      yield call(Account.setTenant, response.id);
      yield put(setLoading({ showAllPage: false, showInline: false }));
    } else {
      let message;
      response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
      yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
      yield put(setLoading({ showAllPage: false, showInline: false }));
    }
  }
}

function* getPermissionsFN() {
  const response = yield call(getPermissionsHttp);
  if (response.isAxiosError !== undefined) {
    throw new Error('Failed on getting permissions.');
  } else {
    yield put(setPermissions(response));
  }
}

export function* loginUser() {
  yield takeEvery(UserActions.Login, loginUserFn);
}

export function* authentication() {
  yield takeEvery(UserActions.Authentication, authUserFN);
}

export function* sendRecoverEmail() {
  yield takeEvery(UserActions.SendRecoverEmail, sendRecoverEmailFN);
}

export function* changePassword() {
  yield takeEvery(UserActions.ChangePassword, changePasswordFN);
}

export function* logoutUser() {
  yield takeEvery(UserActions.Logout, logoutUserFn);
}

export function* checkCurrency() {
  yield takeEvery(UserActions.ChangeCurrency, checkCurrencyFN);
}

export function* resetPassword() {
  yield takeEvery(UserActions.ResetPassword, resetPasswordFN);
}

export function* updateUser() {
  yield takeEvery(UserActions.UpdateUser, updateUserFN);
}

export function* getTenant() {
  yield takeEvery(UserActions.GetTenant, getTenantFN);
}

export function* getPermissions() {
  yield takeLatest(UserActions.GetPermissions, getPermissionsFN);
}
