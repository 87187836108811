import { push } from 'connected-react-router';
//import library
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { setSelectedFlight } from '../Actions/FlightAction';
import { IssuePNR } from '../Actions/FlightAction';
import { book, getHotelSelected } from '../Actions/HotelAction';
import { setLoading, setNotification } from '../Actions/NotificationAction';
import {
  bookErrorMessage,
  setBookErrorMessage,
  setBookStatus,
  setDepositBalance,
  setDeposits,
  setDepositsGateways,
  setPaymentList,
  setReserveInformation,
} from '../Actions/PaymentAction';
import Flight from '../APIs/Flight';
import Payment from '../APIs/Payment';
//import actionType and action
import { PaymentActions } from '../Enums/ActionTypes';
import { Urls } from '../Enums/APIAddress';
import { bookStatus } from '../Enums/Types';
import { bookStatus as BookStatus, MessageType } from '../Enums/Types';
import queryString from 'query-string';

//fetch general function
function* getReserveByIDFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  const response = yield call(Payment.getReserveID, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setReserveInformation(response));
    if (response.type === 'HotelGlobal') {
      yield put(getHotelSelected(action.input));
    }
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

function* getPaymentListFN(action) {
  //clear model
  yield put(setPaymentList(null));
  const response = yield call(Payment.getPaymentList, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setPaymentList(response));
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
}

function* setPaymentInformationFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  const response = yield call(Payment.sendPaymentMethod, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    window.location.href = `${Urls.Type}${Urls.Payment}/en/Reserves/Payment/PaymentRequest?tokenId=${response.tokenId}`;
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

function* getDepositsFN() {
  let permissions;
  for (let i = 0; i < 100; i++) {
    yield delay(500);
    permissions = yield select((state) => state.User.permissions);
    if (permissions && permissions.length > 0) {
      break;
    }
  }
  if (permissions.includes('UseDeposit')) {
    const response = yield call(Payment.getDeposits);
    //clear model
    yield put(setDeposits(null));
    yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
    if (response.isAxiosError === undefined) {
      yield put(setDeposits(response));
    } else {
      let message = null;
      response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
      yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
    }
  }
}

function* getDepositsGatewaysFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  //clean model before get
  yield put(setDepositsGateways(null));
  const response = yield call(Payment.getDepositsGateway, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setDepositsGateways(response));
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

function* setDepositInformationFN(action) {
  yield put(setLoading({ showAllPage: true, showInline: false }));
  const response = yield call(Payment.sendDepositMethod, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    window.location.href = `${Urls.Type}${Urls.Payment}/en/Deposit/Payment/PaymentRequest?tokenId=${response.tokenId}`;
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
}

function* getDepositBalanceFN(action) {
  yield put(setDepositBalance(null));
  const response = yield call(Payment.getDepositsBalance, action.input);
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setDepositBalance(response));
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
}

function* confirmDepositFN(action) {
  yield put(setBookStatus(BookStatus.process));
  const response = yield call(Payment.confirmDeposit, action.input);
  if (response.status === 200) {
    //first we detect Flight or Hotel
    let getParam = new URLSearchParams();
    getParam.append('reserveId', action.input.reserveId);
    getParam.append('username', action.input.username);
    const type = yield call(Payment.getReserveID, getParam);
    if (type.type === 'HotelGlobal') {
      //book hotel;
      yield put(push(`/hotel/confirm?reserveId=${action.input.reserveId}&username=${action.input.username}&deposit=true`));
      // const bookStatus = yield call(Hotel.book, action.input);
      // if (bookStatus.isAxiosError === undefined) {
      //     yield put(setBookStatus(BookStatus.done))
      // } else {
      //     yield call(Payment.refundDeposit, action.input);
      //     yield put(setBookStatus(BookStatus.error));
      // }
    } else if (type.type === 'FlightGlobal') {
      //issue Flight
      const flightPNRStatus = yield call(Flight.getReserveID, getParam);
      if (flightPNRStatus.isAxiosError === undefined) {
        if (flightPNRStatus.paymentBeforePNR) {
          //reserve hasn't PNR and It must be Booked
          const response = yield call(Flight.GetDirectTicket, getParam);
          if (response.isAxiosError === undefined) {
            let bookStatus;
            switch (response) {
              case 0:
                bookStatus = BookStatus.done;
                break;
              default:
              case 1:
              case 2:
                bookStatus = BookStatus.error;
                break;
              case 3:
                bookStatus = BookStatus.inProgress;
                break;
            }
            yield put(setBookStatus(bookStatus));
            if (bookStatus === BookStatus.inProgress) {
              for (let i = 0; i < 100; i++) {
                yield delay(10000);
                const res = yield call(Flight.GetFlightByReserveID, `reserveId=${action.input.reserveId}&username=${action.input.username}`);
                if (res.reserveStatus === 'Issued') {
                  yield put(setBookStatus(BookStatus.done));
                  break;
                } else if (res.reserveStatus !== 'InProgress') {
                  yield put(setBookStatus(BookStatus.error));
                  break;
                }
              }
            }
          } else {
            yield call(Payment.refundDeposit, action.input);
            yield put(setBookStatus(BookStatus.error));
          }
        } else {
          if (flightPNRStatus.isBookNowRequested) {
            yield put(setBookStatus(BookStatus.now));
          } else {
            //reserve has PNR and it must be issued
            const response = yield call(Flight.IssuePNR, `reserveId=${action.input.reserveId}&username=${action.input.username}`);
            if (response.isAxiosError === undefined) {
              yield put(setBookStatus(BookStatus.done));
              window.location.href = `${Urls.Type}${Urls.Local.Url}/flight/ticketing?pnrCode=${response.pnrCode}&supplierId=${response.supplierId}`;
            } else {
              yield call(Payment.refundDeposit, action.input);
              yield put(setBookErrorMessage(response.response?.data?.error?.message));
              yield put(setBookStatus(BookStatus.error));
            }
          }
        }
      } else {
        yield call(Payment.refundDeposit, action.input);
        yield put(setBookStatus(bookStatus.error));
      }
    }
  } else {
    yield put(setBookStatus(BookStatus.error));
    yield call(Payment.refundDeposit, action.input);
  }
}

function* confirmPaymentFN(action) {
  //create param for get request
  let param = new URLSearchParams();
  param.set('reserveId', action.input.reserveId);
  param.set('username', action.input.username);
  //set book status to idle
  yield put(setBookStatus(bookStatus.idle));
  //a-inside function
  yield put(setLoading({ showAllPage: true, showInline: false }));
  const response = yield call(Payment.getReserveID, param);

  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (response.isAxiosError === undefined) {
    yield put(setReserveInformation(response));
    if (response.type === 'HotelGlobal') {
      yield put(getHotelSelected(param));
    } else if (response.type === 'FlightGlobal') {
      const flightResponse = yield call(Flight.GetFlightByReserveID, param);
      yield put(setSelectedFlight(flightResponse));
    }
  } else {
    let message = null;
    response.response ? (message = response.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  yield put(setLoading({ showAllPage: false, showInline: false }));
  //a-inside function
  //get deposit information
  //b-inside function
  yield put(setDepositBalance(null));
  const responseD = yield call(Payment.getDepositsBalance, queryString.stringify({ReserveId:action.input.reserveId,Username:response?.username !==undefined?response.username:action.input.username}));
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (responseD.isAxiosError === undefined) {
    yield put(setDepositBalance(responseD));
  } else {
    let message = null;
    responseD.response ? (message = responseD.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  //b-inside function
  //c-inside function
  yield put(setPaymentList(null));
  const responseE = yield call(Payment.getPaymentList,queryString.stringify({reserveId:action.input.reserveId}));
  yield put(setNotification({ show: false, content: '', type: MessageType.Error }));
  if (responseE.isAxiosError === undefined) {
    yield put(setPaymentList(responseE));
  } else {
    let message = null;
    responseE.response ? (message = responseE.response.data.error.message) : (message = 'Unknown Error,Please check your internet connection');
    yield put(setNotification({ show: true, content: message, type: MessageType.Error }));
  }
  //c-inside function
  const reserverInformation = yield select((state) => state.Payment.reserverInformation);
  let status = parseInt(action.input.status);
  if (status > 0) {
    yield put(setBookStatus(bookStatus.process));
    if (reserverInformation.type === 'HotelGlobal') {
      yield put(book({ reserveId: action.input.reserveId, username: action.input.username }));
    } else if (reserverInformation.type === 'FlightGlobal') {
      //yield put(IssuePNR(`reserveId=${action.input.reserveId}`))
      yield put(IssuePNR(param));
    }
  } else if (status === 0) {
    yield put(setBookStatus(bookStatus.error));
  } else {
    yield put(setBookStatus(bookStatus.idle));
  }
}

//flow function
export function* getReserveByID() {
  yield takeEvery(PaymentActions.getReserveByID, getReserveByIDFN);
}

export function* getPaymentList() {
  yield takeEvery(PaymentActions.getPaymentInformation, getPaymentListFN);
}

export function* setPaymentInformation() {
  yield takeEvery(PaymentActions.setPaymentMethod, setPaymentInformationFN);
}

export function* getDeposits() {
  yield takeEvery(PaymentActions.getDeposits, getDepositsFN);
}

export function* getDepositsGateways() {
  yield takeEvery(PaymentActions.getDepositsGateways, getDepositsGatewaysFN);
}

export function* setDepositInformation() {
  yield takeEvery(PaymentActions.setDepositMethod, setDepositInformationFN);
}

export function* getDepositBalance() {
  yield takeEvery(PaymentActions.getDepositBalance, getDepositBalanceFN);
}

export function* confirmDeposit() {
  yield takeEvery(PaymentActions.confirmDeposit, confirmDepositFN);
}

export function* confirmPayment() {
  yield takeEvery(PaymentActions.confirmPayment, confirmPaymentFN);
}
