import { Button, Col, DatePicker, Form, Input, Modal, Result, Row, Select, Skeleton, Spin } from 'antd';
import moment from 'moment';
//import library
import React from 'react';
import { connect } from 'react-redux';

import { changeCurrency } from '../../../../Actions/AccountAction';
import PaymentApi from '../../../../APIs/Payment';
//import model and action
import { Currency } from '../../../../Enums/Types';

class ManualReceiptForm extends React.Component {
  state = {
    transactionNumber: null,
    amount: 0,
    currencyType: null,
    bankName: null,
    accountName: null,
    accountNumber: null,
    bankAccountId: null,
    transferTime: null,
    id: 0,
    modalVisibility: false,
    modalStatus: '',
    modalTitle: '',
    modalDescriptions: '',
    showHint: false,
    bankListLoading: true,
    selectedBankAccountId: null,
  };
  openHintModal = () => {
    this.setState({
      showHint: true,
    });
  };
  closeHintModal = () => {
    this.setState({
      showHint: false,
    });
  };
  //openModal
  openModal = (option) => {
    this.setState({
      modalVisibility: true,
      modalStatus: option.status,
      modalTitle: option.title,
      modalDescriptions: option.description,
    });
  };
  //closeModal
  closeModal = () => {
    this.setState({
      modalVisibility: false,
      modalStatus: '',
      modalTitle: '',
      modalDescriptions: '',
    });
  };
  changeBankAccount = (value) => {
    let selectedItem;
    selectedItem = this.state.bankAccountId.find((item) => item.id === value);
    this.setState({
      selectedBankAccountId: selectedItem,
    });
  };
  async componentDidMount() {
    const response = await PaymentApi.getAccountNumbers();
    this.setState({
      bankAccountId: response.data.result.items,
      bankListLoading: false,
      selectedBankAccountId: response.data.result.items[0],
    });
  }
  async sendFormData(param) {
    this.openModal({
      status: 'info',
      title: 'Sending Data ...',
      description: 'The information is sending, please wait.',
    });
    const formResponse = await PaymentApi.CreateManualReceipt(param);
    this.props.form.resetFields();
    if (formResponse.data.success) {
      this.openModal({
        status: 'success',
        title: 'Success',
        description: 'The information was successfully sent',
      });
    } else {
      this.openModal({
        status: 'error',
        title: 'Error',
        description: formResponse.statusText,
      });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      } else {
        fieldsValue.transferTime = moment(fieldsValue.transferTime).format('YYYY-MM-DD hh:mm');
        fieldsValue.type = 1;
        this.sendFormData(fieldsValue);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Modal visible={this.state.modalVisibility} footer={null} closable={false} className={'higher-z-index'}>
          <Result
            icon={this.state.modalStatus === 'info' ? <Spin /> : null}
            status={this.state.modalStatus}
            title={this.state.modalTitle}
            subTitle={this.state.modalDescriptions}
            extra={
              this.state.modalStatus === 'info' ? null : (
                <Button htmlType="button" type={'primary'} onClick={this.closeModal}>
                  Ok
                </Button>
              )
            }
          />
        </Modal>
        <Form onSubmit={this.handleSubmit}>
          {this.state.bankListLoading ? (
            <Skeleton active paragraph={{ rows: 1 }} title={{ width: '100%' }} />
          ) : (
            <p>
              You can deposit your desired amount to account number <b> {this.state.selectedBankAccountId.accountNumber} </b>
              with holder name <b> {this.state.selectedBankAccountId.accountOwnerName} </b>
              in Bank <b> {this.state.selectedBankAccountId.bank.name} </b> and then send information to us via the form below.
            </p>
          )}
          <div>
            <Row gutter={15}>
              <Col xs={8} md={6}>
                <Form.Item label="Currency">
                  {getFieldDecorator('currencyType', {
                    initialValue: this.props.currency,
                  })(
                    <select
                      onChange={(e) => {
                        this.props.changeCurrency(e.target.value);
                        window.location.reload();
                      }}>
                      {Currency.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.value}
                        </option>
                      ))}
                    </select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={16} md={18}>
                <Form.Item label="Amount">
                  {getFieldDecorator('amount', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter Amount',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Form.Item label="Transaction Number">
            {getFieldDecorator('TransactionNumber', {
              rules: [
                {
                  required: true,
                  message: 'Please enter Transaction Number',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Bank Brand">
            {getFieldDecorator('bankBrand', {
              rules: [
                {
                  required: true,
                  message: 'Please enter Bank Brand',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Holder Name">
            {getFieldDecorator('holderName', {
              rules: [
                {
                  required: true,
                  message: 'Please enter Holder Name',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Account Number">
            {getFieldDecorator('AccountNumber', {
              rules: [
                {
                  required: true,
                  message: 'Please enter Account Number',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Bank Account Id">
            {getFieldDecorator('BankAccountId', {
              rules: [
                {
                  required: true,
                  message: 'Please enter Bank Account Id',
                },
              ],
            })(
              this.state.bankListLoading ? (
                <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />
              ) : this.state.bankAccountId && this.state.bankAccountId.length > 0 ? (
                <Select placeholder="Select a bank" onChange={this.changeBankAccount}>
                  {this.state.bankAccountId.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      <img className="bank-account-icon" alt={item.bank.name} src={item.bank.picturePath} />
                      {item.accountNumber} ({item.accountOwnerName})
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                'There is no Bank Account to choose!'
              )
            )}
          </Form.Item>
          <Form.Item label="Date and Time">
            {getFieldDecorator('dateAndTime', {
              rules: [
                {
                  required: true,
                  message: 'Please Select Date and Time',
                },
              ],
            })(<DatePicker className="full-width" showTime />)}
          </Form.Item>
          <div className="clearfix">
            {/* <Button type="link" htmlType="button" className="no-padding pull-left" onClick={this.openHintModal}><Icon type="info-circle" /> Payment Guide</Button> */}
            <Button type="primary" htmlType="submit" className="pull-right">
              Send
            </Button>
          </div>
        </Form>
        {/* hint modal: */}
        <div className={this.state.showHint ? 'itours-modal visible' : 'itours-modal'}>
          <div className="modal-overlay" onClick={this.closeHintModal} />
          <div className="modal-inner small-modal-inner">
            <div className="modal-header">Offline Payment Guide</div>
            <div className="modal-main">
              {/* %%% */}
              You can charge the desired amount and then withdraw it at any time on the site. To do this, you can desired card number by card or bank account, then send us the
              Deposit receipt information.
              {/* <img src={hintPicture} alt="payment hint" className="hint-image" /> */}
            </div>
            <div className="modal-footer">
              <Button htmlType="button" type={'primary'} onClick={this.closeHintModal}>
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const stateMapProp = (state) => ({
  currency: state.User.currency,
});
const MapDispatchToProp = (dispatch) => ({
  changeCurrency: (currency) => {
    dispatch(changeCurrency(currency));
  },
});

const WrappedForm = Form.create({ name: 'manualReceiptForm' })(ManualReceiptForm);
export default connect(stateMapProp, MapDispatchToProp)(WrappedForm);
