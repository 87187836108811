import queryString from 'querystring';

import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';

const HistorySearch = (props) => {
  let flightHistory = queryString.parse(props.flightHistory);
  let hotelHistory = queryString.parse(props.hotelHistory);
  return (
    <div className="recent-searches-holder">
      {(props.flightHistory !== 'null' || props.hotelHistory !== 'null') && <h5 className="normal-title">Your recent searches</h5>}
      <Row gutter={15}>
        {props.flightHistory !== 'null' && (
          <Col xs={24} sm={12}>
            <a href={props.flightHistory} target="_blank" rel="noopener noreferrer">
              <button className="margin-bottom-half recent-search type-flight">
                <div className="grey font-12">{flightHistory.airTripType}</div>

                {flightHistory.originNames && (
                  <div className="font-16">
                    {flightHistory.originNames.split(',')[0]} to {flightHistory.destinationNames.split(',')[0]}
                  </div>
                )}

                {flightHistory.departureDateTimes && (
                  <div className="blue">
                    {flightHistory.departureDateTimes.split('|').map((item, index) => {
                      return (
                        <span key={index}>
                          {' '}
                          {index === 0 ? null : ' - '} {moment(item, 'YYYY-MM-DD').format('ddd, D MMM')}{' '}
                        </span>
                      );
                    })}
                  </div>
                )}
                <div className="grey font-12">
                  {flightHistory.cabinClassCode} - {flightHistory.adult} adults {parseInt(flightHistory.child) > 0 && <span>{flightHistory.child} child</span>}{' '}
                  {parseInt(flightHistory.infant) > 0 && <span>{flightHistory.infant} infant</span>}
                </div>
              </button>
            </a>
          </Col>
        )}
        {props.hotelHistory !== 'null' && (
          <Col xs={24} sm={12}>
            <a href={props.hotelHistory} target="_blank" rel="noopener noreferrer">
              <button className="margin-bottom-half recent-search type-hotel">
                <div className="font-16">{hotelHistory.Location}</div>
                <div className="blue">
                  {/* {hotelHistory.CheckIn} - {hotelHistory.CheckOut} */}
                  {/* %%% */}
                  {moment(hotelHistory.CheckIn, 'YYYY-MM-DD').format('ddd, D MMM')} - {moment(hotelHistory.CheckOut, 'YYYY-MM-DD').format('ddd, D MMM')}
                </div>

                {/* %%% */}
                {hotelHistory.Adults && (
                  <div className="grey font-12">
                    {isNaN(hotelHistory.Adults) ? hotelHistory.Adults.length - 1 : 1} room - {hotelHistory.Adults} adult, {hotelHistory.Children} children
                  </div>
                )}
              </button>
            </a>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default HistorySearch;
