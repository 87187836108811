import { Icon, Result, Steps } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import HotelApi from '../../../../APIs/Hotel';
import PaymentApi from '../../../../APIs/Payment';

HotelConfirmPayment.propTypes = {};

function HotelConfirmPayment() {
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [currentStep, setCurrentStep] = useState(2);
  const [voucherIssued, setVoucherIssued] = useState(false);
  const [reserveId, setReserveId] = useState(undefined);
  const [username, setUsername] = useState(undefined);
  const [depositRefunded, setDepositRefunded] = useState(undefined);
  const [bookingFailed, setBookingFailed] = useState(false);

  const location = useLocation();
  const qsParams = queryString.parse(location.search);
  const payByDeposit = qsParams.deposit;

  const confirmBookState = () => {
    setPaymentStatus(true);
    setVoucherIssued(true);
    setCurrentStep(3);
  };

  const failedBookState = () => {
    setPaymentStatus(false);
    setVoucherIssued(false);
    setCurrentStep(2);
    setBookingFailed(true);
  };

  //Booking Hotel
  useEffect(() => {
    setReserveId(qsParams.reserveId);
    setUsername(qsParams.username);

    if (qsParams.status === '0') {
      setPaymentStatus(false);
    } else {
      setPaymentStatus(true);
      HotelApi.book({ username: qsParams.username, reserveId: qsParams.reserveId }).then((response) => {
        if (response.isAxiosError !== undefined) {
          setPaymentStatus(false);
          setBookingFailed(true);
        } else {
          if (response.status) {
            confirmBookState();
          } else {
            failedBookState();
          }
        }
      });
    }
  }, [location.search, qsParams.reserveId, qsParams.username, qsParams.status, payByDeposit]);

  //Refunding Deposit
  useEffect(() => {
    if (payByDeposit && bookingFailed) {
      PaymentApi.refundDeposit({ username: qsParams.username, reserveId: qsParams.reserveId }).then((response) => {
        if (response.isAxiosError === undefined) {
          setDepositRefunded(true);
        } else {
          setDepositRefunded(false);
        }
      });
    }
  }, [bookingFailed, payByDeposit, qsParams.reserveId, qsParams.username]);

  const ShowText = (props) => {
    return (
      <div>
        {props.text} {props.showButton && <Link to={'/tracking/reserves'}>Reserves list</Link>}
      </div>
    );
  };

  const DepositRefundedMessage = () => {
    return (
      <>
        {payByDeposit ? (
          depositRefunded === undefined ? (
            <div className="margin-bottom-light">Refunding deposit. Please wait...</div>
          ) : depositRefunded === true ? (
            <div className="green margin-bottom-light">Your deposit has been refunded.</div>
          ) : (
            <div className="red margin-bottom-light">Refunding deposit has been failed. Please contact iTours directly : +47 22112744</div>
          )
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className="container vr-padding">
        <div className={'vr-padding margin-bottom'}>
          <Steps current={currentStep}>
            <Steps.Step title="Choose your room" />
            <Steps.Step title="Enter your details" />
            <Steps.Step title="Confirm and payment" />
            <Steps.Step title="Complete the purchase" />
          </Steps>
        </div>

        {paymentStatus ? (
          !voucherIssued ? (
            <Result
              status="success"
              icon={
                <div className="result-process-loading">
                  <Icon type="loading" />
                </div>
              }
              title="Your payment has been successful!"
              extra={
                <>
                  <div>Your ticket will be issued in a few moments...</div>
                  <div className="red">Please do not close this page until the ticket is issued.</div>
                  <div>For questions regarding this reservation, please contact iTours directly : +47 22112744</div>
                </>
              }
            />
          ) : (
            <Result
              status="success"
              title="Your ticket was successfully issued!"
              subTitle="We wish you a happy and memorable trip"
              extra={
                <>
                  <strong className="font-22 margin-bottom">Your reserve ID: {reserveId}</strong>
                  <div className="margin-bottom">
                    <a className="button white-btn margin-right-light" rel="noreferrer noopener" target="_blank" href={`/tracking/reserves/hotel?id=${reserveId}`}>
                      Details
                    </a>
                    <a
                      className="button green-btn margin-right-light"
                      rel="noreferrer noopener"
                      target="_blank"
                      href={`https://voucher.imt.as/en/Hotel/Voucher?reserveId=${reserveId}&username=${username}`}>
                      Voucher
                    </a>
                  </div>
                  <div className="margin-bottom-light">
                    <ShowText text={<span>You can check your reserves list in </span>} showButton />
                  </div>
                  <div>For questions regarding this reservation, please contact iTours directly : +47 22112744</div>
                </>
              }
            />
          )
        ) : (
          <Result
            status="error"
            title="Payment operation failed"
            subTitle={`Try again. You can call (+47) 22112744 for more tracing. Your reserve ID: ${reserveId}`}
            extra={
              <>
                <DepositRefundedMessage />
                <Link to={`/payment/choice?reserveId=${reserveId}&username=${username}`}>
                  <button className="button blue-btn">Back to Payment</button>
                </Link>
              </>
            }
          />
        )}
      </div>

      {process.env.REACT_APP_Environment === 'development' ? (
        <>
          <div className="margin-top-light">
            <button
              onClick={() => {
                setCurrentStep(2);
                setPaymentStatus(true);
                setVoucherIssued(false);
              }}>
              Step 3
            </button>
            <button
              onClick={() => {
                setCurrentStep(3);
                setPaymentStatus(true);
                setVoucherIssued(true);
              }}>
              Step4 - Complete
            </button>
            <button
              onClick={() => {
                setCurrentStep(2);
                setPaymentStatus(false);
              }}>
              Fail Payment
            </button>
            <button
              onClick={() => {
                setCurrentStep(2);
                setPaymentStatus(false);
                setDepositRefunded(false);
              }}>
              Fail Payment - Deposit - Not refunded
            </button>
            <button
              onClick={() => {
                setCurrentStep(2);
                setPaymentStatus(false);
                setDepositRefunded(true);
              }}>
              Fail Payment - Deposit - Refunded
            </button>
          </div>
        </>
      ) : null}
    </>
  );
}

export default HotelConfirmPayment;
