import { Button, Card, Checkbox, Form, Icon } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import { updateUser } from '../../../../Actions/AccountAction';

class Newsletter extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        value.gender = this.props.userInformation.gender;
        value.firstName = this.props.userInformation.firstName;
        value.lastName = this.props.userInformation.lastName;
        value.birthDay = this.props.userInformation.birthDay && this.props.userInformation.birthDay;
        value.nationalityId = this.props.userInformation.nationalityId;
        this.props.updateUser({ ...value });
        this.setState({
          disableSubmit: true,
        });
      }
    });
  };
  state = {
    gender: this.props.userInformation.gender,
    firstName: this.props.userInformation.firstName,
    lastName: this.props.userInformation.lastName,
    birthDay: this.props.userInformation.birthDay && this.props.userInformation.birthDay,
    nationalityId: this.props.userInformation.nationalityId,
    isNewsLater: this.props.userInformation.isNewsLater,
    disableSubmit: true,
  };
  checkChange = (e) => {
    if (e.target.checked !== this.props.userInformation.isNewsLater) {
      this.setState({ disableSubmit: false });
    } else {
      this.setState({ disableSubmit: true });
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Card
          className="margin-bottom"
          title={
            <>
              <Icon type="read" /> Subscription
            </>
          }>
          <p>*Unsubscription is possible at any time.</p>
          <Form onSubmit={this.handleSubmit} className="editInformation-form">
            <Form.Item>
              {getFieldDecorator('isNewsLater', { initialValue: this.state.isNewsLater, valuePropName: 'checked' })(
                <Checkbox onChange={this.checkChange}>I want to subscribe to Itours newsletter to receive news and promotions.</Checkbox>
              )}
            </Form.Item>
            <Button disabled={this.state.disableSubmit} type="primary" htmlType="submit">
              Apply
            </Button>
          </Form>
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInformation: state.User,
});
const mapDispatchToProps = (dispatch) => ({
  updateUser: (param) => {
    dispatch(updateUser(param));
  },
});
const WrappedEditInformationForm = Form.create({ name: 'editInformation-form' })(Newsletter);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedEditInformationForm);
