import numeral from 'numeral';
import React from 'react';

export default class Price extends React.PureComponent {
  static defaultProps = {
    currency: 'USD',
  };

  state = {
    price: null,
  };

  checkCurrency = () => {
    let priceValue = this.props.children;
    if (priceValue) {
      let price;
      if (this.props.currency === 'NOK' || this.props.currency === 'SEK' || this.props.currency === 'DKK') {
        price = numeral(priceValue).format('0,0').replace(/,/g, ' ');
      } else {
        price = numeral(priceValue).format('0,0');
      }
      this.setState({
        price: price,
      });
    } else {
      this.setState({
        price: 0,
      });
    }
  };

  componentDidMount() {
    this.checkCurrency();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.checkCurrency();
    }
  }

  render() {
    return (
      <>
        <span className="price">
          <span className="value">{this.state.price}</span> <span className="currency">{this.props.currency}</span>
        </span>
      </>
    );
  }
}
