import { Button, Card, Col, DatePicker, Form, Icon, Input, Radio, Row, Select } from 'antd';
import moment from 'moment';
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { connect } from 'react-redux';

import { updateUser } from '../../../../Actions/AccountAction';
import AccountApi from '../../../../APIs/Account';
import { CountryCode } from '../../../../Enums/Types';
import VerifyMobileNumber from './verifyMobileNumber';

//import model and action

class ChangeInformation extends React.Component {
  phoneNumberElement = React.createRef();
  state = {
    title: this.props.userInformation.gender,
    firstName: this.props.userInformation.firstName,
    lastName: this.props.userInformation.lastName,
    birthday: this.props.userInformation.birthDay && this.props.userInformation.birthDay,
    nationalityId: this.props.userInformation.nationalityId,
    isNewsLater: this.props.userInformation.isNewsLater,
    phoneNumber: this.props.userInformation.phoneNumber,
    editMode: false,
    isEdited: false,
    showVerificationModal: false,
    isPhoneNumberConfirmed: false,
    sendingVerificationSms: false,
  };

  getFullNumber = () => {
    let phoneNumber;
    const phoneNumberInput = this.phoneNumberElement.current.state.value;
    const dialCode = this.phoneNumberElement.current.state.dialCode;
    if (phoneNumberInput.startsWith(dialCode)) {
      phoneNumber = phoneNumberInput.substring(dialCode.length, phoneNumberInput.length);
    } else {
      phoneNumber = phoneNumberInput;
    }
    phoneNumber = dialCode.concat(phoneNumber);
    phoneNumber = phoneNumber.replace(/\s/g, '');
    return phoneNumber;
  };
  activeEdit = () => {
    this.setState({
      editMode: true,
    });
  };
  deactiveEdit = () => {
    this.setState({
      editMode: false,
    });
  };

  async SendVerificationCode() {
    if (!this.state.sendingVerificationSms) {
      this.setState({ sendingVerificationSms: true });
      await AccountApi.sendVerificationSms({ phoneNumber: this.state.phoneNumber });
      this.setState({ sendingVerificationSms: false });
    }
  }
  async checkVerification() {
    const response = await AccountApi.CheckVerificationStatus();
    this.setState({
      isPhoneNumberConfirmed: response.data.result.isPhoneNumberConfirmed,
    });
  }
  cancelAndReload = () => {
    this.closePhoneNumberVerification();
    this.checkVerification();
  };
  openPhoneNumberVerification = () => {
    this.setState({
      showVerificationModal: true,
    });
    this.SendVerificationCode();
  };
  resendSms = () => {
    this.SendVerificationCode();
  };
  closePhoneNumberVerification = () => {
    this.setState({
      showVerificationModal: false,
    });
  };
  changeNumber = () => {
    let currentNumber = this.getFullNumber();
    if (currentNumber !== this.props.userInformation.phoneNumber) {
      this.setState({
        isEdited: true,
        phoneNumber: currentNumber,
      });
    }
  };
  componentDidMount() {
    this.checkVerification();
  }
  componentDidUpdate() {
    //phone input only allow numbers,back,delete,and right & left arrow
    const InputElement = document.getElementById('phoneInputId');
    if (InputElement) {
      InputElement.addEventListener('keydown', (e) => {
        var pattern = new RegExp(/[0-9]/g);
        var checkResult = pattern.test(e.key);
        if (!checkResult && e.which !== 8 && e.which !== 46 && e.which !== 37 && e.which !== 39) {
          e.preventDefault();
        }
      });
      InputElement.addEventListener('change', (e) => {
        this.setState({
          isEdited: true,
        });
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        value.phoneNumber = this.getFullNumber();
        this.props.updateUser({ ...value });
        this.setState({
          isEdited: false,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const { userInformation } = this.props;
    let plusPhoneNumber;
    if (this.state.phoneNumber.includes('+')) {
      plusPhoneNumber = this.state.phoneNumber;
    } else {
      plusPhoneNumber = '+' + this.state.phoneNumber;
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <>
        <Card
          className="margin-bottom"
          title={
            <>
              <Icon type="user" /> Edit your account
            </>
          }>
          <Form {...formItemLayout} onSubmit={this.handleSubmit} className="editInformation-form">
            <Row gutter={15}>
              <Col xs={24}>
                <Form.Item>
                  {getFieldDecorator('gender', {
                    initialValue: this.props.userInformation.gender,
                  })(
                    <Radio.Group
                      onChange={() => {
                        this.setState({ isEdited: true });
                      }}>
                      <Radio value={true}>Male</Radio>
                      <Radio value={false}>Female</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item label="First name">
                  {getFieldDecorator('firstName', {
                    initialValue: this.state.firstName,
                    rules: [{ required: true, message: 'Please input your first name' }],
                  })(
                    <Input
                      prefix={<Icon type="user" className={'user'} />}
                      onChange={() => {
                        this.setState({ isEdited: true });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item label="Last name">
                  {getFieldDecorator('lastName', {
                    initialValue: this.state.lastName,
                    rules: [{ required: true, message: 'Please input your first name' }],
                  })(
                    <Input
                      prefix={<Icon type="user" className={'user'} />}
                      onChange={() => {
                        this.setState({ isEdited: true });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item label="Nationality">
                  {getFieldDecorator('nationalityId', {
                    initialValue: this.state.nationalityId,
                    rules: [{ required: true, message: 'Please input your nationality' }],
                  })(
                    <Select
                      showSearch
                      className="full-width"
                      onChange={() => {
                        this.setState({ isEdited: true });
                      }}>
                      {CountryCode.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.code}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item label="Birthday">
                  {getFieldDecorator('birthDay', {
                    initialValue: this.props.userInformation.birthDay && moment(this.props.userInformation.birthDay),
                    rules: [{ required: true, message: 'Please select your birthday' }],
                  })(
                    <DatePicker
                      className="full-width"
                      onChange={(e) => {
                        this.setState({ isEdited: true });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item label="Email - LoginName">
                  {getFieldDecorator('userName', {
                    initialValue: userInformation.userName,
                  })(<Input disabled={true} readOnly={true} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item label="Mobile Number">
                  <IntlTelInput
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    fieldName={'phoneNumber'}
                    placeholder={'Mobile number'}
                    defaultValue={userInformation.phoneNumber === null ? '' : plusPhoneNumber}
                    useMobileFullscreenDropdown={true}
                    ref={this.phoneNumberElement}
                    fieldId="phoneInputId"
                    separateDialCode={true}
                    onPhoneNumberBlur={this.changeNumber}
                    onSelectFlag={this.changeNumber}
                    disabled={true}
                    allowDropdown={true}
                  />
                  {this.state.isPhoneNumberConfirmed ? (
                    <span className="verification-status green">
                      <Icon type="check" /> Verified
                    </span>
                  ) : (
                    <span className="verification-status orange">
                      <Icon type="warning" /> Not verified yet
                    </span>
                  )}
                </Form.Item>
                {!this.state.isPhoneNumberConfirmed && (
                  <div className="verification-hint">
                    Please confirm your mobile number by entering the code we will send to you via SMS.
                    <Button htmlType="button" type="link" onClick={this.openPhoneNumberVerification}>
                      SEND SMS
                    </Button>
                  </div>
                )}
              </Col>

              {/* <div>
              <Form.Item label="User Role">
                {getFieldDecorator('userrole', {
                  initialValue: userInformation.roleNames,
                })(<Input disabled={true} readOnly={true} />)}
              </Form.Item>
            </div>*/}
              {/* <div>
              <Form.Item label="Country">
                {getFieldDecorator('country', {
                  initialValue: userInformation.nationalityId,
                })(
                  <select disabled={true}>
                    {Country.map((item, index) => (
                      <option key={index} value={item.Code}>
                        {item.Country}
                      </option>
                    ))}
                  </select>
                )}
              </Form.Item>
            </div>
            <div>
              <Form.Item label="City">{getFieldDecorator('city', {})(<Input disabled={true} />)}</Form.Item>
            </div>
            <div>
              <Form.Item label="Postal Code">{getFieldDecorator('postalCode', {})(<Input disabled={true} />)}</Form.Item>
            </div> */}
              {/* <div>
              <Form.Item label="Address">
                {getFieldDecorator('address', {
                  initialValue: null,
                })(<Input.TextArea className="full-width" disabled={true} />)}
              </Form.Item>
            </div> */}
              {/* <div>
              <Form.Item label="Telephone">{getFieldDecorator('telephone', {})(<Input disabled={true} />)}</Form.Item>
            </div>

            <div>
              <Form.Item label="Language">
                {getFieldDecorator(
                  'nationalityId',
                  {}
                )(
                  <select className="full-width" disabled={true}>
                    <option>English</option>
                  </select>
                )}
              </Form.Item>
            </div> */}

              {/* <Form.Item label="Remark">
                {getFieldDecorator(
                  'remark',
                  {}
                )(
                  <Input.TextArea className="full-width" disabled={true}>
                    <option>English</option>
                  </Input.TextArea>
                )}
              </Form.Item> */}
              {/* {this.state.editMode ? (
                <>
                  <Button htmlType={'button'} className="margin-right" onClick={this.deactiveEdit}>
                    Cancel
                  </Button>
                  <Button loading={this.props.showInlineLoad} type="primary" htmlType={'submit'}>
                    Save changes
                  </Button>
                </>
              ) : (
                <Button type="primary" htmlType={'button'} onClick={this.activeEdit}>
                  Edit informations
                </Button>
              )} */}
              <Col xs={24}>
                {/* <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 16, offset: 8 },
                }}
                > */}
                <Button disabled={!this.state.isEdited} className="margin-top" type="primary" htmlType="submit">
                  Save changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {/* <Form onSubmit={this.handleSubmit} className="editInformation-form">
          <Card className="min-height-400">
            <Row gutter={15}>
              <Col xs={24} lg={20} xl={18}>
                <Form.Item>
                  {getFieldDecorator('isNewsLater', {
                    initialChecked: this.state.isNewsLater,
                  })(<Checkbox disabled={this.state.editMode ? false : true}>Subscribe new offers</Checkbox>)}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form> */}

        <div className={this.state.showVerificationModal ? 'itours-modal visible' : 'itours-modal'}>
          <div className="modal-overlay" onClick={this.closePhoneNumberVerification} />
          <div className="modal-inner max-width-600">
            <div className="modal-header">Confirm your mobile number</div>
            <div className="modal-main">
              {this.state.showVerificationModal ? (
                <VerifyMobileNumber
                  cancelAndReload={this.cancelAndReload}
                  phoneNumber={this.state.phoneNumber}
                  resendSms={this.resendSms}
                  cancel={this.closePhoneNumberVerification}
                  sendingVerificationSms={this.state.sendingVerificationSms}
                />
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userInformation: state.User,
});
const mapDispatchToProps = (dispatch) => ({
  updateUser: (param) => {
    dispatch(updateUser(param));
  },
});
const WrappedEditInformationForm = Form.create({ name: 'editInformation-form' })(ChangeInformation);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedEditInformationForm);
