import { Card, Icon, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';

import NullImage from '../../../../Images/design/default-hotel-image.svg';
import { getCancellationStatusName } from '../../../../utils/enumConverter';
import Price from '../../../commond/Price';
import Rate from '../../../commond/Rate';

export default class SummaryHotel extends React.PureComponent {
  render() {
    return (
      <Card title="Booking Summary" className="light-padding">
        <div className="hotel-img-titles">
          {!this.props.model.accommodation.mainPhoto ? (
            <div className="hotel-image default-hotel-image" style={{ backgroundImage: `url(${NullImage})` }}></div>
          ) : (
            <div className="hotel-image  main-hotel-image" style={{ backgroundImage: `url(${this.props.model.accommodation.mainPhoto})` }}></div>
          )}
          <div className="hotel-name-star full-width">
            <h5 className="normal-title">{this.props.model.accommodation.name}</h5>
            <div className="hotelRange">{this.props.model.accommodation.rating && <Rate number={this.props.model.accommodation.rating} />}</div>
            <div className="addressFeild">
              <Icon type="environment" /> {this.props.model.accommodation.address}
            </div>
          </div>
        </div>

        <div className="pair-row">
          <span>Check-in</span>
          <Tooltip placement="top" title="Property local time">
            <b>{this.props.model.date.checkIn.split('T')[0]}</b> <Icon type="info-circle" />
          </Tooltip>
        </div>

        <div className="pair-row">
          <span>Check-out</span>
          <Tooltip placement="top" title="Property local time">
            <b>{this.props.model.date.checkOut.split('T')[0]}</b> <Icon type="info-circle" />
          </Tooltip>
        </div>

        <div className="pair-row">
          <span>Length of stay</span>
          <b>{this.props.night} night(s)</b>
        </div>

        {this.props.model.accommodation.rooms.map((item, index) => (
          <div className="clearfix border-top" key={index}>
            <div className="pull-left">
              <span className="blue"> {item.name} </span>
            </div>
            {/*<div className="pull-right" dir="ltr">*/}
            {/*    <strong><Price currency={this.props.currencyCode}>{item.salePrice}</Price></strong>*/}
            {/*</div>*/}
            <div className="clearfix">
              <ul className="bullet-list">
                <li>{item.board.name}</li>
              </ul>
            </div>
            <div className="clearfix">
              (<span className="inline-block">{item.adults} Adults</span>
              {item.children > 0 ? <span className="inline-block">, {item.children} Children </span> : null})
            </div>
          </div>
        ))}

        {this.props.model.cancellationPolicy ? (
          <div className="border-top">
            <Tag color={this.props.model.cancellationPolicy.status === 'Refundable' ? 'green' : 'red'}>
              <span className="font-14">{getCancellationStatusName(this.props.model.cancellationPolicy.status)}</span>
            </Tag>
            <ul className={'cancelation-rules-list'}>
              {this.props.model.cancellationPolicy.fees.map((fee) => {
                if (fee.amount === 0) {
                  return (
                    <li key={this.props.model.cancellationPolicy.fees.indexOf(fee)}>
                      Cancellation is <span className="green">FREE</span> before{' '}
                      <Tooltip className="no-wrap" placement="top" title={moment(fee.toDate).format('MM/DD/YYYY')}>
                        {moment(fee.toDate).format('DD MMM YYYY')}
                      </Tooltip>
                    </li>
                  );
                } else {
                  return (
                    <li key={this.props.model.cancellationPolicy.fees.indexOf(fee)}>
                      <Price currency={this.props.currencyCode}>{fee.amount}</Price> on and after date{' '}
                      <Tooltip className="no-wrap" placement="top" title={moment(fee.fromDate).format('MM/DD/YYYY')}>
                        {moment(fee.fromDate).format('DD MMM YYYY')}
                      </Tooltip>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        ) : null}

        <div className="border-top">
          <div className="pair-row red font-18">
            <b>Total price</b>
            <b>
              <span>
                {' '}
                <Price currency={this.props.currencyCode}>{this.props.model.salePrice}</Price>
              </span>
            </b>
          </div>

          {this.props.showButton && (
            <div className="confirmButton">
              <button className="button red-btn full-width" type="submit">
                Continue to payment
              </button>
            </div>
          )}
        </div>
      </Card>
    );
  }
}
