import 'react-image-lightbox/style.css';

import '../../CSS/gallery.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from 'react-image-lightbox';

class Gallery extends React.Component {
  static propTypes = {
    images: PropTypes.any.isRequired,
  };
  state = {
    currentImage: 1,
    lightboxIsOpen: false,
  };
  toggleModal = () => {
    this.setState((state) => ({ lightboxIsOpen: !state.lightboxIsOpen }));
  };
  openLightbox = (param) => {
    this.setState({
      currentImage: param,
      lightboxIsOpen: true,
    });
  };

  render() {
    const { currentImage, lightboxIsOpen } = this.state;
    let imagesSrc = [];
    this.props.images.forEach((item) => {
      imagesSrc.push(item.src);
    });
    return (
      <div className="galleryContainer">
        <div className="hotel-gallery">
          <div className="gallery-row relative">
            {this.props.images[0] == null ? null : (
              <div className="large-item">
                <div
                  className="image"
                  onClick={this.openLightbox.bind(this, 0)}
                  style={this.props.images[0] == null ? null : { backgroundImage: `url(${this.props.images[0].src})` }}
                />
              </div>
            )}
            {this.props.images[1] == null ? (
              <span className="absolute-map-link">
                <a className="button white-btn small-btn" href="#map_holder">
                  Show Map
                </a>
              </span>
            ) : (
              <div className="gallery-column">
                <div className="map-link">
                  <div className="image">
                    <a className="button white-btn small-btn" href="#map_holder">
                      Show Map
                    </a>
                  </div>
                </div>
                <div>
                  <div
                    className="image"
                    onClick={this.openLightbox.bind(this, 1)}
                    style={this.props.images[1] == null ? null : { backgroundImage: `url(${this.props.images[1].smallSrc})` }}
                  />
                </div>
                {this.props.images[2] == null ? null : (
                  <div className="">
                    <div
                      className="image"
                      onClick={this.openLightbox.bind(this, 2)}
                      style={this.props.images[2] == null ? null : { backgroundImage: `url(${this.props.images[2].smallSrc})` }}
                    />
                  </div>
                )}
                {this.props.images[3] == null ? null : (
                  <div className="">
                    <div
                      className="image"
                      onClick={this.openLightbox.bind(this, 3)}
                      style={this.props.images[3] == null ? null : { backgroundImage: `url(${this.props.images[3].smallSrc})` }}
                    />
                  </div>
                )}
              </div>
            )}
            {this.props.images[4] == null ? null : (
              <div className="gallery-column">
                <div className="">
                  <div
                    className="image"
                    onClick={this.openLightbox.bind(this, 4)}
                    style={this.props.images[4] == null ? null : { backgroundImage: `url(${this.props.images[4].smallSrc})` }}
                  />
                </div>
                {this.props.images[5] == null ? null : (
                  <div className="">
                    <div
                      className="image"
                      onClick={this.openLightbox.bind(this, 5)}
                      style={this.props.images[5] == null ? null : { backgroundImage: `url(${this.props.images[5].smallSrc})` }}
                    />
                  </div>
                )}
                {this.props.images[6] == null ? null : (
                  <div>
                    <div
                      className="image"
                      onClick={this.openLightbox.bind(this, 6)}
                      style={this.props.images[6] == null ? null : { backgroundImage: `url(${this.props.images[6].smallSrc})` }}
                    />
                  </div>
                )}
                {this.props.images[7] == null ? null : (
                  <div className="">
                    <div
                      className="image"
                      onClick={this.openLightbox.bind(this, 7)}
                      style={this.props.images[7] == null ? null : { backgroundImage: `url(${this.props.images[7].smallSrc})` }}>
                      {this.props.images && this.props.images.length > 7 ? (
                        <div className="hover">
                          <span className="more-images-text">More images</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {lightboxIsOpen ? (
          <Lightbox
            mainSrc={imagesSrc[currentImage]}
            nextSrc={imagesSrc[(currentImage + 1) % imagesSrc.length]}
            prevSrc={imagesSrc[(currentImage + imagesSrc.length - 1) % imagesSrc.length]}
            onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                currentImage: (currentImage + imagesSrc.length - 1) % imagesSrc.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currentImage: (currentImage + 1) % imagesSrc.length,
              })
            }
          />
        ) : null}
      </div>
    );
  }
}
export default Gallery;
